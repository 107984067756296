import React from 'react';

import { ApiRequest } from '@zeal/zeal-lib';
import { useQuery } from '../../App/QueryClient';
import { useQueryString } from '../../App/useQueryString';

async function apiRequests({ queryKey: [, filters] }) {
	//TODO: Story Api Integration
	// return new ApiRequest("v3/rewards")
	//   .addHeader("Accept", "application/json")
	//   .GET();

	console.warn('[API/dummy]', 'useRequests::apiRequests');

	return Promise.resolve([
		{
			uuid: Date.now.toString() + '1',
			title: 'sahel',
			type: 'branch1',
		},
		{
			uuid: Date.now.toString() + '2',
			title: 'sahel',
			type: 'branch2',
		},
		{
			uuid: Date.now.toString() + '3',
			title: 'sahel',
			type: 'branch3',
		},
		{
			uuid: Date.now.toString() + '4',
			title: 'sahel',
			type: 'branch4',
		},
	]);
}

export default function useRequests() {
	const q = useQuery({
		queryFn: apiRequests,
		staleTime: 1000 * 60 * 15, // 15 min
		queryKey: ['z-requests'],
	});

	React.useDebugValue(q);

	return q;
}
