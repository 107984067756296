import React from 'react';
import { numberSuffix }  from '@zeal/zeal-lib';
import { CardStat, InlineError, InlineLoading } from '@zeal/zeal-ui';

import useAnalyticsOverview from '../data/useAnalyticsOverview';

function StatsCards({ label, value, overview }) {
	return (
		<CardStat
			key={label}
			title={value}
			label={label}
			className="col-1"
			width="md"
		>
			<InlineError hide={!overview.isError} />
			<InlineLoading hide={!overview.isLoading} />
			{overview.isSuccess ? numberSuffix(value, value) : null}
		</CardStat>
	);
}

export default function Metrics() {
	const { data: vendorItem } = useAnalyticsOverview();

	let overview = useAnalyticsOverview();

	return (
		<>
			<StatsCards
				overview={overview}
				label="Total Customers"
				value={vendorItem?.total_customers}
			/>
			<StatsCards
				overview={overview}
				label="Redeemed Rewards"
				value={vendorItem?.redeemed_rewards}
			/>
			<StatsCards
				overview={overview}
				label="Transactions Value"
				value={vendorItem?.transactions_value}
			/>
			<StatsCards
				overview={overview}
				label="Average Spent"
				value={vendorItem?.avg_spent}
			/>
			<StatsCards
				overview={overview}
				label="Repeated Purchase Rate"
				value={vendorItem?.repeat_purchase}
			/>
			<StatsCards
				overview={overview}
				label="Total Transactions"
				value={vendorItem?.total_transactions}
			/>
		</>
	);
}
