import React from 'react';
import { useMutation } from 'react-query';

import { ApiRequest } from '@zeal/zeal-lib';
import { useQuery } from '../../App/QueryClient';

async function apiWelcomeReward({ queryKey: [, filters] }) {
	return new ApiRequest('v3/welcome-rewards')
		.addHeader('Accept', 'application/json')
		.addHeader('Content-Type', 'application/json')
		.GET()
		.then((data) => data || {})
		.catch((err) => ({}));
}

export default function useWelcomeReward() {
	const q = useQuery({
		queryFn: apiWelcomeReward,
		queryKey: ['z-welcome-reward'],
		staleTime: Infinity,
	});

	React.useDebugValue(q);

	return q;
}

async function apiUpdateWelcomeReward({ ...payload }) {
	return new ApiRequest(`v3/welcome-rewards/${payload?.rewardId}`)
		.addHeader('Accept', 'application/json')
		.addHeader('Content-Type', 'application/json')
		.PUT(JSON.stringify(payload));
}

export function useUpdateWelcomeReward(opt) {
	const mutation = useMutation(apiUpdateWelcomeReward);

	const { data, mutate, isSuccess, isError, error } = mutation;

	React.useEffect(() => {
		if (isError && opt.onError) {
			opt.onError(error);
		}
		if (!isSuccess) {
			return null;
		}

		if (opt.onSuccess) {
			opt.onSuccess();
		}
	}, [data, isSuccess, isError]);

	return {
		...mutation,
		doUpdateWelcomeReward: mutate,
	};
}

async function apiAddWelcomeReward({ ...payload }) {
	return new ApiRequest('v3/welcome-rewards')
		.addHeader('Accept', 'application/json')
		.addHeader('Content-Type', 'application/json')
		.POST(JSON.stringify(payload));
}

export function useAddWelcomeReward(opt) {
	const mutation = useMutation(apiAddWelcomeReward);

	const { data, mutate, isSuccess, isError, error } = mutation;

	React.useEffect(() => {
		if (isError && opt.onError) {
			opt.onError(error);
		}
		if (!isSuccess) {
			return null;
		}

		if (opt.onSuccess) {
			opt.onSuccess();
		}
	}, [data, isSuccess, isError]);

	return {
		...mutation,
		doAddWelcomeReward: mutate,
	};
}

async function apiDeleteWelcomeReward({ ...payload }) {
	return new ApiRequest(`v3/welcome-rewards/${payload?.uuid}`)
		.addHeader('Accept', 'application/json')
		.addHeader('Content-Type', 'application/json')
		.DELETE();
}

export function useDeleteWelcomeReward(opt) {
	const mutation = useMutation(apiDeleteWelcomeReward);

	const { data, mutate, isSuccess, isError, error } = mutation;

	React.useEffect(() => {
		if (isError && opt.onError) {
			opt.onError(error);
		}
		if (!isSuccess) {
			return null;
		}

		if (opt.onSuccess) {
			opt.onSuccess();
		}
	}, [data, isSuccess, isError]);

	return {
		...mutation,
		doDeleteWelcomeReward: mutate,
	};
}
