import React from 'react';
import { Toaster } from 'react-hot-toast';
import { Redirect, Route, Switch } from 'react-router-dom';

import RewardsRoutes from '../Rewards/Routes';
import AnnouncementsRoutes from '../Announcements/Routes';
import RecommendationsRoutes from '../Recommendations/Routes';
import GalleryRoutes from '../Gallery/Routes';
import FeedbackRoutes from '../Feedback/Routes';
import StoriesRoutes from '../Stories/Routes';
import AnalyticsRoutes from '../Analytics/Routes';
import TeamMembersRoutes from '../TeamMembers/Routes';

import CustomersRoutes from '../Records/Routes';
import SettingsRoutes from '../Settings/Routes';
import OrdersRoutes from '../Orders/Routes';
import PromocodesRoutes from '../Promocodes/Routes';
import ScheduleRoutes from '../Schedule/Routes';
import BranchesRoutes from '../Branches/Routes';
import MenuRoutes from '../Menu/Routes';
import ArticlesRoutes from '../Articles/Routes';
import ExportsRoutes from '../Exports/Routes';
import VisitTransactionsRoutes from '../VisitTransactions/Routes';

import Login from './Login/Login';
import Signup from './Signup/Signup';

export default function AppRoutes() {
	return (
		<React.Fragment>
			<Toaster />

			<Switch>
				<Route path="/login" component={Login} />

				<Route path="/signup" component={Signup} />

				<Route path="/rewards" component={RewardsRoutes} />

				<Route path="/announcements" component={AnnouncementsRoutes} />

				<Route path="/recommendations" component={RecommendationsRoutes} />

				<Route path="/customers" component={CustomersRoutes} />

				<Route path="/feedback" component={FeedbackRoutes} />

				<Route path="/analytics" component={AnalyticsRoutes} />

				<Route path="/gallery" component={GalleryRoutes} />

				<Route path="/stories" component={StoriesRoutes} />

				<Route path="/teams" component={TeamMembersRoutes} />

				<Route path="/visit-transactions" component={VisitTransactionsRoutes} />

				<Route path="/exports" component={ExportsRoutes} />

				<Route path="/settings" component={SettingsRoutes} />

				<Route path="/orders" component={OrdersRoutes} />

				<Route path="/promocodes" component={PromocodesRoutes} />

				<Route path="/schedule" component={ScheduleRoutes} />

				<Route path="/branches" component={BranchesRoutes} />

				<Route path="/menu" component={MenuRoutes} />

				<Route path="/articles" component={ArticlesRoutes} />

				<Route path="/">
					<Redirect push to="/rewards" />
				</Route>
			</Switch>
		</React.Fragment>
	);
}
