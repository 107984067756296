import React from 'react';
import { useMutation } from 'react-query';
import { useQuery } from '../../App/QueryClient';

import { ApiRequest } from '@zeal/zeal-lib';
import { useQueryString } from '../../App/useQueryString';

async function apiRoles({ queryKey: [, filters] }) {
	return new ApiRequest('v3/roles')
		.addHeader('Accept', 'application/json')
		.addHeader('Content-type', 'application/json')
		.addQuery('paginate', 'false')
		.GET();
}

async function apiAddRoles({ ...payload }) {
	return new ApiRequest('v3/roles')
		.addHeader('Accept', 'application/json')
		.addHeader('Content-type', 'application/json')
		.POST(JSON.stringify(payload));
}

async function apiEditRoles({ ...payload }) {
	return new ApiRequest(`v3/roles/${payload?.id}`)
		.addHeader('Accept', 'application/json')
		.addHeader('Content-type', 'application/json')
		.PUT(JSON.stringify(payload));
}

async function apiDeleteRoles({ ...payload }) {
	return new ApiRequest(`v3/roles/${payload.id}`)
		.addHeader('Accept', 'application/json')
		.addHeader('Content-type', 'application/json')
		.DELETE();
}

export function useAddRoles(opt) {
	const mutation = useMutation(apiAddRoles);

	const { data, mutate, isSuccess, isError, error } = mutation;

	React.useEffect(() => {
		if (isError && opt.onError) {
			opt.onError(error);
		}

		if (!isSuccess) {
			return null;
		}

		if (opt.onSuccess) {
			opt.onSuccess();
		}
	}, [data, isSuccess, isError]);

	return {
		...mutation,
		doAddRoles: mutate,
	};
}

export function useEditRoles(opt) {
	const mutation = useMutation(apiEditRoles);

	const { data, mutate, isSuccess, isError, error } = mutation;

	React.useEffect(() => {
		if (isError && opt.onError) {
			opt.onError(error);
		}

		if (!isSuccess) {
			return null;
		}

		if (opt.onSuccess) {
			opt.onSuccess();
		}
	}, [data, isSuccess, isError]);

	return {
		...mutation,
		doEditRoles: mutate,
	};
}

export function useDeleteRoles(opt) {
	const mutation = useMutation(apiDeleteRoles);

	const { data, mutate, isSuccess, isError, error } = mutation;

	React.useEffect(() => {
		if (isError && opt.onError) {
			opt.onError(error);
		}
		if (!isSuccess) {
			return null;
		}

		if (opt.onSuccess) {
			opt.onSuccess();
		}
	}, [data, isSuccess, isError]);

	return {
		...mutation,
		doDeleteRoles: mutate,
	};
}

export default function useRoles() {
	const [queryState] = useQueryString();

	const q = useQuery({
		queryFn: apiRoles,
		staleTime: 1000 * 60 * 15, // 15 min
		queryKey: ['z-roles'],
	});

	React.useDebugValue(q);

	const { roleId } = queryState;

	if (roleId) {
		const returnedRole = q?.data?.data.find((r) => r.id == roleId);

		return { ...q, data: returnedRole };
	}

	return q;
}
