import React from 'react';
import ms from 'ms';
import { get } from 'lodash-es';
import { useMutation } from 'react-query';
import { ApiRequest } from '@zeal/zeal-lib';


import parseJson from '../../utils/parseJson';
// import ApiRequest from '../../utils/apiRequest';

const KEYS_LOGIN = 'zeal-vndr-usr';
const KEYS_PERMISSIONS = 'zeal-vndr-permissions';
const TOKEN_TTL = ms('1d');

class LoginState {
	static state = React.createRef();

	static load() {
		LoginState.loadSS();
		LoginState.loadLS();
	}

	static loadLS() {
		LoginState.state.current = {};

		Object.keys(localStorage).forEach((localStorageKey) =>
			LoginState.setCurrent(LoginState.getLS(localStorageKey)),
		);
	}

	static loadSS() {
		LoginState.state.current = {};

		Object.keys(sessionStorage).forEach((sessionStorageKey) =>
			LoginState.setCurrent(LoginState.getSS(sessionStorageKey)),
		);
	}

	static setItem(key, value) {
		LoginState.setCurrent(key, value);

		if (value?.keepSignIn !== false) {
			LoginState.setLS(key, value);
		} else {
			LoginState.setSS(key, value);
		}
	}

	static getItem(key, otherwise) {
		const lsVal = LoginState.getLS(key);
		const ssVal = LoginState.getSS(key);

		const val = LoginState.getCurrent(key);

		if (lsVal && val === undefined) {
			LoginState.setItem(key, lsVal);
		} else if (ssVal && val === undefined) {
			LoginState.setItem(key, ssVal);
		}

		return val || lsVal || ssVal || otherwise;
	}

	static setCurrent(key, value) {
		LoginState.state.current[key] = value;
	}

	static getCurrent(key) {
		return get(LoginState.state.current, key);
	}

	static setLS(key, value) {
		localStorage.setItem(key, JSON.stringify(value));
	}

	static getLS(key) {
		return parseJson(localStorage.getItem(key));
	}

	static setSS(key, value) {
		sessionStorage.setItem(key, JSON.stringify(value));
	}

	static getSS(key) {
		return parseJson(sessionStorage.getItem(key));
	}
}

LoginState.load();

window._foo_ = LoginState;

async function apiLogin({ keepSignIn, ...payload }) {
	return new ApiRequest('v3/auth/login')
		.addHeader('Accept', 'application/json')
		.addHeader('Content-Type', 'application/json')
		.POST(JSON.stringify(payload), { noAuth: true })
		.then(({ data }) => {
			const permissions = (data?.business_admin?.roles || []).reduce(
				(acc, role) => [...acc, ...(role.permissions || [])],
				[],
			);

			const isSuperAdmin = !!(data?.business_admin?.roles || []).find(
				(r) => r.name === 'Super Admin',
			);

			return {
				token: data?.token,
				permissions,
				keepSignIn,
				isSuperAdmin,
			};
		})
		.catch((err) => {
			const isDev = process.env.ALLOW_DEV_TOOLS === 'yes';
			const isFakeLogin
				= isDev && localStorage.getItem('dev/log-me-in') === 'please';

			if (isFakeLogin) {
				return {
					keepSignIn,
					token: 'fake-login',
				};
			}

			throw err;
		});
}

async function apiLogout() {
	// return new ApiRequest("auth/logout")
	//   .addHeader("Accept", "application/json")
	//   .GET();

	console.warn('[API/dummy]', 'useLogin::apiLogout');

	return Promise.resolve(true);
}

export function useLoggedIn() {
	const { tk, id, ttl, isSuperAdmin } = LoginState.getItem(KEYS_LOGIN, {});

	const { permissions } = LoginState.getItem(KEYS_PERMISSIONS, {});

	const isTimeout = id + ttl <= new Date().getTime();

	const token = isTimeout ? null : tk;

	const updateCachedUserPermissions = (permissions) => {
		LoginState.setItem(KEYS_PERMISSIONS, {
			permissions,
		});
	};

	return {
		token,
		isTimeout,
		isLoggedOut: !tk,
		isLoggedIn: !!token,
		authorization: `Bearer ${token}`,
		isSuperAdmin: isSuperAdmin || false,
		permissions: permissions || [],
		updateCachedUserPermissions,
	};
}

export function useLogin(opt) {
	const mutation = useMutation(apiLogin);

	const { data, mutate, isSuccess } = mutation;

	React.useEffect(() => {
		if (!isSuccess) {
			return null;
		}

		const loginVal = {
			tk: data?.token,
			id: new Date().getTime(),
			ttl: TOKEN_TTL,
			keepSignIn: !!data?.keepSignIn,
			isSuperAdmin: data?.isSuperAdmin,
		};

		LoginState.setItem(KEYS_LOGIN, loginVal);

		LoginState.setItem(KEYS_PERMISSIONS, {
			permissions: data?.permissions,
		});

		if (opt.onSuccess) {
			opt.onSuccess();
		}
	}, [data, isSuccess]);

	return {
		...mutation,
		doLogin: mutate,
	};
}

export function useLogout(opt) {
	const mutation = useMutation(apiLogout);

	const { mutate, status, isError, isLoading, isSuccess } = mutation;

	React.useEffect(() => {
		if (isSuccess) {
			LoginState.setItem(KEYS_LOGIN, {});

			if (opt.onSuccess) {
				opt.onSuccess();
			}
		}
	}, [status, isSuccess]);

	return {
		isError,
		isLoading,
		isSuccess,
		doLogout: mutate,
	};
}

export function getLoggedInSession() {
	const { tk, id, ttl } = LoginState.getItem(KEYS_LOGIN, {});

	const isTimeout = id + ttl <= new Date().getTime();

	const token = isTimeout ? null : tk;

	return {
		token,
		isTimeout,
		isLoggedOut: !tk,
		isLoggedIn: !!token,
		authorization: `Bearer ${token}`,
	};
}
