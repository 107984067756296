import React, { useState } from 'react';
import { Tabs } from '@zeal/zeal-ui';
import AppContainer from '../App/AppContainer';

export default function StoriesFrame(props) {
	const paths = [
		{
			name: 'Stories',
			to: '/stories/page',
			breadcrumbs: [
				{
					name: 'Stories',
					to: '/stories/page',
					icon: 'commentSmile',
				},
			],
		},
		{
			name: 'archived',
			to: '/stories/archived',
			breadcrumbs: [
				{
					name: 'Archived',
					to: '/stories/archived',
				},
			],
		},
	];
	const tabContent = paths.map((d) => <Tabs to={d.to} name={d.name} />);

	return (
		<AppContainer
			ownApp
			protected
			middleContent={tabContent}
			breadcrumbs={paths[0].breadcrumbs}
		>
			{props?.children}
		</AppContainer>
	);
}
