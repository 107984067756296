import React from 'react';
import { CardTable, NavStaticWithCard } from '@zeal/zeal-ui';
import useSubscriptions from '../data/useSubscription';

export default function Subscription() {
	const { data: content } = useSubscriptions();

	return (
		<>
			<NavStaticWithCard
				HeaderclassName="headerWidth"
				checkboxType="radio"
				labelClassName="text-md font-semibold"
				buttontextSize="md"
				subtitleClassName="sm font-semibold black"
				buttonSize="sm"
				items={content}
			/>
			<div className="!pt-36 w-11/12">
				{content?.data?.map((i) => (
					<CardTable key={i.label} menu={i.menu} label={i.label} />
				))}
			</div>
		</>
	);
}
