import React, { useReducer, useState } from 'react';

import {
	ButtonWithIcon,
	Center,
	InlineError,
	InlineLoading,
	Stack,
	Title,
} from '@zeal/zeal-ui';

import ACLBoundary from '../App/ACL/ACLBoundary';
import useRecommendations from './data/useRecommendations';

export default function EmptyState({ data, addRecommendationBtnClick }) {
	const overview = useRecommendations();
	return (
		(data || []).length === 0 && (
			<Center className="contents">
				<Stack m="xl">
					<Title
						className={overview.isSuccess ? '' : 'hidden'}
						title="No Data Yet!"
						subtitle="Create your own"
						size="md"
					/>
					<Center className={overview.isSuccess ? 'mt-8' : 'hidden'}>
						<ACLBoundary>
							<ButtonWithIcon
								primary
								label="Add Recommendations"
								btnIcon="plus"
								size="xs"
								m="none"
								onClick={addRecommendationBtnClick}
								aclUnAuthProps={{ disabled: true }}
								aclGrants={['control:recommendations']}
							/>
						</ACLBoundary>
					</Center>
					<Center className="mt-8">
						<InlineError hide={!overview.isError} />
						<InlineLoading hide={!overview.isLoading} />
					</Center>
				</Stack>
			</Center>
		)
	);
}
