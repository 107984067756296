import React from 'react';
import {
	Center,
	Grid,
	InlineEmpty,
	InlineError,
	InlineLoading,
	LabelRounded,
	Stack,
	Title,
} from '@zeal/zeal-ui';

import { indexOf } from 'lodash-es';
import usePunchesAnalytics from '../data/usePunchesAnalytics';

export default function PunchesAnalytics(props) {
	const { data } = usePunchesAnalytics();
	let overview = usePunchesAnalytics();

	let obj = Object.values(data?.count || []);
	obj.splice(indexOf(data?.count?.exceed), 1);
	const punchesList = obj?.map((i, index) => (
		<LabelRounded label={`${i}%`} userName={index + 1} />
	));

	return (
		<Stack
			gap="md"
			p="xs"
			mx="md"
			borderColor="gray"
			className="border-b overFlow-card "
		>
			<Title titleText="Punches Analytics" />
			{punchesList.length > 0 ? (
				<Grid cols="5" gap="3" children={punchesList} my="sm" />
			) : (
				<Stack
					m="xs"
					gap="sm"
					overflowAuto
					mx="sm"
					maxH="md"
					children={
						<Center>
							<Stack m="lg">
								<InlineError hide={!overview.isError} />
								<InlineLoading hide={!overview.isLoading} />
								{punchesList.length == 0 && !overview.isLoading ? (
									<InlineEmpty />
								) : null}
							</Stack>
						</Center>
					}
				/>
			)}
		</Stack>
	);
}
