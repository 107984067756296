import React from 'react';
import { useMutation } from 'react-query';

import { ApiRequest } from '@zeal/zeal-lib';
import { useQuery } from '../../App/QueryClient';
import { useQueryString } from '../../App/useQueryString';

async function apiPromocodes({ queryKey: [, filters] }) {
	return new ApiRequest('promos')
		.addHeader('Accept', 'application/json')
		.GET();
}

async function apiDeletePromocode({ ...payload }) {
	return new ApiRequest(`promos/${payload?.uuid}`)
		.addHeader('Accept', 'application/json')
		.addHeader('Content-Type', 'application/json')
		.DELETE();
}

async function apiAddPromocode(payload) {
	return new ApiRequest('promos')
		.addHeader('Accept', 'application/json')
		.addHeader('Content-Type', 'application/json')
		.POST(JSON.stringify(payload));
}

export default function usePromocodes() {
	const [queryState] = useQueryString();

	const q = useQuery({
		queryFn: apiPromocodes,
		staleTime: 1000 * 60 * 10, // 10 min
		queryKey: [
			'z-promocodes',
			{
				page: queryState?.page,
				...(queryState?.status !== 'all' && { status: queryState?.status }),
			},
		],
	});

	const [searchTerm, setSearchTerm] = React.useState();
	const [dataGrouped, setDataGrouped] = React.useState([]);

	React.useEffect(() => {
		const grouped = q.data;
		setDataGrouped(grouped);
	}, [searchTerm, q.data, setDataGrouped]);

	const search = React.useCallback(
		(term) => {
			setSearchTerm(term);
		},
		[setSearchTerm],
	);

	return { search, ...q, data: dataGrouped };
}

export function useDeletePromocode(opt) {
	const mutation = useMutation(apiDeletePromocode);

	const { data, mutate, isSuccess } = mutation;

	React.useEffect(() => {
		if (!isSuccess) {
			return null;
		}

		if (opt.onSuccess) {
			opt.onSuccess();
		}
	}, [data, isSuccess]);

	return {
		...mutation,
		doDeletePromocode: mutate,
	};
}

export function useAddPromocode(opt) {
	const mutation = useMutation(apiAddPromocode);

	const { data, mutate, isSuccess, isError } = mutation;

	React.useEffect(() => {
		if (isError) {
			if (opt.onError) {
				opt.onError();
			}
		}
		if (!isSuccess) {
			return null;
		}

		if (opt.onSuccess) {
			opt.onSuccess();
		}
	}, [data, isSuccess, isError]);

	return {
		...mutation,
		doAddPromocode: mutate,
	};
}
