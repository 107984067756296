import React from 'react';
import { get } from 'lodash-es';
import { DateTime } from 'luxon';
import { Align, Input, SizedBox } from '@zeal/zeal-ui';

import { useQueryString } from '../App/useQueryString';

export default function FiltersToolbar() {
	const [queryState, setQuery, queryStr] = useQueryString();

	const [dateRange, setDateRange] = React.useState([
		DateTime.fromISO(
			get(queryState?.date, '0', DateTime.now().minus({ days: 1 })
				.toISODate()),
		).toJSDate(),
		DateTime.fromISO(
			get(queryState?.date, '1', DateTime.now().toISODate()),
		).toJSDate(),
	]);

	React.useEffect(() => {
		const [dateFrom, dateTo] = queryState?.date || [];

		const defaultRange = [
			DateTime.now().minus({ days: 1 })
				.toJSDate(),
			DateTime.now().toJSDate(),
		];

		const qsRange
			= dateFrom && dateTo
				? [
					DateTime.fromISO(dateFrom).toJSDate(),
					DateTime.fromISO(dateTo).toJSDate(),
				  ]
				: defaultRange;

		const isValidRange = !isNaN(qsRange[0]) && !isNaN(qsRange[0]);

		if (isValidRange) {
			setDateRange(qsRange);
		} else {
			setQuery({ date: undefined });
		}
	}, [queryStr, queryState]);

	const handleOnChange = React.useCallback(
		(newRange) => {
			const dateOld = DateTime.now().minus({ days: 1 })
				.toJSDate();
			const dateNow = DateTime.now().toJSDate();

			const [dateFrom, dateTo] = newRange || [dateOld, dateNow];

			const isResetDate = !dateFrom && !dateTo;

			const newDateRange = isResetDate
				? [dateOld, dateNow]
				: [dateFrom, dateTo];

			setDateRange(newDateRange);

			if (newDateRange[0] && newDateRange[1]) {
				setQuery({
					date: [
						DateTime.fromJSDate(newDateRange[0]).toISODate(),
						DateTime.fromJSDate(newDateRange[1]).toISODate(),
					],
				});
			}
		},
		[setDateRange],
	);

	return (
		<Align align="right">
			<SizedBox width="2xl">
				<Input.Date.Range
					fillWidth
					onChange={handleOnChange}
					maxDate={DateTime.now().toJSDate()}
					startDate={dateRange[0]}
					endDate={dateRange[1]}
					calender="calender"
				/>
			</SizedBox>
		</Align>
	);
}
