import React, { useDebugValue, useEffect } from 'react';

import { ApiRequest } from '@zeal/zeal-lib';
import { useQuery } from '../../App/QueryClient';
import { useLoggedIn } from '../ACL/useLogin';

async function apiBusinessId() {
	return new ApiRequest('me/app')
		.addHeader('Accept', 'application/json')
		.GET();
}

export default function useBusinessId() {
	const { isLoggedIn } = useLoggedIn();

	const q = useQuery({
		queryFn: apiBusinessId,
		staleTime: Infinity,
		queryKey: ['z-account-id'],
		enabled: isLoggedIn,
		skipOwnApp: true,
	});

	const { isSuccess, data } = q;

	useEffect(() => {
		if (!isSuccess) {
			return null;
		}

		// `Whitelabel` means `OwnApp`
		if (data?.hasWhitelabel) {
			localStorage.setItem('app-id', data?.appId.toString());
			localStorage.setItem('z:v; pa/id', data?.appId.toString());

			const { currentApp } = getAccountInfo();

			localStorage.setItem('current-dash-app', currentApp || 'zeal');
			localStorage.setItem('z:v; pa/ndx', currentApp || 'zeal');
		}
	}, [data, isSuccess]);

	useDebugValue(q);

	return q;
}

async function apiBusinessInfo() {
	return new ApiRequest('me')
		.addHeader('Accept', 'application/json')
		.GET();
}

export function useBusinessInfo() {
	const q = useQuery({
		queryFn: apiBusinessInfo,
		staleTime: Infinity,
		queryKey: ['z-account-info'],
		skipOwnApp: true,
	});

	useDebugValue(q);

	return q;
}

/**
 * Own App Toggle
 */

const APP_ID_KEY = 'z:v; pa/id';
const APP_NAME_KEY = 'z:v; pa/nam';
const ACCOUNT_INFO_UPDATE_EVENT = 'account-info-update';

export const accountInfoManger = (() => {
	const emitState = () => {
		const { updateApp, ...accInfo } = getAccountInfo();

		const evt = new CustomEvent(ACCOUNT_INFO_UPDATE_EVENT, { detail: accInfo });
		window.dispatchEvent(evt);
	};

	const update = (...args) => {
		setAccountInfo(...args);
		setImmediate(emitState);
	};

	const onUpdate = (eventHandler) => {
		const evtHandler = ({ detail }) => {
			eventHandler(detail);
		};

		window.addEventListener(ACCOUNT_INFO_UPDATE_EVENT, evtHandler);

		return () => {
			window.removeEventListener(ACCOUNT_INFO_UPDATE_EVENT, evtHandler);
		};
	};

	window.addEventListener('storage', (evt) => {
		if (evt.key === APP_ID_KEY || evt.key === APP_NAME_KEY) {
			emitState();
		}
	});

	return () => ({
		getInfo: getAccountInfo,
		...getAccountInfo(),
		onUpdate,
		update,
	});
})();

export function getAccountInfo() {
	const appId = localStorage.getItem(APP_ID_KEY) ?? '1';
	const appName = localStorage.getItem(APP_NAME_KEY); // "current-dash-app"
	const isAppOwn = appId !== '1';

	return {
		isAppOwn,
		appName,
		appId,
	};
}

function setAccountInfo(id, name) {
	localStorage.setItem(APP_ID_KEY, id);
	localStorage.setItem(APP_NAME_KEY, name);
}

export function useAccountInfo() {
	const { onUpdate, update, ...initData } = accountInfoManger();

	const [appId, setAppId] = React.useState(initData.appId);
	const [appName, setAppName] = React.useState(initData.appName);
	const [isAppOwn, setIsAppOwn] = React.useState(initData.isAppOwn);

	const updateState = React.useCallback(
		(data) => {
			setAppId(data.appId);
			setAppName(data.appName);
			setIsAppOwn(data.isAppOwn);
		},
		[setAppId, setAppName, setIsAppOwn],
	);

	React.useEffect(() => onUpdate(updateState), [updateState]);

	return {
		appId,
		appName,
		isAppOwn,
		updateApp: update,
	};
}

async function apiBusinessPermissions() {
	return new ApiRequest('v3/permissions/business')
		.addHeader('Accept', 'application/json')
		.GET()
		.then(({ data }) => {
			const { updateCachedUserPermissions } = useLoggedIn();
			updateCachedUserPermissions(data);

			return data;
		});
}

const REFETCH_PERMISSIONS_EVENT = 'refetch-permissions-event';

export function refetchUserPermissionsEvent() {
	return {
		emit() {
			const evt = new CustomEvent(REFETCH_PERMISSIONS_EVENT, {
				detail: { status: 403 },
			});
			window.dispatchEvent(evt);
		},
		on(handler) {
			window.addEventListener(REFETCH_PERMISSIONS_EVENT, handler);

			return () => {
				window.removeEventListener(REFETCH_PERMISSIONS_EVENT, handler);
			};
		},
	};
}

export function useBusinessPermissions() {
	const q = useQuery({
		queryFn: apiBusinessPermissions,
		staleTime: Infinity,
		queryKey: ['z-business-permissions'],
	});

	refetchUserPermissionsEvent().on((evt) => {
		q.refetch();
	});

	useDebugValue(q);

	return q;
}
