import React from 'react';
import {
	Align,
	Button,
	ButtonWithIcon,
	Grid,
	InlineError,
	InlineLoading,
	Input,
	Modal,
	Stack,
	Textarea,
	Title,
	TwoSideLayout,
} from '@zeal/zeal-ui';

import useVideos, { useAddVideo } from '../data/useVideos';

export default function VideoAddEditModal(props) {
	const {
		isEditing,
		isAddEditModalOpen,
		closeAddEditModal,
		setIsAddEditModalOpen,
		formDispatch,
		formState,
		removeError,
		setRemoveError,
	} = props;

	const modalSubmitLabel = isEditing ? 'Save' : 'Add';

	const modalTitle = isEditing ? 'Edit Video' : 'Add Video';

	const videosQuery = useVideos();
	const { data } = videosQuery;

	const { doAddVideo, isError, isLoading, status } = useAddVideo({
		onSuccess: () => {
			videosQuery.refetch();
			setIsAddEditModalOpen(false);
			// resetAddEditModal();
		},
	});

	const handleAddEditSubmit = () => {
		setRemoveError(true);
		doAddVideo(formState);
	};

	return (
		<Modal
			isModalOpen={isAddEditModalOpen}
			title={modalTitle}
			onClose={closeAddEditModal}
		>
			<Stack>
				<Title title="Youtube Video Url" margin="xs" />
				<Input
					type="text"
					inputName="title"
					placeholder="Url"
					onChange={(evt) => formDispatch({ url: evt.target.value })}
					value={formState.url}
				/>
				<Stack>
					<Title title="Add Description" margin="xs" />
					<Textarea
						name="description"
						placeholder="Type Something"
						rows="4"
						onChange={(evt) => formDispatch({ description: evt.target.value })}
						value={formState.description}
					/>
				</Stack>
				<Grid gap="5">
					<span></span>
					<TwoSideLayout>
						<ButtonWithIcon
							stretch={false}
							m="xxs"
							px="md"
							py="sm"
							onClick={closeAddEditModal}
						>
							Cancel
						</ButtonWithIcon>
						<Align align="left">
							<InlineError hide={!isError + !removeError} />
							<InlineLoading hide={!isLoading} />

							<ButtonWithIcon
								stretch={false}
								primary
								m="xxs"
								px="md"
								py="sm"
								onClick={handleAddEditSubmit}
							>
								{modalSubmitLabel}
							</ButtonWithIcon>
						</Align>
					</TwoSideLayout>
				</Grid>
			</Stack>
		</Modal>
	);
}
