import React from 'react';
import { useMutation } from 'react-query';
import { ApiRequest } from '@zeal/zeal-lib';

async function apiSendNotification({ ...payload }) {
	return new ApiRequest('notifications/review')
		.addHeader('Accept', 'application/json')
		.addHeader('Content-Type', 'application/json')
		.POST(JSON.stringify(payload));
}

export function useSendNotification(opt) {
	const mutation = useMutation(apiSendNotification);

	const { data, mutate, isSuccess, isError, error } = mutation;

	React.useEffect(() => {
		if (isError) {
			if (opt?.onError) {
				opt?.onError(error);
			}
		}
		if (!isSuccess) {
			return null;
		}

		if (opt?.onSuccess) {
			opt?.onSuccess();
		}
	}, [data, isSuccess, isError]);

	return {
		...mutation,
		doSendNotification: mutate,
	};
}
