import React, { useEffect, useState } from 'react';
import {
	Align,
	Button,
	Grid,
	InlineError,
	InlineLoading,
	Input,
	Label,
	Stack,
} from '@zeal/zeal-ui';
import useSubmitAccount from '../data/useAccount';
import { renderErrorMessage } from '../../Promocodes/ErrorMessage';
import openToastr from '../../App/Toastr/openToastr';
import { useProfile } from '../data/useProfile';

export default function Account() {
	const [password, setPassword] = useState('');
	const [current_password, setCurrentPass] = useState();
	const [password_confirmation, setConfirmPass] = useState();
	const [errMsgs, setErrMsgs] = useState({});
	const [email, setEmail] = useState(null);
	const refetchProfile = () => {
		profileQuery.refetch();
	};

	const { doAddAccount, isError, isLoading, error, status } = useSubmitAccount({
		onSuccess: () => {
			openToastr({
				message: 'Account data changed',
			});
			refetchProfile();
		},
		onError: () => {
			let errMsg = '';
			if ([400, 422].includes(error?.response?.status))
			{errMsg = 'Failed to change Account';}
			else {errMsg = 'Server error. Please try again Later';}
			openToastr({ message: errMsg, isError: true });
		},
	});

	const profileQuery = useProfile();
	const { data } = profileQuery;

	useEffect(() => {
		setEmail(data?.data?.email);

		error?.response.json().then((err) => {
			setErrMsgs(err?.errors);
		});
	}, [error, data]);

	const addAccount = (data) => {
		doAddAccount(data);
	};
	const handleAccountSubmit = React.useCallback(() => {
		let account = {
			password,
			current_password,
			password_confirmation,
		};
		addAccount(account);
	}, [email, password, current_password, password_confirmation, addAccount]);
	return (
		<>
			<Grid cols="5" gap="3">
				<div className="col-span-2">
					<Input.Email
						inputValue="text"
						mb="xl"
						autocomplete="off"
						disabled
						selectNone
						placeholder="E-Mail Address"
						label="E-Mail Address"
						onChange={(evt) => setEmail(evt.target.value)}
						value={email}
						startHint={renderErrorMessage('email', isError, errMsgs)}
					/>
					<Input.password
						inputValue="pass"
						placeholder="Current Password"
						mb="xl"
						label="Current Password"
						onChange={(evt) => setCurrentPass(evt.target.value)}
						value={current_password}
						startHint={renderErrorMessage('current_password', isError, errMsgs)}
					/>
				</div>
			</Grid>
			<Grid cols="5" gap="3">
				<div className="col-span-2">
					<Input.password
						inputName="Text"
						placeholder="Enter New Password"
						label="New Password"
						onChange={(evt) => {
							setPassword(evt.target.value);
						}}
						value={password}
						mb="xl"
						validateType="true"
						startHint={renderErrorMessage('password', isError, errMsgs)}
					/>
				</div>
				<div className="col-span-2">
					<Input.password
						inputName="Text"
						placeholder="Confirm New Password"
						label="Confirm New Password"
						onChange={(evt) => setConfirmPass(evt.target.value)}
						value={password_confirmation}
						passwordword={password}
						mb="xl"
						validateType="true"
						startHint={renderErrorMessage(
							'password_confirmation',
							isError,
							errMsgs,
						)}
					/>
				</div>
			</Grid>
			<Grid cols="2" gap="3">
				<Align align="left" className="mt-28">
					<Button
						size="sm"
						secondary
						label="Save Changes"
						disabled={!password_confirmation || !password || !current_password}
						onClick={handleAccountSubmit}
					/>
					<Stack mx="md">
						<InlineLoading hide={!isLoading} />
						<InlineError hide={!isError} />
					</Stack>
				</Align>
			</Grid>
		</>
	);
}
