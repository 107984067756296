import React from 'react';
import { Card, ChartBar } from '@zeal/zeal-ui';
import { max, min } from 'lodash-es';

import { numberSuffix } from '@zeal/zeal-lib';
import getDataScaleLog from '../../utils/getDataScaleLog';
import useAnalyticsPeakHours from '../data/useAnalyticsPeakHours';

export default function ChartPeakHours(props) {
	const { data: peakHours } = useAnalyticsPeakHours();
	const overview = useAnalyticsPeakHours();

	const chartData = peakHours?.reduce?.(
		(a, i) => {
			a.axis.push(i.hour);
			a.series.push(i.count);
			return a;
		},
		{
			axis: [],
			series: [],
		},
	);

	const chartLog = getDataScaleLog(chartData?.series || [], { type: 'value' });

	return (
		<Card>
			<ChartBar
				title="Peak Hours"
				className="mb-12"
				isError={overview.isError}
				showLoading={overview.isLoading}
				options={{
					chartLog,
					yAxis: {
						...chartLog,
						minorSplitLine: {
							show: true,
						},
						axisLabel: {
							formatter: numberSuffix,
						},
					},
					xAxis: {
						data: chartData?.axis,
					},
					series: [{ data: chartData?.series }],
				}}
			/>
		</Card>
	);
}
