import React from 'react';
import { Tabs } from '@zeal/zeal-ui';
import AppContainer from '../App/AppContainer';

export default function GalleryFrame(props) {
	const currentPath = `${window.location.pathname}${window.location.search}`;

	const pointsBreadCrumb = [
		{
			name: 'Gallery',
			to: currentPath,
			icon: 'image',
		},
	];

	const paths = [
		{
			name: 'Photos',
			to: '/gallery/photos',
		},
		{
			name: 'Videos',
			to: '/gallery/videos',
		},
	];
	const tabContent = paths.map((d) => (
		<Tabs key={d.name} to={d.to} name={d.name} />
	));

	return (
		<AppContainer
			ownApp
			protected
			breadcrumbs={pointsBreadCrumb}
			middleContent={tabContent}
		>
			{props?.children}
		</AppContainer>
	);
}
