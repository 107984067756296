export default function formatDate(date, formatter = null) {
	const timestamp = Date.parse(date);

	if (isNaN(timestamp)) {
		return 'NA';
	}
	const formattedDate = new Date(timestamp);

	if (!formatter) {
		formatter = new Intl.DateTimeFormat('en-us', {
			year: 'numeric',
			month: 'long',
			day: 'numeric',
			hour: 'numeric',
			minute: 'numeric',
			hour12: true,
			timeZone: 'UTC',
		});
	}

	return formatter.format(formattedDate);
}
