import React, { useEffect, useReducer, useState } from 'react';
import {
	Align,
	ButtonWithIcon,
	Card,
	Checkbox,
	Grid,
	InlineLoading,
	Input,
	MainStackWithAside,
	RadioButton,
	Stack,
	Title,
} from '@zeal/zeal-ui';

import { useHistory } from 'react-router-dom';
import AppContainer from '../../App/AppContainer';

import { useBranches } from '../../Orders/data/useBranches';
import useRoles from '../data/useRoles';
import openToastr from '../../App/Toastr/openToastr';

import useBusinessAdmins, {
	useEditBusinessAdmin,
	useShowBusinessAdmin,
} from '../data/useDashboard';

import { useQueryString } from '../../App/useQueryString';
import { renderErrorMessage } from './ErrorMessage';

export default function EditBusinessAdmin(props) {
	const [errMsgs, setErrMsgs] = useState({});

	const businessAdminQuery = useShowBusinessAdmin();
	const { data: businessAdminData, isLoading: isAdminDataLoading }
		= businessAdminQuery;

	const { data: branchesData, isLoading: isBranchesLoading } = useBranches({
		formatBranch(data) {
			return {
				uuid: data?.uuid,
				name: data?.name,
			};
		},
	});

	const selectBranchesTypes = {
		all: 'all',
		multiple: 'multiple',
	};

	const formatRoles = (roles) => (roles || [])?.reduce(
		(acc, cur) => ({
			[cur?.id]: 'checked',
		}),
		{},
	);

	const formatBranches = (branches) => (branches || [])?.reduce(
		(acc, cur) => ({
			...acc,
			[cur?.uuid]: 'checked',
		}),
		{},
	);

	const formInitialValues = {
		name: businessAdminData?.name || '',
		email: businessAdminData?.email || '',
		password: '',
		selectedRoles: formatRoles(businessAdminData?.roles) || {},
		selectedBranches: formatBranches(businessAdminData?.branches) || {},
		branchesType: selectBranchesTypes.multiple,
	};

	useEffect(() => {
		if (businessAdminData) {
			formDispatch(formInitialValues);
		}
	}, [businessAdminData]);

	const formReducer = (prevState, newState) => ({
		...prevState,
		...newState,
	});

	const [formState, formDispatch] = useReducer(formReducer, formInitialValues);

	const resetForm = () => {
		formDispatch(formInitialValues);
	};

	const history = useHistory();

	const businessAdminsQuery = useBusinessAdmins();
	const rolesQuery = useRoles();
	const { data: rolesData } = rolesQuery;

	const {
		doEditBusinessAdmin,
		isError,
		error,
		isLoading: isEditAdminLoading,
	} = useEditBusinessAdmin({
		onSuccess: () => {
			resetForm();
			openToastr({ message: 'Admin Created Successfully' });
			businessAdminsQuery.refetch();
			businessAdminQuery.refetch();
			history.push({
				pathname: '/teams/dashboard',
			});
		},
		onError: (error) => {
			const clonedError = error?.response.clone();

			if (!clonedError?.bodyUsed) {
				clonedError.json().then((err) => {
					openToastr({
						message: err?.message || 'Failed to create new admin',
						isError: true,
					});
				});
			}
		},
	});

	useEffect(() => {
		error?.response?.json().then((err) => {
			setErrMsgs(err?.errors);
		});
	}, [error]);

	const [queryState] = useQueryString();

	const handleTeamSubmit = () => {
		const isAllBranchesSelected
			= formState.branchesType === selectBranchesTypes.all;

		//remove unused states from request body
		const { selectedBranches, selectedRoles, branchesType, ...restStates }
			= formState;

		const businessAdminBody = {
			...restStates,
			all_branches: isAllBranchesSelected,
			...(!isAllBranchesSelected && {
				branch_uuids: Object.keys(formState.selectedBranches),
			}),
			roles: Object.keys(formState.selectedRoles),
			uuid: queryState?.adminId,
		};

		doEditBusinessAdmin(businessAdminBody);
	};

	const isFormValid = () => {
		const isNameValid = !!formState.name?.length;
		const isEmailValid = !!formState.email?.length;
		const isRolesValid = Object.keys(formState.selectedRoles)?.length > 0;
		const isBranchesValid
			= Object.keys(formState.selectedBranches)?.length > 0
			|| formState.branchesType === selectBranchesTypes.all;

		return isNameValid && isEmailValid && isRolesValid && isBranchesValid;
	};

	const pointsBreadCrumb = [
		{ name: 'Dashboard', to: '/teams/dashboard' },
		{ name: 'Edit Admin', to: '/teams/dashboard/edit-admin' },
	];

	const updateSelectedRoles = (evt) => {
		if (evt.target.checked) {
			formDispatch({
				selectedRoles: {
					...formState.selectedRoles,
					[evt.target.name]: 'checked',
				},
			});
		} else {
			const { [evt.target.name]: removed, ...restRoles }
				= formState.selectedRoles;

			formDispatch({ selectedRoles: restRoles });
		}
	};

	const rolesCheckBoxes = React.Children.toArray(
		(rolesData?.data || []).map((role) => (
			<div>
				<Checkbox
					labelText={role?.name}
					inputName={role?.id.toString()}
					onChange={updateSelectedRoles}
					defaultChecked={!!formState?.selectedRoles?.[role?.id]}
					checked={!!formState?.selectedRoles?.[role?.id]}
				/>
			</div>
		)),
	);

	useEffect(() => {
		if (
			Object.keys(formState.selectedBranches)?.length === branchesData?.length
		)
		{formDispatch({ branchesType: selectBranchesTypes.all });}
	}, [formState.selectedBranches]);

	const updateSelectedBranches = (evt) => {
		if (evt.target.checked) {
			formDispatch({
				selectedBranches: {
					...formState.selectedBranches,
					[evt.target.name]: 'checked',
				},
			});
		} else {
			const { [evt.target.name]: removed, ...restBranches }
				= formState.selectedBranches;

			formDispatch({ selectedBranches: restBranches });
		}
	};

	const branchesCheckBoxes = React.Children.toArray(
		(branchesData || []).map((branch) => (
			<div>
				<Checkbox
					labelText={branch?.name}
					inputName={branch?.uuid}
					defaultChecked={
						!!formState?.selectedBranches?.[branch?.uuid]
						|| formState.branchesType === selectBranchesTypes.all
					}
					checked={
						!!formState?.selectedBranches?.[branch?.uuid]
						|| formState.branchesType === selectBranchesTypes.all
					}
					disabled={formState.branchesType === selectBranchesTypes.all}
					onChange={updateSelectedBranches}
				/>
			</div>
		)),
	);

	const changeBranchesSelectionType = (evt) => {
		formDispatch({ branchesType: evt.target.value });
	};

	const FormModal = (
		<Card p="lg" m="none">
			<Grid cols="3" gap="3">
				<div>
					<Input
						inputName="name"
						placeholder="Enter Name"
						label="Name"
						onChange={(evt) => formDispatch({ name: evt.target.value })}
						value={!isAdminDataLoading ? formState.name : 'Loading...'}
						validateType="true"
						startHint={renderErrorMessage('name', isError, errMsgs)}
						autocomplete="off"
					/>
					<Input.Email
						inputName="email"
						placeholder="add Email"
						label="Email"
						onChange={(evt) => formDispatch({ email: evt.target.value })}
						value={!isAdminDataLoading ? formState.email : 'Loading...'}
						startHint={renderErrorMessage('email', isError, errMsgs)}
						autocomplete="off"
					/>
					<Input.password
						inputName="password"
						placeholder="type password"
						label="Password"
						onChange={(evt) => formDispatch({ password: evt.target.value })}
						value={formState.password}
						startHint={renderErrorMessage('password', isError, errMsgs)}
						autocomplete="new-password"
					/>
				</div>
			</Grid>
			<Stack mx="none" my="lg" p="none">
				<hr />
			</Stack>
			<Stack m="none" p="none">
				<Stack mx="none" mt="none" my="md" p="none">
					<Title size="sm" title="Roles" />
				</Stack>
				<Grid cols="2" gap="3">
					<div>
						<Grid cols="3" gap="6">
							{rolesCheckBoxes}
						</Grid>
					</div>
				</Grid>
			</Stack>

			<Stack mx="none" my="lg" p="none">
				<hr />
			</Stack>
			<Stack m="none" p="none">
				<Stack mx="none" mt="none" my="md" p="none">
					<Title size="sm" title="Branch Access" />
				</Stack>
				<Stack>
					<RadioButton
						onChange={changeBranchesSelectionType}
						items={[
							{
								value: selectBranchesTypes.all,
								inputName: 'All Branches',
								name: 'allBranches',
								id: 'allBranches',
							},
							{
								value: selectBranchesTypes.multiple,
								inputName: 'Multiple Branches',
								name: 'multipleBranches',
								id: 'multipleBranches',
							},
						]}
						selected={formState.branchesType}
					/>
				</Stack>
				<Stack m="md">
					<Grid cols="3" gap="3">
						{branchesCheckBoxes}
					</Grid>
				</Stack>
			</Stack>
		</Card>
	);

	const handleCancelBtn = () =>
		history.push({
			pathname: '/teams/dashboard',
		});

	const isLoadingShown
		= isAdminDataLoading || isBranchesLoading || isEditAdminLoading;

	return (
		<AppContainer protected breadcrumbs={pointsBreadCrumb}>
			<MainStackWithAside
				fullWidth="true"
				toolbarEnd={
					<Align align="right" gap="1">
						<InlineLoading hide={!isLoadingShown} />
						<ButtonWithIcon
							light
							label="Cancel"
							m="none"
							onClick={handleCancelBtn}
						/>
						<ButtonWithIcon
							primary
							label="Save"
							m="none"
							onClick={handleTeamSubmit}
							disabled={!isFormValid()}
						/>
					</Align>
				}
			>
				{FormModal}
			</MainStackWithAside>
		</AppContainer>
	);
}
