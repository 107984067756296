import React from 'react';
import { CardWithAside } from '@zeal/zeal-ui';
import AppContainer from '../App/AppContainer';
import SettingsMenuCard from './data/MenuMap';

export default function SettingsFrame(props) {
	const breadcrumbs = [
		{
			name: 'settings',
			to: '/settings/profile',
		},
	];

	return (
		<AppContainer breadcrumbs={breadcrumbs}>
			<CardWithAside
				asideContent={SettingsMenuCard}
				asideClassName="!px-0 !py-0"
			>
				{props.children}
			</CardWithAside>
		</AppContainer>
	);
}
