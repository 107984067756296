import React, { useEffect, useMemo, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';

import {
	Button,
	ButtonWithIcon,
	Grid,
	InlineLoading,
	Label,
	Modal,
	Select,
	SizedBox,
	Stack,
	Textarea,
	Title,
	TwoSideLayout,
} from '@zeal/zeal-ui';

import openToastr from '../../App/Toastr/openToastr';

import { useBranchTechnicians, useBranches } from '../data/useBranches';
import useOrders, {
	useOrderDetails,
	useUpdateOrderActions,
} from '../data/useOrders';
import ACLBoundary from '../../App/ACL/ACLBoundary';

export default function OrderActions() {
	const { orderId } = useParams();
	const history = useHistory();

	const [isRejectModalOpen, setIsRejectModalOpen] = useState(false);
	const [statusSelection, setStatusSelection] = useState();
	const [assigneeSelection, setAssigneeSelection] = useState();
	const [branchSelection, setBranchSelection] = useState();
	const [isActionsChanged, setIsActionsChanged] = useState(false);

	const orderDetailsQuery = useOrderDetails();
	const { data, isFetching: isFetchingOrderDetail } = orderDetailsQuery;
	const orderData = data?.data;

	const { doUpdateOrderActions } = useUpdateOrderActions({
		onSuccess: () => {
			orderDetailsQuery.refetch();
			openToastr({
				message: 'Order Actions Updated',
			});
			setIsRejectModalOpen(false);

			history.push({
				pathname: '/orders',
				search: '?page=1&refreshOrders=true',
			});
		},
		onError: () => {
			openToastr({
				message: 'Failed to update order actions ',
				isError: true,
			});
		},
	});

	const { data: branchesData } = useBranches({
		formatBranch(data) {
			return {
				value: data?.uuid,
				label: data?.name,
			};
		},
	});

	const techniciansQuery = useBranchTechnicians({
		formatTechnicians(data) {
			return {
				value: data?.uuid,
				label: data?.name,
			};
		},
		branchId: branchSelection?.value,
	});

	const { data: teamData, isLoading: isLoadingTechnicians } = techniciansQuery;

	const statusOptions = useMemo(
		() => [
			...(orderData?.status === 'placed'
				? [
						{ value: 'placed', label: 'Pending' },
						{ value: 'accepted', label: 'Accept Order' },
						{ value: 'rejected', label: 'Reject Order' },
				  ]
				: []),
			...(orderData?.status === 'cancelled'
				? [{ value: 'cancelled', label: 'Cancel Order' }]
				: []),
			...(orderData?.status === 'rejected'
				? [{ value: 'rejected', label: 'Reject Order' }]
				: []),
			...(orderData?.status === 'accepted'
				? [
						{ value: 'accepted', label: 'Accept Order' },
						{ value: 'cancelled', label: 'Cancel Order' },
				  ]
				: []),
			...(orderData?.status === 'finished'
				? [{ value: 'finished', label: 'Finished' }]
				: []),
		],
		[orderData?.status]
	);

	useEffect(() => {
		if (!isFetchingOrderDetail) {
			const selectedStatus = statusOptions.find(
				(s) => s.value === orderData?.status
			);

			setStatusSelection(selectedStatus);

			const selectedBranch = branchesData?.find(
				(b) => b.value === orderData?.assignee_branch?.uuid
			);

			setBranchSelection(selectedBranch);
		}
	}, [orderData, branchesData, isFetchingOrderDetail]);

	useEffect(() => {
		if (
			branchSelection?.value == orderData?.assignee_branch?.uuid &&
			assigneeSelection?.value == orderData?.assignee?.uuid &&
			statusSelection?.value == orderData?.status
		) {
			setIsActionsChanged(false);
		} else {
			setIsActionsChanged(true);
		}
	}, [assigneeSelection, statusSelection, branchSelection]);

	useEffect(() => {
		if (branchSelection?.value) {
			techniciansQuery.refetch();
		}
	}, [branchSelection]);

	useEffect(() => {
		if (teamData) {
			const selectedMember = teamData?.find(
				(t) => t.value === orderData?.assignee?.uuid
			);

			setAssigneeSelection(selectedMember);
		}
	}, [teamData, isFetchingOrderDetail]);

	const isAcceptedWithoutAssignee =
		statusSelection?.value === 'accepted' && !assigneeSelection?.value;

	const showAssigneeError = isAcceptedWithoutAssignee &&
		!isFetchingOrderDetail &&
		!isLoadingTechnicians && (
			<Label
				textColor="danger"
				children="You can't accept the order without assign it"
				size="xs"
				mt="xs"
			/>
		);

	const handleApplyChangesClick = () => {
		const isOrderCancelledOrRejected = ['rejected', 'cancelled'].includes(
			statusSelection?.value
		);

		if (isOrderCancelledOrRejected) {
			setIsRejectModalOpen(true);
		} else {
			updateOrderActions();
		}
	};

	const handleCancelChangesClick = () => {
		setIsActionsChanged(false);
	};

	const updateOrderActions = (reason = null) => {
		doUpdateOrderActions({
			status: statusSelection?.value,
			assignee_uuid: assigneeSelection?.value,
			assignee_branch_uuid: branchSelection?.value,
			...(reason && { reason }),
			orderId,
		});
	};

	const emptyMembersMessage = teamData?.length === 0 && (
		<Label textColor="danger" size="xs">
			No Members in this branch yet
		</Label>
	);

	const isMemberSelectDisabled = teamData?.length === 0 || !branchSelection;

	const selectInputLoading = (
		<Stack p="none" m="sm">
			<InlineLoading />
		</Stack>
	);

	const branchSelectInput = isFetchingOrderDetail ? (
		selectInputLoading
	) : (
		<ACLBoundary>
			<Select
				inputName="branch"
				placeholder={branchSelection?.value ? '' : 'Select Branch'}
				options={branchesData}
				selected={branchSelection}
				onChange={setBranchSelection}
				aclUnAuthProps={{ disabled: true }}
				aclGrants={['control:orders']}
				disabled={['cancelled', 'finished'].includes(orderData?.status)}
			/>
		</ACLBoundary>
	);

	const assigneeSelectInput =
		isFetchingOrderDetail || isLoadingTechnicians ? (
			selectInputLoading
		) : (
			<ACLBoundary>
				<Select
					stretch
					disabled={
						isMemberSelectDisabled ||
						['cancelled', 'finished'].includes(orderData?.status)
					}
					state={isMemberSelectDisabled && 'light'}
					inputName="assignee"
					placeholder={assigneeSelection?.value ? '' : 'Select Member'}
					options={teamData}
					selected={assigneeSelection}
					onChange={setAssigneeSelection}
					startHint={emptyMembersMessage}
					aclUnAuthProps={{ disabled: true }}
					aclGrants={['control:orders']}
				/>
			</ACLBoundary>
		);

	const statusSelectInput = isFetchingOrderDetail ? (
		selectInputLoading
	) : (
		<ACLBoundary>
			<Select
				stretch
				inputName="state"
				placeholder={statusSelection?.value ? '' : 'Select State'}
				options={statusOptions}
				selected={statusSelection}
				onChange={setStatusSelection}
				aclUnAuthProps={{ disabled: true }}
				aclGrants={['control:orders']}
				disabled={['cancelled', 'rejected', 'finished'].includes(
					orderData?.status
				)}
			/>
		</ACLBoundary>
	);

	const branchSelect = !['rejected', 'placed'].includes(
		statusSelection?.value
	) && (
		<Stack my="sm">
			<Title title="Branch" size="sm" />
			{branchSelectInput}
		</Stack>
	);

	const assignToSelect = !['rejected', 'placed'].includes(
		statusSelection?.value
	) && (
		<Stack my="sm">
			<Title title="Assign To" size="sm" />
			{assigneeSelectInput}
			{showAssigneeError}
		</Stack>
	);

	const applyActionsButtons = !isFetchingOrderDetail &&
		!isLoadingTechnicians && (
			<ActionButtons
				isActionsChanged={isActionsChanged}
				handleApplyChangesClick={handleApplyChangesClick}
				handleCancelChangesClick={handleCancelChangesClick}
				isAcceptedWithoutAssignee={isAcceptedWithoutAssignee}
			/>
		);

	return (
		<Stack m="md">
			<Title title="Order Actions" size="sm" />
			<Stack my="md" mb="none">
				<Title title="State" size="sm" />
				{statusSelectInput}
			</Stack>
			{branchSelect}
			{assignToSelect}
			{applyActionsButtons}

			<RejectReasonModal
				isModalOpen={isRejectModalOpen}
				closeModal={() => setIsRejectModalOpen(false)}
				onSubmitReason={updateOrderActions}
				status={statusSelection?.value}
			/>
		</Stack>
	);
}

function ActionButtons(props) {
	const {
		isActionsChanged,
		handleApplyChangesClick,
		handleCancelChangesClick,
		isAcceptedWithoutAssignee,
	} = props;

	const showActionButtons = isActionsChanged && (
		<Stack my="lg" mb="none" gap="md">
			<SizedBox>
				<ACLBoundary>
					<Button
						stretch
						secondary
						size="md"
						m="none"
						onClick={handleApplyChangesClick}
						disabled={isAcceptedWithoutAssignee}
						aclUnAuthProps={{ disabled: true }}
						aclGrants={['control:orders']}
					>
						Apply Changes
					</Button>
				</ACLBoundary>
			</SizedBox>
			<SizedBox>
				<Button size="md" m="none" onClick={handleCancelChangesClick}>
					Cancel
				</Button>
			</SizedBox>
		</Stack>
	);

	return showActionButtons;
}

function RejectReasonModal(props) {
	const [reason, setReason] = useState('');

	const { isModalOpen, closeModal, onSubmitReason, status } = props;

	const isRejected = status === 'rejected';

	const modalTitle = isRejected ? 'Reject The Order' : 'Cancel The Order';

	const reasonTitle = isRejected
		? 'Provide A Rejection Reason*'
		: 'Provide A Cancellation Reason*';

	const submitReason = () => {
		if (reason) {
			onSubmitReason(reason);
		}
	};

	return (
		<Modal isModalOpen={isModalOpen} title={modalTitle} onClose={closeModal}>
			<Stack p="none">
				<Stack p="none">
					<Title title={reasonTitle} margin="xs" />
					<Textarea
						name="reason"
						placeholder="Type Something"
						rowsVal="4"
						onChange={(evt) => setReason(evt.target.value)}
						value={reason}
					/>
				</Stack>
				<Stack p="none" mb="none" my="lg">
					<hr />
				</Stack>
				<Grid gap="5">
					<span></span>
					<TwoSideLayout>
						<Button stretch={false} size="sm" m="xs" onClick={closeModal}>
							Cancel
						</Button>
						<ButtonWithIcon
							secondary
							stretch={false}
							size="sm"
							m="xs"
							onClick={submitReason}
							disabled={!reason}
						>
							{modalTitle}
						</ButtonWithIcon>
					</TwoSideLayout>
				</Grid>
			</Stack>
		</Modal>
	);
}
