import React, { useEffect, useState } from 'react';
import { useQuery } from '../../App/QueryClient';

import { ApiRequest } from '@zeal/zeal-lib';

async function apiPermissions() {
	return new ApiRequest('permissions')
		.addHeader('Accept', 'application/json')
		.addHeader('Content-type', 'application/json')
		.GET();
}

export default function useAllPermissions(passedData) {
	const [formattedPermissions, setFormattedPermissions] = useState([]);

	const q = useQuery({
		skipOwnApp: true,
		queryFn: apiPermissions,
		staleTime: Infinity,
		keepPreviousData: true,
		queryKey: ['z-permissions'],
	});

	const { data: permissionsData } = q;

	useEffect(() => {
		if (permissionsData) {
			const formattedData = permissionsData?.data?.reduce((acc, cur) => ({ ...acc, ...formatPermission(cur) }), {});

			setFormattedPermissions(formattedData);
		}
	}, [permissionsData, setFormattedPermissions]);

	React.useDebugValue(q);

	return { ...q, formattedPermissions };
}

function formatPermission(mainPermission) {
	return (mainPermission?.permissions || []).reduce((acc, cur) => {
		// Extract the verb from the permission name.
		const permissionFirstWord = cur?.name?.split(' ')?.[0];

		const permissionKey
			= `${permissionFirstWord}:${mainPermission?.name}`.toLowerCase();
		const permissionKeyWithId = `${cur?.id}:${permissionKey}`.toLowerCase();

		return {
			...acc,
			[permissionKey]: `${cur?.id}`,
			[permissionKeyWithId]: `${cur?.id}`,
		};
	}, {});
}
