import React, { useCallback, useState } from 'react';

const AppMenuContext = React.createContext({ state: 'open' });

export function AppMenuProvider({ children }) {
	const [menuState, setMenuState] = useState('open');

	const stateToggleMap = {
		open: 'minimizing',
		minimizing: 'minimized',
		minimized: 'expanding',
		expanding: 'open',
	};

	const toggleMenuState = useCallback(() => {
		setMenuState(stateToggleMap[menuState]);
	}, [menuState, setMenuState]);

	const ctxValue = { state: menuState, toggle: toggleMenuState };

	return (
		<AppMenuContext.Provider value={ctxValue}>
			{children}
		</AppMenuContext.Provider>
	);
}

export function useAppMenuProvider() {
	const { state, toggle } = React.useContext(AppMenuContext);

	return [state, toggle];
}
