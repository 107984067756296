import { useEffect } from 'react';
import { useMutation } from 'react-query';

import { ApiRequest } from '@zeal/zeal-lib';

async function apiDeleteItem({ ...payload }) {
	return new ApiRequest(`menus/item/${payload?.uuid}`)
		.addHeader('Accept', 'application/json')
		.addHeader('Content-Type', 'application/json')
		.DELETE();
}

export function useDeleteItem(opt) {
	const mutation = useMutation(apiDeleteItem);

	const { data, mutate, isSuccess } = mutation;

	useEffect(() => {
		if (!isSuccess) {
			return null;
		}

		if (opt.onSuccess) {
			opt.onSuccess();
		}
	}, [data, isSuccess]);

	return {
		...mutation,
		doDeleteItem: mutate,
	};
}

async function apiAddItem({ ...payload }) {
	return new ApiRequest('menus/item')
		.addHeader('Accept', 'application/json')
		.addHeader('Content-Type', 'application/json')
		.addHeader('responseType', 'application/json')
		.POST(JSON.stringify(payload));
}

export function useAddItem(opt) {
	const mutation = useMutation(apiAddItem);

	const { data, mutate, isSuccess, isError, error } = mutation;

	useEffect(() => {
		if (isError) {
			if (opt.onError) {
				opt.onError(error);
			}
		}
		if (!isSuccess) {
			return null;
		}

		if (opt.onSuccess) {
			opt.onSuccess();
		}
	}, [data, isSuccess, isError]);

	return {
		...mutation,
		doAddItem: mutate,
	};
}

async function apiUpdateItem({ ...payload }) {
	return new ApiRequest(`v3/menus/categories/items/${payload?.itemId}`)
		.addHeader('Accept', 'application/json')
		.addHeader('Content-Type', 'application/json')
		.addHeader('responseType', 'application/json')
		.PUT(JSON.stringify(payload));
}

export function useUpdateItem(opt) {
	const mutation = useMutation(apiUpdateItem);

	const { data, mutate, isSuccess } = mutation;

	useEffect(() => {
		if (!isSuccess) {
			return null;
		}

		if (opt.onSuccess) {
			opt.onSuccess();
		}
	}, [data, isSuccess]);

	return {
		...mutation,
		doUpdateItem: mutate,
	};
}

async function apiAddItemPhoto({ ...payload }) {
	return new ApiRequest('menus/item/photo')
		.addHeader('Accept', 'application/json')
		.POST(payload.formData);
}

async function apiUpdateItemEvents({ ...payload }) {
	return new ApiRequest(`menus/item/${payload?.itemId}`)
		.addHeader('Accept', 'application/json')
		.addHeader('Content-Type', 'application/json')
		.addHeader('responseType', 'application/json')
		.PUT(JSON.stringify({ events: payload.events }));
}

export function useUpdateItemEvents(opt) {
	const mutation = useMutation(apiUpdateItemEvents);

	const { data, mutate, isSuccess, isError } = mutation;

	useEffect(() => {
		if (isError) {
			if (opt.onError) {
				opt.onError();
			}
		}

		if (!isSuccess) {
			return null;
		}

		if (opt.onSuccess) {
			opt.onSuccess();
		}
	}, [data, isSuccess, isError]);

	return {
		...mutation,
		doUpdateItemEvents: mutate,
	};
}

export function useAddItemPhoto(opt) {
	const mutation = useMutation(apiAddItemPhoto);

	const { data, mutate, isSuccess, isError } = mutation;

	useEffect(() => {
		if (isError) {
			if (opt.onError) {
				opt.onError();
			}
		}

		if (!isSuccess) {
			return null;
		}

		if (opt.onSuccess) {
			opt.onSuccess();
		}
	}, [data, isSuccess, isError]);

	return {
		...mutation,
		doAddItemPhoto: mutate,
	};
}

async function apiDeleteItemPhoto({ ...payload }) {
	return new ApiRequest(`menus/item/photo/${payload.photoId}`)
		.addHeader('Accept', 'application/json')
		.DELETE();
}

export function useDeleteItemPhoto(opt) {
	const mutation = useMutation(apiDeleteItemPhoto);

	const { data, mutate, isSuccess } = mutation;

	useEffect(() => {
		if (!isSuccess) {
			return null;
		}

		if (opt.onSuccess) {
			opt.onSuccess();
		}
	}, [data, isSuccess]);

	return {
		...mutation,
		doDeleteItemPhoto: mutate,
	};
}
