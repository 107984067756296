import React from 'react';
import { useMutation } from 'react-query';
import { useParams } from 'react-router-dom';

import { ApiRequest } from '@zeal/zeal-lib';
import { useQuery } from '../../App/QueryClient';
import { useQueryString } from '../../App/useQueryString';

async function apiBranches({ queryKey: [, filters] }) {
	return new ApiRequest('v3/branch')
		.addHeader('Accept', 'application/json')
		.addQuery('paginate', filters?.paginate)
		.GET();
}

async function apiBranch({ queryKey: [, filters] }) {
	return new ApiRequest(`v3/branch/${filters?.branchId}`)
		.addHeader('Accept', 'application/json')
		.addHeader('Content-Type', 'application/json')
		.GET();
}

async function apiPhotos({ queryKey: [, filters] }) {
	return new ApiRequest(`v3/branch/${filters?.branchId}/photos`)
		.addHeader('Accept', 'application/json')
		.addHeader('Content-Type', 'application/json')
		.GET();
}

async function apiAddBranch(payload) {
	return new ApiRequest('v3/branch')
		.addHeader('Accept', 'application/json')
		.POST(payload);
}

async function apiEditBranch(payload) {
	return new ApiRequest(`v3/branch/${payload?.uuid}`)
		.addHeader('Accept', 'application/json')
		.addQuery('_method', 'put')
		.POST(payload.formData);
}

async function apiAddPhoto({ ...payload }) {
	return new ApiRequest(`v3/branch/${payload.branchId}/photos`)
		.addHeader('Accept', 'application/json')
		.POST(payload.formData);
}

async function apiDeletePhoto({ branchId, photoId }) {
	return new ApiRequest(`v3/branch/${branchId}/photos/${photoId}`)
		.addHeader('Accept', 'application/json')
		.DELETE();
}

export default function useBranches() {
	const q = useQuery({
		queryFn: apiBranches,
		queryKey: [
			'z-all-branches-not-paginated',
			{
				paginate: false,
			},
		],
		staleTime: 1000 * 60 * 15, // 15 min
	});

	React.useDebugValue(q);

	return q;
}

export function useShowBranch(branchData) {
	const [queryState] = useQueryString();

	const { branchId } = useParams();

	const q = useQuery({
		queryFn: apiBranch,
		queryKey: [
			'z-single-branch',
			{
				branchId,
			},
		],
		enabled: !branchData,
		staleTime: 1000 * 60 * 15, // 15 min
	});

	React.useDebugValue(q);

	return q;
}

export function useBranchGalleryPhotos() {
	const [queryState] = useQueryString();

	const { branchId } = useParams();

	const q = useQuery({
		queryFn: apiPhotos,
		queryKey: [
			'z-branch-photos',
			{
				branchId,
			},
		],
		staleTime: 1000 * 60 * 15, // 15 min
	});

	React.useDebugValue(q);

	return q;
}

export function useAddBranch(opt) {
	const mutation = useMutation(apiAddBranch);

	const { data, mutate, isSuccess, isError } = mutation;

	React.useEffect(() => {
		if (isError) {
			if (opt.onError) {
				opt.onError();
			}
		}

		if (!isSuccess) {
			return null;
		}

		if (opt.onSuccess) {
			opt.onSuccess();
		}
	}, [data, isSuccess, isError]);

	return {
		...mutation,
		doAddBranch: mutate,
	};
}

export function useEditBranch(opt) {
	const mutation = useMutation(apiEditBranch);

	const { data, mutate, isSuccess, isError } = mutation;

	React.useEffect(() => {
		if (isError) {
			if (opt.onError) {
				opt.onError();
			}
		}

		if (!isSuccess) {
			return null;
		}

		if (opt.onSuccess) {
			opt.onSuccess();
		}
	}, [data, isSuccess, isError]);

	return {
		...mutation,
		doEditBranch: mutate,
	};
}

export function useAddBranchPhoto(opt) {
	const mutation = useMutation(apiAddPhoto);

	const { data, mutate, isSuccess, isError } = mutation;

	React.useEffect(() => {
		if (isError) {
			if (opt.onError) {
				opt.onError();
			}
		}

		if (!isSuccess) {
			return null;
		}

		if (opt.onSuccess) {
			opt.onSuccess();
		}
	}, [data, isSuccess, isError]);

	return {
		...mutation,
		doAddPhoto: mutate,
	};
}

export function useDeleteBranchPhoto(opt) {
	const mutation = useMutation(apiDeletePhoto);

	const { data, mutate, isSuccess, isError } = mutation;

	React.useEffect(() => {
		if (isError) {
			if (opt.onError) {
				opt.onError();
			}
		}

		if (!isSuccess) {
			return null;
		}

		if (opt.onSuccess) {
			opt.onSuccess();
		}
	}, [data, isSuccess, isError]);

	return {
		...mutation,
		doDeletePhoto: mutate,
	};
}

export function usePagination(pageData) {
	const { pageCurrent, pagesCount } = pageData;
	const [queryState] = useQueryString();

	if (queryState?.page != pageCurrent) {
		console.error(
			new Error(
				`Pagination mismatch query vs api, expected ${queryState?.page} found ${pageCurrent}`,
			),
		);
	}

	const pageNum = parseInt(pageCurrent);

	const pagesList = Array.from(
		new Set([
			1,
			2,
			pageNum - 1,
			pageNum,
			pageNum + 1,
			pagesCount - 1,
			pagesCount,
		]),
	).reduce((a, i, ndx, arr) => {
		const prv = arr[ndx - 1];
		const isCurrent = pageNum == i;

		if (i === 0) {
			i = prv + 1;
		}

		if (i - prv > 1) {
			a.push({ dff: i - prv, isMany: true });
		}

		if (isNaN(i) || i > pagesCount) {
			return a;
		}

		a.push({ num: i, isCurrent });
		return a;
	}, []);

	return {
		pagesList,
		pagesCount,
		page: queryState?.page,
	};
}
