import { Card, Center, Icon, Title } from '@zeal/zeal-ui';
import React from 'react';
import AppContainer from './AppContainer';

export default function UnAuth() {
	return (
		<AppContainer protected>
			<UnAuthCard />
		</AppContainer>
	);
}

export function UnAuthCard() {
	return (
		<Card fillHeight m="md" p="sm">
			<Center column>
				<Icon name="lock" size="3xl" margin="md" />
				<Title
					margin="md"
					size="md"
					title="Access Restricted"
					subtitle="You don't have permissions to view this page. Contact the admin to ask
                for access to this page"
				/>
			</Center>
		</Card>
	);
}
