import React from 'react';
import { Route, Switch } from 'react-router-dom';
import ACLBoundary from '../App/ACL/ACLBoundary';

import Schedule from './Schedule';

export default function ScheduleRoutes() {
	return (
		<Switch>
			<ACLBoundary
				aclUnAuthProps={{ replace: true }}
				aclGrants={['view:orders schedule']}
			>
				<Route exact path="/schedule" component={Schedule} />
			</ACLBoundary>
		</Switch>
	);
}
