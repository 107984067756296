import React, { Fragment, useEffect, useReducer, useState } from 'react';
import { useHistory } from 'react-router-dom';

import {
	ButtonWithIcon,
	Card,
	Center,
	Checkbox,
	HalvesMainImg,
	InlineError,
	InlineLoading,
	Input,
	Label,
	Select,
	Stack,
	Title,
} from '@zeal/zeal-ui';

import signupImage from '@zeal/zeal-ui/src/assets/images/signup-img.png';
import zealLogoMin from '@zeal/zeal-ui/src/assets/images/zeal-logo-min.png';


import { countryCodes } from '../../utils/countryCodes';

import { getLoggedInSession } from '../ACL/useLogin';
import openToastr from '../Toastr/openToastr';
import useCategories from './data/useCategories';
import { useSignup } from './data/useSignup';

export default function Signup(props) {
	const [errMsgs, setErrMsgs] = useState({});

	const initialFormValues = {
		email: '',
		name: '',
		password: '',
		passwordConfirm: '',
		category: '',
		country: { value: 'EG', label: 'Egypt' },
		agreeTerms: false,
	};

	const formReducer = (prevState, newState) => ({ ...prevState, ...newState });

	const [formState, formDispatch] = useReducer(formReducer, initialFormValues);

	const { categoriesList } = useCategories({
		formatCategory(data) {
			return {
				...data,
				label: data?.name,
				value: data?.id,
			};
		},
	});

	const { isLoggedIn } = getLoggedInSession();
	const history = useHistory();

	useEffect(() => {
		if (isLoggedIn) {history.push('/');}
	}, [isLoggedIn]);

	const { doSignup, isError, isLoading, error } = useSignup({
		onSuccess: () => {
			const { history } = props;
			openToastr({
				message:
					'Registered Successfully, your email is being reviewed and approved by Zeal',
			});
			history.push('/login');
			return null;
		},
		onError: () => {
			if (error?.response?.status === 500)
			{openToastr({
				message: 'Something went wrong',
			});}
		},
	});

	useEffect(() => {
		error?.response?.json().then((err) => {
			setErrMsgs(err?.errors);
		});
	}, [error]);

	const isObjectEmpty = (o) => Object.keys(o).some(function (x) {
		return o[x] === '' || o[x] === null || o[x] === false;
	});

	const isFormValid
		= !isObjectEmpty(formState)
		&& formState.password === formState.passwordConfirm
		&& formState.password?.length > 7;

	const handleSignupSubmit = React.useCallback(() => {
		const { email, name, password, passwordConfirm, category, country }
			= formState;

		if (isFormValid) {
			doSignup({
				email,
				password,
				country: country?.value,
				password_confirmation: passwordConfirm,
				name,
				category_id: category?.value,
			});
		} else {
			// pass
		}
	}, [formState]);

	const agreeText = (
		<Fragment>
			I agree to the processing and sharing of my personal data as required to
			use zeal app
			<br />
			{'service and as outlined within '}
			<a
				href="https://myzeal.app/legal"
				target="_blank"
				rel="noopener noreferrer"
				className="zeal-color"
			>
				Terms and Conditions
			</a>
			{' and '}
			<a
				href="https://myzeal.app/privacy"
				target="_blank"
				rel="noopener noreferrer"
				className="zeal-color"
			>
				Privacy Policy
			</a>
		</Fragment>
	);

	const showPasswordErrorMsg = formState.password?.length < 8
		&& formState.password?.length > 0 && (
		<Label textColor="danger" size="xs">
				Password must be at least 8 characters
		</Label>
	);

	const showConfirmPasswordErrorMsg = formState.password
		!== formState.passwordConfirm && (
		<Label textColor="danger" size="xs">
			Password confirm does not match
		</Label>
	);

	return (
		<HalvesMainImg
			img={signupImage}
			smallLogo={zealLogoMin}
			imageContainerClassName="side-bg "
			imageClassName="signup-img"
			wrapOn="lg"
		>
			<Stack m="sm">
				<Card shadow="xl" border={false} p="lg" maxWidth="xxl">
					<Stack>
						<Title
							size="lg"
							titleText="Create An Account"
							subtitleText="Accept QR code payments &amp; loyalty today!."
						/>
						<Input
							type="text"
							inputName="name"
							placeholder="Business name"
							onChange={(evt) => formDispatch({ name: evt.target.value })}
							startHint={renderErrorMessage('name', isError, errMsgs)}
						/>
					</Stack>
					<Stack>
						<Center gap="3">
							<Select
								stretch
								placeholder="country"
								selected={formState?.country}
								onChange={(country) => formDispatch({ country })}
								options={countryCodes}
								startHint={renderErrorMessage('country', isError, errMsgs)}
							/>
							<Select
								stretch
								placeholder="Category"
								selected={formState?.category}
								onChange={(category) => formDispatch({ category })}
								options={categoriesList}
								startHint={renderErrorMessage('category', isError, errMsgs)}
							/>
						</Center>
					</Stack>
					<Stack>
						<Input
							type="email"
							inputName="email"
							placeholder="Email Address"
							onChange={(evt) => formDispatch({ email: evt.target.value })}
							startHint={renderErrorMessage('email', isError, errMsgs)}
						/>
					</Stack>
					<Stack>
						<Input
							type="password"
							placeholder="Password"
							inputName="password"
							onChange={(evt) => formDispatch({ password: evt.target.value })}
							startHint={
								renderErrorMessage('password_confirmation', isError, errMsgs)
								|| showPasswordErrorMsg
							}
						/>
					</Stack>
					<Stack>
						<Input
							type="password"
							placeholder="Re-Enter Password"
							inputName="rePassword"
							onChange={(evt) =>
								formDispatch({ passwordConfirm: evt.target.value })
							}
							startHint={
								renderErrorMessage('password_confirmation', isError, errMsgs)
								|| showConfirmPasswordErrorMsg
							}
						/>
					</Stack>
					<Stack m="md">
						<Checkbox
							labelText={<Title size="xs" margin="xs" title={agreeText} />}
							inputName="agreeCheckbox"
							onChange={(evt) =>
								formDispatch({ agreeTerms: evt.target.checked })
							}
						/>
					</Stack>
					<ButtonWithIcon
						primary
						stretch
						size="md"
						label="Signup"
						disabled={isLoading || !isFormValid}
						onClick={handleSignupSubmit}
					/>
				</Card>
				<InlineError hide={!isError} />
				<InlineLoading hide={!isLoading} />
			</Stack>
		</HalvesMainImg>
	);
}

function renderErrorMessage(input, isError, errMsgs) {
	if (isError && errMsgs?.[input])
	{return (
		<Label textColor="danger" size="xs">
			{errMsgs?.[input]?.[0]}
		</Label>
	);}
	return null;
}
