import React, { useState } from 'react';
import { Card, ChartLineBarStack } from '@zeal/zeal-ui';

export default function ChartRating(props) {
	let obj = Object.values(props?.data);
	return (
		<ChartLineBarStack
			options={{
				series: [
					{
						name: `5 stars ${' '} ${obj[0].count}`,
						type: 'bar',
						stack: 'total',
						label: {
							show: false,
						},
						emphasis: {
							focus: 'series',
						},
						data: [obj[0].rate],
					},
					{
						name: `4 stars ${' '} ${obj[1].count}`,
						type: 'bar',
						stack: 'total',
						label: {
							show: false,
						},
						emphasis: {
							focus: 'series',
						},
						data: [obj[1].rate],
					},
					{
						name: `3 stars ${' '} ${obj[2].count}`,
						type: 'bar',
						stack: 'total',
						label: {
							show: false,
						},
						emphasis: {
							focus: 'series',
						},
						data: [obj[2].rate],
					},
					{
						name: `2 stars ${' '} ${obj[3].count}`,
						type: 'bar',
						stack: 'total',
						label: {
							show: false,
						},
						emphasis: {
							focus: 'series',
						},
						data: [obj[3].rate],
					},
					{
						name: `1 stars ${' '} ${obj[4].count}`,
						type: 'bar',
						stack: 'total',
						label: {
							show: false,
						},
						emphasis: {
							focus: 'series',
						},
						data: [obj[4].rate],
					},
				],
			}}
		/>
	);
}
