import React, { useEffect, useReducer, useState } from 'react';
import { Prompt } from 'react-router-dom';
import { useHistory } from 'react-router-dom';
import { cloneDeep } from 'lodash';

import {
	Align,
	ButtonWithIcon,
	Card,
	CenterStackWithToolbar,
	InlineLoading,
	Stack,
} from '@zeal/zeal-ui';

import AppContainer from '../App/AppContainer';

import openToastr from '../App/Toastr/openToastr';
import ACLBoundary from '../App/ACL/ACLBoundary';
import { useAddBranch, useAddBranchPhoto } from './data/useBranches';
import { useAddWorkingDays } from './data/useWorkingDays';

import BranchInfo from './components/BranchInfo';
import BranchAccount from './components/BranchAccounts';
import WorkingHours from './components/WorkingHours';
import BranchGallery from './components/BranchGallery';

export default function AddBranch() {
	const [errMsgs, setErrMsgs] = useState({});
	const history = useHistory();

	const initialFormValues = {
		name: '',
		phone: '',
		map_url: '',
		username: '',
		password: '',
		photo: null,
		confirmPassword: '',
		workingHours: [],
		workingDaysChanged: false,
		photos: [],
	};

	const addEditFormReducer = (prevState, newState) => ({ ...prevState, ...newState });

	const [formState, formDispatch] = useReducer(
		addEditFormReducer,
		initialFormValues,
	);

	const {
		doAddBranch,
		isError,
		error,
		data: addedBranchData,
		isLoading,
	} = useAddBranch({
		onSuccess: () => {
			formDispatch({
				name: '',
				phone: '',
				map_url: '',
				username: '',
				password: '',
				confirmPassword: '',
				photo: null,
			});
			openToastr({
				message: 'Branch Added',
			});

			if (formState.photos.length > 0) {uploadPhotos(addedBranchData);}
			if (formState.workingHours?.length > 0) {setWorkingDays(addedBranchData);}
		},
		onError: () => {
			openToastr({
				message: 'Failed to add branch',
				isError: true,
			});
		},
	});

	const uploadPhotos = (addedBranchData) => {
		togglePhotosStatus('loading');

		for (let i = 0; i < formState.photos.length; i++) {
			const formData = new FormData();
			formData.append('photo', formState.photos[i].file);
			formData.append('description', 'any description');
			doAddPhoto({
				branchId: addedBranchData.data.uuid,
				formData,
				isLastPhoto: i === formState.photos.length - 1,
			});
		}
	};

	const togglePhotosStatus = (status) => {
		const photos = cloneDeep(formState.photos);

		const newPhotos = photos.map((p) => {
			if (status === 'uploaded') {p.loading = false;}
			p[status] = !p[status];
			return p;
		});

		formDispatch({ photos: newPhotos });
	};

	const setWorkingDays = (addedBranchData) => {
		const selectedWorkingDays = formState.workingHours.filter(
			(day) => day.selected,
		);

		doAddDays({
			branchId: addedBranchData.data.uuid,
			body: selectedWorkingDays,
		});
	};

	const { doAddDays } = useAddWorkingDays({
		onSuccess: () => {
			formDispatch({ workingHours: [], workingDaysChanged: false });
			openToastr({
				message: 'Working hours Set',
			});
		},
		onError: () => {
			openToastr({
				message: 'Failed to add working hours ',
				isError: true,
			});
		},
	});

	const { doAddPhoto, variables } = useAddBranchPhoto({
		onSuccess: () => {
			const { isLastPhoto } = variables;
			if (isLastPhoto) {
				togglePhotosStatus('uploaded');
				formDispatch({ photos: [] });
				openToastr({
					message: 'Branch Gallery updated',
				});
			}
		},
		onError: () => {
			openToastr({
				message: 'Failed to add branch photos ',
				isError: true,
			});
		},
	});

	useEffect(() => {
		error?.response.json().then((err) => {
			setErrMsgs(err?.errors);
		});
	}, [error]);

	const addBranchBreadCrumb = [
		{
			icon: 'mapMarker',
			name: 'Branches',
			to: '/branches',
		},
		{
			name: 'Add',
			to: '/branches/add',
		},
	];

	const isFromInvalid = (o) => ['name', 'username', 'password', 'confirmPassword'].some(function (
		x,
	) {
		return o[x] === '' || o[x] === null;
	});

	const submitAddBranch = () => {
		if (!isFromInvalid(formState)) {
			const {
				confirmPassword,
				photos,
				workingDaysChanged,
				workingHours,
				...requestBody
			} = formState;

			var formData = new FormData();

			for (const key in requestBody) {
				formData.append(key, requestBody[key]);
			}

			doAddBranch(formData);
		} else {
			openToastr({
				message: 'Please fill form with valid data',
				isError: true,
			});
		}
	};

	const arePasswordsMatch
		= formState.password?.length > 5
		&& formState.password === formState.confirmPassword;

	const headerEndContent = (
		<Align align="right">
			<Stack row p="none" gap="md">
				<InlineLoading hide={!isLoading} />

				<ButtonWithIcon
					label="Cancel"
					size="xs"
					m="none"
					onClick={() => {
						history.push('/branches');
					}}
				/>
				<ACLBoundary>
					<ButtonWithIcon
						primary
						label="Add Branch"
						size="xs"
						m="none"
						onClick={submitAddBranch}
						disabled={isFromInvalid(formState) || !arePasswordsMatch}
						aclUnAuthProps={{ disabled: true }}
						aclGrants={['control:branches']}
					/>
				</ACLBoundary>
			</Stack>
		</Align>
	);

	return (
		<AppContainer protected breadcrumbs={addBranchBreadCrumb}>
			<Prompt
				when={formState.photos.length > 0 || formState.workingHours.length > 0}
				message="Are you sure you want to leave without saving?"
			/>

			<CenterStackWithToolbar
				toolbarStart={<span></span>}
				toolbarEnd={headerEndContent}
			>
				<Card p="none">
					<BranchInfo
						formDispatch={formDispatch}
						formState={formState}
						errMsgs={errMsgs}
						isError={isError}
					/>
					<hr />
					<BranchAccount
						formDispatch={formDispatch}
						formState={formState}
						errMsgs={errMsgs}
						isError={isError}
						arePasswordsMatch={arePasswordsMatch}
					/>
					<hr />
					<WorkingHours formDispatch={formDispatch} formState={formState} />
					{/*
          Removed temporarily

          <hr />
          <BranchGallery
            formDispatch={formDispatch}
            photos={formState.photos}
          /> */}
				</Card>
			</CenterStackWithToolbar>
		</AppContainer>
	);
}
