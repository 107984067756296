import React, { useCallback } from 'react';
import {
	ButtonWithIcon,
	Center,
	Icon,
	Stack,
	Title,
	TwoSideLayout,
} from '@zeal/zeal-ui';
import ACLBoundary from '../../App/ACL/ACLBoundary';

export default function MultiplePunchesTable({
	data,
	openEditModal,
	openDeleteModal,
}) {
	const punchRangesRows = data?.map((punch, index) => (
		<tr key={index}>
			<td>
				<Title
					muteSubtitle={false}
					subtitle={`${punch.punches} - ${getNextRangePunch(data, index)} EGP`}
					size="sm"
				/>
			</td>
			<td>
				<Center>
					<Title muteSubtitle={false} subtitle={`${punch.value}`} size="sm" />
				</Center>
			</td>
			<td>
				<ActionButtons
					punch={punch}
					openEditModal={openEditModal}
					openDeleteModal={openDeleteModal}
				/>
			</td>
		</tr>
	));

	return data?.length > 0 ? (
		<table>
			<tbody>
				<tr>
					<td>
						<Title title="Punch Range" size="sm" />
					</td>
					<td>
						<Center>
							<Title title="NO." size="sm" />
						</Center>
					</td>
					<td>
						<span />
					</td>
				</tr>
				{punchRangesRows}
			</tbody>
		</table>
	) : (
		<Title subtitle="No Punch Ranges." margin="md" />
	);
}

function ActionButtons(props) {
	const { openEditModal, punch, openDeleteModal } = props;

	const onEditBtnClick = useCallback(
		() => openEditModal(punch),
		[openEditModal, punch]
	);

	const onDeleteBtnClick = useCallback(
		() => openDeleteModal(punch),
		[openDeleteModal, punch]
	);

	return (
		<TwoSideLayout>
			<Stack row p="none" gap="md">
				<ACLBoundary>
					<ButtonWithIcon
						transparent
						p="none"
						onClick={onEditBtnClick}
						aclUnAuthProps={{ disabled: true }}
						aclGrants={['control:loyalty']}
					>
						<Title
							title={<Icon name="pen" bg="white" margin="none" size="sm" />}
							primary
						/>
					</ButtonWithIcon>
					<ButtonWithIcon
						transparent
						p="none"
						onClick={onDeleteBtnClick}
						aclUnAuthProps={{ disabled: true }}
						aclGrants={['control:loyalty']}
					>
						<Icon
							rounded
							bg="danger"
							name="trashAlt"
							size="lg"
							p="xs"
							margin="none"
						/>
					</ButtonWithIcon>
				</ACLBoundary>
			</Stack>
		</TwoSideLayout>
	);
}

function getNextRangePunch(punchRanges, index) {
	if (index >= punchRanges?.length - 1) {
		return 'Greater';
	} else {
		return punchRanges[index + 1]?.punches;
	}
}
