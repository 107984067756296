import React, { useEffect, useReducer, useState } from 'react';
import { Prompt } from 'react-router-dom';
import { useHistory, useLocation, useParams } from 'react-router-dom';
import { cloneDeep, isEqual } from 'lodash';

import {
	Align,
	ButtonWithIcon,
	Card,
	CenterStackWithToolbar,
	InlineLoading,
	Stack,
} from '@zeal/zeal-ui';

import AppContainer from '../App/AppContainer';

import openToastr from '../App/Toastr/openToastr';
import ACLBoundary from '../App/ACL/ACLBoundary';
import useBranches, {
	useAddBranchPhoto,
	useBranchGalleryPhotos,
	useDeleteBranchPhoto,
	useEditBranch,
	useShowBranch,
} from './data/useBranches';
import useWorkingDays, { useAddWorkingDays } from './data/useWorkingDays';

import BranchInfo from './components/BranchInfo';
import BranchAccount from './components/BranchAccounts';
import WorkingHours from './components/WorkingHours';
import BranchGallery from './components/BranchGallery';

export default function EditBranch() {
	const [errMsgs, setErrMsgs] = useState({});
	const [initialPhotos, setInitialPhotos] = useState([]);

	const daysQuery = useWorkingDays({
		formatDays(data) {
			return {
				...data,
				selected: true,
				day: data.day_index,
				from: data.from.substring(0, data.from.length - 3),
				to: data.to.substring(0, data.to.length - 3),
			};
		},
	});

	const { data: daysData } = daysQuery;

	useEffect(() => {
		formDispatch({ workingHours: daysData?.data || [] });
	}, [daysData]);

	const location = useLocation();

	const passedData = location?.state?.branch;

	const allBranchesQuery = useBranches(passedData);

	const branchQuery = useShowBranch(passedData);

	const branchData = branchQuery.data;

	useEffect(() => {
		if (branchData?.data) {fillBranchForm(branchData?.data);}
	}, [branchData]);

	useEffect(() => {
		if (passedData) {fillBranchForm(passedData);}
	}, [passedData]);

	function fillBranchForm(dataSource) {
		const { name, map_url, username, phone, uuid, photo } = dataSource;
		formDispatch({
			map_url,
			name,
			username,
			phone,
			uuid,
			photo,
		});
	}

	const photosQuery = useBranchGalleryPhotos();

	const { data: photosData } = photosQuery;

	const { branchId } = useParams();

	useEffect(() => {
		formDispatch({ photos: photosData?.data || [] });
		setInitialPhotos(initialPhotos || []);
	}, [photosData]);

	const history = useHistory();

	const { doEditBranch, isError, error, isLoading } = useEditBranch({
		onSuccess: () => {
			branchQuery.refetch();
			allBranchesQuery.refetch();
			openToastr({
				message: 'Branch updated',
			});
		},
		onError: () => {
			openToastr({
				message: 'Failed to update branch',
				isError: true,
			});
		},
	});

	const uploadPhotos = () => {
		for (let i = 0; i < formState.photos.length; i++) {
			if (!formState.photos[i].uuid) {
				const formData = new FormData();
				formData.append('photo', formState.photos[i].file);
				formData.append('description', 'any description');
				doAddPhoto({
					branchId: branchId,
					formData,
					isLastPhoto: i === formState.photos.length - 1,
				});
			}
		}
		for (let i = 0; i < formState?.deletePhotosIds?.length; i++) {
			doDeletePhoto({
				branchId,
				photoId: formState?.deletePhotosIds[i],
				isLastPhoto: i === formState?.deletePhotosIds.length - 1,
			});
		}
	};

	const togglePhotosStatus = (status) => {
		const photos = cloneDeep(formState.photos);

		const newPhotos = photos.map((p) => {
			if (status === 'uploaded') {p.loading = false;}
			p[status] = !p[status];
			return p;
		});

		formDispatch({ photos: newPhotos });
	};

	const setWorkingDays = () => {
		const selectedWorkingDays = formState.workingHours.filter(
			(day) => day.selected,
		);

		doAddDays({
			branchId,
			body: selectedWorkingDays,
		});
	};

	const { doAddDays } = useAddWorkingDays({
		onSuccess: () => {
			daysQuery.refetch();
			openToastr({
				message: 'Working hours Updated',
			});
		},
		onError: () => {
			openToastr({
				message: 'Failed to update working hours ',
				isError: true,
			});
		},
	});

	const { doAddPhoto, variables: addBranchVariables } = useAddBranchPhoto({
		onSuccess: () => {
			const { isLastPhoto } = addBranchVariables;
			if (isLastPhoto) {
				togglePhotosStatus('uploaded');

				photosQuery.refetch();
				openToastr({
					message: 'Branch Gallery updated',
				});
			}
		},
		onError: () => {
			openToastr({
				message: 'Failed to add branch photos ',
				isError: true,
			});
		},
	});

	const {
		variables: deleteBranchVariables,
		doDeletePhoto,
		isLoading: isPhotosLoading,
	} = useDeleteBranchPhoto({
		onSuccess: () => {
			const { isLastPhoto } = deleteBranchVariables;

			if (isLastPhoto) {
				photosQuery.refetch();
				openToastr({
					message: 'Branch Gallery Updated',
				});
			}
		},
		onError: () => {
			openToastr({
				message: 'Failed to update branch gallery',
				isError: true,
			});
		},
	});

	useEffect(() => {
		error?.response.json().then((err) => {
			setErrMsgs(err?.errors);
		});
	}, [error]);

	const initialFormValues = {
		name: '',
		phone: '',
		map_url: '',
		username: '',
		password: '',
		photo: null,
		confirmPassword: '',
		workingHours: [],
		workingDaysChanged: false,
		photos: [],
		deletePhotosIds: [],
	};

	const addEditFormReducer = (prevState, newState) => ({ ...prevState, ...newState });

	const [formState, formDispatch] = useReducer(
		addEditFormReducer,
		initialFormValues,
	);

	const isFormDirty = (state) => {
		let branchDataSource = null;
		if (branchData?.data) {branchDataSource = branchData?.data;}
		if (passedData) {branchDataSource = passedData;}

		if (branchDataSource) {
			const { name, phone, map_url, username, photo } = branchDataSource;

			const oldState = { name, phone, map_url, username, photo };

			const newState = {
				name: formState?.name,
				photo: formState?.photo,
				phone: formState?.phone,
				map_url: formState?.map_url,
				username: formState?.username,
			};

			return !isEqual(oldState, newState);
		}

		return true;
	};

	const isFormInValid = (o) => false;

	const submitEditBranch = () => {
		if (!isFormInValid(formState) && isFormDirty(formState)) {
			const { workingDaysChanged, photos, uuid, ...requestBody } = formState;

			var formData = new FormData();

			for (const key in requestBody) {
				formData.append(key, requestBody[key]);
			}

			doEditBranch({ formData, uuid });
		}
		if (formState.photos.length > 0 || formState?.deletePhotosIds?.length > 0)
		{uploadPhotos(formState.uuid);}
		if (formState.workingDaysChanged) {setWorkingDays(formState.uuid);}
	};

	const areAllImagesUploadedBefore = formState.photos.every((p) => p.uuid);

	const arePasswordsMatch
		= formState.password?.length > 5
		&& formState.password === formState.confirmPassword;

	const isWorkingDaysChanged = formState.workingDaysChanged;

	const isBranchGalleryChanged
		= !areAllImagesUploadedBefore || formState?.deletePhotosIds?.length > 0;

	const isConfirmButtonEnabled
		= (!isFormInValid(formState)
			&& arePasswordsMatch
			&& formState.password?.length > 5)
		|| isFormDirty(formState)
		|| isWorkingDaysChanged
		|| isBranchGalleryChanged;

	const headerEndContent = (
		<Align align="right">
			<Stack row p="none" gap="md">
				<InlineLoading hide={!isLoading} />

				<ButtonWithIcon
					label="Cancel"
					size="xs"
					m="none"
					onClick={() => {
						history.push('/branches');
					}}
				/>
				<ACLBoundary>
					<ButtonWithIcon
						primary
						label="Confirm Edit"
						size="xs"
						m="none"
						onClick={submitEditBranch}
						disabled={!isConfirmButtonEnabled}
						aclUnAuthProps={{ disabled: true }}
						aclGrants={['control:branches']}
					/>
				</ACLBoundary>
			</Stack>
		</Align>
	);

	const isDeletePhotosIdsEmpty = formState.deletePhotosIds.length === 0;

	const shouldUserStay
		= !areAllImagesUploadedBefore
		|| !isDeletePhotosIdsEmpty
		|| formState.workingDaysChanged;

	const currentPath = `${window.location.pathname}${window.location.search}`;

	const editBranchBreadCrumb = [
		{
			icon: 'mapMarker',
			name: 'Branches',
			to: '/branches',
		},
		{
			name: 'Edit',
			to: currentPath,
		},
	];

	return (
		<AppContainer protected breadcrumbs={editBranchBreadCrumb}>
			<Prompt
				when={shouldUserStay}
				message="Are you sure you want to leave without saving?"
			/>
			<CenterStackWithToolbar
				toolbarStart={<span></span>}
				toolbarEnd={headerEndContent}
			>
				<Card p="none">
					<BranchInfo
						formDispatch={formDispatch}
						formState={formState}
						errMsgs={errMsgs}
						isError={isError}
					/>
					<hr />
					<BranchAccount
						formDispatch={formDispatch}
						formState={formState}
						errMsgs={errMsgs}
						isError={isError}
						arePasswordsMatch={arePasswordsMatch}
					/>
					<hr />
					<WorkingHours formDispatch={formDispatch} formState={formState} />
					{/*
          Removed temporarily

          <hr />
          <BranchGallery
            formDispatch={formDispatch}
            formState={formState}
            isPhotosLoading={isPhotosLoading}
            deletePhotosIds={formState.deletePhotosIds}
            photos={formState.photos}
          /> */}
				</Card>
			</CenterStackWithToolbar>
		</AppContainer>
	);
}
