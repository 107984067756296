import React, { useEffect, useState } from 'react';

import {
	Align,
	Button,
	ButtonWithIcon,
	Grid,
	Image,
	InlineError,
	InlineLoading,
	Input,
	Stack,
	Textarea,
	Title,
} from '@zeal/zeal-ui';
import useSubmitProfile, {
	useProfile,
	useUpdateCover,
	useUpdatePhoto,
} from '../data/useProfile';
import openToastr from '../../App/Toastr/openToastr';
import { renderErrorMessage } from '../../Promocodes/ErrorMessage';
import ProfilePhotoUploader from './ProfilePhotoUploader';

export default function Profile() {
	const profileQuery = useProfile();
	const { data } = profileQuery;

	const refetchProfile = () => {
		profileQuery.refetch();
	};

	const [name, setName] = useState(data?.data?.name);
	const [description, setDesc] = useState(data?.data?.description);
	const [errMsgs, setErrMsgs] = useState({});

	const { doAddProfile, isError, isLoading, isSuccess, error, status }
		= useSubmitProfile({
			onSuccess: () => {
				openToastr({
					message: 'Profile date changed',
				});
				refetchProfile();
			},
			onError: () => {
				let errMsg = '';
				if ([400, 422].includes(error?.response?.status))
				{errMsg = 'Failed to change Profile';}
				else {errMsg = 'Server error. Please try again Later';}
				openToastr({ message: errMsg, isError: true });
			},
		});

	useEffect(() => {
		setDesc(data?.data?.description);
		setName(data?.data?.name);
		error?.response.json().then((err) => {
			setErrMsgs(err?.errors);
		});
	}, [error, data]);

	const handleProfileSubmit = React.useCallback(() => {
		let profileData = {
			name,
			description,
		};
		doAddProfile(profileData);
	}, [name, description, doAddProfile]);

	return (
		<Grid cols="5" gapX="8" gapY="11" span="2">
			<div className="col-span-2">
				<Input
					id="textInput"
					type="text"
					mb="xl"
					value={name}
					inputName="Text"
					placeholder="Type Something"
					label="Place Name *"
					onChange={(evt) => setName(evt.target.value)}
					validateType="true"
					startHint={renderErrorMessage('name', isError, errMsgs)}
				/>
			</div>
			<div className="col-span-2">
				<Title title="Profile Picture" margin="none" />
				<ProfilePhotoUploader
					media={data?.data?.photo}
					circle="circle"
					width="sm"
					refetchProfile={refetchProfile}
				/>
			</div>
			<div className="col-span-2">
				<Textarea
					inputName="Text"
					placeholder="Type Something"
					label="Description *"
					value={description}
					endHint={`${description?.length || 0}/60`}
					rowsVal="4"
					colsVal="50"
					onChange={(evt) => setDesc(evt.target.value)}
					validateType="true"
					startHint={renderErrorMessage('description', isError, errMsgs)}
				/>
			</div>

			<div className="col-span-2">
				<Title title="Cover Picture" margin="none" />
				<ProfilePhotoUploader
					media={data?.data?.cover}
					rounded="rounded"
					cover="cover"
					width="2xl"
					refetchProfile={refetchProfile}
				/>
			</div>
			<div className="col-span-2 mt-20">
				<Align align="left">
					<Button
						size="sm"
						disabled={!description || description?.length >= '60' || !name}
						secondary
						label="Save Changes"
						onClick={handleProfileSubmit}
					/>
					<Stack mx="md">
						<InlineLoading hide={!isLoading} />
						<InlineError hide={!isError} />
					</Stack>
				</Align>
			</div>
		</Grid>
	);
}
