const checkList = [
	{
		value: 'Cashier',
		inputName: 'Cashier',
		name: 'check',
		checked: 'role',
	},
	{
		value: 'Technician',
		inputName: 'Technician',
		name: 'check',
		checked: 'role',
	},
];
export default checkList;
