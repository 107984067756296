import React, { useEffect, useState } from 'react';
import { cloneDeep } from 'lodash';
import * as yup from 'yup';

import {
	BasicTable,
	Button,
	ButtonWithIcon,
	Center,
	Checkbox,
	Grid,
	Icon,
	Input,
	Modal,
	Stack,
	Title,
	TwoSideLayout,
} from '@zeal/zeal-ui';

export default function WorkingHours(props) {
	const { formDispatch, formState } = props;
	const [isSetHoursModalOpen, setIsSetHoursModalOpen] = useState(false);
	const [editingDay, setEditingDay] = useState(null);

	const handleWorkingHoursEditClick = (rows) => {
		setIsSetHoursModalOpen(true);
		setEditingDay(rows.row.values);
	};

	const handleSelectionChange = (checked, day) => {
		const newWorkingDays = cloneDeep(formState.workingHours);

		const selectedDayIndex = newWorkingDays?.findIndex((d) => d.day === day);

		if (selectedDayIndex !== -1)
		{newWorkingDays[selectedDayIndex].selected = checked;}

		formDispatch({ workingHours: newWorkingDays, workingDaysChanged: true });
	};

	const renderHoursTable = formState.workingHours?.length > 0 && (
		<WorkingDaysTable
			workingHours={formState.workingHours}
			handleEditClicked={handleWorkingHoursEditClick}
			handleSelectionChange={handleSelectionChange}
		/>
	);

	const openAddWorkingDaysModal = () => {
		setIsSetHoursModalOpen(true);
		setEditingDay(null);
	};

	const renderSetWorkingHoursTitle = formState.workingHours?.length === 0 && (
		<SetWorkingHoursTitle openAddWorkingDaysModal={openAddWorkingDaysModal} />
	);

	const handleSetHoursSubmit = (day) => {
		editingDay ? editWorkingDay(day) : addWorkingDays(day);
	};

	const addWorkingDays = (hours) => {
		const workingHours = [...Array(7).keys()].map((index) => ({
			...hours,
			day: index,
			selected: true,
		}));

		formDispatch({ workingHours });
		setIsSetHoursModalOpen(false);
	};

	const editWorkingDay = (newDay) => {
		const dayIndex = formState.workingHours.findIndex(
			(d) => d.day === editingDay.day,
		);

		const workingHours = cloneDeep(formState.workingHours);

		if (dayIndex !== -1) {
			workingHours[dayIndex] = { ...workingHours[dayIndex], ...newDay };
			formDispatch({ workingHours });
		}

		setIsSetHoursModalOpen(false);
	};

	return (
		<>
			<Stack m="md" p="none">
				<Stack p="none">
					<Title title="Working Hours" size="md" />
				</Stack>
				<Stack>
					<Title primary title={renderSetWorkingHoursTitle} size="sm" />
					<TwoSideLayout>{renderHoursTable}</TwoSideLayout>
				</Stack>
			</Stack>
			<SetWorkingHoursModal
				isSetHoursModalOpen={isSetHoursModalOpen}
				closeModal={() => setIsSetHoursModalOpen(false)}
				handleModalSubmit={handleSetHoursSubmit}
				day={editingDay}
			/>
		</>
	);
}

function renderActionCell(rows, handleEditClicked) {
	return (
		<ButtonWithIcon p="none" m="none" onClick={() => handleEditClicked(rows)}>
			<Icon rounded name="edit" size="xs" bg="ghost" />
		</ButtonWithIcon>
	);
}

function renderCheckbox(handleSelectionChange, { row }) {
	const rowData = row?.original;
	const handleCheckedChange = (evt) => {
		const checked = evt?.target?.checked;
		handleSelectionChange(checked, rowData.day);
	};

	return (
		<div>
			<Checkbox
				inputName="day-checkbox"
				defaultChecked={rowData?.selected}
				onChange={(evt) => handleCheckedChange(evt, rowData?.uuid, row.id)}
			/>
		</div>
	);
}

function WorkingDaysTable(props) {
	const { handleEditClicked, handleSelectionChange, workingHours } = props;

	const tableColumns = [
		{
			Header: '',
			accessor: 'selected',
			Cell: (rows) => renderCheckbox(handleSelectionChange, rows),
		},
		{ Header: 'Day', accessor: 'day', Cell: renderDayCell },
		{ Header: 'From', accessor: 'from', Cell: renderTimeCell },
		{ Header: 'To', accessor: 'to', Cell: renderTimeCell },
		{
			Header: 'Actions',
			accessor: 'actions',
			Cell: (rows) => renderActionCell(rows, handleEditClicked),
		},
	];

	return (
		<BasicTable
			cellPadding="lg"
			headerPadding="lg"
			data={workingHours || []}
			columns={tableColumns}
		/>
	);
}

function renderDayCell({ value: dayIndex }) {
	const weekDays = [
		'Sunday',
		'Monday',
		'Tuesday',
		'Wednesday',
		'Thursday',
		'Friday',
		'Saturday',
	];
	return weekDays[dayIndex] || '';
}

function SetWorkingHoursModal(props) {
	const [fromInput, setFromInput] = useState();
	const [toInput, setToInput] = useState();

	const { isSetHoursModalOpen, closeModal, handleModalSubmit, day } = props;

	useEffect(() => {
		setToInput(day?.to);
		setFromInput(day?.from);
	}, [day]);

	return (
		<Modal
			isModalOpen={isSetHoursModalOpen}
			title={renderDayCell({ value: day?.day }) || 'Today'}
			onClose={() => {
				closeModal();
				setFromInput('');
				setToInput('');
			}}
		>
			<Stack p="none">
				<Title title="Starting Work Hour" margin="xs" mb="none" />
				<Input
					type="time"
					inputName="from"
					placeholder="09:00"
					onChange={(evt) => setFromInput(evt.target.value)}
					value={fromInput}
				/>
			</Stack>
			<Stack>
				<Title title="Ending Work Hour" margin="xs" mb="none" />
				<Input
					type="time"
					inputName="to"
					placeholder="09:00"
					onChange={(evt) => setToInput(evt.target.value)}
					value={toInput}
				/>
			</Stack>
			<Grid gap="5">
				<span></span>
				<TwoSideLayout>
					<Button stretch={false} size="md" m="xs" onClick={closeModal}>
						Cancel
					</Button>
					<ButtonWithIcon
						stretch={false}
						size="md"
						primary
						m="xs"
						onClick={() => handleModalSubmit({ from: fromInput, to: toInput })}
						disabled={!validateHhMm(fromInput) || !validateHhMm(toInput)}
					>
						Set Hours
					</ButtonWithIcon>
				</TwoSideLayout>
			</Grid>
		</Modal>
	);
}

function renderTimeCell({ value }) {
	return value || 'NA';
}

function validateHhMm(value) {
	const timeSchema = yup
		.string()
		.trim()
		.matches(
			/^([0-1]?[0-9]|2[0-4]):([0-5][0-9])(:[0-5][0-9])?$/,
			'Time Is not in correct format',
		)
		.required();

	return timeSchema.isValidSync(value);
}

yup.addMethod(yup.string, 'isValidTime', validateHhMm);

function SetWorkingHoursTitle(props) {
	const { openAddWorkingDaysModal } = props;
	return (
		<Button transparent p="none" onClick={openAddWorkingDaysModal}>
			<Stack row gap="sm">
				<Center horizontal={false}>
					<Title
						primary
						title={<Icon name="plusCircle" size="xs" m="none" />}
						size="xs"
					/>
					<Title primary title="Set branch working hours" size="xs" />
				</Center>
			</Stack>
		</Button>
	);
}
