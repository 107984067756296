import React, { useState } from 'react';
import {
	Align,
	Badge,
	BasicTable,
	Button,
	ButtonWithIcon,
	Card,
	Center,
	Grid,
	Icon,
	InlineLoading,
	Input,
	MainStackWithAside,
	Modal,
	Stack,
	Title,
	TwoSideLayout,
} from '@zeal/zeal-ui';

import AppContainer from '../../../App/AppContainer';

import ACLBoundary from '../../../App/ACL/ACLBoundary';

import RewardsToolbarStart from '../../components/RewardsToolbarStart';

import useLoyaltyPoints, {
	useAddReward,
	useDeleteReward,
	useUpdateReward,
} from './data/useLoyaltyPoints';

import RewardProperties from './components/RewardProperties';

export default function LoyaltyPoints(props) {
	const [rewardName, setRewardName] = useState(null);
	const [rewardPoints, setRewardPoints] = useState(null);
	const [isAddEditModalOpen, setIsAddEditModalOpen] = useState(false);
	const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
	const [isEditingReward, setIsEditingReward] = useState(false);
	const [deletedRewardIndex, setDeletedRewardIndex] = useState(false);

	const loyaltyPointsQuery = useLoyaltyPoints();

	const { data: rewardsData, isLoading: isDataLoading } = loyaltyPointsQuery;

	const { doAddReward, isLoading: isUpdateRewardsLoading } = useAddReward({
		onSuccess: () => {
			loyaltyPointsQuery.refetch();
			setIsAddEditModalOpen(false);
			resetAddEditModal();
		},
	});

	const { doUpdateReward } = useUpdateReward({
		onSuccess: () => {
			loyaltyPointsQuery.refetch();
			setIsAddEditModalOpen(false);
			resetAddEditModal();
		},
	});

	const { doDeleteReward, isLoading: isDeleteLoading } = useDeleteReward({
		onSuccess: () => {
			loyaltyPointsQuery.refetch();
			setIsDeleteModalOpen(false);
		},
	});

	const resetAddEditModal = () => {
		setRewardName('');
		setRewardPoints('');
	};

	const pointsBreadCrumb = [
		{
			name: 'Rewards: Points And Rewards',
			to: '/rewards',
			icon: 'gift',
		},
	];

	const openAddEditModal = () => {
		setIsAddEditModalOpen(true);
	};

	const handleOpenDeleteModal = (index) => {
		setIsDeleteModalOpen(true);
		setDeletedRewardIndex(index);
	};

	const actionsCell = ({ row }) => (
		<Align align="right" gap="1">
			{/* todo: Commented until API error resolved */}
			{/*
        <Button
          transparent
          stretch={false}
          p="none"
          m="none"
          onClick={() => handleOpenEditModal(row)}
        >
          <Icon
            rounded
            bg="disabled"
            name="pen"
            size="lg"
            p="xs"
            margin="none"
          />
        </Button>
        */}
			<ACLBoundary
				aclUnAuthProps={{ disabled: true }}
				aclGrants={['control:loyalty']}
			>
				<Button
					transparent
					stretch={false}
					p="none"
					m="none"
					onClick={() => handleOpenDeleteModal(row.id)}
				>
					<Icon
						rounded
						bg="danger"
						name="trashAlt"
						size="lg"
						p="xs"
						margin="none"
					/>
				</Button>
			</ACLBoundary>
		</Align>
	);

	const addRewardBtnClick = () => {
		setIsEditingReward(false);
		resetAddEditModal();
		openAddEditModal();
	};

	const handleOpenEditModal = (row) => {
		const { values: rowData } = row;
		setRewardName(JSON.parse(rowData?.rewards || '{}'));
		setRewardPoints(rowData?.points);
		setIsEditingReward(true);
		openAddEditModal();
	};

	const handleRewardSubmit = React.useCallback(() => {
		const hasRewardName = !!rewardName;
		const hasRewardPoints = !isNaN(rewardPoints) && rewardPoints > 0;

		if (hasRewardName && hasRewardPoints) {
			isEditingReward
				? doUpdateReward({ rewards: [rewardName], points: rewardPoints })
				: doAddReward({ rewards: [rewardName], points: rewardPoints });
		} else {
			// pass
		}
	}, [rewardName, rewardPoints]);

	const handleRewardDelete = (index) => {
		const selectedReward = rewardsData[index];

		doDeleteReward({ rewardId: selectedReward?.uuid });

		setIsDeleteModalOpen(false);
	};

	const rewardPointColumns = [
		{ Header: 'Reward Name', accessor: 'rewards', Cell: RenderRewardsCell },
		{
			Header: 'Points Worth',
			accessor: 'points',
			Cell: RenderWithBadge,
		},
		{ Header: '', accessor: 'actions', Cell: actionsCell },
	];

	const rewardsTable = (rewardsData || []).length > 0 && (
		<BasicTable
			data={rewardsData || []}
			columns={rewardPointColumns}
			headerPadding="lg"
			cellPadding="lg"
		/>
	);

	const onChangeRewardPoints = (evt) => {
		setRewardPoints(evt.target.value);
	};

	const onChangeRewardName = (evt) => {
		setRewardName(evt.target.value);
	};

	const closeAddEditModal = () => {
		setIsAddEditModalOpen(false);
	};

	const closeDeleteModal = () => {
		setIsDeleteModalOpen(false);
	};

	const showLoading = (isUpdateRewardsLoading
		|| isDataLoading
		|| isDeleteLoading) && <InlineLoading />;

	const toolbarEnd = (
		<Align align="right">
			{showLoading}
			<ACLBoundary
				aclUnAuthProps={{ disabled: true }}
				aclGrants={['control:loyalty']}
			>
				<ButtonWithIcon
					secondary
					label="Add Reward"
					btnIcon="plus"
					size="xs"
					m="none"
					onClick={addRewardBtnClick}
				/>
			</ACLBoundary>
		</Align>
	);

	const loadingComp = (
		<Card p="none">
			<Center>
				<Stack m="xl">
					<InlineLoading />
				</Stack>
			</Center>
		</Card>
	);

	const pageContent = isDataLoading ? (
		loadingComp
	) : (
		<Card p="none">
			<br />
			{rewardsTable}
			<EmptyState data={rewardsData} addRewardBtnClick={addRewardBtnClick} />
		</Card>
	);

	return (
		<AppContainer protected breadcrumbs={pointsBreadCrumb}>
			<MainStackWithAside
				toolbarStart={<RewardsToolbarStart />}
				toolbarEnd={toolbarEnd}
				aside={<RewardProperties />}
			>
				<Stack m="md" p="none">
					{pageContent}
				</Stack>
				<AddEditModal
					isEditingReward={isEditingReward}
					rewardName={rewardName}
					rewardPoints={rewardPoints}
					isAddEditModalOpen={isAddEditModalOpen}
					handleRewardSubmit={handleRewardSubmit}
					onChangeRewardPoints={onChangeRewardPoints}
					onChangeRewardName={onChangeRewardName}
					closeModal={closeAddEditModal}
				/>
				<DeleteModal
					closeModal={closeDeleteModal}
					isDeleteModalOpen={isDeleteModalOpen}
					handleRewardDelete={handleRewardDelete}
					rewardIndex={deletedRewardIndex}
				/>
			</MainStackWithAside>
		</AppContainer>
	);
}

function RenderWithBadge({ value }) {
	return <Badge secondary>{value}</Badge>;
}

function RenderRewardsCell({ value }) {
	return JSON.parse(value || '{}');
}

function EmptyState({ data, addRewardBtnClick }) {
	return (
		(data || []).length === 0 && (
			<Center>
				<Stack>
					<Title
						title="Start Adding Rewards"
						subtitle="Add A Punch Card And Fill It With Your Rewards "
						size="md"
					/>
					<Center>
						<ACLBoundary
							aclUnAuthProps={{ disabled: true }}
							aclGrants={['control:loyalty']}
						>
							<ButtonWithIcon
								secondary
								label="Add Reward"
								btnIcon="plus"
								size="xs"
								m="sm"
								onClick={addRewardBtnClick}
							/>
						</ACLBoundary>
					</Center>
				</Stack>
			</Center>
		)
	);
}

function AddEditModal(props) {
	const {
		isAddEditModalOpen,
		isEditingReward,
		rewardName,
		rewardPoints,
		handleRewardSubmit,
		onChangeRewardName,
		onChangeRewardPoints,
		closeModal,
	} = props;

	const modalTitle = isEditingReward ? 'Edit Reward' : 'Add Reward';

	const modalBtnText = isEditingReward ? 'Save' : 'Add';

	return (
		<Modal
			isModalOpen={isAddEditModalOpen}
			title={modalTitle}
			onClose={closeModal}
		>
			<Stack p="none">
				<Stack>
					<Input
						inputName="name"
						placeholder="name"
						margin="sm"
						label="Reward Name"
						onChange={onChangeRewardName}
						value={rewardName}
					/>
					<Input.Number
						inputName="points"
						placeholder="points"
						margin="sm"
						label="Points"
						onChange={onChangeRewardPoints}
						value={rewardPoints}
					/>
				</Stack>
				<Grid gap="5">
					<span></span>
					<TwoSideLayout>
						<Button stretch={false} size="md" onClick={closeModal}>
							Cancel
						</Button>
						<ButtonWithIcon
							stretch={false}
							size="md"
							primary
							onClick={handleRewardSubmit}
						>
							{modalBtnText}
						</ButtonWithIcon>
					</TwoSideLayout>
				</Grid>
			</Stack>
		</Modal>
	);
}

function DeleteModal(props) {
	const { isDeleteModalOpen, handleRewardDelete, closeModal, rewardIndex }
		= props;

	return (
		<Modal
			isModalOpen={isDeleteModalOpen}
			title="Delete Reward"
			onClose={closeModal}
		>
			<Stack p="none">
				<Title title="Are you sure you want to delete this reward?" />

				<Grid gap="5">
					<span></span>
					<TwoSideLayout>
						<Button stretch={false} size="md" onClick={closeModal}>
							Cancel
						</Button>
						<ButtonWithIcon
							secondary
							stretch={false}
							size="md"
							onClick={() => handleRewardDelete(rewardIndex)}
						>
							Delete
						</ButtonWithIcon>
					</TwoSideLayout>
				</Grid>
			</Stack>
		</Modal>
	);
}
