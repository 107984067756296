import { max, min } from 'lodash-es';

export default function getDataScaleLog(data, defaultCfg) {
	const meta = {};

	if (!data) {
		return {};
	}

	const d = data?.filter?.((v) => v) || data;

	meta.min = min(d);
	meta.max = max(d);
	meta.minPct = meta.min / (meta.max / 100);
	meta.isLog10 = meta.minPct < 5;
	meta.isLog02 = meta.minPct < 2;
	meta.isLog = meta.isLog10 || meta.isLog02;

	meta.base = meta.isLog02 ? 2 : meta.isLog10 ? 10 : 1;

	return {
		...defaultCfg,
		isLog: meta.isLog,
		logBase: meta.base,
		type: meta.isLog ? 'log' : defaultCfg?.type,
	};
}
