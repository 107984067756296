import React from 'react';

import {
	Align,
	Button,
	ButtonWithIcon,
	Center,
	Grid,
	Icon,
	InlineError,
	InlineLoading,
	Modal,
	Stack,
	Title,
	TwoSideLayout,
} from '@zeal/zeal-ui';

export default function DeleteModal(props) {
	const {
		isDeleteModalOpen,
		handleTeamDelete,
		closeModal,
		uuid,
		isError,
		isLoading,
	} = props;

	return (
		<Modal
			isModalOpen={isDeleteModalOpen}
			title="Delete Team"
			onClose={closeModal}
		>
			<Stack p="none">
				<Center>
					<Icon rounded name="cross" bg="danger" size="3xl" />
				</Center>
				<Center>
					<Title
						title="Are you sure you want to delete this team?"
						margin="md"
					/>
				</Center>

				<Grid gap="5">
					<span></span>
					<TwoSideLayout>
						<ButtonWithIcon
							stretch={false}
							m="xxs"
							px="md"
							py="sm"
							onClick={closeModal}
						>
							Cancel
						</ButtonWithIcon>
						<Align align="left">
							<InlineError hide={!isError} />
							<InlineLoading hide={!isLoading} />
							<ButtonWithIcon
								secondary
								stretch={false}
								m="xxs"
								px="md"
								py="sm"
								onClick={() => handleTeamDelete(uuid)}
							>
								Delete
							</ButtonWithIcon>
						</Align>
					</TwoSideLayout>
				</Grid>
			</Stack>
		</Modal>
	);
}
