import React, { useState } from 'react';
import {
	ButtonWithIcon,
	Grid,
	MainStackWithIcon,
	Modal,
	Stack,
	Title,
	TwoSideLayout,
} from '@zeal/zeal-ui';
import useCategories, { useDeleteTag } from '../data/useCategories';


export default function CategoriesCard(props) {
	const { m, my } = props;
	const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
	const [deleteTagIndex, setDeleteTagIndex] = useState(false);
	const [deleteTag, setDeleteTag] = useState([]);

	const closeDeleteModal = () => {
		setIsDeleteModalOpen(false);
	};

	const handleDeleteTag = (index, tag) => {
		props.handleDeleteTag(index, tag);
		setIsDeleteModalOpen(false);
	};

	const handleOpenDeleteModal = (index, tag) => {
		setIsDeleteModalOpen(true);
		setDeleteTagIndex(index);
		setDeleteTag(tag);
	};

	const handleAddTag = (tag) => {
		props.handleAddTag(tag);
	};

	return (
		<React.Fragment>
			<MainStackWithIcon>
				<Stack row gap="lg" my={my} m={m} wrap p="none">
					{props.tags?.map((tag, index) => (
						<>
							{props.handleDeleteTag ? (
								<ButtonWithIcon
									danger="true"
									py="xxs"
									label={tag?.name}
									btnIcon="minus"
									onClick={() => handleOpenDeleteModal(index, tag)}
								/>
							) : (
								<ButtonWithIcon
									primary="true"
									py="xxs"
									label={tag?.name}
									btnIcon="plus"
									onClick={() => handleAddTag(tag)}
								/>
							)}
						</>
					))}
				</Stack>
				<DeleteModal
					closeModal={closeDeleteModal}
					isDeleteModalOpen={isDeleteModalOpen}
					handleDeleteTag={handleDeleteTag}
					deleteTag={deleteTag}
					setDeleteTagIndex={deleteTagIndex}
				/>
			</MainStackWithIcon>
		</React.Fragment>
	);
}
function DeleteModal(props) {
	const {
		isDeleteModalOpen,
		handleDeleteTag,
		closeModal,
		deleteTag,
		setDeleteTagIndex,
	} = props;

	return (
		<Modal
			isModalOpen={isDeleteModalOpen}
			title="Delete Tag"
			onClose={closeModal}
		>
			<Stack p="none">
				<Title title="Are you sure you want to delete this tag?" />

				<Grid gap="5">
					<span></span>
					<TwoSideLayout>
						<ButtonWithIcon
							stretch={false}
							sm="xxs"
							px="md"
							py="sm"
							onClick={closeModal}
						>
							Cancel
						</ButtonWithIcon>
						<ButtonWithIcon
							secondary
							stretch={false}
							m="xxs"
							px="md"
							py="sm"
							onClick={() => handleDeleteTag(setDeleteTagIndex, deleteTag)}
						>
							Delete
						</ButtonWithIcon>
					</TwoSideLayout>
				</Grid>
			</Stack>
		</Modal>
	);
}
