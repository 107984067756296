import React from 'react';
import { Card, MainCardWithAside } from '@zeal/zeal-ui';

export default function LoyaltyCardComponent(props) {
	const {
		LoyaltyCardPunchValue,
		LoyaltyCardRewards,
		index,
		handleCardBlur,
		fillingCardIndex,
	} = props;

	const disabledCardStyle = (index) => fillingCardIndex !== index && fillingCardIndex !== -1
		? { pointerEvents: 'none', opacity: '0.6' }
		: {};

	return (
		<Card p="none">
			<MainCardWithAside
				tabIndex={index}
				onBlur={(event) => handleCardBlur(event, index)}
				style={disabledCardStyle(index)}
				aside={LoyaltyCardPunchValue}
			>
				{LoyaltyCardRewards}
			</MainCardWithAside>
		</Card>
	);
}
