import React from 'react';
import {
	Align,
	Button,
	ButtonWithIcon,
	Grid,
	InlineLoading,
	Input,
	Modal,
	Stack,
	Title,
	TwoSideLayout,
} from '@zeal/zeal-ui';

export default function RewardAddEditModal(props) {
	const {
		isEditing,
		isAddEditModalOpen,
		welcomeRewardInput,
		onInputChange,
		quotaInput,
		closeModal,
		handleSubmit,
		handleDelete,
		isLoading,
	} = props;

	const deleteButtonContent = isEditing && (
		<ButtonWithIcon
			stretch={false}
			m="xxs"
			px="md"
			py="sm"
			secondary
			onClick={handleDelete}
		>
			Delete
		</ButtonWithIcon>
	);

	const welcomeRewardModalTitle = isEditing
		? 'Edit Welcome Reward'
		: 'Add Welcome Reward';

	return (
		<Modal
			isModalOpen={isAddEditModalOpen}
			title={welcomeRewardModalTitle}
			onClose={closeModal}
		>
			<Stack p="none">
				<Title title="Welcome Reward Name" margin="xs" />
				<Input
					type="text"
					inputName="welcomeRewardInput"
					name="welcomeRewardInput"
					placeholder="Type name"
					onChange={onInputChange}
					value={welcomeRewardInput}
				/>
			</Stack>
			<Stack>
				<Title title="Quota" margin="xs" />
				<Input.Number
					type="text"
					inputName="quotaInput"
					name="quotaInput"
					placeholder="Quota"
					onChange={onInputChange}
					value={quotaInput}
				/>
			</Stack>
			<Grid gap="5">
				<span></span>
				<TwoSideLayout>
					<ButtonWithIcon
						stretch={false}
						m="xxs"
						px="md"
						py="sm"
						onClick={closeModal}
					>
						Cancel
					</ButtonWithIcon>
					<Stack p="none" gap="md" row>
						<Align align="left">
							<InlineLoading hide={!isLoading} />
							{deleteButtonContent}
						</Align>

						<ButtonWithIcon
							stretch={false}
							m="xxs"
							px="md"
							py="sm"
							primary
							onClick={handleSubmit}
						>
							Save
						</ButtonWithIcon>
					</Stack>
				</TwoSideLayout>
			</Grid>
		</Modal>
	);
}
