import React from 'react';

export default function HandleOnStorySybmit(
	expireDate,
	announcement,
	selected,
	uuid,
) {
	let expiresIn;
	if (expireDate) {
		let dtt = new Date(expireDate);

		let dd = dtt.getDate();
		if (dd < 10) {
			dd = '0' + dd;
		}

		let MMM = dtt.getMonth() + 1;
		if (MMM < 10) {
			MMM = '0' + MMM;
		}

		let yyyy = dtt.getFullYear();

		let h = dtt.getHours();
		if (h < 10) {
			h = '0' + h;
		}

		let mm = dtt.getMinutes();
		if (mm < 10) {
			mm = '0' + mm;
		}

		expiresIn = yyyy + '-' + MMM + '-' + dd + ' ' + h + ':' + mm + ':' + '00';
	}

	const formData = new FormData();
	formData.append('image', announcement.photo);
	formData.append('uuid', uuid);
	formData.append('expire_at', expiresIn ? expiresIn : selected);
	formData.append('description', announcement.description);

	if (announcement.promo_code_uuid || announcement?.promo_code?.uuid)
	{formData.append(
		'promo_code_uuid',
		announcement.promo_code_uuid || announcement?.promo_code?.uuid,
	);}

	formData.append('type', announcement.type.value || announcement.type);
	formData.append('title', announcement.title);

	return formData;
}
