import React from 'react';

import { Button, Icon, Stack, Title } from '@zeal/zeal-ui';

export default function FilterRuleHandle(props) {
	const { filtersCount } = props;

	const addNewFilterLabel
		= filtersCount > 0 ? 'Add another filter' : 'Add Filter';

	return (
		<Stack row gap="md" my="sm">
			<Button
				size="xs"
				m="none"
				p="none"
				className="-m-0.5"
				onClick={props.handleOnInit}
			>
				<Icon name="plus" size="xs" />
			</Button>
			<Title subtitle={addNewFilterLabel} size="sm" muteSubtitle={false} />
		</Stack>
	);
}
