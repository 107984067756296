import React from 'react';
import { useMutation } from 'react-query';

import { ApiRequest } from '@zeal/zeal-lib';
import { useQuery } from '../../App/QueryClient';

async function apiArchived({ queryKey: [, filters] }) {
	return new ApiRequest('v3/specifics')
		.addHeader('Accept', 'application/json')
		.GET();
}

async function apiAddReward({ ...payload }) {
	// return new ApiRequest("v3/specifics")
	// .addHeader("Accept", "application/json")
	// .addHeader("Content-type", "application/json")
	// .POST(JSON.stringify(payload), true)

	console.warn('[API/dummy]', 'useArchived::apiAddReward');

	return Promise.resolve(true);
}

async function apiEditReward({ ...payload }) {
	// return new ApiRequest("v3/specifics")
	// .addHeader("Accept", "application/json")
	// .addHeader("Content-type", "application/json")
	// .POST(JSON.stringify(payload), true)

	console.warn('[API/dummy]', 'useArchived::apiEditReward');

	return Promise.resolve(true);
}

async function apiDeleteReward({ ...payload }) {
	// return new ApiRequest("v3/specifics")
	// .addHeader("Accept", "application/json")
	// .addHeader("Content-type", "application/json")
	// .POST(JSON.stringify(payload), true)

	console.warn('[API/dummy]', 'useArchived::apiDeleteReward');

	return Promise.resolve(true);
}

export function useAddReward(opt) {
	const mutation = useMutation(apiAddReward);

	const { data, mutate, isSuccess } = mutation;

	React.useEffect(() => {
		if (!isSuccess) {
			return null;
		}

		if (opt.onSuccess) {
			opt.onSuccess();
		}
	}, [data, isSuccess]);

	return {
		...mutation,
		doAddReward: mutate,
	};
}

export function useEditReward(opt) {
	const mutation = useMutation(apiEditReward);

	const { data, mutate, isSuccess } = mutation;

	React.useEffect(() => {
		if (!isSuccess) {
			return null;
		}

		if (opt.onSuccess) {
			opt.onSuccess();
		}
	}, [data, isSuccess]);

	return {
		...mutation,
		doEditReward: mutate,
	};
}

export function useDeleteReward(opt) {
	const mutation = useMutation(apiDeleteReward);

	const { data, mutate, isSuccess } = mutation;

	React.useEffect(() => {
		if (!isSuccess) {
			return null;
		}

		if (opt.onSuccess) {
			opt.onSuccess();
		}
	}, [data, isSuccess]);

	return {
		...mutation,
		doDeleteReward: mutate,
	};
}

export default function useArchived() {
	const q = useQuery({
		queryFn: apiArchived,
		staleTime: 1000 * 60 * 15, // 15 min
		queryKey: ['z-archived-stories'],
	});

	React.useDebugValue(q);

	return q;
}
