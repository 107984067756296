import React from 'react';

import {
	Align,
	BasicTable,
	Button,
	Center,
	Icon,
	InlineEmpty,
	Stack,
	Title,
} from '@zeal/zeal-ui';
import ACLBoundary from '../../App/ACL/ACLBoundary';

export default function MenuItemsTable(props) {
	const { items } = props;
	const columns = [
		{ Header: 'Item name', accessor: 'name' },
		{
			Header: 'Description',
			accessor: 'description',
		},
		{
			Header: 'Price',
			accessor: 'price',
		},
		{
			Header: 'Add-ons',
			accessor: 'attribute_groups',
			Cell: renderAddOnsCell,
		},
		{
			Header: 'Points Value',
			accessor: 'loyalty_multiplier',
		},
		{ Header: '', accessor: 'actions', Cell: renderActionsCell(props) },
	];

	const isEmpty = (items || [])?.length === 0;

	const emptyContent = (
		<Center>
			<Stack m="lg">
				<InlineEmpty label="No Items yet" />
			</Stack>
		</Center>
	);

	return (
		<Stack p="none" m="md">
			<Stack p="none" m="md" mx="none" mt="none">
				<Title title="Items" size="sm" />
			</Stack>
			<BasicTable
				border
				shadow={false}
				data={items || []}
				columns={columns}
				headerPadding="lg"
				cellPadding="lg"
				isEmpty={isEmpty}
				emptyContent={emptyContent}
			></BasicTable>
		</Stack>
	);
}

function renderActionsCell(props) {
	const { handleRowDelete, handleRowEdit } = props;

	return ({ row }) => (
		<Align align="right" gap="1">
			<ACLBoundary>
				<Button
					transparent
					stretch={false}
					p="none"
					m="none"
					onClick={() => handleRowEdit(row?.original)}
					aclUnAuthProps={{ disabled: true }}
					aclGrants={['control:menu']}
				>
					<Icon
						rounded
						bg="disabled"
						name="pen"
						size="lg"
						p="xs"
						margin="none"
					/>
				</Button>
				<Button
					transparent
					stretch={false}
					p="none"
					m="none"
					onClick={() => handleRowDelete(row?.original)}
					aclUnAuthProps={{ disabled: true }}
					aclGrants={['control:menu']}
				>
					<Icon
						rounded
						bg="danger"
						name="trashAlt"
						size="lg"
						p="xs"
						margin="none"
					/>
				</Button>
			</ACLBoundary>
		</Align>
	);
}

function renderAddOnsCell({ value }) {
	return (value || [])?.length;
}
