import React from 'react';

import { ApiRequest } from '@zeal/zeal-lib';
import { useQuery } from '../../App/QueryClient';
import { useQueryString } from '../../App/useQueryString';

async function apiVouchers({ queryKey: [, filters] }) {
	let memberUuid = window.location.href.split('/')[4];
	return new ApiRequest(`v3/voucher-wallet/${memberUuid}`)

		.addHeader('Accept', 'application/json')
		.GET();
}

export default function useVouchers() {
	const [queryState] = useQueryString();

	const q = useQuery({
		queryFn: apiVouchers,
		staleTime: 1000 * 60 * 15, // 15 min
		queryKey: [
			'z-voucherss',
			{
				data: queryState?.date,
			},
		],
	});

	React.useDebugValue(q);

	return q;
}
