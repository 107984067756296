import React, { useCallback, useReducer } from 'react';
import { ButtonWithIcon, Icon, Stack, TwoSideLayout } from '@zeal/zeal-ui';

import useWelcomeReward, {
	useAddWelcomeReward,
	useDeleteWelcomeReward,
	useUpdateWelcomeReward,
} from '../data/useWelcomeReward';
import ACLBoundary from '../../App/ACL/ACLBoundary';
import EmptyState from './EmptyState';
import WelcomeRewardRow from './WelcomeRewardRow';
import RewardAddEditModal from './RewardAddEditModal';
import { cloneDeep } from 'lodash-es';
import openToastr from '../../App/Toastr/openToastr';

export default function WelcomeReward(props) {
	const welcomeRewardQuery = useWelcomeReward();
	const welcomeRewardData = welcomeRewardQuery?.data;

	const initialState = {
		welcomeRewards: [],
		isEditing: false,
		welcomeRewardInput: '',
		quotaInput: '',
		isAddEditModalOpen: false,
		selectedReward: null,
	};

	const propertiesReducer = (prevState, newState) => ({
		...prevState,
		...newState,
	});

	const [rewardState, rewardDispatch] = useReducer(
		propertiesReducer,
		initialState
	);

	React.useEffect(() => {
		rewardDispatch({ welcomeRewards: welcomeRewardData?.data || [] });
	}, [welcomeRewardData]);

	const { doAddWelcomeReward, isLoading: isAddRewardLoading } =
		useAddWelcomeReward({
			onSuccess: () => {
				rewardDispatch({ isAddEditModalOpen: false });
				welcomeRewardQuery.refetch();
			},
			onError: (error) => {
				const clonedError = error?.response.clone();

				if (!clonedError?.bodyUsed) {
					clonedError.json().then((err) => {
						openToastr({
							message: err?.message || 'Failed to add welcome reward',
							isError: true,
						});
					});
				}
			},
		});

	const { doUpdateWelcomeReward, isLoading: isUpdateRewardLoading } =
		useUpdateWelcomeReward({
			onSuccess: () => {
				rewardDispatch({ isAddEditModalOpen: false });
				welcomeRewardQuery.refetch();
			},
			onError: (error) => {
				const clonedError = error?.response.clone();

				if (!clonedError?.bodyUsed) {
					clonedError.json().then((err) => {
						openToastr({
							message: err?.message || 'Failed to update welcome reward',
							isError: true,
						});
					});
				}
			},
		});

	const { doDeleteWelcomeReward, isLoading: isLoadingDeleteReward } =
		useDeleteWelcomeReward({
			onSuccess: () => {
				rewardDispatch({ isAddEditModalOpen: false });
				welcomeRewardQuery.refetch();
			},
			onError: (error) => {
				const clonedError = error?.response.clone();

				if (!clonedError?.bodyUsed) {
					clonedError.json().then((err) => {
						openToastr({
							message: err?.message || 'Failed to delete welcome reward',
							isError: true,
						});
					});
				}
			},
		});

	const openModal = () => {
		rewardDispatch({ isAddEditModalOpen: true });
	};

	const openAddModal = useCallback(() => {
		rewardDispatch({
			welcomeRewardInput: '',
			isEditing: false,
			quotaInput: '',
		});
		openModal();
	}, []);

	const openEditModal = (reward) => {
		rewardDispatch({
			welcomeRewardInput: reward?.reward,
			quotaInput: reward?.quota,
			isEditing: true,
			selectedReward: reward,
		});
		openModal();
	};

	const handleSubmit = useCallback(() => {
		if (rewardState.isEditing) {
			const payload = {
				reward: rewardState.welcomeRewardInput,
				quota: rewardState.quotaInput,
				rewardId: rewardState?.selectedReward?.uuid,
				active: rewardState?.selectedReward?.active || false,
			};
			doUpdateWelcomeReward(payload);
		} else {
			const payload = {
				reward: rewardState.welcomeRewardInput,
				quota: rewardState.quotaInput,
				active: true,
			};
			doAddWelcomeReward(payload);
		}
	}, [
		doAddWelcomeReward,
		doUpdateWelcomeReward,
		rewardState.isEditing,
		rewardState.quotaInput,
		rewardState?.selectedReward?.active,
		rewardState?.selectedReward?.uuid,
		rewardState.welcomeRewardInput,
	]);

	const handleDelete = useCallback(() => {
		doDeleteWelcomeReward({ uuid: rewardState?.selectedReward?.uuid });
	}, [doDeleteWelcomeReward, rewardState?.selectedReward?.uuid]);

	const changeWelcomeRewardToggle = (welcomeReward) => {
		const welcomeRewardsClone = cloneDeep(rewardState.welcomeRewards);

		const updatedWelcomeRewards = (welcomeRewardsClone || []).map((r) => {
			if (r?.uuid === welcomeReward?.uuid) {
				r.active = !r.active;
			} else {
				r.active = false;
			}

			return r;
		});

		rewardDispatch({ welcomeRewards: updatedWelcomeRewards });

		const payload = {
			active: !welcomeReward.active,
			rewardId: welcomeReward?.uuid,
			reward: welcomeReward?.reward,
			quota: welcomeReward?.quota,
		};
		doUpdateWelcomeReward(payload);
	};

	const welcomeRewardsTable = (rewardState.welcomeRewards || []).map(
		(r, index) => (
			<WelcomeRewardRow
				key={index}
				welcomeRewardValue={r}
				openEditModal={openEditModal}
				changeWelcomeRewardToggle={changeWelcomeRewardToggle}
				isLoading={isUpdateRewardLoading}
			/>
		)
	);

	const welcomeRewardRow =
		welcomeRewardData?.data?.length > 0 ? (
			<Stack m="md" gap="lg" p="none" marginY={false}>
				{welcomeRewardsTable}
			</Stack>
		) : (
			<EmptyState data={welcomeRewardData} title="Welcome Reward" />
		);

	const onInputChange = useCallback(
		(evt) => rewardDispatch({ [evt.target.name]: evt.target.value }),
		[]
	);

	const closeAddEditModal = useCallback(
		() => rewardDispatch({ isAddEditModalOpen: false }),
		[]
	);

	return (
		<div>
			<div>
				<TwoSideLayout>
					<Stack wrap row gap="xl" m="md">
						<h3>Welcome Reward</h3>
						<Icon name="questionCircle" />
					</Stack>
					<Stack wrap row gap="xl" m="sm" marginY={false}>
						<ACLBoundary>
							<ButtonWithIcon
								stretch={false}
								primary
								circle
								onClick={openAddModal}
								py="xs"
								aclUnAuthProps={{ disabled: true }}
								aclGrants={['control:loyalty']}
							>
								<Icon name="plusCircle" size="sm" margin="none" bg="pure" />
							</ButtonWithIcon>
						</ACLBoundary>
					</Stack>
				</TwoSideLayout>
				{welcomeRewardRow}
			</div>
			<RewardAddEditModal
				isEditing={rewardState.isEditing}
				isAddEditModalOpen={rewardState.isAddEditModalOpen}
				welcomeRewardInput={rewardState.welcomeRewardInput}
				quotaInput={rewardState.quotaInput}
				handleSubmit={handleSubmit}
				handleDelete={handleDelete}
				isLoading={
					isLoadingDeleteReward || isUpdateRewardLoading || isAddRewardLoading
				}
				onInputChange={onInputChange}
				closeModal={closeAddEditModal}
			/>
		</div>
	);
}
