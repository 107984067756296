import React from 'react';
import { Route, Switch } from 'react-router-dom';

import ACLBoundary from '../App/ACL/ACLBoundary';

import Announcements from './Announcements';

export default function AnnouncementsRoutes() {
	return (
		<Switch>
			<ACLBoundary
				aclUnAuthProps={{ replace: true }}
				aclGrants={['view:announcements']}
			>
				<Route exact path="/announcements" component={Announcements} />
			</ACLBoundary>
		</Switch>
	);
}
