import { useDebugValue, useEffect, useState } from 'react';
import { useMutation } from 'react-query';

import { ApiRequest } from '@zeal/zeal-lib';
import { useQuery } from '../../App/QueryClient';

async function apiAddBranchMenu({ ...payload }) {
	return new ApiRequest('menus')
		.addHeader('Accept', 'application/json')
		.addHeader('Content-Type', 'application/json')
		.POST(JSON.stringify(payload));
}

export function useAddBranchMenu(opt) {
	const mutation = useMutation(apiAddBranchMenu);

	const { data, mutate, isSuccess } = mutation;

	useEffect(() => {
		if (!isSuccess) {
			return null;
		}

		if (opt.onSuccess) {
			opt.onSuccess();
		}
	}, [data, isSuccess]);

	return {
		...mutation,
		doAddBranchMenu: mutate,
	};
}

async function apiDeleteBranchMenu({ ...payload }) {
	return new ApiRequest(`menus/${payload?.uuid}`)
		.addHeader('Accept', 'application/json')
		.addHeader('Content-Type', 'application/json')
		.DELETE();
}

export function useDeleteBranchMenu(opt) {
	const mutation = useMutation(apiDeleteBranchMenu);

	const { data, mutate, isSuccess } = mutation;

	useEffect(() => {
		if (!isSuccess) {
			return null;
		}

		if (opt.onSuccess) {
			opt.onSuccess();
		}
	}, [data, isSuccess]);

	return {
		...mutation,
		doDeleteBranchMenu: mutate,
	};
}

async function apiBranchesMenus({ queryKey: [, filters] }) {
	return new ApiRequest('menus/branches')
		.addHeader('Accept', 'application/json')
		.addQuery('paginate', filters?.paginate)
		.GET();
}

export default function useBranchesMenus(opt) {
	const [branchesWithMenu, setBranchesWithMenu] = useState([]);
	const [branchesWithoutMenu, setBranchesWithoutMenu] = useState([]);

	const q = useQuery({
		queryFn: apiBranchesMenus,
		staleTime: Infinity,
		queryKey: [
			'z-all-branches-menus',
			{
				paginate: false,
			},
		],
	});

	const formatBranch = opt?.formatBranch || ((d) => d);

	const { data: branchesData } = q;

	useEffect(() => {
		if (branchesData) {
			setBranchesWithoutMenu([]);
			setBranchesWithMenu([]);
			branchesData.forEach((b, index, arr) => {
				if (b.menu)
				{setBranchesWithMenu((prevArray) => [...prevArray, formatBranch(b)]);}
				else
				{setBranchesWithoutMenu((prevArray) => [
					...prevArray,
					formatBranch(b),
				]);}
			});
		} else {
			setBranchesWithoutMenu([]);
			setBranchesWithMenu([]);
		}
	}, [branchesData]);

	useDebugValue(q);

	return {
		...q,
		branchesWithMenu,
		branchesWithoutMenu,
	};
}

async function apiMenus({ queryKey: [, filters] }) {
	return new ApiRequest(`menus/${filters?.menuId}`)
		.addHeader('Accept', 'application/json')
		.addQuery('paginate', filters?.paginate)
		.GET();
}

export function useMenu(opt) {
	const { menuId, itemId, categoryId, subcategoryId } = opt;

	const q = useQuery({
		queryFn: apiMenus,
		staleTime: Infinity,
		queryKey: [
			'z-single-menu',
			{
				menuId,
			},
		],
		enabled: !!menuId,
	});

	useDebugValue(q);

	if (itemId && categoryId) {
		const selectedCategory = q?.data?.categories?.find(
			(c) => c.uuid === categoryId,
		);

		const selectedSubcategory = q?.data?.categories?.find(
			(c) => c.uuid === subcategoryId,
		);

		if (selectedCategory) {
			const selectedItem = [
				...(selectedCategory?.items || []),
				...(selectedSubcategory?.items || []),
			]?.find((i) => i.uuid === itemId);

			return {
				...q,
				data: selectedItem,
			};
		}

		return { ...q, data: null };
	}

	return q;
}
