import React from 'react';

import {
	Badge,
	Button,
	Center,
	Icon,
	InlineLoading,
	Stack,
	Title,
	TitleChild,
} from '@zeal/zeal-ui';

export default function CloseOwnAppMode(props) {
	const { appName, turnOffCustomDashboard, isLoading } = props;

	return (
		<Badge secondary rounded="md">
			<Stack row p="xs">
				<Center text="false" gap="3">
					<Title
						primary
						title={
							<Icon
								rounded
								size="sm"
								bg="primary"
								margin="none"
								name="checkCircleSolid"
							/>
						}
					/>

					<OwnAppModeMarker appName={appName} isLoading={isLoading} />

					<Button
						p="xxs"
						size="xs"
						bg="snow"
						stretch={false}
						onClick={turnOffCustomDashboard}
					>
						<Title subtitle="Turn Off" size="xs" muteSubtitle={false} />
					</Button>
				</Center>
			</Stack>
		</Badge>
	);
}

function OwnAppModeMarker(props) {
	return props.isLoading ? (
		<InlineLoading textColor="default" />
	) : (
		<TitleChild white title={`${props.appName} app mode is on`} size="sm" />
	);
}
