import React from 'react';
import { useMutation } from 'react-query';

import { ApiRequest } from '@zeal/zeal-lib';
import { useQuery } from '../../App/QueryClient';
import { getLoggedInSession } from '../../App/ACL/useLogin';

async function apiAnnouncements({ queryKey: [, filters] }) {
	return new ApiRequest('v3/actionable-offers')
		.addHeader('Accept', 'application/json')
		.GET();
}

async function apiPromoCode({ queryKey: [, filters] }) {
	return new ApiRequest('promos')
		.addHeader('Accept', 'application/json')
		.GET();
}
async function apiDeleteAnnou({ ...payload }) {
	const { authorization } = getLoggedInSession();

	return new ApiRequest(`v3/actionable-offers/${payload.uuid}`)
		.addHeader('Accept', 'application/json')
		.addHeader('Authorization', authorization)
		.addHeader('Content-type', 'application/json')
		.DELETE(JSON.stringify(payload), true);
}
async function apiAddAnnou(payload) {
	let url = 'v3/actionable-offers';
	if (payload.get('uuid')) {url = `v3/actionable-offers/${payload.get('uuid')}`;}
	return new ApiRequest(url)
		.addHeader('Accept', 'application/json')
		.POST(payload);
}

export default function useAnnouncements() {
	const q = useQuery({
		queryFn: apiAnnouncements,
		staleTime: 1000 * 60 * 15, // 15 min
		queryKey: ['z-announcements'],
	});

	React.useDebugValue(q);

	return q;
}
export function useBromoCode() {
	const q = useQuery({
		queryFn: apiPromoCode,
		staleTime: 1000 * 60 * 15, // 15 min
		queryKey: ['z-promo-code'],
	});

	React.useDebugValue(q);

	return q;
}
export function useDeleteAnnou(opt) {
	const mutation = useMutation(apiDeleteAnnou);

	const { data, mutate, isSuccess, isError, error } = mutation;

	React.useEffect(() => {
		if (isError && opt.onError) {
			opt.onError(error);
		}

		if (!isSuccess) {
			return null;
		}

		if (opt.onSuccess) {
			opt.onSuccess();
		}
	}, [data, isSuccess, isError]);

	return {
		...mutation,
		doDeleteAnnou: mutate,
	};
}
export function useAddAnnou(opt) {
	const mutation = useMutation(apiAddAnnou);

	const { data, mutate, isSuccess, error, isError } = mutation;

	React.useEffect(() => {
		if (isError && opt.onError) {
			opt.onError(error);
		}

		if (!isSuccess) {
			return null;
		}

		if (opt.onSuccess) {
			opt.onSuccess();
		}
	}, [data, isSuccess, isError]);

	return {
		...mutation,
		doAddAnnou: mutate,
	};
}
