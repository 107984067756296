import React from 'react';

import { Align, Select, SizedBox, Stack } from '@zeal/zeal-ui';

import {
	useAccountInfo,
	useBusinessInfo,
} from '../../BusinessAccount/useBusinessInfo';

import CloseOwnAppMode from './CloseOwnAppMode';

export default function OwnAppSelector(props) {
	const { data, isLoading } = useBusinessInfo();

	const { appId, isAppOwn, updateApp } = useAccountInfo();

	const { name: appName } = data || {};

	const appsOptions = [
		{
			id: '1',
			isZeal: true,
			value: 'zeal',
			label: 'Zeal Board Analytics',
		},
		{
			id: '2',
			isOwn: true,
			value: appName,
			label: `${appName} App`,
		},
	];

	const getAppById = (id) => appsOptions.find((a) => a.id === id) || -1;

	const handleOwnAppSwitch = (item) => {
		updateApp(item.id, item.value);
	};

	const handleOwnAppDeactivate = () => {
		updateApp(appsOptions[0].id, appsOptions[0].value);
	};

	return (
		<Align align="right" text={false}>
			<Stack p="none" m="sm" my="none">
				{!isAppOwn && (
					<SizedBox width="xl" margin="sm">
						<Select
							stretch
							fitContent
							px="lg"
							margin="none"
							options={appsOptions}
							inputName="dashboardToggle"
							selected={getAppById(appId)}
							onChange={handleOwnAppSwitch}
							disabled={isLoading}
						/>
					</SizedBox>
				)}

				{isAppOwn && (
					<CloseOwnAppMode
						appName={appName}
						isLoading={isLoading}
						turnOffCustomDashboard={handleOwnAppDeactivate}
					/>
				)}
			</Stack>
		</Align>
	);
}
