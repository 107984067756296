import React from 'react';
import { Route, Switch } from 'react-router-dom';
import ACLBoundary from '../App/ACL/ACLBoundary';

import Exports from './Exports';

export default function ExportsRoutes() {
	return (
		<Switch>
			<ACLBoundary
				aclUnAuthProps={{ replace: true }}
				aclGrants={['view:reports']}
			>
				<Route exact path="/exports" component={Exports} />
			</ACLBoundary>
		</Switch>
	);
}
