import React, { useState } from 'react';
import {
	Align,
	Button,
	Card,
	ChartBar,
	Checkbox,
	Label,
	Stack,
} from '@zeal/zeal-ui';
import useBranchAnalytics from '../data/useBranchAnalytics';
import ToggleButtons from './ToggleButtons';

export default function ChartBranchAnalytics(props) {
	const { data: branches } = useBranchAnalytics();
	const [label, setLabel] = useState();
	const overview = useBranchAnalytics();
	const topLabel = [];
	const topValues = [];

	for (let index = 0; index < 5; index++) {
		topLabel?.push(branches?.label[index]);
		topValues?.push(branches?.values[index]);
	}

	const topData = () => setLabel(topLabel);
	const allData = () => setLabel(branches?.label);

	let options = {
		xAxis: {
			type: 'value',
			boundaryGap: [0, 0.01],
		},
		yAxis: {
			type: 'category',
			data: label ? label : topLabel,
			inverse: true,
		},
		series: [{ data: topValues }, { data: branches?.values }],
	};
	return (
		<Card>
			<ChartBar
				customToggl={<ToggleButtons topData={topData} allData={allData} />}
				className="mb-20"
				title="Branches Analytics"
				isError={overview.isError}
				showLoading={overview.isLoading}
				options={options}
			/>
		</Card>
	);
}
