import React, { useEffect, useReducer, useState } from 'react';
import { Prompt } from 'react-router-dom';
import { useHistory, useLocation } from 'react-router-dom';
import { cloneDeep, pick } from 'lodash';

import {
	Align,
	ButtonWithIcon,
	Card,
	CenterStackWithToolbar,
	InlineLoading,
	Stack,
} from '@zeal/zeal-ui';

import AppContainer from '../App/AppContainer';

import { useQueryString } from '../App/useQueryString';
import openToastr from '../App/Toastr/openToastr';
import ACLBoundary from '../App/ACL/ACLBoundary';
import {
	useAddItemPhoto,
	useDeleteItemPhoto,
	useUpdateItem,
	useUpdateItemEvents,
} from './data/useMenuItem';
import ItemInfo from './components/ItemInfo';
import ItemGallery from './components/ItemGallery';
import ItemSections from './components/ItemSections';
import { useMenu } from './data/useBranchesMenu';

export default function EditMenuItem() {
	const [errMsgs, setErrMsgs] = useState({});
	const history = useHistory();
	const location = useLocation();
	const [{ menuId, categoryId, subcategoryId, itemId }] = useQueryString();

	const passedItemData = location?.state?.selectedItem;

	const itemQuery = useMenu({
		itemId,
		menuId,
		categoryId,
		subcategoryId,
	});

	const initialEventUpdateState = { events: {} };

	const eventUpdateReducer = (state, action) => {
		switch (action.type) {
		case 'set':
			const newEvents = cloneDeep(state.events);
			newEvents[action.key] = { data: action.data, event: action.action };

			return { events: newEvents };
		case 'clear':
			return { events: {} };
		default:
			return initialEventUpdateState;
		}
	};

	const [eventUpdateState, eventUpdateDispatch] = useReducer(
		eventUpdateReducer,
		initialEventUpdateState,
	);

	const setUpdateEvent = (event) => {
		eventUpdateDispatch({ type: 'set', ...event });
	};

	const clearUpdateEvents = () => {
		eventUpdateDispatch({ type: 'clear' });
	};

	const itemData = itemQuery.data;

	useEffect(() => {
		if (itemData) {fillBranchForm(itemData);}
	}, [itemData]);

	useEffect(() => {
		if (passedItemData) {fillBranchForm(passedItemData);}
	}, [passedItemData]);

	function fillBranchForm(dataSource) {
		if (dataSource) {
			const {
				name,
				price,
				loyalty_multiplier: points,
				description,
				attribute_groups: sections,
				photos,
			} = dataSource;
			formDispatch({
				name,
				price,
				points,
				description,
				sections,
				photos: photos || [],
			});
		}
	}

	const initialFormValues = {
		name: '',
		price: '',
		points: '',
		description: '',
		sections: [],
		sectionsChanged: false,
		photos: [],
		deletedPhotosIds: [],
	};

	const addEditFormReducer = (prevState, newState) => ({ ...prevState, ...newState });

	const [formState, formDispatch] = useReducer(
		addEditFormReducer,
		initialFormValues,
	);

	const { doUpdateItemEvents, isError, error, isLoading } = useUpdateItemEvents(
		{
			onSuccess: () => {
				itemQuery.refetch();
				clearUpdateEvents();
				openToastr({
					message: 'Item Updated',
				});
				if (
					formState.photos.length === 0
					&& formState?.deletedPhotosIds?.length === 0
				) {
					goToMenu(true);
				}
			},
			onError: () => {
				openToastr({
					message: 'Failed to update Item',
					isError: true,
				});
			},
		},
	);

	const { variables: deleteBranchVariables, doDeleteItemPhoto }
		= useDeleteItemPhoto({
			onSuccess: () => {
				const { isLastPhoto } = deleteBranchVariables;

				if (isLastPhoto) {
					formDispatch({ deletedPhotosIds: [] });
					openToastr({
						message: 'Item Gallery Updated',
					});

					goToMenu(true);
				}
			},
		});

	const { doAddItemPhoto, variables } = useAddItemPhoto({
		onSuccess: () => {
			const { isLastPhoto } = variables;
			if (isLastPhoto) {
				togglePhotosStatus('uploaded');

				goToMenu(true);

				openToastr({
					message: 'Item Gallery Updated',
				});
			}
		},
		onError: () => {
			const { isLastPhoto } = variables;
			if (isLastPhoto) {
				togglePhotosStatus('uploaded');
				formDispatch({ photos: [] });
				openToastr({
					message: 'Failed to update Item Gallery',
					isError: true,
				});
			}
		},
	});

	const syncPhotos = () => {
		togglePhotosStatus('loading');

		for (let i = 0; i < formState.photos.length; i++) {
			if (!formState.photos[i].uuid) {
				const formData = new FormData();
				formData.append('file', formState.photos[i].file);
				formData.append('item', itemId);
				doAddItemPhoto({
					formData,
					isLastPhoto: i === formState.photos.length - 1,
				});
			}
		}

		for (let i = 0; i < formState?.deletedPhotosIds?.length; i++) {
			doDeleteItemPhoto({
				photoId: formState?.deletedPhotosIds[i],
				isLastPhoto: i === formState.deletedPhotosIds.length - 1,
			});
		}
	};

	const togglePhotosStatus = (status) => {
		const photos = cloneDeep(formState.photos);

		const newPhotos = photos.map((p) => {
			if (status === 'uploaded') {p.loading = false;}
			p[status] = !p[status];
			return p;
		});

		formDispatch({ photos: newPhotos });
	};

	useEffect(() => {
		error?.response?.json().then((err) => {
			setErrMsgs(err?.errors);
		});
	}, [error]);

	const currentPath = `${window.location.pathname}${window.location.search}`;

	const editItemBreadCrumb = [
		{
			name: 'Menu',
			to: `/menu/${menuId}`,
			icon: 'utensils',
		},
		{
			name: 'Edit',
			to: currentPath,
		},
	];

	const isEmptyObject = (o) => Object.keys(o).some(function (x) {
		return o[x] === '' || o[x] === null;
	});

	const submitAddItem = () => {
		if (
			!isEmptyObject(formState)
			|| Object.keys(eventUpdateState.events)?.length
		) {
			const eventsArray = Object.values(eventUpdateState.events);

			doUpdateItemEvents({
				itemId,
				events: [
					...eventsArray,
					{
						event: 'item.update',
						data: {
							...pick(formState, ['name', 'description', 'price']),
							loyalty_multiplier: formState.points,
						},
					},
				],
			});
		}

		if (formState.photos.length > 0 || formState?.deletedPhotosIds?.length > 0)
		{syncPhotos();}
	};

	const areAllImagesUploadedBefore = formState.photos.every((p) => p.uuid);

	const isBranchGalleryChanged
		= !areAllImagesUploadedBefore || formState?.deletedPhotosIds?.length > 0;

	const goToMenu = (refreshMenu = false) => {
		history.push({
			pathname: `/menu/${menuId}`,
			search: `?categoryId=${categoryId}&subcategoryId=${subcategoryId}&refreshMenu=${refreshMenu}`,
		});
	};

	const loadingComp = isLoading && <InlineLoading />;

	const headerEndContent = (
		<Align align="right">
			<Stack row p="none" gap="md">
				{loadingComp}
				<ButtonWithIcon label="Cancel" size="xs" m="none" onClick={goToMenu} />
				<ACLBoundary>
					<ButtonWithIcon
						primary
						label="Edit Item"
						size="xs"
						m="none"
						onClick={submitAddItem}
						aclUnAuthProps={{ disabled: true }}
						aclGrants={['control:menu']}
					/>
				</ACLBoundary>
			</Stack>
		</Align>
	);

	return (
		<AppContainer ownApp protected breadcrumbs={editItemBreadCrumb}>
			<Prompt
				when={isBranchGalleryChanged}
				message="Are you sure you want to leave without saving?"
			/>

			<CenterStackWithToolbar
				toolbarStart={<span></span>}
				toolbarEnd={headerEndContent}
			>
				<Card p="none">
					<ItemInfo
						formDispatch={formDispatch}
						formState={formState}
						errMsgs={errMsgs}
						isError={isError}
					/>
					<hr />
					<ItemSections
						formDispatch={formDispatch}
						formState={formState}
						setUpdateEvent={setUpdateEvent}
					/>
					<hr />
					<ItemGallery
						formDispatch={formDispatch}
						photos={formState.photos}
						deletedPhotosIds={formState.deletedPhotosIds}
					/>
				</Card>
			</CenterStackWithToolbar>
		</AppContainer>
	);
}
