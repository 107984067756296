import React, { useEffect, useState } from 'react';

import {
	Align,
	ButtonWithIcon,
	InlineError,
	InlineLoading,
	Input,
	Modal,
	Select,
	Stack,
	Title,
	TwoSideLayout,
} from '@zeal/zeal-ui';
import openToastr from '../App/Toastr/openToastr';
import HandleOnPromoSybmit from './HandleOnPromoSybmit';
import usePromocodes, { useAddPromocode } from './data/usePromocodes';
import { renderErrorMessage } from './ErrorMessage';

export default function AddModal(props) {
	const {
		formState,
		formDispatch,
		isModalOpen,
		closeModal,
		resetForm,
		removeError,
		setRemoveError,
	} = props;
	const storiesQuery = usePromocodes();
	const [errMsgs, setErrMsgs] = useState({});

	const { doAddPromocode, isError, isLoading, status, error } = useAddPromocode(
		{
			onSuccess: () => {
				storiesQuery.refetch();
				openToastr({
					message: 'Promo Code Added',
				});
				closeModal(false);
				resetForm();
			},
			onError: () => {
				let errMsg = '';
				if ([400, 422].includes(error?.response?.status))
				{errMsg = 'Failed to add Promo code';}
				else {errMsg = 'Server error. Please try again Later';}
				openToastr({ message: errMsg, isError: true });
			},
		},
	);

	useEffect(() => {
		error?.response.json().then((err) => {
			setErrMsgs(err?.errors);
		});
	}, [error]);

	const addPromo = (story) => {
		doAddPromocode(story);
		storiesQuery.refetch();
		setRemoveError(true);
	};

	const handleOn = React.useCallback(() => {
		let formData = HandleOnPromoSybmit(formState);

		addPromo(formData);
	}, [formState]);
	const showRewardSelect = formState?.type?.value === 'FREE_REWARD';

	const handleNumber = (event) => {
		if (!/[0-9]/.test(event.key)) {
			event.preventDefault();
		}
	};

	const whenSelected = (
		<Stack>
			<Title title="When" margin="xs" />
			<Select
				inputName="when"
				options={[
					{ value: 'INSTANT', label: 'Instant' },
					{ value: 'FIRST', label: 'IF First Visit' },
					{ value: 'NEXT', label: 'On Next Visit' },
				]}
				placeholder={formState?.when ? formState?.when : 'instant'}
				selected={formState?.when}
				onChange={(when) => formDispatch({ when: when })}
				validateType="true"
				startHint={renderErrorMessage('when', isError, errMsgs)}
			/>
		</Stack>
	);
	const dis_title
		= formState?.type?.value === 'FLAT_DISCOUNT'
			? 'Disount Amount'
			: ' Discount Percentage';
	const FLAT_PERCENTAGE = (formState?.type?.value === 'FLAT_DISCOUNT'
		|| formState?.type?.value === 'PERCENTAGE_DISCOUNT') && (
		<Stack>
			<Stack>
				<Title title={dis_title} margin="xs" />
				<Input
					type="text"
					pattern="[0-9]"
					onKeyPress={handleNumber}
					inputName="Disount"
					placeholder={dis_title}
					onChange={(evt) => formDispatch({ amount: evt.target.value })}
					value={formState.amount}
					startHint={renderErrorMessage('amount', isError, errMsgs)}
				/>
			</Stack>
			<Stack>
				<Title title="Min Payment" margin="xs" />
				<Input
					type="text"
					pattern="[0-9]"
					onKeyPress={handleNumber}
					inputName="Payment"
					placeholder="Min Payment"
					onChange={(evt) => formDispatch({ min_payment: evt.target.value })}
					value={formState.min_payment}
					startHint={renderErrorMessage('min_payment', isError, errMsgs)}
				/>
			</Stack>
		</Stack>
	);
	const maxDiscount = formState?.type?.value === 'PERCENTAGE_DISCOUNT' && (
		<Stack>
			<Stack>
				<Title title="Max Discount" margin="xs" />
				<Input
					type="text"
					pattern="[0-9]"
					onKeyPress={handleNumber}
					inputName="maxDiscount"
					placeholder="Max Discount"
					onChange={(evt) => formDispatch({ max_discount: evt.target.value })}
					value={formState.max_discount}
					startHint={renderErrorMessage('max_discount', isError, errMsgs)}
				/>
			</Stack>
		</Stack>
	);

	const rewardSelect = showRewardSelect && (
		<Stack>
			<Title title="Reward Name" margin="xs" />
			<Input
				type="text"
				inputName="rewardName"
				placeholder="Reward Name"
				onChange={(evt) => formDispatch({ name: evt.target.value })}
				value={formState.name}
				startHint={renderErrorMessage('name', isError, errMsgs)}
			/>
		</Stack>
	);
	return (
		<Modal
			isModalOpen={isModalOpen}
			title="Add Promo code"
			onClose={closeModal}
		>
			<Stack>
				<Title title="Promo Code Name" margin="xs" />
				<Input
					type="text"
					inputName="name"
					placeholder="Add Name"
					onChange={(evt) => formDispatch({ code: evt.target.value })}
					value={formState.code}
					startHint={renderErrorMessage('code', isError, errMsgs)}
				/>
			</Stack>
			<Stack>
				<Title title="Promo Code Action" margin="xs" />
				<Select
					inputName="type"
					options={[
						{ value: 'FLAT_DISCOUNT', label: 'Flat Discount' },
						{ value: 'FREE_PUNCH', label: 'Free Point' },
						{ value: 'PERCENTAGE_DISCOUNT', label: 'Percentage Discount' },
						{ value: 'FREE_REWARD', label: 'Free Reward' },
					]}
					selected={formState.type}
					onChange={(type) => formDispatch({ type: type })}
					placeholder={formState?.type ? formState?.type : 'select action'}
					startHint={renderErrorMessage('action', isError, errMsgs)}
				/>
			</Stack>
			{rewardSelect}
			{whenSelected}
			{FLAT_PERCENTAGE}
			{maxDiscount}
			<Stack>
				<Title title="Qouta" margin="xs" />
				<Input
					type="text"
					pattern="[0-9]"
					onKeyPress={handleNumber}
					inputName="Qouta"
					placeholder="Enter Number"
					onChange={(evt) => formDispatch({ quota: evt.target.value })}
					value={formState.quota}
					startHint={renderErrorMessage('quota', isError, errMsgs)}
				/>
			</Stack>
			<Stack>
				<Title title="Expiration Date" margin="xs" />
				<Input.Date
					calender
					transparent
					placeholder="YYYY-MM-DD"
					selected={formState.expireDate}
					onChange={(date) => formDispatch({ expireDate: date })}
					startHint={renderErrorMessage('expire_at', isError, errMsgs)}
				/>
			</Stack>
			<Title title={<hr />} margin="xl" />
			<TwoSideLayout>
				<ButtonWithIcon
					stretch={false}
					m="xxs"
					px="md"
					py="sm"
					onClick={closeModal}
				>
					Cancel
				</ButtonWithIcon>
				<Align align="left">
					<InlineError hide={!isError + !removeError} />
					<InlineLoading hide={!isLoading} />
					<ButtonWithIcon
						stretch={false}
						m="xxs"
						px="md"
						py="sm"
						primary
						onClick={handleOn}
					>
						Add Promo code
					</ButtonWithIcon>
				</Align>
			</TwoSideLayout>
		</Modal>
	);
}
