import React, { useEffect, useState } from 'react';

import {
	Anchor,
	Badge,
	BasicSelect,
	BasicTable,
	Button,
	ButtonWithIcon,
	Card,
	Center,
	Checkbox,
	Icon,
	InlineLoading,
	MainStackWithAside,
	Stack,
	Title,
} from '@zeal/zeal-ui';

import AppContainer from '../App/AppContainer';
import openToastr from '../App/Toastr/openToastr';
import { useQueryString } from '../App/useQueryString';

import ACLBoundary from '../App/ACL/ACLBoundary';
import Holidays from './components/Holidays';
import SetDayModal from './components/SetDayModal';

import useSchedule, {
	useAddBookingDay,
	useBranches,
	useEditBookingDay,
	useUpdateBookingDurations,
} from './data/useSchedule';

export default function Schedule() {
	const [selectedBranch, setSelectedBranch] = useState(null);
	const [isDayModalOpen, setIsDayModalOpen] = useState(false);
	const [editedDay, setEditedDay] = useState(null);

	const scheduleQuery = useSchedule({
		formatSlotDurations(data) {
			return {
				...data,
				slot_duration: Math.floor((data?.slot_duration || 0) / 60),
			};
		},
	});
	const { data: scheduleData, isLoading: isScheduleLoading } = scheduleQuery;

	const [, updateQuery] = useQueryString();

	const { data: branchesData } = useBranches({
		formatBranch(data) {
			return {
				uuid: data?.uuid,
				name: data?.name,
			};
		},
	});

	useEffect(() => {
		updateQuery({
			branchId: selectedBranch?.uuid,
		});
	}, [selectedBranch]);

	const onChangeBranch = (event) => {
		const branch = branchesData?.find((i) => i.uuid === event.target.value);
		setSelectedBranch(branch);
	};

	const { doUpdateDurations } = useUpdateBookingDurations({
		onSuccess: () => {
			scheduleQuery.refetch();
			setIsDayModalOpen(false);
		},
	});

	const submitDayModal = (durations) => {
		durations = durations?.map((d) => {
			d.booking_day_uuid = editedDay?.uuid;
			d.start = d.start.substring(0, 5);
			d.end = d.end.substring(0, 5);
			d.slot_duration = d.slot_duration * 60;
			return d;
		});
		doUpdateDurations({ durations });
	};

	const openSetDayModal = (day) => {
		if (day?.uuid) {
			setIsDayModalOpen(true);
			setEditedDay(day);
		} else {
			openToastr({
				message: `Please enable ${day.day} checkbox first`,
				isError: true,
			});
		}
	};

	const selectBranch = (
		<Stack row p="none">
			<Center horizontal={false} text={false}>
				<Title subtitle="Select Branch" size="sm" m="sm" muteSubtitle={false} />
				<BranchSelect
					branchesData={branchesData}
					selectedBranch={selectedBranch}
					onChangeBranch={onChangeBranch}
				/>
			</Center>
		</Stack>
	);

	const currentPath = `${window.location.pathname}${window.location.search}`;

	const breadCrumb = [
		{
			name: 'Schedule',
			to: currentPath,
			icon: 'calendarLight',
		},
	];

	const showDaysTable = selectedBranch?.uuid ? (
		<Card p="none">
			<br />
			<DaysTable
				data={scheduleData || []}
				selectedBranch={selectedBranch}
				openSetDayModal={openSetDayModal}
				isLoading={isScheduleLoading}
			/>
		</Card>
	) : (
		<Card p="md">
			<Center>
				<Title title="No branch selected" />
			</Center>
		</Card>
	);

	return (
		<AppContainer ownApp protected breadcrumbs={breadCrumb}>
			<MainStackWithAside
				toolbarStart={selectBranch}
				aside={<Holidays branchId={selectedBranch?.uuid} />}
			>
				<Stack>{showDaysTable}</Stack>
				<SetDayModal
					isModalOpen={isDayModalOpen}
					closeModal={() => setIsDayModalOpen(false)}
					handleSubmit={submitDayModal}
					editedDay={editedDay}
				/>
			</MainStackWithAside>
		</AppContainer>
	);
}

function BranchSelect(props) {
	const { branchesData, selectedBranch, onChangeBranch } = props;

	const branchesOptions = React.Children.toArray([
		<option>-- Select Branch --</option>,
		...branchesData?.map((b) => <option value={b.uuid}>{b.name}</option>),
	]);

	return (
		<BasicSelect
			name="branches"
			value={selectedBranch?.uuid}
			onChange={onChangeBranch}
			placeholder="-- Select Branch --"
			options={branchesOptions}
		/>
	);
}

function DaysTable(props) {
	const { data, selectedBranch, openSetDayModal, isLoading } = props;
	const scheduleQuery = useSchedule();

	const { doEditDay, isEditLoading } = useEditBookingDay({
		onSuccess: () => {
			scheduleQuery.refetch();
		},
	});

	const { doAddDay, isLoading: isAddLoading } = useAddBookingDay({
		onSuccess: () => {
			scheduleQuery.refetch();
		},
	});

	const renderCheckbox = ({ row }) => {
		const rowData = row?.original;

		const isLoading = isEditLoading || isAddLoading || scheduleQuery.isFetching;

		return isLoading ? (
			<InlineLoading label=" " />
		) : (
			<div>
				<ACLBoundary>
					<Checkbox
						inputName="remember-me"
						defaultChecked={rowData?.enabled}
						onChange={(evt) => handleCheckedChange(evt, rowData?.uuid, row.id)}
						aclUnAuthProps={{ disabled: true }}
						aclGrants={['control:orders schedule']}
					/>
				</ACLBoundary>
			</div>
		);
	};

	const handleCheckedChange = (evt, dayId, dayIndex) => {
		const checked = evt?.target?.checked;

		dayId
			? doEditDay({ enabled: checked, id: dayId })
			: doAddDay({ day: dayIndex, branch_uuid: selectedBranch?.uuid });
	};

	const tableColumns = [
		{ Header: '', accessor: 'selection', Cell: renderCheckbox },
		{ Header: 'Day', accessor: 'day' },
		{
			Header: 'From',
			accessor: 'start',
			Cell: renderTimeCell,
		},
		{
			Header: 'To',
			accessor: 'end',
			Cell: renderTimeCell,
		},
		{
			Header: 'Assigned Slots',
			accessor: 'bookingDurations',
			Cell: renderSlotsCell,
		},
		{
			Header: 'Status',
			accessor: 'enabled',
			Cell: renderStatusCell,
		},
		{
			Header: 'Actions',
			accessor: 'uuid',
			Cell: (rows) => renderActionCell(rows, openSetDayModal),
		},
	];

	const tableLoadingContent = (
		<Center>
			<Stack m="xl">
				<InlineLoading />
			</Stack>
		</Center>
	);

	return (
		<BasicTable
			data={data}
			columns={tableColumns}
			isLoading={isLoading}
			loadingContent={tableLoadingContent}
		/>
	);
}

function renderStatusCell({ value }) {
	const status = value ? (
		<Title primary title="Enabled" size="xs" />
	) : (
		<Title danger title="Disabled" size="xs" />
	);

	return status;
}

function renderActionCell({ value, row }, openSetDayModal) {
	const rowData = row?.original;

	const buttonContent
		= rowData?.bookingDurations?.length > 0 ? (
			<Icon rounded bg="disabled" name="edit" size="lg" p="xs" margin="none" />
		) : (
			<Anchor label="Assign" disabled />
		);

	return (
		<Button transparent stretch={false} p="none" m="none">
			<ACLBoundary>
				<ButtonWithIcon
					transparent
					p="none"
					m="none"
					onClick={() => {
						openSetDayModal(rowData);
					}}
					aclUnAuthProps={{ disabled: true }}
					aclGrants={['control:orders schedule']}
				>
					{buttonContent}
				</ButtonWithIcon>
			</ACLBoundary>
		</Button>
	);
}

function renderSlotsCell({ row }) {
	const rowData = row?.original;

	const emptySlot = (
		<Stack>
			<Badge bg="secondary" size="xs" p="xs" rounded="lg">
				0 SLOTS
			</Badge>
		</Stack>
	);

	const cell
		= rowData?.bookingDurations?.length > 0
			? React.Children.toArray(
				rowData?.bookingDurations?.map((item) => {
					const hoursDiff = hoursDifference(item.start, item.end);

					const slotDuration = item.slot_duration || 1;

					const slotNumber = Math.ceil(hoursDiff / slotDuration || 0);

					return (
						<Stack>
							<Badge bg="secondary" size="xs" p="xs" rounded="lg">
								{slotNumber} SLOTS
							</Badge>
						</Stack>
					);
				}),
			)
			: emptySlot;

	return cell;
}

function renderTimeCell(row) {
	const cell
		= row?.row?.original?.bookingDurations?.length > 0 ? (
			React.Children.toArray(
				row?.row?.original?.bookingDurations?.map((item) => (
					<Stack>
						<Badge bg="transparent" size="xs" p="xs" rounded="lg">
							<Title title={item?.[row?.column?.id]} margin="sm" />
						</Badge>
					</Stack>
				)),
			)
		) : (
			<Stack>
				<Title subtitle="Not Assigned" margin="sm" muteSubtitle={false} />
			</Stack>
		);

	return cell;
}

export function hoursDifference(start, end) {
	const startDate = new Date(`1/1/2000 ${start}`);
	const endDate = new Date(`1/1/2000 ${end}`);

	let diff = (endDate.getTime() - startDate.getTime()) / 1000;
	diff /= 60 * 60;

	return diff;
}
