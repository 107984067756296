import React, { useCallback, useReducer } from 'react';
import {
	Align,
	Button,
	ButtonWithIcon,
	Grid,
	Icon,
	InlineLoading,
	Modal,
	Stack,
	Title,
	TwoSideLayout,
} from '@zeal/zeal-ui';

import useMultiplePunches, {
	useDeleteMultiplePunches,
} from '../data/useMultiplePunches';
import ACLBoundary from '../../App/ACL/ACLBoundary';
import EmptyState from './EmptyState';
import MultiplePunchesTable from './MultiplePunchesTable';
import MultiplePunchesAddEditModal from './MultiplePunchesAddEditModal';
import openToastr from '../../App/Toastr/openToastr';

export default function MultiplePunches(props) {
	const initialState = {
		isAddEditModalOpen: false,
		isDeleteModalOpen: false,
		editedPunchRange: null,
		punchRangeInput: '',
		punchNumberInput: '',
		removeError: true,
	};

	const punchesReducer = (prevState, newState) => ({
		...prevState,
		...newState,
	});

	const [punchState, punchDispatch] = useReducer(punchesReducer, initialState);

	const multiplePunchesData = useMultiplePunches()?.data?.data;

	const openEditModal = useCallback((punch) => {
		punchDispatch({
			punchRangeInput: punch.punches,
			punchNumberInput: punch.value,
			editedPunchRange: punch,
		});
		openModal();
	}, []);

	const openDeleteModal = useCallback((punch) => {
		punchDispatch({
			editedPunchRange: punch,
		});
		punchDispatch({ isDeleteModalOpen: true });
	}, []);

	const openModal = () => {
		punchDispatch({ isAddEditModalOpen: true });
	};

	const resetForm = useCallback(() => {
		punchDispatch(initialState);
	}, [initialState]);

	const openAddMultiplePunchesModal = useCallback(() => {
		resetForm();
		openModal();
	}, [resetForm]);

	const multiplePunchesTable = multiplePunchesData ? (
		<Stack m="md" gap="lg" p="none" mt="none">
			<MultiplePunchesTable
				data={multiplePunchesData}
				openEditModal={openEditModal}
				openDeleteModal={openDeleteModal}
			/>
		</Stack>
	) : (
		<EmptyState data={multiplePunchesData} title="Multiple Punches" />
	);

	const onCloseModal = useCallback(() => {
		punchDispatch({ removeError: true, isAddEditModalOpen: false });
	}, []);

	const closeDeleteModal = useCallback(() => {
		punchDispatch({ isDeleteModalOpen: false });
	}, []);

	return (
		<div>
			<TwoSideLayout>
				<Stack wrap row gap="xl" m="md" pt="none">
					<h3>Multiple Punches</h3>
					<Icon name="questionCircle" />
				</Stack>
				<Stack row gap="xl" m="sm" wrap>
					<ACLBoundary>
						<ButtonWithIcon
							primary
							circle
							stretch={false}
							onClick={openAddMultiplePunchesModal}
							py="xs"
							aclUnAuthProps={{ disabled: true }}
							aclGrants={['control:loyalty']}
						>
							<Icon name="plusCircle" size="sm" margin="none" bg="pure" />
						</ButtonWithIcon>
					</ACLBoundary>
				</Stack>
			</TwoSideLayout>
			{multiplePunchesTable}
			<MultiplePunchesAddEditModal
				isAddEditModalOpen={punchState.isAddEditModalOpen}
				closeModal={onCloseModal}
				editedMultiplePunch={punchState.editedPunchRange}
				onChangePunchRange={(evt) =>
					punchDispatch({ punchRangeInput: evt.target.value })
				}
				punchRangeInput={punchState.punchRangeInput}
				onChangePunchNumber={(evt) =>
					punchDispatch({ punchNumberInput: evt.target.value })
				}
				punchesNumberInput={punchState.punchNumberInput}
				setRemoveError={(value) => punchDispatch({ removeError: value })}
				resetForm={resetForm}
				removeError={punchState.removeError}
			/>
			<DeleteModal
				isDeleteModalOpen={punchState.isDeleteModalOpen}
				closeModal={closeDeleteModal}
				deletedMultiplePunch={punchState.editedPunchRange}
			/>
		</div>
	);
}

function DeleteModal(props) {
	const { isDeleteModalOpen, closeModal, deletedMultiplePunch } = props;

	const multiplePunchesQuery = useMultiplePunches();

	const { doDeleteMultiplePunches, isLoading } = useDeleteMultiplePunches({
		onSuccess: () => {
			multiplePunchesQuery.refetch();
			closeModal();
			openToastr({
				message: 'Multiple Punch Deleted',
			});
		},
		onError: (error) => {
			error?.response?.json().then((err) => {
				openToastr({
					message: err?.message || 'Failed to delete multiple punch',
					isError: true,
				});
			});
		},
	});

	const handleDelete = useCallback(() => {
		doDeleteMultiplePunches({ id: deletedMultiplePunch?.id });
	}, [deletedMultiplePunch?.id, doDeleteMultiplePunches]);

	return (
		<Modal
			isModalOpen={isDeleteModalOpen}
			title="Delete Multiple Punch"
			onClose={closeModal}
		>
			<Stack p="none">
				<Title title="Are you sure you want to delete this multiple punch?" />

				<Grid gap="5">
					<span></span>
					<TwoSideLayout>
						<Button stretch={false} size="md" onClick={closeModal}>
							Cancel
						</Button>
						<Align align="left">
							<InlineLoading hide={!isLoading} />
							<ButtonWithIcon
								secondary
								stretch={false}
								size="md"
								onClick={handleDelete}
							>
								Delete
							</ButtonWithIcon>
						</Align>
					</TwoSideLayout>
				</Grid>
			</Stack>
		</Modal>
	);
}
