import React, { useEffect, useReducer, useState } from 'react';
import { useHistory } from 'react-router-dom';

import {
	Align,
	ButtonWithIcon,
	Card,
	Checkbox,
	Grid,
	Input,
	MainStackWithAside,
	RadioButton,
	Stack,
	Title,
} from '@zeal/zeal-ui';

import AppContainer from '../../App/AppContainer';
import openToastr from '../../App/Toastr/openToastr';
import { useBranches } from '../../Orders/data/useBranches';

import { useAddBusinessAdmin } from '../data/useDashboard';
import useRoles from '../data/useRoles';

import { renderErrorMessage } from './ErrorMessage';
import { useQueryClient } from 'react-query';

export default function CreateBusinessAdmin(props) {
	const [errMsgs, setErrMsgs] = useState({});

	const { data: branchesData } = useBranches({
		formatBranch(data) {
			return {
				uuid: data?.uuid,
				name: data?.name,
			};
		},
	});

	const rolesQuery = useRoles();

	const { data: rolesData } = rolesQuery;

	const selectBranchesTypes = {
		all: 'all',
		multiple: 'multiple',
	};

	const formInitialValues = {
		name: '',
		email: '',
		password: '',
		selectedRoles: {},
		selectedBranches: {},
		branchesType: '',
	};

	const formReducer = (prevState, newState) => ({
		...prevState,
		...newState,
	});

	const [formState, formDispatch] = useReducer(formReducer, formInitialValues);

	const resetForm = () => {
		formDispatch(formInitialValues);
	};

	const history = useHistory();

	const queryClient = useQueryClient();

	const { doAddBusinessAdmin, isError, error } = useAddBusinessAdmin({
		onSuccess: () => {
			resetForm();
			openToastr({ message: 'Admin Created Successfully' });
			queryClient.invalidateQueries('z-business-admins');
			rolesQuery.refetch();
			history.push({
				pathname: '/teams/dashboard',
			});
		},
		onError: (error) => {
			const clonedError = error?.response.clone();

			if (!clonedError?.bodyUsed) {
				clonedError.json().then((err) => {
					openToastr({
						message: err?.message || 'Failed to create new admin',
						isError: true,
					});
				});
			}
		},
	});

	useEffect(() => {
		error?.response?.json().then((err) => {
			setErrMsgs(err?.errors);
		});
	}, [error]);

	const handleTeamSubmit = () => {
		const isAllBranchesSelected =
			formState.branchesType === selectBranchesTypes.all;

		//remove unused states from request body
		const { selectedBranches, selectedRoles, branchesType, ...restStates } =
			formState;

		const businessAdminBody = {
			...restStates,
			all_branches: isAllBranchesSelected,
			...(!isAllBranchesSelected && {
				branch_uuids: Object.keys(formState.selectedBranches),
			}),
			roles: Object.keys(formState.selectedRoles),
		};

		doAddBusinessAdmin(businessAdminBody);
	};

	const isFormValid = () => {
		const isNameValid = !!formState.name?.length;
		const isEmailValid = !!formState.email?.length;
		const isPasswordValid = !!formState.password?.length;
		const isRolesValid = Object.keys(formState.selectedRoles)?.length > 0;
		const isBranchesValid =
			Object.keys(formState.selectedBranches)?.length > 0 ||
			formState.branchesType === selectBranchesTypes.all;

		return (
			isNameValid &&
			isEmailValid &&
			isPasswordValid &&
			isRolesValid &&
			isBranchesValid
		);
	};

	const pointsBreadCrumb = [
		{ name: 'Dashboard', to: '/teams/dashboard' },
		{ name: 'Add User', to: '/teams/dashboard/create-admin' },
	];

	const updateSelectedRoles = (evt) => {
		if (evt.target.checked) {
			formDispatch({
				selectedRoles: {
					...formState.selectedRoles,
					[evt.target.name]: 'checked',
				},
			});
		} else {
			const { [evt.target.name]: removed, ...restRoles } =
				formState.selectedRoles;

			formDispatch({ selectedRoles: restRoles });
		}
	};

	const rolesCheckBoxes = React.Children.toArray(
		(rolesData?.data || []).map((role) => (
			<div>
				<Checkbox
					labelText={role?.name}
					inputName={role?.id.toString()}
					onChange={updateSelectedRoles}
					defaultChecked={!!formState?.selectedRoles?.[role?.id]}
					checked={!!formState?.selectedRoles?.[role?.id]}
				/>
			</div>
		))
	);

	useEffect(() => {
		if (
			Object.keys(formState.selectedBranches)?.length === branchesData?.length
		) {
			formDispatch({ branchesType: selectBranchesTypes.all });
		}
	}, [formState.selectedBranches]);

	const updateSelectedBranches = (evt) => {
		if (evt.target.checked) {
			formDispatch({
				selectedBranches: {
					...formState.selectedBranches,
					[evt.target.name]: 'checked',
				},
			});
		} else {
			const { [evt.target.name]: removed, ...restBranches } =
				formState.selectedBranches;

			formDispatch({ selectedBranches: restBranches });
		}
	};

	const branchesCheckBoxes = React.Children.toArray(
		(branchesData || []).map((branch) => (
			<div>
				<Checkbox
					labelText={branch?.name}
					inputName={branch?.uuid}
					defaultChecked={
						!!formState?.selectedBranches?.[branch?.uuid] ||
						formState.branchesType === selectBranchesTypes.all
					}
					checked={
						!!formState?.selectedBranches?.[branch?.uuid] ||
						formState.branchesType === selectBranchesTypes.all
					}
					disabled={formState.branchesType === selectBranchesTypes.all}
					onChange={updateSelectedBranches}
				/>
			</div>
		))
	);

	const changeBranchesSelectionType = (evt) => {
		formDispatch({ branchesType: evt.target.value });
	};

	const FormModal = (
		<Card p="lg" m="none">
			<Grid cols="3" gap="3">
				<div>
					<Input
						inputName="name"
						placeholder="Enter Name"
						label="Name"
						onChange={(evt) => formDispatch({ name: evt.target.value })}
						value={formState.name}
						validateType="true"
						startHint={renderErrorMessage('name', isError, errMsgs)}
					/>
					<Input.Email
						inputName="email"
						placeholder="add Email"
						label="Email"
						onChange={(evt) => formDispatch({ email: evt.target.value })}
						value={formState.email}
						startHint={renderErrorMessage('email', isError, errMsgs)}
						autocomplete="off"
					/>
					<Input.password
						inputName="password"
						placeholder="type password"
						label="Password"
						onChange={(evt) => formDispatch({ password: evt.target.value })}
						value={formState.password}
						startHint={renderErrorMessage('password', isError, errMsgs)}
						autocomplete="new-password"
					/>
				</div>
			</Grid>
			<Stack mx="none" my="lg" p="none">
				<hr />
			</Stack>
			<Stack m="none" p="none">
				<Stack mx="none" mt="none" my="md" p="none">
					<Title size="sm" title="Roles" />
				</Stack>
				<Grid cols="2" gap="3">
					<div>
						<Grid cols="3" gap="6">
							{rolesCheckBoxes}
						</Grid>
					</div>
				</Grid>
			</Stack>

			<Stack mx="none" my="lg" p="none">
				<hr />
			</Stack>
			<Stack m="none" p="none">
				<Stack mx="none" mt="none" my="md" p="none">
					<Title size="sm" title="Branch Access" />
				</Stack>
				<Stack>
					<RadioButton
						onChange={changeBranchesSelectionType}
						items={[
							{
								value: selectBranchesTypes.all,
								inputName: 'All Branches',
								name: 'allBranches',
								id: 'allBranches',
							},
							{
								value: selectBranchesTypes.multiple,
								inputName: 'Multiple Branches',
								name: 'multipleBranches',
								id: 'multipleBranches',
							},
						]}
						selected={formState.branchesType}
					/>
				</Stack>
				<Stack m="md">
					<Grid cols="3" gap="3">
						{branchesCheckBoxes}
					</Grid>
				</Stack>
			</Stack>
		</Card>
	);

	const handleCancelBtn = () =>
		history.push({
			pathname: '/teams/dashboard',
		});

	const toolbarEnd = (
		<Align align="right" gap="1">
			{/* <InlineLoading hide={!isLoading} /> */}
			<ButtonWithIcon label="Cancel" m="none" onClick={handleCancelBtn} />
			<ButtonWithIcon
				primary
				label="Create"
				m="none"
				onClick={handleTeamSubmit}
				disabled={!isFormValid()}
			/>
		</Align>
	);

	return (
		<AppContainer protected breadcrumbs={pointsBreadCrumb}>
			<MainStackWithAside fullWidth="true" toolbarEnd={toolbarEnd}>
				{FormModal}
			</MainStackWithAside>
		</AppContainer>
	);
}
