import React, { useState } from 'react';
import cardImage from '@zeal/zeal-ui/src/assets/images/stories-request.png';
import { cloneDeep } from 'lodash-es';
import {
	Button,
	ButtonWithIcon,
	Card,
	Grid,
	Icon,
	Image,
	Input,
	Label,
	MainStackWithAside,
	MainStackWithIcon,
	Modal,
	Select,
	SizedBox,
	Stack,
	Title,
	TwoSideLayout,
} from '@zeal/zeal-ui';

import useRequests from '../data/useRequests';

export default function Requests({ ...props }) {
	const [isAddEditModalOpen, setIsAddEditModalOpen] = useState(false);
	const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
	const [editedStory, setEditedStory] = useState(null);
	const [storiesData, setStoriesData] = useState([]);
	const [photo, setPhoto] = useState();
	const [type, setType] = useState(null);
	const [promocode, setPromocode] = useState();
	const [title, setTitle] = useState();
	const [description, setDescription] = useState();
	const [expireDate, setExpireDate] = useState(new Date());
	const [hideAtQuotaEnd, setHideAtQuotaEnd] = useState(false);
	const [isShown, setIsShown] = useState(false);

	const storiesQuery = useRequests();
	const { data } = storiesQuery;

	React.useEffect(() => {
		setStoriesData(data);
	}, [data]);

	const openStoryModal = () => {
		setIsAddEditModalOpen(true);
	};

	const addStoryBtnClick = () => {
		resetForm();
		setEditedStory(null);
		openStoryModal();
	};

	const handleStoryEdit = (item) => {
		openStoryModal();
		setPhoto(item?.photo);
		setTitle(item?.title);
		setType(item?.type);
		setDescription(item?.description);
		setExpireDate(item?.expireDate);
		setHideAtQuotaEnd(item?.hideAtQuotaEnd);
		setPromocode(item?.promocode);
		setEditedStory(item);
	};

	const handleStoryDelete = (item) => {
		setStoriesData((prev) => {
			let oldStories = cloneDeep(prev);
			return oldStories.filter(function (a) {
				return a.uuid !== item.uuid;
			});
		});
		storiesQuery.refetch();
		setIsDeleteModalOpen(false);
	};

	const handleFilesUpload = (files) => {
		setPhoto(files[0]);
	};

	const resetForm = () => {
		setTitle('');
		setPhoto('');
		setDescription('');
		setExpireDate(new Date());
		setType('');
		setPromocode('');
		setHideAtQuotaEnd(false);
	};

	const addStory = (story) => {
		setStoriesData([...storiesData, story]);
		storiesQuery.refetch();
	};

	const editStory = (story) => {
		let oldStories = cloneDeep(storiesData);

		const editedItemIndex = oldStories.findIndex(
			(a) => a?.uuid === editedStory?.uuid,
		);

		oldStories.splice(editedItemIndex, 1, story);
		setStoriesData(oldStories);
		storiesQuery.refetch();
	};

	const handleStorySubmit = React.useCallback(() => {
		let story = {
			uuid: Date.now().toString,
			title,
			photo,
			type,
			promocode,
			description,
			expireDate,
			hideAtQuotaEnd,
		};

		editedStory ? editStory(story) : addStory(story);

		setIsAddEditModalOpen(false);
		resetForm();
	}, [
		title,
		photo,
		type,
		promocode,
		description,
		expireDate,
		hideAtQuotaEnd,
		editedStory,
	]);

	const ActionsComp = (item) => (
		<Stack row>
			<Icon
				bg="disabled"
				rounded
				name="edit"
				size="lg"
				p="xs"
				onClick={() => handleStoryEdit(item)}
			/>
			<Icon
				bg="disabled"
				rounded
				name="trash"
				size="lg"
				p="xs"
				onClick={() => setIsDeleteModalOpen(true)}
			/>
		</Stack>
	);

	const storiesCards = React.Children.toArray(
		storiesData?.map((item) => (
			<Card p="none" display="contents">
				<Button
					m="none"
					p="none"
					stretch
					display="contents"
					fullHight
					onMouseEnter={() => setIsShown(true)}
					onMouseLeave={() => setIsShown(false)}
				>
					<MainStackWithIcon
						icon={isShown && ActionsComp(item)}
						onMouseEnter={() => setIsShown(true)}
						onMouseLeave={() => setIsShown(false)}
						xPosition="right"
						yPosition="top"
						iconPadding="none"
						pointer
						fillContainer={false}
						primary={props.isNewlyCreated}
					>
						<Label
							xPosition="left"
							yPosition="bottom"
							textColor="default"
							iconName="eye"
							children="1,300 views"
						/>
						<Label
							xPosition="right"
							yPosition="bottom"
							textColor="default"
							p="xs"
							children="Today"
						/>
						<Stack p="none">
							<SizedBox height="3xl">
								<img
									src={
										(item?.photo && URL.createObjectURL(item?.photo))
										|| cardImage
									}
									alt="card"
								/>
							</SizedBox>
						</Stack>
					</MainStackWithIcon>
				</Button>
			</Card>
		)),
	);

	return (
		<MainStackWithAside
			toolbarEnd={
				<ButtonWithIcon
					primary
					label="Add Story"
					btnIcon="plus"
					size="xs"
					m="none"
					onClick={addStoryBtnClick}
				/>
			}
		>
			<Grid cols="4" gap="3">
				{storiesCards}
			</Grid>
			<AddEditModal
				isModalOpen={isAddEditModalOpen}
				closeModal={() => setIsAddEditModalOpen(false)}
				handleFilesUpload={handleFilesUpload}
				photo={photo}
				editedStory={editedStory}
				type={type}
				onTypeChange={setType}
				handleStorySubmit={handleStorySubmit}
			/>
			<DeleteStoryModal
				isDeleteModalOpen={isDeleteModalOpen}
				closeDeleteModal={() => setIsDeleteModalOpen(false)}
				handleDelete={handleStoryDelete}
			/>
		</MainStackWithAside>
	);
}

function AddEditModal(props) {
	const {
		isModalOpen,
		closeModal,
		handleFilesUpload,
		photo,
		editedStory,
		type,
		onTypeChange,
		handleStorySubmit,
	} = props;
	const [openUploader, setOpenUploader] = useState();
	const modalTitle = editedStory ? 'Edit Story' : 'Add Story';
	const modalSubmitLabel = editedStory ? 'Edit Story' : 'Add Story';

	const photoUploader = photo ? (
		<Stack>
			<Title title="Preview" margin="xs" />
			<Image
				my="md"
				cover
				rounded
				src={URL.createObjectURL(photo)}
				alt="Story photo"
			/>
			<ButtonWithIcon
				transparent
				p="xs"
				m="none"
				onClick={() => {
					setOpenUploader(Date.now());
				}}
			>
				<Title primary title="Change Photo" size="sm" />
			</ButtonWithIcon>
		</Stack>
	) : (
		<Stack>
			<Title title="Upload Photo" margin="xs" />
		</Stack>
	);

	return (
		<Modal isModalOpen={isModalOpen} title={modalTitle} onClose={closeModal}>
			<Stack>
				{photoUploader}
				<Input.Upload
					onDropFiles={handleFilesUpload}
					acceptedFiles="image/*"
					maxFiles="1"
					openUploader={openUploader}
					style={{ display: photo ? 'none' : 'block' }}
				/>

				<Stack>
					<Title title="Pick Branch" margin="xs" />
					<Select
						inputName="type"
						placeholder={type ? type : '--Choose Branch--'}
						options={[
							{ value: 'news', label: 'News' },
							{ value: 'promocode', label: 'Promocode' },
						]}
						selected={type}
						onChange={onTypeChange}
					/>
				</Stack>
				<Grid gap="5">
					<Title title={<hr />} margin="2xl" />
					<TwoSideLayout>
						<Button stretch={false} size="md" m="xs" onClick={closeModal}>
							Cancel
						</Button>
						<ButtonWithIcon
							stretch={false}
							size="md"
							primary
							m="xs"
							px="lg"
							onClick={handleStorySubmit}
						>
							{modalSubmitLabel}
						</ButtonWithIcon>
					</TwoSideLayout>
				</Grid>
			</Stack>
		</Modal>
	);
}

function DeleteStoryModal(props) {
	const { isDeleteModalOpen, closeDeleteModal, handleDelete } = props;

	return (
		<Modal isModalOpen={isDeleteModalOpen} title="Delete Story">
			<Stack p="none">
				<Title title="Are you sure you want to delete this story?" />

				<Grid gap="5">
					<span></span>
					<TwoSideLayout>
						<Button stretch={false} size="md" m="xs" onClick={closeDeleteModal}>
							Cancel
						</Button>
						<ButtonWithIcon
							secondary
							stretch={false}
							size="md"
							m="xs"
							onClick={() => handleDelete('uuid')}
						>
							Delete
						</ButtonWithIcon>
					</TwoSideLayout>
				</Grid>
			</Stack>
		</Modal>
	);
}
