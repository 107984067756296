import React from 'react';
import { useMutation } from 'react-query';

import { ApiRequest } from '@zeal/zeal-lib';
import { useQuery } from '../../App/QueryClient';
import { useQueryString } from '../../App/useQueryString';

async function apiCategories({ queryKey: [, filters] }) {
	return new ApiRequest('v3/categories')
		.addHeader('Accept', 'application/json')
		.GET();
}

async function apiSubmitCategory(body) {
	return new ApiRequest('v3/settings/category')
		.addHeader('Accept', 'application/json')
		.addHeader('Content-Type', 'application/json')
		.PUT(JSON.stringify(body))
		.then((payload) => ({
			data: payload?.data,
		}));
}

export default function useCategories() {
	const [queryState] = useQueryString();

	const q = useQuery({
		queryFn: apiCategories,
		staleTime: 1000 * 60 * 15, // 15 min
		queryKey: [
			'z-Categories',
			{
				date: queryState?.date,
			},
		],
	});

	React.useDebugValue(q);

	return q;
}

async function apiDeleteTag({ ...payload }) {
	// return new ApiRequest("v3/points")
	// .addHeader("Accept", "application/json")
	// .addHeader("Content-Type", "application/json")
	// .POST(JSON.stringify(payload), true)

	console.warn('[API/dummy]', 'useCategories::apiDeleteTag');

	return Promise.resolve(true);
}

export function useDeleteTag(opt) {
	const mutation = useMutation(apiDeleteTag);

	const { data, mutate, isSuccess } = mutation;

	React.useEffect(() => {
		if (!isSuccess) {
			return null;
		}

		if (opt.onSuccess) {
			opt.onSuccess();
		}
	}, [data, isSuccess]);

	return {
		...mutation,
		doDeleteTag: mutate,
	};
}

export function useSubmitCategory(opt) {
	const mutation = useMutation(apiSubmitCategory);

	const { data, mutate, isSuccess, isError } = mutation;

	React.useEffect(() => {
		if (isError) {
			if (opt.onError) {
				opt.onError();
			}
		}
		if (!isSuccess) {
			return null;
		}

		if (opt.onSuccess) {
			opt.onSuccess();
		}
	}, [data, isSuccess]);

	return {
		...mutation,
		doAddCategory: mutate,
	};
}
