import React from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';

import ACLBoundary from '../App/ACL/ACLBoundary';
import CreateTeamApp from './components/CreateTeamApp';
import Dashboard from './components/Dashboard';
import TeamApp from './components/TeamApp';
import CreateBusinessAdmin from './components/CreateBusinessAdmin';
import EditBusinessAdmin from './components/EditBusinessAdmin';
import CreateRoles from './components/CreateRoles';
import Roles from './components/Roles';
import EditRoles from './components/EditRoles';

export default function TeamMembersRoutes() {
	return (
		<Switch>
			<ACLBoundary
				aclUnAuthProps={{ replace: true }}
				aclGrants={['superAdmin']}
			>
				<Route exact path="/teams">
					<Redirect push to="/teams/dashboard" />
				</Route>
				<Route exact path="/teams/app" component={TeamApp} />
				<Route path="/teams/app/add" component={CreateTeamApp} />
				<Route path="/teams/app/edit/:uuid" component={CreateTeamApp} />
				<Route exact path="/teams/dashboard" component={Dashboard} />
				<Route
					path="/teams/dashboard/create-admin"
					component={CreateBusinessAdmin}
				/>
				<Route
					path="/teams/dashboard/edit-admin"
					component={EditBusinessAdmin}
				/>
				<Route
					exact
					path="/teams/dashboard/roles/create"
					component={CreateRoles}
				/>
				<Route exact path="/teams/dashboard/roles/edit" component={EditRoles} />
				<Route exact path="/teams/dashboard/roles" component={Roles} />
			</ACLBoundary>
		</Switch>
	);
}
