import React, { useEffect, useState } from 'react';
import { get, has, isNaN } from 'lodash-es';

import { ApiRequest } from '@zeal/zeal-lib';
import { useQuery } from '../../App/QueryClient';
import { useQueryString } from '../../App/useQueryString';
import { relationalOperatorsMap } from '../../utils/relationalOperators';

async function apiCustomersRecords({ queryKey: [, { page, filters }] }) {
	return new ApiRequest('v3/members')
		.addHeader('Accept', 'application/json')
		.addHeader('Content-Type', 'application/json')
		.POST(
			JSON.stringify({
				page,
				filters,
				take: 10,
				per_page: 20,
				sortBy: 'created_at',
				sortDirection: 'asc',
			}),
		)
		.then((payload) => ({
			data: payload?.data,
			pageNum: payload?.meta.current_page,
			pagesCount: payload?.meta.last_page || 1,
			total: payload?.meta.total,
		}));
}

export function usePagination({ pageNum, pagesCount }) {
	const [queryState] = useQueryString();

	if (queryState?.page != pageNum) {
		console.error(
			new Error(
				`Pagination mismatch query vs api, expected ${queryState?.page} found ${pageNum}`,
			),
		);
	}

	const pagesList = Array.from(
		new Set([
			1,
			2,
			pageNum - 1,
			pageNum,
			pageNum + 1,
			pagesCount - 1,
			pagesCount,
		]),
	).reduce((a, i, ndx, arr) => {
		const prv = arr[ndx - 1];
		const isCurrent = pageNum == i;

		if (i === 0) {
			i = prv + 1;
		}

		if (i - prv > 1) {
			a.push({ dff: i - prv, isMany: true });
		}

		if (isNaN(i) || i > pagesCount) {
			return a;
		}

		a.push({ num: i, isCurrent });
		return a;
	}, []);

	return {
		pagesList,
		pagesCount,
		page: queryState?.page,
	};
}

export default function useCustomersRecords(opt) {
	const [queryState] = useQueryString();

	const filters = formatFilters(queryState);

	const { data, status, ...queryProps } = useQuery({
		queryFn: apiCustomersRecords,
		staleTime: 1000 * 60 * 15, // 15 min,
		keepPreviousData: true,
		queryKey: ['z-customers-records', { filters, page: queryState.page }],
	});

	const pagination = usePagination(data || {});

	React.useDebugValue([
		{ opt },
		{ data, status, ...queryProps },
		{ pagination },
	]);

	const [customersList, setCustomersList] = useState([]);

	const formatCustomer = opt?.formatCustomer || ((d) => d);

	useEffect(() => {
		if (data?.data) {
			const formattedData = data?.data?.map(formatCustomer);
			setCustomersList(formattedData);
		}
	}, [data?.data, setCustomersList]);

	return {
		status,
		...pagination,
		...queryProps,
		data: customersList,
		totalCustomersCount: data?.total,
	};
}

const customerFilters = [
	['visits', 'visits'],
	['phoneNumber', 'phone_number'],
	['spent', 'spent'],
	['redeems', 'redeems'],
	['signupDate', 'signup_date'],
	['firstPunch', 'first_punch'],
	['lastVisit', '24 - September - 2020'],
];

export function formatFilters(queryState, filters = customerFilters) {
	const formattedFilters = filters?.reduce?.((a, [k, v]) => {
		if (has(queryState, k)) {
			const [operator, operand] = get(queryState, k, []);

			a.push({
				column: v,
				value: operand,
				condition: relationalOperatorsMap().getSymbol(operator),
			});
		}

		return a;
	}, []);

	return formattedFilters;
}
