import React, { useReducer, useState } from 'react';
import {
	Align,
	Button,
	ButtonWithIcon,
	Grid,
	InlineError,
	InlineLoading,
	Modal,
	Stack,
	Title,
	TwoSideLayout,
} from '@zeal/zeal-ui';

import usePhotos, { useDeletePhoto } from '../data/usePhotos';

export default function DeletePhotoModal(props) {
	const {
		isDeleteModalOpen,
		closeDeleteModal,
		setIsDeleteModalOpen,
		removeError,
		setRemoveError,
		id,
	} = props;
	const photosQuery = usePhotos();

	const { doDeletePhoto, isError, isLoading, isSuccess, status }
		= useDeletePhoto({
			onSuccess: () => {
				photosQuery.refetch();
				setIsDeleteModalOpen(false);
			},
		});
	const handleDelete = (id) => {
		doDeletePhoto({ id });
		setRemoveError(true);
	};
	return (
		<Modal
			isModalOpen={isDeleteModalOpen}
			title="Delete Photo"
			onClose={closeDeleteModal}
		>
			<Stack p="none">
				<Title title="Are you sure you want to delete this photo?" />

				<Grid gap="5">
					<span></span>
					<TwoSideLayout>
						<ButtonWithIcon
							stretch={false}
							m="xxs"
							px="md"
							py="sm"
							onClick={closeDeleteModal}
						>
							Cancel
						</ButtonWithIcon>
						<Align align="left">
							<InlineError hide={!isError + !removeError} />
							<InlineLoading hide={!isLoading} />
							<ButtonWithIcon
								secondary
								stretch={false}
								m="xxs"
								px="md"
								py="sm"
								onClick={() => handleDelete(id)}
							>
								Delete
							</ButtonWithIcon>
						</Align>
					</TwoSideLayout>
				</Grid>
			</Stack>
		</Modal>
	);
}
