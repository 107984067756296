import React, { useEffect, useReducer, useState } from 'react';
import { cloneDeep, omit, pick } from 'lodash';

import {
	Align,
	BasicTable,
	Button,
	ButtonWithIcon,
	Card,
	Center,
	Grid,
	Icon,
	Input,
	Modal,
	SizedBox,
	Stack,
	Title,
	ToggleButton,
	TwoSideLayout,
} from '@zeal/zeal-ui';

export default function ItemSections(props) {
	const { formDispatch, formState, setUpdateEvent } = props;

	const initialItemValues = {
		sectionsModal: false,
		attributesModal: false,
		editingSection: {
			value: null,
			index: -1,
		},
		editingAttribute: {
			value: null,
			index: -1,
		},
	};

	const addEditFormReducer = (prevState, newState) => ({ ...prevState, ...newState });

	const [itemState, itemDispatch] = useReducer(
		addEditFormReducer,
		initialItemValues,
	);

	const handleAttributesEditClick = (sectionIndex, { row }) => {
		const rowData = row?.original;

		itemDispatch({
			editingSection: {
				value: null,
				index: sectionIndex,
			},
			editingAttribute: {
				value: rowData,
				index: row.id,
			},
			attributesModal: true,
		});
	};

	const sectionEditClicked = (section, index = -1) => {
		itemDispatch({
			editingSection: {
				value: section,
				index: index,
			},
			sectionsModal: true,
		});
	};

	const openAddAttributeModal = (sectionIndex) => {
		itemDispatch({
			editingAttribute: {
				value: null,
				index: -1,
			},
			editingSection: {
				value: null,
				index: sectionIndex,
			},
			attributesModal: true,
		});
	};

	const openAddSectionModal = () => {
		itemDispatch({
			editingSection: {
				value: null,
				index: -1,
			},
			sectionsModal: true,
		});
	};

	const handleSectionSubmit = (section) => {
		formDispatch({ sectionsChanged: true });

		itemState?.editingSection?.value
			? editSection(section)
			: addSection(section);
	};

	const addSection = (section) => {
		const newSection = {
			attributes: [],
			...section,
			id: new Date().valueOf(),
		};

		formDispatch({ sections: [...formState?.sections, newSection] });
		itemDispatch({
			sectionsModal: false,
		});

		setUpdateEvent?.({
			key: newSection?.id,
			data: newSection,
			action: 'attributeGroup.create',
		});
	};

	const editSection = (section) => {
		const sections = cloneDeep(formState.sections);

		const editedIndex = itemState?.editingSection?.index;

		if (editedIndex !== -1) {
			sections[editedIndex] = { ...sections[editedIndex], ...section };

			if (sections[editedIndex]?.uuid)
			{setUpdateEvent?.({
				key: sections[editedIndex]?.uuid,
				data: {
					...pick(sections[editedIndex], [
						'name',
						'only_one',
						'required',
						'uuid',
					]),
				},
				action: 'attributeGroup.update',
			});}
			else {
				setUpdateEvent?.({
					key: sections[editedIndex]?.id,
					data: omit(sections[editedIndex], ['updated_at', 'created_at']),
					action: 'attributeGroup.create',
				});
			}

			formDispatch({ sections });
		}

		itemDispatch({
			sectionsModal: false,
		});
	};

	const handleAttributeSubmit = (attr) => {
		formDispatch({ sectionsChanged: true });

		itemState?.editingAttribute?.value
			? editAttribute(attr)
			: addAttribute(attr);
	};

	const editAttribute = (attr) => {
		const oldSections = cloneDeep(formState.sections);

		const { editingAttribute, editingSection } = itemState;

		oldSections[editingSection?.index].attributes[editingAttribute?.index] = {
			...editingAttribute?.value,
			...attr,
		};

		const newAttributeData
			= oldSections[editingSection?.index].attributes[editingAttribute?.index];

		if (newAttributeData?.uuid)
		{setUpdateEvent?.({
			key: newAttributeData?.uuid,
			data: omit(newAttributeData, ['updated_at', 'created_at']),
			action: 'attribute.update',
		});}
		else {
			if (editingSection?.value?.uuid) {
				setUpdateEvent?.({
					key: newAttributeData?.id,
					data: {
						attribute: omit(newAttributeData, ['updated_at', 'created_at']),
						attributeGroup: editingSection?.value?.uuid,
					},
					action: 'attribute.create',
				});
			} else {
				setUpdateEvent?.({
					key: oldSections[editingSection?.index]?.id,
					data: oldSections[editingSection?.index],
					action: 'attributeGroup.create',
				});
			}
		}

		formDispatch({ sections: oldSections });
		itemDispatch({ attributesModal: false });
	};

	const addAttribute = (attr) => {
		const oldSections = cloneDeep(formState.sections);

		const { editingSection } = itemState;

		const newAttribute = {
			...attr,
			id: new Date().valueOf(),
		};

		oldSections[editingSection?.index]?.attributes.push(newAttribute);

		if (oldSections[editingSection?.index]?.uuid) {
			setUpdateEvent?.({
				key: newAttribute?.id,
				data: {
					attribute: omit(newAttribute, ['updated_at', 'created_at']),
					attributeGroup: oldSections[editingSection?.index]?.uuid,
				},
				action: 'attribute.create',
			});
		} else {
			setUpdateEvent?.({
				key: oldSections[editingSection?.index]?.id,
				data: oldSections[editingSection?.index],
				action: 'attributeGroup.create',
			});
		}

		formDispatch({ sections: oldSections });
		itemDispatch({ attributesModal: false });
	};

	return (
		<>
			<Stack m="md" p="none">
				<Stack p="none">
					<Title title="Item Add-Ons" size="md" />
				</Stack>
				<Stack>
					<Sections
						sectionsData={formState.sections}
						formDispatch={formDispatch}
						handleAttributesEditClick={handleAttributesEditClick}
						sectionEditClicked={sectionEditClicked}
						openAddAttributeModal={openAddAttributeModal}
					/>
					<Title
						primary
						title={
							<AddTitle
								handleClick={openAddSectionModal}
								items={[]}
								title="Add Sections"
								subtitle="add sections to your item. ex ( size, extras, sides, sauces,  combo's )"
							/>
						}
						size="sm"
					/>
				</Stack>
			</Stack>

			<AddEditSectionModal
				isModalOpen={itemState.sectionsModal}
				closeModal={() => itemDispatch({ sectionsModal: false })}
				handleModalSubmit={handleSectionSubmit}
				section={itemState.editingSection?.value}
			/>

			<AddEditAttributesModal
				isAttributesModalOpen={itemState.attributesModal}
				closeModal={() => itemDispatch({ attributesModal: false })}
				handleModalSubmit={handleAttributeSubmit}
				attribute={itemState.editingAttribute?.value}
			/>
		</>
	);
}

function Sections(props) {
	const {
		sectionsData,
		handleAttributesEditClick,
		sectionEditClicked,
		openAddAttributeModal,
	} = props;

	const sections = React.Children.toArray(
		sectionsData?.map((section, index) => (
			<div>
				<SizedBox fillWidth>
					<Stack m="sm">
						<Card shadow="0">
							<TwoSideLayout>
								<Title title={section.name} size="sm" />
								<ButtonWithIcon
									p="none"
									m="none"
									onClick={() => sectionEditClicked(section, index)}
								>
									<Icon rounded name="edit" size="xs" bg="ghost" />
								</ButtonWithIcon>
							</TwoSideLayout>
						</Card>
						<AttributesTable
							attributes={section.attributes}
							handleEditClicked={(rows) =>
								handleAttributesEditClick(index, rows)
							}
						/>
						<AddTitle
							handleClick={() => openAddAttributeModal(index)}
							items={[]}
							title="Add New Attribute"
						/>
					</Stack>
				</SizedBox>
			</div>
		)),
	);

	return sections;
}

function AttributesTable(props) {
	const { handleEditClicked, attributes } = props;

	const renderActionCell = (rows) => (
		<Align align="right">
			<ButtonWithIcon p="none" m="none" onClick={() => handleEditClicked(rows)}>
				<Icon rounded name="edit" size="xs" bg="ghost" />
			</ButtonWithIcon>
		</Align>
	);

	const tableColumns = [
		{ Header: 'Name', accessor: 'name' },
		{ Header: 'Price', accessor: 'price' },
		{ Header: '', accessor: 'actions', Cell: renderActionCell },
	];

	return (
		attributes?.length > 0 && (
			<BasicTable
				border
				shadow={false}
				cellPadding="lg"
				headerPadding="lg"
				data={attributes || []}
				columns={tableColumns}
			/>
		)
	);
}

function AddEditSectionModal(props) {
	const formInitialState = {
		nameInput: '',
		requiredToggle: false,
		multipleToggle: false,
	};

	const formReducer = (prevState, newState) => ({ ...prevState, ...newState });

	const [formState, formDispatch] = useReducer(formReducer, formInitialState);

	const { isModalOpen, closeModal, handleModalSubmit, section } = props;

	const onCloseModal = () => {
		closeModal();
		formDispatch(formInitialState);
	};

	useEffect(() => {
		if (section)
		{formDispatch({
			nameInput: section?.name,
			requiredToggle: section?.required,
			multipleToggle: section?.only_one,
		});}
		else {formDispatch(formInitialState);}
	}, [section, isModalOpen]);

	const modalTitle = section ? 'Edit Section' : 'Add Section';

	const submitModal = () => {
		handleModalSubmit({
			name: formState.nameInput,
			required: formState.requiredToggle,
			only_one: formState.multipleToggle,
		});

		formDispatch(formInitialState);
	};

	const onToggleChange = (ev) => {
		formDispatch({ [ev.target.name]: !formState[ev.target.name] });
	};

	return (
		<Modal isModalOpen={isModalOpen} title={modalTitle} onClose={onCloseModal}>
			<Stack p="none">
				<Title title="Section Name" margin="xs" mb="none" />
				<Input
					type="text"
					inputName="from"
					placeholder="Type Name"
					onChange={(evt) => formDispatch({ nameInput: evt.target.value })}
					value={formState.nameInput}
				/>
				<Stack p="none" m="md" mx="none">
					<hr />
				</Stack>
				<Stack row gap="xl">
					<ToggleButton
						isChecked={formState.requiredToggle}
						name="requiredToggle"
						size="sm"
						onChange={onToggleChange}
					/>
					<p> Required</p>
				</Stack>
				<Stack row gap="xl" m="xs" mx="none" mb="none">
					<ToggleButton
						isChecked={formState.multipleToggle}
						name="multipleToggle"
						size="sm"
						onChange={onToggleChange}
					/>
					<p> Multiple Selection</p>
				</Stack>
			</Stack>

			<Grid gap="5">
				<span></span>
				<TwoSideLayout>
					<Button stretch={false} size="md" m="xs" onClick={onCloseModal}>
						Cancel
					</Button>
					<ButtonWithIcon
						stretch={false}
						size="md"
						primary
						m="xs"
						onClick={submitModal}
					>
						Save
					</ButtonWithIcon>
				</TwoSideLayout>
			</Grid>
		</Modal>
	);
}

function AddEditAttributesModal(props) {
	const [nameInput, setNameInput] = useState();
	const [priceInput, setPriceInput] = useState();

	const { isAttributesModalOpen, closeModal, handleModalSubmit, attribute }
		= props;

	useEffect(() => {
		setNameInput(attribute?.name);
		setPriceInput(attribute?.price);
	}, [attribute, isAttributesModalOpen]);

	const onCloseModal = () => {
		closeModal();
		setNameInput('');
		setPriceInput('');
	};

	return (
		<Modal
			isModalOpen={isAttributesModalOpen}
			title="Add A New Attribute"
			onClose={onCloseModal}
		>
			<Stack p="none">
				<Title title="Attribute Name" margin="xs" mb="none" />
				<Input
					type="text"
					inputName="from"
					placeholder="Type Name"
					onChange={(evt) => setNameInput(evt.target.value)}
					value={nameInput}
				/>
			</Stack>

			<Stack p="none">
				<Title title="Add Branch Price" margin="xs" mb="none" />
				<Input.Number
					margin="none"
					inputName="price"
					placeholder="Type Price/EGP"
					onChange={(evt) => setPriceInput(Number(evt.target.value) || 0)}
					value={priceInput}
				/>
			</Stack>

			<Grid gap="5">
				<span></span>
				<TwoSideLayout>
					<Button stretch={false} size="md" m="xs" onClick={closeModal}>
						Cancel
					</Button>
					<ButtonWithIcon
						stretch={false}
						size="md"
						primary
						m="xs"
						onClick={() =>
							handleModalSubmit({
								name: nameInput,
								price: priceInput,
								incremental: false,
								max_increment: 3,
							})
						}
						disabled={!nameInput || !priceInput}
					>
						Save
					</ButtonWithIcon>
				</TwoSideLayout>
			</Grid>
		</Modal>
	);
}

function AddTitle(props) {
	const { handleClick, items, title, subtitle } = props;
	return (
		items?.length === 0 && (
			<Button transparent p="none" onClick={handleClick}>
				<Stack gap="sm">
					<Center horizontal={false}>
						<Title
							primary
							title={<Icon name="plusCircle" size="xs" m="none" />}
							size="xs"
						/>
						<Title primary title={title} size="xs" />
					</Center>
					<Title primary subtitle={subtitle} size="xs" />
				</Stack>
			</Button>
		)
	);
}
