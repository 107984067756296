import { useDebugValue, useEffect, useState } from 'react';

import { ApiRequest } from '@zeal/zeal-lib';
import { useQuery } from '../../App/QueryClient';

async function apiBranches({ queryKey: [, filters] }) {
	return new ApiRequest('v3/branch')
		.addHeader('Accept', 'application/json')
		.addQuery('paginate', filters?.paginate)
		.GET()
		.then((payload) => ({
			data: payload?.data,
		}));
}

async function apiTechnicians({ queryKey: [, filters] }) {
	return new ApiRequest(`v3/branch/${filters?.branchId}/technicians`)
		.addHeader('Accept', 'application/json')
		.addQuery('paginate', filters?.paginate)
		.GET()
		.then((payload) => ({
			data: payload?.data,
		}));
}

export function useBranches(opt) {
	const [branchesList, setBranchesList] = useState([]);

	const q = useQuery({
		queryFn: apiBranches,
		queryKey: [
			'z-branches-not-paginated',
			{
				paginate: false,
			},
		],
		staleTime: Infinity,
	});

	const formatBranch = opt?.formatBranch || ((d) => d);

	const { data: branchesData } = q;

	useEffect(() => {
		if (branchesData) {
			const formattedData = branchesData?.data?.map(formatBranch);
			setBranchesList(formattedData);
		}
	}, [branchesData, setBranchesList]);

	useDebugValue(q);

	return { ...q, data: branchesList };
}

export function useBranchTechnicians(opt) {
	const [teamList, setTeamList] = useState([]);

	const q = useQuery({
		queryFn: apiTechnicians,
		queryKey: [
			'z-branch-technicians-not-paginated',
			{
				paginate: 0,
				branchId: opt?.branchId,
			},
		],
		staleTime: Infinity,
		enabled: !!opt?.branchId,
	});

	const formatTechnicians = opt?.formatTechnicians || ((d) => d);

	const { data: teamData } = q;

	useEffect(() => {
		if (teamData) {
			const formattedData = teamData?.data?.map(formatTechnicians);
			setTeamList(formattedData);
		}
	}, [teamData, setTeamList]);

	useDebugValue(q);

	return { ...q, data: teamList };
}
