import React, { useCallback, useEffect, useState } from 'react';
import { DateTime } from 'luxon';
import { useHistory } from 'react-router-dom';
import {
	Align,
	BasicTable,
	Button,
	ButtonWithIcon,
	Card,
	Center,
	Grid,
	Icon,
	InlineError,
	InlineLoading,
	MainStackWithAside,
	Modal,
	Pagination,
	Stack,
	TabsHorizontal,
	Title,
	TwoSideLayout,
} from '@zeal/zeal-ui';

import useBusinessAdmins, {
	useDeleteBusinessAdmin,
} from '../data/useDashboard';

import TeamMembersFrame from '../TeamMembersFrame';
import openToastr from '../../App/Toastr/openToastr';
import useRoles from '../data/useRoles';
import { useQueryString } from '../../App/useQueryString';

export default function Dashboard(props) {
	const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
	const [deletedAdminId, setDeletedAdminId] = useState();
	const [selectedRoleId, setSelectedRoleId] = useState(null);

	const businessAdminsQuery = useBusinessAdmins({ roleId: selectedRoleId });
	const {
		data: businessAdminData,
		pagesList,
		isLoading: isDataLoading,
		isError,
	} = businessAdminsQuery;

	const rolesQuery = useRoles();
	const { data: rolesData } = rolesQuery;

	useEffect(() => {
		setSelectedRoleId(rolesData?.data?.[0]?.id);
	}, [rolesData]);

	const { doDeleteBusinessAdmin, isLoading: isDeleteLoading } =
		useDeleteBusinessAdmin({
			onSuccess: () => {
				businessAdminsQuery.refetch();
				rolesData.refetch();
				setIsDeleteModalOpen(false);
				setDeletedAdminId(null);
				openToastr({ message: 'Admin Deleted Successfully' });
			},
			onError: (error) => {
				const clonedError = error?.response.clone();

				if (!clonedError?.bodyUsed) {
					clonedError.json().then((err) => {
						openToastr({
							message: err?.message || 'Failed to delete admin',
							isError: true,
						});
					});
				}
			},
		});

	const handleOpenDeleteModal = (uuid) => {
		setIsDeleteModalOpen(true);
		setDeletedAdminId(uuid);
	};

	const actionsCell = ({ row }) => (
		<Align align="right" gap="1">
			<Button
				transparent
				stretch={false}
				p="none"
				m="none"
				onClick={() => {
					handleDashEdit(row.original);
				}}
			>
				<Icon rounded bg="disabled" name="pen" size="lg" p="xs" margin="none" />
			</Button>

			<Button
				transparent
				stretch={false}
				p="none"
				m="none"
				onClick={() => handleOpenDeleteModal(row?.original?.uuid)}
			>
				<Icon
					rounded
					bg="danger"
					name="trashAlt"
					size="lg"
					p="xs"
					margin="none"
				/>
			</Button>
		</Align>
	);
	const history = useHistory();

	const handleDashAdd = useCallback(
		() =>
			history.push({
				pathname: '/teams/dashboard/create-admin',
			}),
		[history]
	);

	const handleDashEdit = useCallback(
		(rowData) =>
			history.push({
				pathname: '/teams/dashboard/edit-admin',
				search: `?adminId=${rowData?.uuid}`,
			}),
		[history]
	);

	const handleDashDelete = () => {
		doDeleteBusinessAdmin({ uuid: deletedAdminId });
	};

	const dashPointColumns = [
		{ Header: 'Name', accessor: 'name' },
		{
			Header: 'Email',
			accessor: 'email',
			Cell: handleNoneData,
		},

		{
			Header: 'Invitation date',
			accessor: 'created_at',
			Cell: renderRelativeDatetime,
		},
		{ Header: '', accessor: 'actions', Cell: actionsCell },
	];

	const [{ page }, updateQuery] = useQueryString();

	const handleOnPageChange = ({ num }) => {
		updateQuery({ page: num });
	};

	const showPagination = pagesList?.length > 1 &&
		businessAdminData?.length > 0 && (
			<Pagination
				pages={pagesList}
				current={page}
				onPageChange={handleOnPageChange}
			></Pagination>
		);

	const dashTable = (businessAdminData) => (
		<div>
			{businessAdminData?.length > 0 && (
				<BasicTable
					shadow={false}
					data={businessAdminData}
					columns={dashPointColumns}
					headerPadding="md"
					cellPadding="lg"
				></BasicTable>
			)}
			{businessAdminData?.length === 0 && !isDataLoading && (
				<EmptyBusinessAdmins handleDashAdd={handleDashAdd} />
			)}
			{showPagination}
		</div>
	);

	const closeDeleteModal = () => {
		setIsDeleteModalOpen(false);
	};

	const tabsData = (rolesData?.data || []).map((r) => ({
		id: r?.id,
		tabTitle: `${r?.name} (${r?.users_count || 0})`,
		tabContent: dashTable(businessAdminData || []),
	}));

	const onTabChange = useCallback(
		(item) => {
			setSelectedRoleId(item.id);
		},
		[selectedRoleId]
	);

	const tabContent = (
		<TabsHorizontal
			data={tabsData}
			my="sm"
			onTabChange={onTabChange}
			initialTabId={selectedRoleId}
		/>
	);

	const handleManageRoles = useCallback(
		() =>
			history.push({
				pathname: '/teams/dashboard/roles',
			}),
		[history]
	);

	const breadcrumbs = [{ name: 'Dashboard', to: '/teams/dashboard' }];

	const emptyRolesState = rolesData?.data?.length > 0 && (
		<div>
			<Title
				title="Administrators"
				subtitle="Find all of your company's team accounts and their associated Dashboards."
				size="md"
			/>
			{tabContent}
		</div>
	);

	const ErrorAndLoadingStates = (isError || isDataLoading) && (
		<Center>
			<InlineLoading hide={!isDataLoading} />
			<InlineError hide={!isError} />
		</Center>
	);

	return (
		<TeamMembersFrame breadcrumbs={breadcrumbs}>
			<MainStackWithAside
				fullWidth="true"
				toolbarStart={
					<ButtonWithIcon
						label="Manage Roles"
						btnIcon="cog"
						size="xs"
						m="none"
						pt="none"
						onClick={handleManageRoles}
					/>
				}
				toolbarEnd={
					<ButtonWithIcon
						primary
						label="Add User"
						btnIcon="plus"
						size="xs"
						m="none"
						pt="none"
						onClick={handleDashAdd}
					/>
				}
			>
				<Card>
					<Stack>
						{emptyRolesState}
						{ErrorAndLoadingStates}
						<EmptyState
							data={rolesData?.data}
							handleDashAdd={handleDashAdd}
							isLoading={isDataLoading}
							isError={isError}
						/>
					</Stack>
				</Card>

				<DeleteModal
					closeModal={closeDeleteModal}
					isDeleteModalOpen={isDeleteModalOpen}
					isLoading={isDeleteLoading}
					handleDelete={handleDashDelete}
				/>
			</MainStackWithAside>
		</TeamMembersFrame>
	);
}

function EmptyState({ data, handleDashAdd, isLoading, isError }) {
	return (
		(data || []).length === 0 &&
		!isLoading &&
		!isError && (
			<Center>
				<Stack m="xl">
					<Title
						title="Dash management page is empty "
						subtitle="Create some Roles and invite members to have access to your dashboard "
						size="md"
					/>
					<Center className="mt-8">
						<Stack>
							<ButtonWithIcon
								primary
								label="Add User"
								btnIcon="plus"
								size="xs"
								m="none"
								pt="none"
								onClick={handleDashAdd}
							/>
						</Stack>
					</Center>
				</Stack>
			</Center>
		)
	);
}

function EmptyBusinessAdmins({ handleDashAdd }) {
	return (
		<Center>
			<Stack m="xl">
				<Title
					title="Empty Role"
					subtitle="Assign Some Business Admins to that role"
					size="md"
				/>
				<Center className="mt-8">
					<Stack>
						<ButtonWithIcon
							primary
							label="Add User"
							btnIcon="plus"
							size="xs"
							m="none"
							pt="none"
							onClick={handleDashAdd}
						/>
					</Stack>
				</Center>
			</Stack>
		</Center>
	);
}

function DeleteModal(props) {
	const { isDeleteModalOpen, handleDelete, closeModal, isLoading } = props;

	return (
		<Modal
			isModalOpen={isDeleteModalOpen}
			title="Delete Admin"
			onClose={closeModal}
		>
			<Stack p="none">
				<Title title="Are you sure you want to delete this admin?" />

				<Grid gap="5">
					<span></span>
					<TwoSideLayout>
						<Button stretch={false} size="md" onClick={closeModal}>
							Cancel
						</Button>
						<Align align="left">
							<InlineLoading hide={!isLoading} />
							<ButtonWithIcon
								secondary
								stretch={false}
								size="md"
								onClick={() => handleDelete()}
							>
								Delete
							</ButtonWithIcon>
						</Align>
					</TwoSideLayout>
				</Grid>
			</Stack>
		</Modal>
	);
}
function renderRelativeDatetime({ value }) {
	if (!value) {
		return '__';
	}
	if (DateTime.fromFormat(value, 'yyyy-MM-dd HH:mm:ss').isValid) {
		value = value.split(' ')[0];
	}

	let dtt = new Date(value);
	let dd = dtt.getDate();
	let mm = dtt.toLocaleString('default', { month: 'long' });
	let yyyy = dtt.getFullYear();

	if (dd < 10) {
		dd = '0' + dd;
	}
	if (mm < 10) {
		mm = '0' + mm;
	}

	return dd + '-' + mm + '-' + yyyy;
}
function handleNoneData({ value }) {
	if (value == '0') {
		return value;
	}
	if (!value) {
		return '__';
	}

	return value;
}
