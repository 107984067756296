import React from 'react';
import { DateTime } from 'luxon';
import { get, has } from 'lodash-es';
import {
	CenterStackWithToolbar,
	InlineEmpty,
	InlineError,
	InlineLoading,
	Table,
	className as cx,
} from '@zeal/zeal-ui';

import AppContainer from '../App/AppContainer';
import { useQueryString } from '../App/useQueryString';
import CompoundFilters from './components/CompoundFilters';
import useActivitiesRecords from './data/useActivitiesRecords';

function Select(props) {
	const cn = cx(
		'rounded-lg border-blue-600 px-2 ',
		props.selected
			? 'bg-gray-100 border !border-gray-200'
			: 'border-2 border-blue-600 bg-blue-500 text-gray-50',
		props.className,
	);

	const optionCn = cx('p-4 rounded-lg', 'bg-gray-50 text-gray-900');

	const handleOnSelect = (evt) => {
		const val = evt.target.value;

		if (val === '<reset>') {
			props?.onChange?.(null);
		} else if (val !== '<placeholder>') {
			props?.onChange?.(val);
		}
	};

	const placeholder = props.placeholder ? (
		<option
			className={optionCn}
			value="<placeholder>"
			className="text-gray-100"
		>
			{props.placeholder}
		</option>
	) : null;

	const resetOption = props.reset ? (
		<option className={optionCn} value="<reset>">
			{props.reset}
		</option>
	) : (
		placeholder
	);

	const options = props.options?.map((i, ndx) => (
		<option
			key={(i.value || i) + ndx}
			className={optionCn}
			value={i.value || i}
		>
			{i.name || i}
		</option>
	));

	return (
		<select
			id={props.id}
			className={cn}
			name={props.name}
			value={props?.selected}
			onChange={handleOnSelect}
			style={{
				borderColor: props?.selected
					? 'rgba(229, 231, 235, var(--tw-border-opacity))'
					: 'rgba(37, 99, 235, var(--tw-border-opacity))',
			}}
		>
			{resetOption}
			{options}
		</select>
	);
}

function ActivitiesRecords(props) {
	const [{ page }, updateQuery] = useQueryString();

	const { data, pagesList, isLoading, isError } = useActivitiesRecords({
		filters: [
			['memberName', 'member_name'],
			['branchName', 'branch_name'],
			['teamName', 'team_name'],
			['points', 'points'],
			['receipt', 'receipt'],
			['visitTime', 'visit_time'],
		],
	});

	React.useEffect(() => {
		updateQuery((s) => ({ page: s.page || 1 }));
	}, []);

	const handleOnPageChange = ({ num }) => {
		updateQuery({ page: num });
	};

	const isEmpty = !data?.data?.length;

	return (
		<Table
			pages={pagesList}
			currentPage={page}
			onPageChange={handleOnPageChange}
			data={data?.data || []}
			topContent={<CompoundFilters />}
			isError={isError}
			isEmpty={isEmpty}
			isLoading={isLoading}
			errorContent={<InlineError className="w-full py-10 !justify-center" />}
			emptyContent={<InlineEmpty className="w-full py-10 !justify-center" />}
			loadingContent={
				<InlineLoading className="w-full py-10 !justify-center" />
			}
			columns={[
				{ Header: 'Customer', accessor: 'member.name' },
				{ Header: 'Business', accessor: 'business.name' },
				{ Header: 'Branch', accessor: 'branch.name' },
				{ Header: 'Team', accessor: 'team.name' },
				{ Header: 'Payment Method', accessor: 'payment_method' },
				{
					Header: 'Receipt Value',
					accessor: 'receipt',
					Cell: renderCurrencyCell,
				},
				{ Header: 'Cash', accessor: 'cash_paid', Cell: renderCurrencyCell },
				{
					Header: 'Voucher',
					accessor: 'voucher_paid',
					Cell: renderCurrencyCell,
				},
				{
					Header: 'Zeal Wallet',
					accessor: 'wallet_paid',
					Cell: renderCurrencyCell,
				},
				{ Header: 'Zeal Pay', accessor: 'card_paid', Cell: renderCurrencyCell },
				{
					Header: 'Zeal Discount',
					accessor: 'discount_paid',
					Cell: renderCurrencyCell,
				},
				{
					Header: 'Points Added',
					accessor: 'details.points',
					Cell: renderNumberCell,
				},
				{
					Header: 'Date',
					accessor: 'created_at',
					Cell: renderRelativeDatetime,
				},
			]}
		/>
	);
}

export default function Activities(props) {
	return (
		<AppContainer
			protected
			breadcrumbs={[
				{
					name: 'Activities',
					to: '/records/activities',
					icon: 'pos',
				},
			]}
		>
			<CenterStackWithToolbar>
				<ActivitiesRecords />
			</CenterStackWithToolbar>
		</AppContainer>
	);
}

function renderCurrencyCell({ value }) {
	return new Intl.NumberFormat('en', {
		style: 'currency',
		currency: 'EGP',
		// compactDisplay: '', // compact | [short] | long
		currencyDisplay: 'code', // symbol | narrowSymbol | code | name
		maximumFractionDigits: 3,
	}).format(value || 0);
}

function renderNumberCell({ value }) {
	return new Intl.NumberFormat('en', {
		// style:'unit',
		// unit: 'byte',
		maximumFractionDigits: 3,
	}).format(value || 0);
}

function renderRelativeDatetime({ value }) {
	if (!value) {
		return '--';
	}

	let dt;

	dt = DateTime.fromFormat(value, 'yyyy-MM-dd HH:mm:ss');

	if (dt.isValid) {
		return dt.toRelative();
	}

	dt = DateTime.fromISO(value);

	if (dt.isValid) {
		return dt.toRelative();
	}

	return value;
}
