import React from 'react';
import { useMutation } from 'react-query';
import { ApiRequest } from '@zeal/zeal-lib';

async function apiSubmitVisits(body) {
	return new ApiRequest('visits')

		.addHeader('Accept', 'application/json')
		.addHeader('Content-Type', 'application/json')
		.POST(JSON.stringify(body))
		.then((payload) => ({
			data: payload.data,
			totalCount: payload?.meta?.total,
			pageSize: payload?.meta?.per_page,
			pageCurrent: payload?.meta?.current_page,
		}));
}
export default function useSubmitVisits(opt) {
	const mutation = useMutation(apiSubmitVisits);

	const { data, mutate, isSuccess } = mutation;

	React.useEffect(() => {
		if (!isSuccess) {
			return null;
		}

		if (opt.onSuccess) {
			opt.onSuccess();
		}
	}, [data, isSuccess]);

	return {
		...mutation,
		getVisitsList: mutate,
	};
}
