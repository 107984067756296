import React from 'react';

export default function HandleOnPromoSybmit(promocode) {
	let expiresIn;
	if (promocode.expireDate) {
		let dtt = new Date(promocode.expireDate);

		let dd = dtt.getDate();
		if (dd < 10) {
			dd = '0' + dd;
		}

		let MMM = dtt.getMonth() + 1;
		if (MMM < 10) {
			MMM = '0' + MMM;
		}

		let yyyy = dtt.getFullYear();

		expiresIn = yyyy + '-' + MMM + '-' + dd;
	}

	let promos = {
		code: promocode.code,
		when: promocode?.when?.value || 'INSTANT',
		quota: Number(promocode.quota),
		expire_at: expiresIn,
		expiration_type: 'date',
		type: promocode?.type?.value,
	};
	if (promocode?.type?.value == 'PERCENTAGE_DISCOUNT') {
		promos.max_discount = parseFloat(promocode.max_discount);
	}
	if (
		promocode?.type?.value == 'PERCENTAGE_DISCOUNT'
		|| promocode?.type?.value == 'FLAT_DISCOUNT'
	) {
		promos.min_payment = parseFloat(promocode.min_payment);
		promos.amount = parseFloat(promocode.amount);
	}
	return promos;
}
