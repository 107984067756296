import React, { useEffect } from 'react';
import { useMutation } from 'react-query';

import { ApiRequest } from '@zeal/zeal-lib';
import { useQuery } from '../../../../App/QueryClient';

async function apiLoyaltyRewards({ queryKey: [, filters] }) {
	return new ApiRequest('rewards')
		.addHeader('Accept', 'application/json')
		.GET();
}

export default function useLoyaltyRewards() {
	const q = useQuery({
		queryFn: apiLoyaltyRewards,
		staleTime: Infinity,
		queryKey: ['z-all-item-rewards'],
	});

	React.useDebugValue(q);

	return q;
}

async function apiDeleteCard({ ...payload }) {
	return new ApiRequest(`rewards/${payload?.uuid}`)
		.addHeader('Accept', 'application/json')
		.addHeader('Content-Type', 'application/json')
		.DELETE();
}

export function useDeleteItem(opt) {
	const mutation = useMutation(apiDeleteCard);

	const { data, mutate, isSuccess } = mutation;

	useEffect(() => {
		if (!isSuccess) {
			return null;
		}

		if (opt.onSuccess) {
			opt.onSuccess();
		}
	}, [data, isSuccess]);

	return {
		...mutation,
		doDeleteCard: mutate,
	};
}

async function apiUpdateCard({ ...payload }) {
	return new ApiRequest('rewards')
		.addHeader('Accept', 'application/json')
		.addHeader('Content-Type', 'application/json')
		.POST(JSON.stringify(payload.data));
}

export function useUpdateCard(opt) {
	const mutation = useMutation(apiUpdateCard);

	const { data, mutate, isSuccess } = mutation;

	useEffect(() => {
		if (!isSuccess) {
			return null;
		}

		if (opt.onSuccess) {
			opt.onSuccess();
		}
	}, [data, isSuccess]);

	return {
		...mutation,
		doUpdateCards: mutate,
	};
}
