import { DateTime } from 'luxon';

export default function renderRelativeDatetime({ value }) {
	if (!value) {
		return '--';
	}
	if (DateTime.fromFormat(value, 'yyyy-MM-dd HH:mm:ss').isValid) {
		value = value.split(' ')[0];
	}

	let dtt = new Date(value);
	let dd = dtt.getDate();
	let mm = dtt.toLocaleString('default', { month: 'long' });
	let yyyy = dtt.getFullYear();

	if (dd < 10) {
		dd = '0' + dd;
	}
	if (mm < 10) {
		mm = '0' + mm;
	}

	return dd + '-' + mm + '-' + yyyy;
}
