import React, { useEffect, useState } from 'react';
import {
	Align,
	Button,
	Grid,
	InlineError,
	InlineLoading,
	Input,
	Label,
	Stack,
} from '@zeal/zeal-ui';
import useSubmitSocialMedia from '../data/useSocialMedia';
import { useProfile } from '../data/useProfile';
import openToastr from '../../App/Toastr/openToastr';

export default function SocialMedia() {
	const profileQuery = useProfile();
	const { data } = profileQuery;

	const refetchProfile = () => {
		profileQuery.refetch();
	};
	const [facebook, setFacebook] = useState(data?.data?.facebook);
	const [instagram, setInstagram] = useState(data?.data?.instagram);
	const { doAddSocialMedia, isError, isLoading, error } = useSubmitSocialMedia({
		onSuccess: () => {
			openToastr({
				message: 'URLs changed',
			});
			refetchProfile();
		},
		onError: () => {
			let errMsg = '';
			if ([400, 422].includes(error?.response?.status))
			{errMsg = 'Failed to change URLs';}
			else {errMsg = 'Server error. Please try again Later';}
			openToastr({ message: errMsg, isError: true });
		},
	});
	const resetForm = () => {
		setFacebook('');
		setInstagram('');
	};
	const addSocialMedia = (data) => {
		doAddSocialMedia(data);
		// resetForm();
	};
	const handlesocialMediaSubmit = React.useCallback(() => {
		let socialMedia = {
			facebook,
			instagram,
		};
		addSocialMedia(socialMedia);
	}, [facebook, instagram, addSocialMedia]);

	useEffect(() => {
		setFacebook(data?.data?.facebook);
		setInstagram(data?.data?.instagram);
	}, [data]);

	return (
		<>
			<Grid cols="2" gap="3">
				<Label textColor="basic">Social Media </Label>
			</Grid>
			<Grid cols="5" gap="3">
				<div className="col-span-2">
					<Input.Email
						id="faceInput"
						mb="xl"
						inputName="Text"
						placeholder="Add Facebook Url"
						value={facebook}
						label="Facebook"
						onChange={(evt) => {
							setFacebook(evt.target.value);
						}}
						value={facebook}
						validateType="true"
						startHint=""
						url="true"
					/>
				</div>
			</Grid>
			<Grid cols="5" gap="3">
				<div className="col-span-2">
					<Input.Email
						id="instaInput"
						type="email"
						mb="xl"
						value={instagram}
						inputName="Text"
						placeholder="Add Instagram Url"
						label="Instagram"
						onChange={(evt) => {
							setInstagram(evt.target.value);
						}}
						value={instagram}
						validateType="true"
						startHint=""
						url="true"
					/>
				</div>
			</Grid>
			<Grid cols="2" gap="3">
				<Align align="left" className="mt-28">
					<Button
						size="sm"
						secondary
						label="Save Changes"
						onClick={handlesocialMediaSubmit}
					/>
					<Stack mx="md">
						<InlineLoading hide={!isLoading} />
						<InlineError hide={!isError} />
					</Stack>
				</Align>
			</Grid>
		</>
	);
}
