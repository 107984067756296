import React, { useCallback, useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import {
	Align,
	ButtonWithIcon,
	Card,
	Grid,
	InlineLoading,
	Input,
	Label,
	MainStackWithAside,
	RadioButton,
	Select,
	Stack,
	Title,
} from '@zeal/zeal-ui';
import AppContainer from '../../App/AppContainer';
import checkList from '../data/CheckList';
import openToastr from '../../App/Toastr/openToastr';


import useBranches from '../../Branches/data/useBranches';

import useTeamApp, { useAddTeam } from '../data/useTeamApp';
import TeamMembersFrame from '../TeamMembersFrame';
import { renderErrorMessage } from './ErrorMessage';
import { useParams } from 'react-router-dom';

export default function CreateTeamApp(props) {
	const data = props.location.state;
	const { data: branchesList } = useBranches();
	const optionList = [];

	const optionsHandel = () => {
		branchesList?.data.map((d) =>
			optionList.push({ label: d.name, value: d.uuid }),
		);
	};

	const { uuid: uuidParam } = useParams();

	const [role, setRole] = useState(data?.roles && data?.roles[0]?.name);
	const [branch, setBranch] = useState(data?.branch?.name);
	const [name, setName] = React.useState(data?.name);
	const [uuid, setUuid] = React.useState(
		data?.uuid || uuidParam,
	);
	const [username, setUserName] = useState(data?.username);
	const [password, setPassword] = React.useState(data?.password);
	const [branch_uuid, setBranchUuid] = React.useState(data?.branch?.uuid);
	const [errMsgs, setErrMsgs] = useState({});

	const teamAppQuery = useTeamApp();


	const { doAddTeam, isError, isLoading, error } = useAddTeam({
		onSuccess: () => {
			const { history, location } = props;
			openToastr({
				message: 'Team Added',
			});
			teamAppQuery.refetch();
			history.push('/teams/app');
		},
		onError: () => {
			let errMsg = '';
			if ([400, 422].includes(error?.response?.status)) { errMsg = 'Failed to update Team'; }
			else { errMsg = 'Server error. Please try again Later'; }
			openToastr({ message: errMsg, isError: true });
		},
	});
	const handleTeamSubmit = React.useCallback(() => {
		let val = {
			name,
			username,
			password,
			branch_uuid,
			role,
			uuid,
		};
		if (password || name || username || branch_uuid || role) {
			doAddTeam(val);
		}
	}, [name, username, password, branch_uuid, role, uuid, doAddTeam]);

	const currentPath = `${window.location.pathname}${window.location.search}`;

	const isEditingTeam = currentPath?.includes('edit');

	const pointsBreadCrumb = [
		{ name: 'Teams', to: '/teams/app', icon: 'idCard' },
		{ name: isEditingTeam ? 'Edit Team' : 'Add Team', to: currentPath },
	];

	useEffect(() => {
		error?.response.json().then((err) => {
			setErrMsgs(err?.errors);
		});
	}, [error]);
	const FormModal = (
		<Card p="lg" m="none">
			<Grid cols="3" gap="3" className="mb-4">
				<div cols="1">
					<Input
						inputName="Text"
						placeholder="Enter Name"
						label="Name*"
						onChange={(evt) => setName(evt.target.value)}
						value={name}
						mb="xl"
						validateType="true"
						startHint={renderErrorMessage('name', isError, errMsgs)}
					/>
					<Input
						id="username"
						type="text"
						inputName="Text"
						placeholder="add user name"
						label="Username*"
						mb="xl"
						onChange={(evt) => setUserName(evt.target.value)}
						value={username}
						validateType="true"
						startHint={renderErrorMessage('username', isError, errMsgs)}
					/>
					<Input.password
						id="password"
						inputName="Text"
						mb="xl"
						placeholder="type password"
						label="password*"
						onChange={(evt) => setPassword(evt.target.value)}
						value={password}
						validateType="true"
						startHint={renderErrorMessage('password', isError, errMsgs)}
					/>
					<Select
						options={optionList}
						onClick={optionsHandel()}
						selected={branch}
						onChange={(e) => {
							setBranchUuid(e.value);
							setBranch(e.label);
						}}
						mb="xl"
						placeholder={branch ? branch : '-- select branch -- '}
						startHint={renderErrorMessage('branch_uuid', isError, errMsgs)}
					/>
				</div>
			</Grid>
			<hr />
			<Stack m="none">
				<Title size="sm" margin="xs" py="sm" title="Role" />
				<RadioButton
					onChange={(e) => setRole(e.target.value)}
					items={checkList}
					selected={role}
				/>
				<Label hide={!isError} error="red">
					{renderErrorMessage('role', isError, errMsgs)}
				</Label>
			</Stack>
		</Card>
	);
	const history = useHistory();

	const cancelBtn = useCallback(
		() =>
			history.push({
				pathname: '/teams',
			}),
		[history],
	);
	return (
		<TeamMembersFrame breadcrumbs={pointsBreadCrumb}>
			<MainStackWithAside
				fullWidth="true"
				toolbarStart=""
				toolbarEnd={
					uuid ? (
						<Align align="right">
							<Stack row p="none" gap="md">
								<InlineLoading hide={!isLoading} />
								<ButtonWithIcon
									light
									label="cancel"
									size="xs"
									m="none"
									onClick={cancelBtn}
								/>
								<ButtonWithIcon
									primary
									label="Confirm Edit"
									size="xs"
									m="none"
									onClick={handleTeamSubmit}
								/>
							</Stack>
						</Align>
					) : (
						<Align align="right" gap="1">
							<Stack row p="none" gap="md">
								<InlineLoading hide={!isLoading} />
								<ButtonWithIcon
									light
									label="Cancel"
									size="xs"
									m="none"
									onClick={cancelBtn}
								/>
								<ButtonWithIcon
									primary
									label="Add Team"
									size="xs"
									m="none"
									onClick={handleTeamSubmit}
									disabled={
										!password || !name || !username || !branch_uuid || !role
									}
								/>
							</Stack>
						</Align>
					)
				}
			>
				{FormModal}
			</MainStackWithAside>
		</TeamMembersFrame>
	);
}
