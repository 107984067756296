import React from 'react';

import { useQuery } from '../../App/QueryClient';
import { ApiRequest } from '@zeal/zeal-lib';
// import { useQueryString } from "../../App/useQueryString";

async function apiSubscriptions({ queryKey: [, filters] }) {
	// return new ApiRequest("v3/rewards")
	//   .addHeader("Accept", "application/json")
	//   .GET();

	console.warn('[API/dummy]', 'apiSubscriptions::apiSubscriptions');

	return Promise.resolve({
		checkbox: ['bill yearly', 'bill monthly'],
		TitleButton: [
			{
				title: 'Basic',
				subtitle: 'Free',
				children: 'Your Current Plan',
			},
			{
				title: 'Silver',
				subtitle: '9.99%/Mo',
				children: 'Get Started',
				status: 'true',
			},
			{
				title: 'Gold',
				subtitle: '9.99%/Mo',
				children: 'Get Started',
				status: 'true',
			},
		],
		data: [
			{
				label: 'Core Features',
				menu: [
					{
						name: 'Campaigns',
						bool: '',
						count: '10 Campaigns',
						limit: 'Unlimited Campaigns',
					},
					{
						name: 'Campaigns',
						bool: '',
						count: '10 Campaigns',
						limit: 'Unlimited Campaigns',
					},
					{
						name: 'Campaigns',
						bool: '',
						count: '10 Campaigns',
						limit: 'Unlimited Campaigns',
					},
					{
						name: 'Campaigns',
						bool: '',
						count: '10 Campaigns',
						limit: 'Unlimited Campaigns',
					},
					{
						name: 'Campaigns',
						bool: '',
						count: '10 Campaigns',
						limit: 'Unlimited Campaigns',
					},
				],
			},
			{
				label: 'Access',
				menu: [
					{
						name: 'Campaigns',
						bool: '',
						count: '10 Campaigns',
						limit: 'Unlimited Campaigns',
					},
					{
						name: 'Campaigns',
						bool: '',
						count: '10 Campaigns',
						limit: 'Unlimited Campaigns',
					},
					{
						name: 'Campaigns',
						bool: '',
						count: '10 Campaigns',
						limit: 'Unlimited Campaigns',
					},
					{
						name: 'Campaigns',
						bool: '',
						count: '10 Campaigns',
						limit: 'Unlimited Campaigns',
					},
					{
						name: 'Campaigns',
						bool: '',
						count: '10 Campaigns',
						limit: 'Unlimited Campaigns',
					},
				],
			},
			{
				label: 'Access',
				menu: [
					{
						name: 'Campaigns',
						bool: '',
						count: '10 Campaigns',
						limit: 'Unlimited Campaigns',
					},
					{
						name: 'Campaigns',
						bool: '',
						count: '10 Campaigns',
						limit: 'Unlimited Campaigns',
					},
					{
						name: 'Campaigns',
						bool: '',
						count: '10 Campaigns',
						limit: 'Unlimited Campaigns',
					},
					{
						name: 'Campaigns',
						bool: '',
						count: '10 Campaigns',
						limit: 'Unlimited Campaigns',
					},
					{
						name: 'Campaigns',
						bool: '',
						count: '10 Campaigns',
						limit: 'Unlimited Campaigns',
					},
				],
			},
		],
	});
}

export default function useSubscriptions() {
	const q = useQuery({
		queryFn: apiSubscriptions,
		staleTime: 1000 * 60 * 15, // 15 min
		queryKey: ['z-subscription'],
	});

	React.useDebugValue(q);

	return q;
}
