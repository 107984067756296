import React from 'react';
import { useHistory, useLocation } from 'react-router-dom';

export default function UnAuthorized(props) {
	const { unauthorizedRender, unauthorizedComponent, unauthorizedRedirect }
		= props;

	const history = useHistory();
	const location = useLocation();

	if (unauthorizedRender) {
		return unauthorizedRender?.();
	}
	if (unauthorizedRender) {
		const UnAuth = unauthorizedComponent;

		return <UnAuth {...props} />;
	}

	history.push(unauthorizedRedirect || '/login', {
		fromLocation: { ...location },
		unauthorizedRedirect: true,
		shouldGoBack: true,
	});

	return null;
}
