import React from 'react';
import styled from 'styled-components';
import { useHistory } from 'react-router-dom';
import { motion } from 'framer-motion/dist/framer-motion';

import { Button, LineWrappedText, MenuBar, Stack } from '@zeal/zeal-ui';
import headerLogo from '@zeal/zeal-ui/src/assets/images/menu-header-logo.png';

import { useQueryClient } from 'react-query';
import { useLogout } from './ACL/useLogin';
import {
	useAccountInfo,
	useBusinessInfo,
} from './BusinessAccount/useBusinessInfo';

const appMenuCfg = [
	{
		to: '/rewards',
		iconName: 'gift',
		label: 'Rewards',
	},
	{
		to: '/analytics',
		iconName: 'chartBar',
		label: 'Analytics',
	},
	{
		to: '/teams',
		iconName: 'idCard',
		label: 'Teams',
		exact: false,
	},

	{
		to: '/feedback',
		iconName: 'commentSmile',
		label: 'Feedback',
	},
	{
		to: '/customers',
		iconName: 'userShoppingAlt',
		label: 'Customers',
	},
	{
		to: '/branches',
		iconName: 'mapMarker',
		label: 'Branches',
	},
	{
		to: '/promocodes',
		iconName: 'badgePercent',
		label: 'Promo Codes',
	},
	{
		to: '/visit-transactions',
		iconName: 'cash',
		label: 'Visit Transactions',
	},
	{
		to: '/exports',
		iconName: 'download',
		label: 'Exports',
	},
];

const customDashboardMenuCfg = [
	{
		to: {
			search: '?page=1',
			pathname: '/orders',
		},
		iconName: 'receipt',
		label: 'Orders',
	},
	{
		to: '/announcements',
		iconName: 'horn',
		label: 'Announcements',
	},
	{
		to: '/gallery',
		iconName: 'image',
		label: 'Gallery',
		exact: false,
	},
	{
		to: '/stories',
		iconName: 'commentSmile',
		label: 'Stories',
		exact: false,
	},
	{
		to: '/schedule',
		iconName: 'calendarLight',
		label: 'Schedule',
	},
	{
		to: '/menu',
		iconName: 'utensils',
		label: 'Menu',
	},
	{
		to: '/recommendations',
		iconName: 'award',
		label: 'Recommendations',
	},
	{
		to: '/articles',
		iconName: 'newspaper',
		label: 'Articles',
	},
];

function MenuHeader({ isCollapsed }) {
	return (
		<MenuHeaderContainer
			alt="zeal logo"
			className="w-full h-full"
			src={headerLogo}
			initial={isCollapsed ? 'mini' : 'full'}
			animate={isCollapsed ? 'mini' : 'full'}
			transition={{ ease: 'liner', duration: 0.05 }}
			variants={{
				mini: { width: '70px' },
				full: { width: '130px' },
			}}
		/>
	);
}

const MenuHeaderContainer = styled(motion.div)`
	background-image: url(${({ src }) => src});
	background-repeat: no-repeat;
	background-position-x: 10px;
	background-position-y: center;
`;

function MenuFooter(props) {
	const history = useHistory();

	const queryClient = useQueryClient();

	const { doLogout } = useLogout({
		onSuccess() {
			history.push('/login');
			queryClient.removeQueries();
			localStorage.clear();
		},
	});

	const settingsRoute = () => {
		history.push('/settings');
	};

	return (
		<div className="grid grid-cols-2 grid-rows-1 justify-items-center items-center border-t border-gray-50">
			{/*<Avatar.WithInfo*/}
			{/*  userTitle="COO"*/}
			{/*  userName="Foo Bar"*/}
			{/*  userImg="https://randomuser.me/api/portraits/lego/1.jpg"*/}
			{/*/>*/}
			<Button
				ghost
				size="xs"
				iconEnd="cog"
				onClick={settingsRoute}
				className="col-start-1 row-start-1 w-full justify-self-stretch self-stretch"
			>
				{!props.isCollapsed && 'Settings'}
			</Button>

			<Button
				ghost
				size="xs"
				iconEnd="signOut"
				onClick={doLogout}
				className="col-start-2 row-start-1 w-full justify-self-stretch self-stretch"
			>
				{!props.isCollapsed && 'Logout'}
			</Button>
		</div>
	);
}

function MenuLinks(props) {
	const { isAppOwn } = useAccountInfo();

	const generalDashboardLinks = appMenuCfg.map((i) => (
		<>
			<MenuBar.Link
				exact
				vendorTheme
				key={i.to}
				{...i}
				arrow="hide"
				{...props}
			/>
		</>
	));
	const customDashboardLinks = customDashboardMenuCfg.map((i) => (
		<>
			<MenuBar.Link
				exact
				vendorTheme
				key={i.to}
				{...i}
				arrow={i.subnav ? 'show' : 'hide'}
				{...props}
			/>
		</>
	));

	const { data: accountInfo } = useBusinessInfo();

	const customDashboardMenu = isAppOwn && (
		<>
			<Stack p="none" m="md" mx="none">
				<LineWrappedText size="xxs" textColor="muted">
					{accountInfo?.name}
				</LineWrappedText>
			</Stack>
			{customDashboardLinks}
		</>
	);

	return (
		<>
			{generalDashboardLinks}
			{customDashboardMenu}
		</>
	);
}

function AppMenu(props) {
	return (
		<MenuBar
			fixedLogo
			className={props.className}
			headerContent={<MenuHeader {...props} />}
			footerContent={<MenuFooter {...props} />}
			isCollapsed={props.isCollapsed}
		>
			<MenuLinks {...props} />
		</MenuBar>
	);
}

export default styled(AppMenu)`
	grid-area: ${({ gridArea }) => gridArea};
`;
