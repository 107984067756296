import React from 'react';
import { Route, Switch } from 'react-router-dom';

import ACLBoundary from '../App/ACL/ACLBoundary';
import ConsumersRecords from './Consumers';
import ActivitiesRecords from './Activities';
import Members from './Members';

export default function CustomersRoutes() {
	return (
		<Switch>
			<ACLBoundary
				aclUnAuthProps={{ replace: true }}
				aclGrants={['view:customers']}
			>
				<Route exact path="/customers" component={ConsumersRecords} />
				<Route exact path="/customers/:memberId" component={Members} />
				<Route
					exact
					path="/customers/activities"
					component={ActivitiesRecords}
				/>
			</ACLBoundary>
		</Switch>
	);
}
