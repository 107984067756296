import './init'

import '@zeal/zeal-ui/dist/index.css';
import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter } from 'react-router-dom';

import AppRoutes from './App/Routes';
import { QueryClientProvider } from './App/QueryClient';
import { QueryStateProvider } from './App/useQueryString';
import { AppMenuProvider } from './App/AppMenuProvider';
import { OwnAppProvider } from './App/AppContainer/useOwnApp';


ReactDOM.render(
	<React.StrictMode>
		<BrowserRouter>
			<QueryClientProvider>
				<QueryStateProvider>
					<AppMenuProvider>
						<OwnAppProvider>
							<AppRoutes />
						</OwnAppProvider>
					</AppMenuProvider>
				</QueryStateProvider>
			</QueryClientProvider>
		</BrowserRouter>
	</React.StrictMode>,
	document.getElementById('root'),
);
