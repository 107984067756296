const SettingsMenu = [
	{
		title: 'App Setting',
		menu: [
			{
				to: 'profile',
				children: 'Profile',
			},
			{
				to: 'categories',
				children: 'Category',
			},
			{
				to: 'socialMedia',
				children: 'Social Media',
			},
		],
	},
	{
		title: 'dashboard',
		menu: [
			{
				to: 'account',
				children: 'Account',
			},
			// TO DO
			// {
			//   to: 'pos',
			//   children: 'Inegrate POS',
			// },
			// {
			//   to: 'subscription',
			//   children: 'Subscription',
			// },
		],
	},
];
export default SettingsMenu;
