import React, { useEffect, useReducer, useState } from 'react';

import {
	Align,
	Button,
	ButtonWithIcon,
	Checkbox,
	Grid,
	Image,
	InlineError,
	InlineLoading,
	Input,
	Label,
	Modal,
	Select,
	Stack,
	Textarea,
	Title,
	TwoSideLayout,
} from '@zeal/zeal-ui';
import openToastr from '../App/Toastr/openToastr';
import PhotoUploader from './PhotoUploader';
import HandleOnStorySybmit from './HandleOnStorySybmit';
import FormatDate from './FormatDate';
import useAnnouncements, {
	useAddAnnou,
	useBromoCode,
} from './data/useAnnouncements';

export default function AddEditModal(props) {
	const {
		formState,
		formDispatch,
		isModalOpen,
		closeModal,
		selected,
		expireDate,
		setExpireDate,
		editedAnnouncement,
		handleFilesUpload,
		uuid,
		resetForm,
		removeError,
		setRemoveError,
	} = props;
	const [openUploader, setOpenUploader] = useState();
	const storiesQuery = useAnnouncements();

	const { data: promoList } = useBromoCode();
	const [optionList, setOptionsList] = useState([]);

	useEffect(() => {
		if (promoList?.data) {
			const list = [];
			promoList?.data.map((d) => list.push({ label: d.code, value: d.uuid }));
			setOptionsList(list);
		}
	}, [promoList?.data]);

	const { doAddAnnou, isError, isLoading, status } = useAddAnnou({
		onSuccess: () => {
			storiesQuery.refetch();
			closeModal(false);
			resetForm();

			const successMsg = editedAnnouncement
				? 'Announcement edited successfully'
				: 'Announcement added successfully';

			openToastr({
				message: successMsg,
			});
		},
		onError: (error) => {
			const clonedError = error?.response.clone();

			if (!clonedError?.bodyUsed) {
				clonedError.json().then((err) => {
					openToastr({
						message: err?.message || 'Failed to add announcement',
						isError: true,
					});
				});
			}
		},
	});

	const addStory = (story) => {
		doAddAnnou(story);
		storiesQuery.refetch();
		setRemoveError(true);
	};

	const handleOn = React.useCallback(() => {
		const formData = HandleOnStorySybmit(expireDate, formState, selected, uuid);

		addStory(formData);
	}, [expireDate, formState, selected, uuid]);

	const showPromoSelect
		= formState?.type?.value === 'promo' || formState?.type === 'promo';

	const modalTitle = editedAnnouncement
		? 'Edit Announcement'
		: 'Add Announcement';

	const modalSubmitLabel = editedAnnouncement
		? 'Edit Announcement'
		: 'Add Announcement';

	const isEmptyObject = (o, keys) => keys.some(function (x) {
		return o[x] === '' || o[x] === null;
	});

	const isSubmitButtonDisabled
		= isEmptyObject(formState, ['photo', 'type', 'title', 'description'])
		|| (!expireDate && !selected)
		|| (showPromoSelect && !formState.promocode);

	const promoCodeStartHint = optionList.length === 0 && (
		<Label textColor="danger" size="xs">
			No promocodes available
		</Label>
	);

	const promoCodeSelect = showPromoSelect && (
		<Stack>
			<Title title="Select Promocode" margin="xs" />
			<Select
				stretch
				inputName="promocode"
				placeholder={
					formState?.promo_code?.code
						? formState?.promo_code?.code
						: '--Select Promocode--'
				}
				options={optionList}
				selected={formState.promocode}
				onChange={(promocode) =>
					formDispatch({
						promo_code_uuid: promocode.value,
						promocode: promocode,
					})
				}
				startHint={promoCodeStartHint}
			/>
		</Stack>
	);

	return (
		<Modal isModalOpen={isModalOpen} title={modalTitle} onClose={closeModal}>
			<Stack>
				<PhotoUploader
					setOpenUploader={setOpenUploader}
					photo={formState?.photo}
					image={formState?.image}
				/>
				<Input.Upload
					onDropFiles={handleFilesUpload}
					acceptedFiles="image/*"
					maxFiles="1"
					openUploader={openUploader}
					style={{
						display: formState.photo || formState.image ? 'none' : 'block',
					}}
				/>
				<Stack>
					<Title title="Select Type" margin="xs" />
					<Select
						stretch
						inputName="type"
						options={[
							{ value: 'announcement', label: 'Announcement' },
							{ value: 'promo', label: 'Promocode' },
						]}
						selected={formState.type}
						onChange={(type) => formDispatch({ type: type })}
						placeholder={
							formState?.type ? formState?.type : '--Select Announcement Type--'
						}
					/>
				</Stack>
				{promoCodeSelect}
				<Stack>
					<Title title="Add Title" margin="xs" />
					<Input
						type="text"
						inputName="title"
						placeholder="Add Title"
						onChange={(evt) => formDispatch({ title: evt.target.value })}
						value={formState.title}
					/>
				</Stack>
				<Stack>
					<Title title="Add Description" margin="xs" />
					<Textarea
						name="description"
						placeholder="Type Something"
						rows="4"
						onChange={(evt) => formDispatch({ description: evt.target.value })}
						value={formState.description}
					/>
				</Stack>
				<Stack>
					<Title title="Expiration Date" margin="xs" />
					<Input.Date
						calender
						transparent
						showTimeSelect
						placeholder="dd MMM yyyy  hh:mm aa"
						selected={expireDate ? expireDate : new Date(selected)}
						onChange={(date) => setExpireDate(date)}
					/>
				</Stack>
				<Stack m="sm">
					<Checkbox
						labelText="Hide When Quota Ends"
						inputName="isQuotaEnds"
						defaultChecked={formState.hideAtQuotaEnd}
						onChange={(evt) =>
							formDispatch({ hideAtQuotaEnd: evt.target.checked })
						}
					/>
				</Stack>
				<Grid gap="5">
					<span />
					<TwoSideLayout>
						<ButtonWithIcon
							stretch={false}
							m="xxs"
							px="md"
							py="sm"
							onClick={closeModal}
						>
							Cancel
						</ButtonWithIcon>
						<Align align="left">
							<InlineError hide={!isError + !removeError} />
							<InlineLoading hide={!isLoading} />
							<ButtonWithIcon
								stretch={false}
								m="xxs"
								px="md"
								py="sm"
								primary
								onClick={handleOn}
								disabled={isSubmitButtonDisabled}
							>
								{modalSubmitLabel}
							</ButtonWithIcon>
						</Align>
					</TwoSideLayout>
				</Grid>
			</Stack>
		</Modal>
	);
}
