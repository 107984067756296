import React, { useState } from 'react';

import loyaltyCardImage from '@zeal/zeal-ui/src/assets/images/loyalty-card.png';
import pointsAndRewardsImage from '@zeal/zeal-ui/src/assets/images/points-rewards.png';
import scopedRewardsImages from '@zeal/zeal-ui/src/assets/images/scoped-rewards.png';
import cashAndPointsImages from '@zeal/zeal-ui/src/assets/images/points-cashback.png';

import {
	ButtonWithIcon,
	Card,
	CenterStackWithToolbar,
	Grid,
	Image,
	SizedBox,
	Stack,
	Title,
	TwoSideLayout,
} from '@zeal/zeal-ui';

import AppContainer from '../../../App/AppContainer';
import openToastr from '../../../App/Toastr/openToastr';
import { useSetModel } from './data/useSetRewardModel';

export default function SetReward(props) {
	const [selectedModel, setSelectedModel] = useState(null);

	const { rewardModelQuery } = props;

	const { doSetModel } = useSetModel({
		onSuccess: () => {
			rewardModelQuery.refetch();
		},
		onError: () => {
			openToastr({ message: 'Failed to set model', isError: true });
		},
	});

	const breadCrumb = [
		{
			name: 'Loyalty Rewards',
			to: '/rewards',
		},
	];

	const submitModel = () => {
		doSetModel({
			model: selectedModel,
		});
	};

	const isNextButtonDisabled = !selectedModel;

	const headerNextButton = (
		<ButtonWithIcon
			primary
			label="Next"
			size="xs"
			m="none"
			onClick={submitModel}
			disabled={isNextButtonDisabled}
		/>
	);

	const headerContent = (
		<SizedBox fillWidth>
			<TwoSideLayout>
				<Title title="Select a reward system" size="sm" />
				<Title
					subtitle=" Please choose carefully, because once you select a loyalty system there is no turning back."
					muteSubtitle={false}
					size="xs"
				/>
				{headerNextButton}
			</TwoSideLayout>
		</SizedBox>
	);

	return (
		<AppContainer protected breadcrumbs={breadCrumb}>
			<CenterStackWithToolbar toolbarCenter={headerContent}>
				<ModelsCards
					selectCard={(card) => setSelectedModel(card.model)}
					selectedModel={selectedModel}
				/>
			</CenterStackWithToolbar>
		</AppContainer>
	);
}

function ModelsCards(props) {
	const { selectedModel, selectCard } = props;

	const cardsData = [
		{
			model: 1,
			title: 'Loyalty Card',
			description:
				'The Zeal classic is how most of our customers grew. Choose your rewards and choose when to give them on a stamp card. Control how you record a punch and when maximize your stamp card for maximum loyalty.',
			image: loyaltyCardImage,
			recommendation: 'Works For Everyone',
		},

		{
			model: 3,
			title: 'Scoped Rewards',
			description:
				'The simplest way to get started with your loyalty program on ZealBuy X of Coffees and get the X+1 for Free.',
			image: scopedRewardsImages,
			recommendation: 'Specialty Shops',
		},
		{
			model: 2,
			title: 'Points And Rewards',
			description:
				'Make it as straightforward as you want and give your customers points on every transaction and decide which rewards to give your customers after how many points.',
			image: pointsAndRewardsImage,
			recommendation: 'Works For Everyone',
		},
		{
			model: 4,
			title: 'Points And Cashback',
			description:
				'Don’t overthink your rewards and give your customers cash on their wallet for their transactions. Making it easier for them to choose how they want to redeem it at your store.',
			image: cashAndPointsImages,
			recommendation: 'Retails',
		},
	];

	const getActiveCn = (cardModel) =>
		cardModel === selectedModel && 'zeal-active';

	const cards = cardsData?.map((card) => (
		<Card
			pointer
			onClick={() => selectCard(card)}
			className={getActiveCn(card.model)}
			shadow="0"
		>
			<Stack p="none" m="md">
				<Grid cols="2">
					<Stack p="none">
						<Title title={card.title} size="md" margin="md" />
						<Title subtitle={card.description} size="md" />
					</Stack>
					<TwoSideLayout>
						<span></span>
						<Image cover src={card.image} />
					</TwoSideLayout>
					<Stack>
						<Title
							primary
							title="RECOMMENDED FOR"
							size="sm"
							className="font-xxs"
							margin="md"
						/>
						<ButtonWithIcon size="sm" border="dashed" textColor="gray">
							<span className="font-xxs">{card.recommendation}</span>
						</ButtonWithIcon>
					</Stack>
				</Grid>
			</Stack>
		</Card>
	));
	return (
		<Grid cols="2" gap="3">
			{cards}
		</Grid>
	);
}
