import React from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';
import ACLBoundary from '../App/ACL/ACLBoundary';
import { UnAuthCard } from '../App/UnAuth';
import Profile from './Views/Profile';
import Account from './Views/Account';
import POS from './Views/POS';
import SocialMedia from './Views/SocialMedia';
import Subscription from './Views/Subscription';
import Categories from './Views/Categories';
import SettingsFrame from './SettingsFrame';

export default function SettingsRoutes() {
	return (
		<SettingsFrame>
			<Switch>
				<ACLBoundary
					unAuthComponent={UnAuthCard}
					aclUnAuthProps={{ replace: true }}
				>
					<Route
						path="/settings/profile"
						component={Profile}
						aclGrants={['superAdmin']}
					/>
					<Route path="/settings/account" component={Account} />
					<Route
						path="/settings/pos"
						component={POS}
						aclGrants={['superAdmin']}
					/>
					<Route
						path="/settings/socialMedia"
						component={SocialMedia}
						aclGrants={['superAdmin']}
					/>
					<Route
						path="/settings/subscription"
						component={Subscription}
						aclGrants={['superAdmin']}
					/>
					<Route
						path="/settings/categories"
						component={Categories}
						aclGrants={['superAdmin']}
					/>
					<Route path="/settings">
						<Redirect push to="/settings/profile" />
					</Route>
				</ACLBoundary>
			</Switch>
		</SettingsFrame>
	);
}
