import React from 'react';
import { MenuAside, Title } from '@zeal/zeal-ui';
import SettingsMenu from './staticMenu';
const SettingsMenuCard = SettingsMenu?.map((item) => (
	<>
		<Title
			key={item.title}
			titleText={item.title}
			className="px-6 py-6 uppercase"
		/>
		{item.menu?.map((i) => (
			<MenuAside key={i.to} {...i} />
		))}
		<br />
		<br />
		<br />
	</>
));
export default SettingsMenuCard;
