import React, { useState } from 'react';
import { Align, Label } from '@zeal/zeal-ui';

export default function ToggleButtons({ topData, allData }) {
	return (
		<Align align="right">
			<Label my="md">View </Label>

			<input
				defaultChecked
				type="radio"
				id="best-performance"
				name="chart-view"
				value="best"
				className="ml-10 mr-3 cursor-pointer"
				onChange={topData}
			/>
			<Label as="label" my="md" for="best-performance">
				Best Performance{' '}
			</Label>

			<input
				type="radio"
				id="all-branches"
				name="chart-view"
				value="all"
				className="ml-10 mr-3 cursor-pointer"
				onChange={allData}
			/>
			<Label as="label" my="md" for="all-branches">
				{' '}
				All Branches{' '}
			</Label>
		</Align>
	);
}
