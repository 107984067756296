import { get } from 'lodash-es';

export function relationalOperatorsMap() {
	const symbolToCode = {
		'>': 'gt',
		'>=': 'ge',
		'<': 'lt',
		'<=': 'le',
		'=': 'eq',
		'!=': 'ne',
		like: 'fz',
	};

	const codeToSymbol = {
		gt: '>',
		ge: '>=',
		lt: '<',
		le: '<=',
		eq: '=',
		ne: '!=',
		fz: 'like',
	};
	return {
		allSymbols() {
			return Object.keys(symbolToCode);
		},

		allCodes() {
			return Object.keys(codeToSymbol);
		},

		getSymbol(c) {
			return get(codeToSymbol, c, c);
		},

		getCode(s) {
			return get(symbolToCode, s, s);
		},

		getOnlySymbol(only) {
			return Object.keys(symbolToCode).filter((item) => only.includes(item));
		},

		getExcludeSymbol(Exclude) {
			return Object.keys(symbolToCode).filter(
				(item) => !Exclude.includes(item),
			);
		},
	};
}
