import React, { useState } from 'react';
import ReactTooltip from 'react-tooltip';
import {
	Align,
	Badge,
	BasicTable,
	ButtonWithIcon,
	Card,
	Center,
	Icon,
	InlineError,
	InlineLoading,
	Input,
	Label,
	MainStackWithAside,
	Stack,
	ThreeCardsAside,
	Title,
	TwoSideLayoutCentered,
} from '@zeal/zeal-ui';

import AppContainer from '../App/AppContainer';
import useFeedback from './data/useFeedback';
import useOverview from './data/useOverview';
import ChartRating from './ChartRating';
import FiltersToolbar from './FiltersToolbar';
export default function Feedback(props) {
	const overviewFeedback = useFeedback();
	const { data: feedbackData } = overviewFeedback;

	const overview = useOverview();
	const { data: overviewData } = overview;

	const rewardPointColumns = [
		{ Header: 'customer name', accessor: 'member', Cell: handleName },
		{
			Header: 'rating',
			accessor: 'feedback_category_rate',
			Cell: WrapWithIcon,
		},
		{
			Header: ' tags',
			accessor: 'options',
			Cell: WrapWithBadge,
		},
		{
			Header: 'comment',
			accessor: 'comment',
			Cell: handleNoneData,
		},
	];

	const feedbackTable = feedbackData?.data?.length > 0 && (
		<Card p="none">
			<Title title="visits feedback" size="md" m="md" />
			<BasicTable
				data={feedbackData?.data || []}
				columns={rewardPointColumns}
				headerPadding="md"
				cellPadding="lg"
			></BasicTable>
		</Card>
	);

	const ratingCard = (
		<ThreeCardsAside
			firstCard={
				<TwoSideLayoutCentered
					defaultL
					label="Total Rating"
					title={
						<RatingTitle value={`${overviewData?.data?.avg_rating} / 5`} />
					}
					subtitle="avg. rating"
				/>
			}
			secondCard={
				<TwoSideLayoutCentered
					defaultL
					title={<RatingTitle value={overviewData?.data?.total_feedbacks} />}
					label="Total reviews"
					subtitle="reviews"
				/>
			}
			thirdCard={<ChartRating data={overviewData?.data?.percentage} />}
		/>
	);

	const breadcrumbs = [
		{
			name: 'Feedback',
			to: '/feedback',
			icon: 'commentSmile',
		},
	];

	return (
		<AppContainer breadcrumbs={breadcrumbs}>
			<MainStackWithAside
				fullWidth="true"
				toolbarStart={<Title title="" />}
				toolbarEnd={<FiltersToolbar />}
			>
				<Stack m="md" p="none" mb="none">
					{overviewData && ratingCard}
				</Stack>

				<Stack m="md" p="none">
					{feedbackTable}
				</Stack>
				<EmptyState data={feedbackData?.data} />

				<Center className="mt-8">
					<InlineError hide={!overviewFeedback.isError} />
					<InlineLoading hide={!overviewFeedback.isLoading} />
				</Center>

				<ReactTooltip />
			</MainStackWithAside>
		</AppContainer>
	);
}
function RatingTitle({ value }) {
	const overview = useOverview();

	return (
		<>
			{overview?.isSuccess ? `${value}` : null}
			<InlineError hide={!overview.isError} />
			<InlineLoading hide={!overview.isLoading} />
		</>
	);
}

function WrapWithIcon({ value }) {
	return (
		<div className="flex">
			<Icon name="star" size="sm" margin="none" mx="xs" bg="achived" />
			<span className="mx-2">{value?.rate}</span>
		</div>
	);
}
function WrapWithBadge({ value }) {
	let list = [];

	return (
		<>
			{value?.length > 0 ? (
				<div className="flex">
					<Badge dark>{value[0]?.name} </Badge>
					{value?.length > 1 && (
						<Badge
							plusAdded
							data-for="soclose"
							data-tip={list}
							onClick={value?.map((d) => list?.push(d.name))}
						>
							+ {value.length - 1}{' '}
						</Badge>
					)}
				</div>
			) : (
				'No tags selected.'
			)}
			<ReactTooltip
				id="soclose"
				type="light"
				borderColor="Gainsboro"
				border={true}
				effect="solid"
				getContent={(dataTip) => (
					<>
						<Align align="center" gap="1">
							<Label> Selected tags</Label>
						</Align>
						{(dataTip + '').split(',').map((i) => (
							<Badge dark className="my-3">
								{i}
							</Badge>
						))}
					</>
				)}
			></ReactTooltip>
		</>
	);
}

function EmptyState({ data, addRewardBtnClick }) {
	return (
		data?.length == 0 && (
			<Center>
				<Stack>
					<Title title="No feedback yet!" size="md" />
				</Stack>
			</Center>
		)
	);
}
function handleNoneData({ value }) {
	if (value == '0') {
		return value;
	}
	if (!value) {
		return 'No comment.';
	}

	return value;
}
function handleName({ value }) {
	if (!value?.name) {
		return '__';
	}

	return value?.name;
}
