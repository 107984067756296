import React from 'react';
import { useMutation } from 'react-query';

import { ApiRequest } from '@zeal/zeal-lib';
import { useQuery } from '../../App/QueryClient';

async function apiPunchProperties({ queryKey: [, filters] }) {
	return new ApiRequest('v3/punch-properties')
		.addHeader('Accept', 'application/json')
		.GET();
}

export default function usePunchProperties() {
	const q = useQuery({
		queryFn: apiPunchProperties,
		staleTime: Infinity,
		queryKey: ['z-punch-properties'],
	});

	React.useDebugValue(q);

	return q;
}

async function apiUpdatePunchProperties({ ...payload }) {
	return new ApiRequest('v3/punch-properties')
		.addHeader('Accept', 'application/json')
		.addHeader('Content-Type', 'application/json')
		.PUT(JSON.stringify(payload));
}

export function useUpdatePunchProperties(opt) {
	const mutation = useMutation(apiUpdatePunchProperties);

	const { data, mutate, isSuccess } = mutation;

	React.useEffect(() => {
		if (!isSuccess) {
			return null;
		}

		if (opt.onSuccess) {
			opt.onSuccess();
		}
	}, [data, isSuccess]);

	return {
		...mutation,
		doUpdateProperties: mutate,
	};
}

async function apiSubmitPointsPerEgp({ ...payload }) {
	// return new ApiRequest("v3/points")
	// .addHeader("Accept", "application/json")
	// .addHeader("Content-Type", "application/json")
	// .POST(JSON.stringify(payload), true)

	console.warn('[API/dummy]', 'usePunchProperties::apiSubmitPointsPerEgp');

	return Promise.resolve(true);
}

export function usePointsPerEgp(opt) {
	const mutation = useMutation(apiSubmitPointsPerEgp);

	const { data, mutate, isSuccess } = mutation;

	React.useEffect(() => {
		if (!isSuccess) {
			return null;
		}

		if (opt.onSuccess) {
			opt.onSuccess();
		}
	}, [data, isSuccess]);

	return {
		...mutation,
		doSubmitPointsPerEgp: mutate,
	};
}
