import React from 'react';
import { useMutation } from 'react-query';

import { ApiRequest } from '@zeal/zeal-lib';
import { useQuery } from '../../App/QueryClient';
import { useQueryString } from '../../App/useQueryString';

async function apiProfile({ queryKey: [, filters] }) {
	return new ApiRequest('v3/business/settings')
		.addHeader('Accept', 'application/json')
		.GET();
}

async function apiSubmitProfile(body) {
	return new ApiRequest('v3/settings/profile')
		.addHeader('Accept', 'application/json')
		.addHeader('Content-Type', 'application/json')
		.PUT(JSON.stringify(body))
		.then((payload) => ({
			data: payload?.data,
			totalCount: payload?.meta?.total,
			pageSize: payload?.meta?.per_page,
			pageCurrent: payload?.meta?.current_page,
		}));
}

async function apiUpdatePhoto(payload) {
	return new ApiRequest('v3/settings/profile/photo')
		.addHeader('Accept', 'application/json')
		.POST(payload);
}

async function apiUpdateCover(payload) {
	return new ApiRequest('v3/settings/profile/cover')
		.addHeader('Accept', 'application/json')
		.POST(payload);
}

export function useProfile() {
	const [queryState] = useQueryString();

	const q = useQuery({
		queryFn: apiProfile,
		staleTime: 1000 * 60 * 15, // 15 min
		queryKey: [
			'z-profile',
			{
				date: queryState?.date,
			},
		],
	});

	React.useDebugValue(q);

	return q;
}

export default function useSubmitProfile(opt) {
	const mutation = useMutation(apiSubmitProfile);

	const { data, mutate, isSuccess, isError } = mutation;

	React.useEffect(() => {
		if (isError) {
			if (opt.onError) {
				opt.onError();
			}
		}
		if (!isSuccess) {
			return null;
		}

		if (opt.onSuccess) {
			opt.onSuccess();
		}
	}, [data, isSuccess]);

	return {
		...mutation,
		doAddProfile: mutate,
	};
}

export function useUpdatePhoto(opt) {
	const mutation = useMutation(apiUpdatePhoto);

	const { data, mutate, isSuccess } = mutation;

	React.useEffect(() => {
		if (!isSuccess) {
			return null;
		}

		if (opt.onSuccess) {
			opt.onSuccess();
		}
	}, [data, isSuccess]);

	return {
		...mutation,
		doUpdatePhoto: mutate,
	};
}

export function useUpdateCover(opt) {
	const mutation = useMutation(apiUpdateCover);

	const { data, mutate, isSuccess } = mutation;

	React.useEffect(() => {
		if (!isSuccess) {
			return null;
		}

		if (opt.onSuccess) {
			opt.onSuccess();
		}
	}, [data, isSuccess]);

	return {
		...mutation,
		doUpdateCover: mutate,
	};
}
