import React from 'react';
import { DateTime } from 'luxon';

import {
	Button,
	ButtonWithIcon,
	Center,
	Icon,
	Input,
	Select,
	Stack,
	className as cx,
} from '@zeal/zeal-ui';

import { useQueryString } from '../../App/useQueryString';
import { relationalOperatorsMap } from '../../utils/relationalOperators';

import isValidDate from '../../utils/isValidDate';
import FilterRuleHandle from './FilterRuleHandle';

export default function FilterRule(props) {
	const { rule } = props;
	const [, updateQuery] = useQueryString();

	const [hasChanges, setHasChanges] = React.useState(false);
	const [filter, setFilter] = React.useState(rule);
	const [operator, setOperator] = React.useState(rule?.operator);
	const [operand, setOperand] = React.useState(rule?.operand);
	const [col, setCol] = React.useState(rule);

	React.useEffect(() => {
		setFilter(rule);
	}, [rule, setFilter]);

	React.useEffect(() => {
		setHasChanges(
			col?.value !== rule?.value
				|| operator !== rule?.operator
				|| operand !== rule?.operand,
		);
	}, [
		operand,
		operator,
		col?.operand,
		rule?.value,
		rule?.operator,
		rule?.operand,
	]);

	const handleOnInit = () => {
		setFilter({});
	};

	const handleOnCancelFilter = () => {
		setFilter(undefined);
		setCol(null);
		setOperand(null);
		setOperator(null);
	};

	const handleOnClearFilter = () => {
		props?.onClear?.(filter);

		if (filter.value) {
			updateQuery({
				[filter.value]: undefined,
				page: 1,
			});
		}
	};

	const handleOnApplyFilter = () => {
		if (!col?.value || !operand || !operator) {
			return null;
		}

		props?.onChange?.(
			{
				...col,
				operand,
				operator,
			},
			filter,
		);

		if (col.value) {
			updateQuery({
				[col.value]: [relationalOperatorsMap().getCode(operator), operand],
				page: 1,
			});
		}

		if (rule === false) {
			setFilter(undefined);
			setOperator(undefined);
			setOperand(undefined);
			setCol(undefined);
		} else {
			setFilter({
				...col,
				operand,
				operator,
			});
		}
	};

	const options = rule ? [rule, ...props.options] : props.options;

	const colOptions = options?.filter((i) => {
		const noSelected = i.value !== col?.value;
		return !(noSelected && i.isActive);
	});

	if (props.hide) {
		return null;
	}

	if (!filter) {
		return (
			<FilterRuleHandle
				handleOnInit={handleOnInit}
				filtersCount={props.filtersCount || 0}
			/>
		);
	}

	const checkIfMatchRule = (e) => {
		if (col?.value === 'points') {
			return /^\d+$/.exec(e) || e === '';
		}
		if (col?.value === 'receipt') {
			return /^[+-]?([0-9]+\.?[0-9]*|\.[0-9]+)$/.exec(e) || e === '';
		}
		return true;
	};

	function getSelectionType() {
		if (
			col?.value == 'branchName'
			|| col?.value == 'teamName'
			|| col?.value == 'memberName'
		)
		{return relationalOperatorsMap().getOnlySymbol(['!=', '=', 'like']);}

		if (col?.value == 'points' || col?.value == 'receipt')
		{return relationalOperatorsMap().getOnlySymbol(['>', '<', '=']);}

		if (col?.value == 'visitTime')
		{return relationalOperatorsMap().getExcludeSymbol(['like', '!=']);}

		return relationalOperatorsMap().allSymbols();
	}

	const operatorSelect = col && (
		<Select
			hideIcon
			hideOutline
			stretchList
			listPadding="sm"
			state={operator ? 'secondary' : 'light'}
			placeholder={operator ? operator : 'Pick an Operator'}
			selected={operator}
			onChange={setOperator}
			options={getSelectionType()}
			margin="none"
		/>
	);

	const onDateTimePickerChange = (date) => {
		if (date) {setOperand(DateTime.fromJSDate(date).toISO());}
	};

	const valueInput = operator && (
		<div>
			{col?.value !== 'visitTime' ? (
				<Input
					className="relative py-1.5"
					mainClassName="w-auto"
					type="text"
					pattern="[0-9]"
					inputName="title"
					margin="none"
					value={operand || ''}
					onChange={(evt) =>
						checkIfMatchRule(evt.target.value) && setOperand(evt.target.value)
					}
					placeholder="Enter value"
				/>
			) : (
				<Input.Date
					onChange={onDateTimePickerChange}
					showTimeSelect
					selected={isValidDate(operand) ? new Date(operand) : null}
				/>
			)}
		</div>
	);

	const applyFilterButton = hasChanges && (
		<ButtonWithIcon
			primary
			rounded="md"
			m="xxs"
			px="sm"
			py="sm"
			onClick={handleOnApplyFilter}
		>
			Apply filter
		</ButtonWithIcon>
	);

	const deleteFilterButton = !props?.rule && (
		<ButtonWithIcon
			danger
			rounded="lg"
			m="xxs"
			px="sm"
			py="sm"
			onClick={handleOnCancelFilter}
		>
			Delete Filter
		</ButtonWithIcon>
	);

	const clearFilterButton = props?.rule && (
		<ButtonWithIcon
			danger
			rounded="lg"
			m="xxs"
			px="sm"
			py="sm"
			onClick={handleOnClearFilter}
		>
			Clear Filter
		</ButtonWithIcon>
	);

	const actionButtons = operand && (
		<Stack row gap="xl" p="none" my="xxs" mt="xs">
			{applyFilterButton}
			{deleteFilterButton}
			{clearFilterButton}
		</Stack>
	);

	return (
		<Stack>
			<Center wrap gap="3" text={false} horizontal={false}>
				<Icon name="dotsVertical" margin="none" />
				<Select
					hideIcon
					hideOutline
					state={col ? 'secondary' : 'light'}
					placeholder={col ? col : 'Select property'}
					selected={col}
					disable={filter?.col}
					onChange={setCol}
					options={colOptions}
					margin="none"
				/>
				{operatorSelect}
				{valueInput}
				{actionButtons}
			</Center>
		</Stack>
	);
}
