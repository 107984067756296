import React, { useReducer, useState } from 'react';
import { ButtonWithIcon, Grid, Header, Icon, Stack } from '@zeal/zeal-ui';
import AppContainer from '../App/AppContainer';
import ACLBoundary from '../App/ACL/ACLBoundary';
import EmptyState from './EmptyState';
import AddEditModal from './AddEditModal';
import DeleteRecommendationModal from './DeleteRecommendationModal';
import RecommendationsCards from './RecommendationsCards';

import useRecommendations, { useDeleteAnnou } from './data/useRecommendations';

export default function Recommendations({ ...props }) {
	const [isAddEditModalOpen, setIsAddEditModalOpen] = useState(false);
	const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
	const [editedRecommendation, setEditedRecommendation] = useState(null);
	const [recommendationsData, setRecommendationsData] = useState([]);
	const [removeError, setRemoveError] = React.useState(true);
	const [uuid, setUuid] = React.useState('');

	const recommendationQuery = useRecommendations();
	const { data } = recommendationQuery;

	const refetchRecommendationsData = () => {
		recommendationQuery.refetch();
	};

	React.useEffect(() => {
		setRecommendationsData(data);
	}, [data]);

	const initialFormValues = {
		photo: '',
		media: '',
		description: '',
		hideAtQuotaEnd: false,
	};

	const addEditFormReducer = (prevState, newState) => ({ ...prevState, ...newState });

	const [formState, formDispatch] = useReducer(
		addEditFormReducer,
		initialFormValues,
	);

	const openRecommendationModal = () => {
		setIsAddEditModalOpen(true);
	};

	const addRecommendationBtnClick = () => {
		resetForm();
		setUuid('');
		setEditedRecommendation(null);
		openRecommendationModal();
	};

	const handleRecommendationEdit = (item) => {
		openRecommendationModal();
		formDispatch(item);
		setEditedRecommendation(item);
		setUuid(item.uuid);
	};

	const handleRecommendationDelete = (item) => {
		setUuid(item.uuid);
		setIsDeleteModalOpen(true);
	};

	const handleFilesUpload = (files) => {
		formDispatch({ photo: files[0] });
	};

	const resetForm = () => {
		formDispatch(initialFormValues);
	};

	const { doDeleteAnnou, isError, isLoading, isSuccess, status }
		= useDeleteAnnou({
			onSuccess: () => {
				refetchRecommendationsData();
				setIsDeleteModalOpen(false);
			},
		});

	const deleteRecommendation = (uuid) => {
		doDeleteAnnou({ uuid });
		setRemoveError(true);
	};

	const ActionsComp = (item) => (
		<Stack row>
			<ACLBoundary
				aclUnAuthProps={{ disabled: true }}
				aclGrants={['control:recommendations']}
			>
				<ButtonWithIcon
					stretch={false}
					p="none"
					m="none"
					transparent
					onClick={() => handleRecommendationEdit(item)}
				>
					<Icon bg="snow" rounded name="edit" size="!w7" p="sm" />
				</ButtonWithIcon>
				<ButtonWithIcon
					stretch={false}
					p="none"
					m="none"
					transparent
					onClick={() => handleRecommendationDelete(item)}
				>
					<Icon bg="snow" rounded name="trash" size="!w7" p="sm" />
				</ButtonWithIcon>
			</ACLBoundary>
		</Stack>
	);

	const recommendationsBreadCrumb = [
		{
			name: 'Recommendations',
			to: '/recommendations',
			icon: 'award',
		},
	];

	return (
		<AppContainer ownApp protected breadcrumbs={recommendationsBreadCrumb}>
			<div>
				<Header
					padding="sm"
					startContent={<span></span>}
					endContent={
						<ACLBoundary>
							<ButtonWithIcon
								primary
								label="Add Recommendations"
								btnIcon="plus"
								size="xs"
								m="none"
								onClick={addRecommendationBtnClick}
								aclUnAuthProps={{ disabled: true }}
								aclGrants={['control:recommendations']}
							/>
						</ACLBoundary>
					}
				/>
				<Stack m="md">
					<Grid cols="3" gap="3" withSpace>
						<RecommendationsCards
							recommendationsData={recommendationsData}
							ActionsComp={ActionsComp}
						/>
					</Grid>
				</Stack>
				<EmptyState
					data={recommendationsData?.data}
					addRecommendationBtnClick={addRecommendationBtnClick}
				/>
			</div>
			<AddEditModal
				closeModal={() => {
					setIsAddEditModalOpen(false);
					setRemoveError(false);
				}}
				formState={formState}
				resetForm={resetForm}
				formDispatch={formDispatch}
				isModalOpen={isAddEditModalOpen}
				editedRecommendation={editedRecommendation}
				handleFilesUpload={handleFilesUpload}
				removeError={removeError}
				setRemoveError={setRemoveError}
				uuid={uuid}
			/>
			<DeleteRecommendationModal
				isDeleteModalOpen={isDeleteModalOpen}
				closeDeleteModal={() => {
					setIsDeleteModalOpen();
					setRemoveError(false);
				}}
				deleteRecommendation={deleteRecommendation}
				isError={isError}
				isLoading={isLoading}
				removeError={removeError}
				uuid={uuid}
			/>
		</AppContainer>
	);
}
