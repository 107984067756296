import React, { useState } from 'react';

import { Button, Grid, Input, Select } from '@zeal/zeal-ui';
import useSubmitPOS from '../data/usePOS';

export default function POS() {
	const args = {
		label: 'Choose Your Pos Software',
		options: [
			{ value: 1, label: 'foobar' },
			{ value: 2, label: 'barbaz' },
			{ value: 3, label: 'bazfoo' },
		],
	};
	const [type, setType] = useState();
	const [pass, setPass] = React.useState();
	const [clientId, setClientId] = useState();
	const [clientSecret, setClientSecret] = React.useState();
	const resetForm = () => {
		setType('');
		setPass('');
		setClientId('');
		setClientSecret('');
	};
	const { doPOS } = useSubmitPOS({
		onSuccess: () => {},
	});

	const handleposSubmit = React.useCallback(() => {
		let pos = {
			type,
			pass,
			clientId,
			clientSecret,
		};
		doPOS(pos);
		resetForm();
	}, [type, pass, clientId, clientSecret, doPOS]);
	return (
		<>
			<Grid cols="2" gap="3">
				<div cols="1">
					<Select
						{...args}
						selected={type}
						onChange={setType}
						mb="xl"
						placeholder={type?.value ? '' : 'Choose Your Pos Software'}
					/>
					<Input.password
						inputName="Text"
						placeholder="enter pos Password"
						label="pos Password"
						onChange={(evt) => setPass(evt.target.value)}
						value={pass}
						mb="xl"
						validateType="true"
					/>
				</div>
			</Grid>
			<Grid cols="2" gap="3">
				<Input
					id="clientId"
					type="text"
					inputName="Text"
					placeholder="enter Client ID"
					label="Client ID"
					mb="xl"
					onChange={(evt) => setClientId(evt.target.value)}
					value={clientId}
				/>
				<Input
					id="clientSecret"
					type="text"
					inputName="Text"
					mb="xl"
					placeholder="enter Client Secret"
					label="Client Secret"
					onChange={(evt) => setClientSecret(evt.target.value)}
					value={clientSecret}
				/>
			</Grid>
			<Grid cols="2" gap="3">
				<Button
					size="md"
					secondary
					label="Save Changes"
					className="w-4/12"
					onClick={handleposSubmit}
				/>
			</Grid>
		</>
	);
}
