import React, { useState } from 'react';

import {
	Card,
	Image,
	MainStackWithIcon,
	SizedBox,
	Stack,
	Title,
} from '@zeal/zeal-ui';

export default function RecommendationsCards({
	recommendationsData,
	ActionsComp,
	...props
}) {
	return (
		(recommendationsData?.data || []).length > 0
		&& recommendationsData?.data?.map((item) => (
			<Card p="none">
				<MainStackWithIcon
					icon={ActionsComp(item)}
					xPosition="right"
					yPosition="top"
					iconPadding="none"
					fillContainer={false}
					primary={props.isNewlyCreated}
				>
					<Stack p="none">
						<SizedBox height="!h60">
							<Image
								cover
								rounded
								src={
									(item?.photo && URL.createObjectURL(item?.photo))
									|| item?.media
								}
								alt="card"
							/>
						</SizedBox>
					</Stack>
				</MainStackWithIcon>
				<Stack m="md" p="none">
					<Title
						subtitleClassName="line-clamp"
						size="sm"
						titleText={item?.title}
						subtitleText={item?.description}
					/>
				</Stack>
			</Card>
		))
	);
}
