import React from 'react';

import { ButtonWithIcon, Image, Stack, Title } from '@zeal/zeal-ui';

export default function PhotoUploader({ setOpenUploader, photo, image }) {
	return photo || image ? (
		<Stack>
			<Title title="Preview" margin="xs" />
			<Image
				my="md"
				height="2xl"
				cover
				rounded
				src={photo ? URL.createObjectURL(photo) : image}
				alt="announcement photo"
			/>

			<ButtonWithIcon
				transparent
				m="xxs"
				px="md"
				py="sm"
				onClick={() => {
					setOpenUploader(Date.now());
				}}
			>
				<Title primary title="Change Photo" size="sm" />
			</ButtonWithIcon>
		</Stack>
	) : (
		<Stack>
			<Title title="Upload Photo" margin="xs" />
		</Stack>
	);
}
