import React from 'react';
import { Route, Switch } from 'react-router-dom';
import ACLBoundary from '../App/ACL/ACLBoundary';

import Articles from './Articles';

export default function BranchesRoutes() {
	return (
		<Switch>
			<ACLBoundary
				aclUnAuthProps={{ replace: true }}
				aclGrants={['view:articles']}
			>
				<Route exact path="/articles" component={Articles} />
			</ACLBoundary>
		</Switch>
	);
}
