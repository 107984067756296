import React from 'react';
import { cloneDeep, orderBy } from 'lodash-es';
import {
	Button,
	ButtonWithIcon,
	Center,
	Grid,
	InlineLoading,
	MainStackWithAside,
	Modal,
	Stack,
	Title,
	TwoSideLayout,
} from '@zeal/zeal-ui';

import AppContainer from '../../../App/AppContainer';

import RewardsToolbarStart from '../../components/RewardsToolbarStart';

import ACLBoundary from '../../../App/ACL/ACLBoundary';
import LoyaltyCardPunchValue from './components/LoyaltyCardPunchValue';
import LoyaltyCardRewards from './components/LoyaltyCardRewards';
import LoyaltyCard from './components/LoyaltyCard';
import RewardPropertiesAside from './components/RewardPropertiesAside';
import useLoyaltyRewards, { useUpdateCard } from './data/useLoyaltyRewards';

export default function LoyaltyRewards(props) {
	const [cardsData, setCardsData] = React.useState([]);
	const [fillingCardIndex, setFillingCardIndex] = React.useState(-1);
	const [newCardIndex, setNewCardIndex] = React.useState(-1);
	const [isDeleteModalOpen, setIsDeleteModalOpen] = React.useState(false);
	const [deletedCardIndex, setDeletedCardIndex] = React.useState(-1);

	const loyaltyQuery = useLoyaltyRewards();
	const { data, isLoading } = loyaltyQuery;

	const {
		doUpdateCards,
		isLoading: isUpdateCardLoading,
		variables: updateVariables,
	} = useUpdateCard({
		onSuccess: () => {
			loyaltyQuery.refetch();
		},
	});

	const updatedCardIndex = updateVariables?.cardIndex;

	React.useEffect(() => {
		let orderedData = orderBy(data, 'punch', 'asc');
		setCardsData(orderedData);
	}, [data]);

	const handleAddCard = () => {
		setFillingCardIndex(cardsData.length);
		setNewCardIndex(cardsData.length);
		setCardsData([...cardsData, { rewards: [], points: 0 }]);
		loyaltyQuery.refetch();
	};

	const handleDeleteCard = (cardIndex) => {
		const newCards = cloneDeep(cardsData);
		newCards?.splice(cardIndex, 1);

		doUpdateCards({ data: newCards, cardIndex });

		setIsDeleteModalOpen(false);
	};

	const handleDeleteCardClick = (index) => {
		setIsDeleteModalOpen(true);
		setDeletedCardIndex(index);
	};

	const handlePunchValueChange = (index, value) => {
		setCardsData((prev) => {
			const newCards = cloneDeep(prev);
			newCards[index].points = value;
			return newCards;
		});
	};

	const isCardPunchValueValid = (index) => {
		let isPunchValueSorted = true;
		let punchValue = Number(cardsData[index]?.points);
		let cardBefore = index - 1 >= 0 ? cardsData[index - 1] : null;
		let cardAfter = index + 1 < cardsData.length ? cardsData[index + 1] : null;

		if (Number(cardBefore?.points) >= punchValue && cardBefore)
		{isPunchValueSorted = false;}

		if (Number(cardAfter?.points) <= punchValue && cardAfter)
		{isPunchValueSorted = false;}

		return punchValue >= 1 && !isNaN(punchValue) && isPunchValueSorted;
	};

	const handleCardBlur = (event, index) => {
		const isBlurOutsideCard = !event.currentTarget.contains(
			event.relatedTarget,
		);

		// Check if the blurred card is the same one we are filling
		const isBlurredCardFilling = fillingCardIndex === index;

		if (
			isBlurOutsideCard
			&& isCardPunchValueValid(index)
			&& isBlurredCardFilling
		) {
			if (newCardIndex !== -1) {
				setNewCardIndex(cardsData.length);
			}

			setTimeout(() => {
				setFillingCardIndex(-1);
			}, 100);

			const selectedCard = cardsData[index];

			if (selectedCard?.rewards?.length > 0)
			{doUpdateCards({ data: cardsData, cardIndex: index });}
		}
	};

	const handleEditBtnClick = (index) => {
		if (fillingCardIndex === -1) {setFillingCardIndex(index);}
	};

	const handleAddRewardModalSubmit = (cardIndex, reward) => {
		const newCards = cloneDeep(cardsData);
		newCards[cardIndex]?.rewards?.push(reward);

		doUpdateCards({ data: newCards, cardIndex });
	};

	const handleDeleteReward = (cardIndex, rewardIndex) => {
		const newCards = cloneDeep(cardsData);
		newCards[cardIndex]?.rewards?.splice(rewardIndex, 1);

		doUpdateCards({ data: newCards, cardIndex });
	};

	let isAddCardDisabled = cardsData?.length >= 5 || fillingCardIndex !== -1;

	let emptyState = cardsData.length === 0 && (
		<Center>
			<Stack>
				<Title
					title="Start Adding Rewards"
					subtitle="Add A Punch Card And Fill It With Your Rewards "
					size="md"
					margin="md"
				/>
				<Center>
					<ACLBoundary>
						<ButtonWithIcon
							secondary
							label="Add Card"
							btnIcon="plus"
							size="xs"
							onClick={handleAddCard}
							disabled={isAddCardDisabled}
							aclUnAuthProps={{ disabled: true }}
							aclGrants={['control:loyalty']}
						/>
					</ACLBoundary>
				</Center>
			</Stack>
		</Center>
	);

	const loyaltyBreadCrumb = [
		{
			name: 'Rewards: Loyalty Cards',
			to: '/rewards',
			icon: 'gift',
		},
	];

	const toolbarEnd = (
		<ACLBoundary>
			<ButtonWithIcon
				secondary
				label="Add Card"
				btnIcon="plus"
				size="xs"
				m="none"
				onClick={handleAddCard}
				disabled={isAddCardDisabled}
				aclUnAuthProps={{ disabled: true }}
				aclGrants={['control:loyalty']}
			/>
		</ACLBoundary>
	);

	const RenderLoyaltyCards = React.Children.toArray(
		cardsData?.map((card, index) => (
			<LoyaltyCard
				LoyaltyCardPunchValue={
					<RenderLoyaltyCardPunchValue
						handleCardBlur={handleCardBlur}
						handlePunchValueChange={handlePunchValueChange}
						handleEditBtnClick={handleEditBtnClick}
						card={card}
						index={index}
						fillingCardIndex={fillingCardIndex}
						newCardIndex={newCardIndex}
					/>
				}
				LoyaltyCardRewards={
					<RenderLoyaltyCardRewards
						card={card}
						index={index}
						handleAddRewardModalSubmit={handleAddRewardModalSubmit}
						handleDeleteReward={handleDeleteReward}
						handleDeleteCard={handleDeleteCardClick}
						isCardLoading={
							(isUpdateCardLoading || isLoading) && index === updatedCardIndex
						}
					/>
				}
				fillingCardIndex={fillingCardIndex}
				handleCardBlur={handleCardBlur}
				index={index}
			/>
		)),
	);

	const loadingComp = (
		<Center>
			<Stack m="xl">
				<InlineLoading />
			</Stack>
		</Center>
	);

	const pageContent = isLoading ? (
		loadingComp
	) : (
		<div>
			<Stack gap="xl" m="none" pt="none">
				{RenderLoyaltyCards}
			</Stack>
			{emptyState}
		</div>
	);

	return (
		<AppContainer protected breadcrumbs={loyaltyBreadCrumb}>
			<MainStackWithAside
				toolbarStart={<RewardsToolbarStart />}
				toolbarEnd={toolbarEnd}
				aside={<RewardPropertiesAside />}
			>
				{pageContent}
			</MainStackWithAside>
			<DeleteCardModal
				isDeleteModalOpen={isDeleteModalOpen}
				closeDeleteModal={() => setIsDeleteModalOpen(false)}
				handleCardDelete={handleDeleteCard}
				cardIndex={deletedCardIndex}
			/>
		</AppContainer>
	);
}

function RenderLoyaltyCardRewards(props) {
	const {
		card,
		index,
		handleAddRewardModalSubmit,
		handleDeleteReward,
		handleDeleteCard,
		getLoyaltyRewardsData,
		isCardLoading,
	} = props;

	return (
		<LoyaltyCardRewards
			rewards={card.rewards}
			isPunchValueFilled={Number(card?.points) < 1}
			cardIndex={index}
			handleAddRewardModalSubmit={handleAddRewardModalSubmit}
			handleDeleteReward={handleDeleteReward}
			deletePunchCard={handleDeleteCard}
			getLoyaltyRewardsData={getLoyaltyRewardsData}
			isCardLoading={isCardLoading}
		/>
	);
}

function RenderLoyaltyCardPunchValue(props) {
	const {
		index,
		card,
		fillingCardIndex,
		newCardIndex,
		handleCardBlur,
		handlePunchValueChange,
		handleEditBtnClick,
	} = props;

	return (
		<LoyaltyCardPunchValue
			isFillingPunch={fillingCardIndex === index}
			isNewlyCreated={newCardIndex === index}
			punch={card.points}
			cardIndex={index}
			handleCardBlur={handleCardBlur}
			handlePunchValueChange={handlePunchValueChange}
			handleEditBtnClick={handleEditBtnClick}
		/>
	);
}

function DeleteCardModal(props) {
	const { isDeleteModalOpen, closeDeleteModal, handleCardDelete, cardIndex }
		= props;

	return (
		<Modal
			isModalOpen={isDeleteModalOpen}
			title="Delete Card"
			onClose={closeDeleteModal}
		>
			<Stack p="none">
				<Title title="Are you sure you want to delete this card?" />

				<Grid gap="5">
					<span></span>
					<TwoSideLayout>
						<Button stretch={false} size="md" m="xs" onClick={closeDeleteModal}>
							Cancel
						</Button>
						<ACLBoundary>
							<ButtonWithIcon
								secondary
								stretch={false}
								size="md"
								m="xs"
								onClick={() => handleCardDelete(cardIndex)}
								aclUnAuthProps={{ disabled: true }}
								aclGrants={['control:loyalty']}
							>
								Delete
							</ButtonWithIcon>
						</ACLBoundary>
					</TwoSideLayout>
				</Grid>
			</Stack>
		</Modal>
	);
}
