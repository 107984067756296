import React from 'react';
import { DateTime } from 'luxon';

export default function renderRelativeDatetime(value) {
	if (!value) {
		return '--';
	}

	let dt;

	dt = DateTime.fromFormat(value, 'yyyy-MM-dd HH:mm:ss');

	if (dt.isValid) {
		return dt.toRelative();
	}

	dt = DateTime.fromISO(value);

	if (dt.isValid) {
		return dt.toRelative();
	}

	return value;
}
