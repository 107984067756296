import React, { useState } from 'react';
import { useMutation } from 'react-query';

import { ApiRequest } from '@zeal/zeal-lib';
import { useQuery } from '../../App/QueryClient';
import { useQueryString } from '../../App/useQueryString';

async function apiSchedule({ queryKey: [, filters] }) {
	return new ApiRequest(`v3/booking-days/branches/${filters?.branchId}`)
		.addHeader('Accept', 'application/json')
		.GET();
}

async function apiEditBookingDay({ ...payload }) {
	return new ApiRequest(`v3/booking-days/${payload.id}`)
		.addHeader('Accept', 'application/json')
		.addHeader('Content-type', 'application/json')
		.PUT(JSON.stringify(payload));
}

async function apiUpdateBookingDurations({ ...payload }) {
	return new ApiRequest('v3/booking-durations')
		.addHeader('Accept', 'application/json')
		.addHeader('Content-type', 'application/json')
		.POST(JSON.stringify(payload));
}

export function useUpdateBookingDurations(opt) {
	const mutation = useMutation(apiUpdateBookingDurations);

	const { data, mutate, isSuccess } = mutation;

	React.useEffect(() => {
		if (!isSuccess) {
			return null;
		}

		if (opt?.onSuccess) {
			opt.onSuccess();
		}
	}, [data, isSuccess]);

	return {
		...mutation,
		doUpdateDurations: mutate,
	};
}

export function useEditBookingDay(opt) {
	const mutation = useMutation(apiEditBookingDay);

	const { data, mutate, isSuccess } = mutation;

	React.useEffect(() => {
		if (!isSuccess) {
			return null;
		}

		if (opt?.onSuccess) {
			opt.onSuccess();
		}
	}, [data, isSuccess]);

	return {
		...mutation,
		doEditDay: mutate,
	};
}

async function apiAddBookingDay({ ...payload }) {
	return new ApiRequest('v3/booking-days')
		.addHeader('Accept', 'application/json')
		.addHeader('Content-type', 'application/json')
		.POST(JSON.stringify(payload));
}

export function useAddBookingDay(opt) {
	const mutation = useMutation(apiAddBookingDay);

	const { data, mutate, isSuccess } = mutation;

	React.useEffect(() => {
		if (!isSuccess) {
			return null;
		}

		if (opt?.onSuccess) {
			opt.onSuccess();
		}
	}, [data, isSuccess]);

	return {
		...mutation,
		doAddDay: mutate,
	};
}

async function apiBranches({ queryKey: [, filters] }) {
	return new ApiRequest('v3/branch')
		.addHeader('Accept', 'application/json')
		.addQuery('paginate', filters?.paginate)
		.GET()
		.then((payload) => ({
			data: payload?.data,
		}));
}

export default function useSchedule(opt) {
	const [queryState] = useQueryString();
	const [branchesList, setBranchesList] = useState([]);

	const q = useQuery({
		queryFn: apiSchedule,
		queryKey: [
			'z-days-schedule',
			{
				branchId: queryState?.branchId,
			},
		],
		enabled: !!queryState?.branchId,
		staleTime: Infinity,
	});

	const { data: branchesData } = q;

	const daysNames = [
		'Sunday',
		'Monday',
		'Tuesday',
		'Wednesday',
		'Thursday',
		'Friday',
		'Saturday',
	];

	const formatSlotDurations = opt?.formatSlotDurations || ((d) => d);

	React.useEffect(() => {
		if (branchesData) {
			const formattedData = daysNames.map((day) => {
				const bookingDay = branchesData?.data?.find((d) => d.day === day);

				if (bookingDay) {
					bookingDay.bookingDurations = (
						bookingDay?.bookingDurations || []
					)?.map(formatSlotDurations);

					return bookingDay;
				}

				return { day: day, enabled: false };
			});
			setBranchesList(formattedData);
		} else {
			setBranchesList(undefined);
		}
	}, [branchesData, setBranchesList]);

	React.useDebugValue(q);

	return { ...q, data: branchesList };
}

export function useBranches(opt) {
	const [branchesList, setBranchesList] = useState([]);

	const q = useQuery({
		queryFn: apiBranches,
		queryKey: [
			'z-branches-not-paginated',
			{
				paginate: false,
			},
		],
		staleTime: Infinity,
	});

	const formatBranch = opt?.formatBranch || ((d) => d);

	const { data: branchesData } = q;

	React.useEffect(() => {
		if (branchesData) {
			const formattedData = branchesData?.data?.map(formatBranch);
			setBranchesList(formattedData);
		}
	}, [branchesData, setBranchesList]);

	React.useDebugValue(q);

	return { ...q, data: branchesList };
}
