import React from 'react';
import { useMutation } from 'react-query';

import { ApiRequest } from '@zeal/zeal-lib';

async function apiSignup({ ...payload }) {
	return new ApiRequest('v3/auth/register')
		.addHeader('Accept', 'application/json')
		.addHeader('Content-Type', 'application/json')
		.POST(JSON.stringify(payload), true)
		.then(({ data }) => ({
			data,
		}));
}

export function useSignup(opt) {
	const mutation = useMutation(apiSignup);

	const { data, mutate, isSuccess, isError } = mutation;

	React.useEffect(() => {
		if (isError) {
			if (opt.onError) {
				opt.onError();
			}
		}

		if (!isSuccess) {
			return null;
		}

		if (opt.onSuccess) {
			opt.onSuccess();
		}
	}, [data, isSuccess, isError]);

	return {
		...mutation,
		doSignup: mutate,
	};
}
