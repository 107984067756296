import {
	Align,
	Anchor,
	BasicTable,
	Button,
	ButtonWithIcon,
	Center,
	CenterStackWithToolbar,
	Grid,
	Icon,
	InlineEmpty,
	InlineError,
	InlineLoading,
	Input,
	Modal,
	Stack,
	Title,
	ToggleButton,
	TwoSideLayout,
} from '@zeal/zeal-ui';
import { isDate } from 'lodash';
import React, { useState } from 'react';
import ReactTooltip from 'react-tooltip';
import ACLBoundary from '../App/ACL/ACLBoundary';
import AppContainer from '../App/AppContainer';
import openToastr from '../App/Toastr/openToastr';
import useArticles, {
	useAddArticle,
	useDeleteArticle,
	usePublishArticle,
} from './data/useArticles';

export default function Articles() {
	const [isAddModalOpen, setIsAddModalOpen] = useState(false);
	const [deletedArticle, setDeletedArticle] = useState(null);

	const articlesQuery = useArticles();

	const {
		data: articlesData,
		isLoading: isArticlesDataLoading,
		isError: isArticlesDataError,
	} = articlesQuery;

	const {
		doAddArticle,
		isLoading: isAddArticleLoading,
		isError: isAddArticleError,
	} = useAddArticle({
		onSuccess: () => {
			setIsAddModalOpen(false);

			articlesQuery.refetch();

			openToastr({
				message: 'Article Added',
			});
		},
		onError: (error) => {
			error?.response.json().then((err) => {
				openToastr({
					message: err?.message || 'Failed to add article',
					isError: true,
				});
			});
		},
	});

	const {
		doDeleteArticle,
		isLoading: isDeleteArticleLoading,
		isError: isDeleteArticleError,
	} = useDeleteArticle({
		onSuccess: () => {
			setDeletedArticle(null);
			articlesQuery.refetch();

			openToastr({
				message: 'Article Deleted',
			});
		},
		onError: (error) => {
			error?.response.json().then((err) => {
				openToastr({
					message: err?.message || 'Failed to delete article',
					isError: true,
				});
			});
		},
	});

	const { doPublishArticle } = usePublishArticle({
		onSuccess: () => {
			articlesQuery.refetch();

			openToastr({
				message: 'Article Updated',
			});
		},
		onError: (error) => {
			error?.response.json().then((err) => {
				openToastr({
					message: err?.message || 'Failed to update article',
					isError: true,
				});
			});
		},
	});

	const breadcrumbs = [
		{
			name: 'Articles',
			to: '/articles',
			icon: 'newspaper',
		},
	];

	const deleteBtnClicked = (article) => {
		setDeletedArticle(article);
	};

	const toggleArticlePublish = (article) => {
		const selectedIndex = articlesData?.findIndex((a) => a.id === article.id);

		//this lines toggles the toggleButton before the request is even dispatched
		if (selectedIndex !== -1) {
			articlesData[selectedIndex].published = !(
				Number(article.published) || false
			);
		}

		doPublishArticle(article);
	};

	const rewardPointColumns = [
		{ Header: 'Link', accessor: 'url', Cell: RenderLinkCell },
		{
			Header: 'Description',
			accessor: 'description',
			Cell: RenderDescriptionCell,
		},
		{
			Header: 'Creation Date',
			accessor: 'created_at',
			Cell: RenderDateCell,
		},
		{
			Header: 'Publish',
			accessor: 'published',
			Cell: RenderPublishCell(toggleArticlePublish),
		},
		{
			Header: '',
			accessor: 'actions',
			Cell: RenderActionsCell(deleteBtnClicked),
		},
	];

	const isArticlesEmpty = !articlesData?.length;

	const handleAddArticle = (url) => {
		doAddArticle({ url });
	};

	const handleAddArticleClick = () => {
		setIsAddModalOpen(true);
	};

	const handleDeleteArticle = (article) => {
		doDeleteArticle({ id: article.id });
	};

	return (
		<AppContainer ownApp protected breadcrumbs={breadcrumbs}>
			<CenterStackWithToolbar
				toolbarStart={<span></span>}
				toolbarEnd={
					<ArticlesHeader handleAddArticleClick={handleAddArticleClick} />
				}
			>
				<BasicTable
					shadow
					data={articlesData || []}
					columns={rewardPointColumns}
					headerPadding="lg"
					cellPadding="lg"
					isError={isArticlesDataError}
					isEmpty={isArticlesEmpty}
					isLoading={isArticlesDataLoading}
					errorContent={
						<Center>
							<Stack m="lg">
								<InlineError />
							</Stack>
						</Center>
					}
					emptyContent={
						<Center>
							<Stack m="lg">
								<InlineEmpty label="No Articles Yet" />
							</Stack>
						</Center>
					}
					loadingContent={
						<Center>
							<Stack m="lg">
								<InlineLoading />
							</Stack>
						</Center>
					}
				/>
			</CenterStackWithToolbar>
			<AddArticleModal
				isModalOpen={isAddModalOpen}
				closeModal={() => setIsAddModalOpen(false)}
				handleSubmit={handleAddArticle}
				isLoading={isArticlesDataLoading || isAddArticleLoading}
				isError={isAddArticleError}
			/>
			<DeleteArticleModal
				deletedArticle={deletedArticle}
				closeModal={() => {
					setDeletedArticle(null);
				}}
				handleSubmit={handleDeleteArticle}
				isLoading={isArticlesDataLoading || isDeleteArticleLoading}
				isError={isDeleteArticleError}
			/>
		</AppContainer>
	);
}

function ArticlesHeader(props) {
	const { handleAddArticleClick } = props;

	return (
		<Align align="right">
			<Stack row wrap p="none" gap="md">
				<ACLBoundary>
					<ButtonWithIcon
						primary
						label="Add Article"
						btnIcon="plus"
						size="xs"
						m="none"
						onClick={handleAddArticleClick}
						aclUnAuthProps={{ disabled: true }}
						aclGrants={['control:articles']}
					/>
				</ACLBoundary>
			</Stack>
		</Align>
	);
}

function RenderPublishCell(toggleArticlePublish) {
	return ({ row, value }) => (
		<ACLBoundary>
			<ToggleButton
				isChecked={Number(value) || false}
				name="requiredToggle"
				size="sm"
				onChange={() => toggleArticlePublish(row?.original)}
				aclUnAuthProps={{ disabled: true }}
				aclGrants={['control:articles']}
			/>
		</ACLBoundary>
	);
}

function RenderDateCell({ value }) {
	const date = new Date(value);

	if (isDate(date))
	{return new Intl.DateTimeFormat('en-GB', {
		dateStyle: 'medium',
	}).format(date);}

	return value;
}

function RenderDescriptionCell({ value }) {
	const allowedCharsCount = 60;
	if (value?.length > allowedCharsCount) {
		return (
			<div>
				<span data-tip={value}>
					{value?.substring(0, allowedCharsCount) + ' ...'}
				</span>
				<ReactTooltip type="light" borderColor="Gainsboro" border={true} />
			</div>
		);
	}
	return value || '';
}

function RenderLinkCell({ value }) {
	return <Anchor label="Link" color="primary" href={value} target="_blank" />;
}

function RenderActionsCell(deleteBtnClicked) {
	return ({ row }) => (
		<Align align="right" gap="1">
			<ACLBoundary>
				<Button
					transparent
					stretch={false}
					p="none"
					m="none"
					onClick={() => deleteBtnClicked(row?.original)}
					aclUnAuthProps={{ disabled: true }}
					aclGrants={['control:articles']}
				>
					<Icon
						rounded
						bg="danger"
						name="trashAlt"
						size="lg"
						p="xs"
						margin="none"
					/>
				</Button>
			</ACLBoundary>
		</Align>
	);
}

function AddArticleModal(props) {
	const { isModalOpen, closeModal, handleSubmit, isLoading, isError } = props;
	const [linkInput, setLinkInput] = useState('');

	const onSubmit = () => {
		handleSubmit(linkInput);
		setLinkInput('');
	};

	return (
		<Modal isModalOpen={isModalOpen} title="Add Article" onClose={closeModal}>
			<Stack p="none">
				<Stack>
					<Title title="Article Link" margin="xs" />
					<Input
						type="text"
						inputName="link"
						placeholder="Article Link"
						onChange={(evt) => setLinkInput(evt.target.value)}
						value={linkInput}
					/>
				</Stack>
			</Stack>
			<Grid gap="5">
				<span></span>
				<TwoSideLayout>
					<ButtonWithIcon
						stretch={false}
						m="xxs"
						px="md"
						py="sm"
						onClick={closeModal}
					>
						Cancel
					</ButtonWithIcon>
					<Align align="left">
						<InlineError hide={!isError} />
						<InlineLoading hide={!isLoading} />
						<ButtonWithIcon
							stretch={false}
							m="xxs"
							px="md"
							py="sm"
							primary
							onClick={onSubmit}
							disabled={!linkInput}
						>
							Add
						</ButtonWithIcon>
					</Align>
				</TwoSideLayout>
			</Grid>
		</Modal>
	);
}

function DeleteArticleModal(props) {
	const { deletedArticle, closeModal, handleSubmit, isError, isLoading }
		= props;

	return (
		<Modal
			isModalOpen={!!deletedArticle}
			title="Delete Article"
			onClose={closeModal}
		>
			<Stack p="none">
				<Title title="Are you sure you want to delete this article" />

				<Grid gap="5">
					<span></span>
					<TwoSideLayout>
						<Button stretch={false} size="md" m="xs" onClick={closeModal}>
							Cancel
						</Button>
						<Align align="left">
							<InlineError hide={!isError} />
							<InlineLoading hide={!isLoading} />
							<ButtonWithIcon
								stretch={false}
								m="xxs"
								px="md"
								py="sm"
								secondary
								onClick={() => handleSubmit(deletedArticle)}
							>
								Delete
							</ButtonWithIcon>
						</Align>
					</TwoSideLayout>
				</Grid>
			</Stack>
		</Modal>
	);
}
