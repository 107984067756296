import React, { createContext, useContext, useMemo, useState } from 'react';
import { useAccountInfo } from '../BusinessAccount/useBusinessInfo';

export function useOwnAppAccess(isOwnAppContainer) {
	const { isAppOwn } = useAccountInfo();

	return {
		isRouteAllowed: (isOwnAppContainer && isAppOwn) || !isOwnAppContainer,
	};
}

const OwnAppContext = createContext({
	isOwnApp: true,
	setIsOwnApp: () => {},
});

export function OwnAppProvider({ children }) {
	const [isOwnApp, setIsOwnApp] = useState(false);

	const ctx = useMemo(() => ({ isOwnApp, setIsOwnApp }), [isOwnApp]);

	return (
		<OwnAppContext.Provider value={ctx}>{children}</OwnAppContext.Provider>
	);
}

export function useOwnAppProvider(init) {
	const { isOwnApp, setIsOwnApp } = useContext(OwnAppContext);

	if (typeof init == 'boolean') {
		setIsOwnApp(init);
	}

	return [isOwnApp, setIsOwnApp];
}
