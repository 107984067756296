import React, { useEffect, useReducer, useState } from 'react';
import { Prompt } from 'react-router-dom';
import { useHistory } from 'react-router-dom';
import { cloneDeep } from 'lodash';

import {
	Align,
	ButtonWithIcon,
	Card,
	CenterStackWithToolbar,
	Stack,
} from '@zeal/zeal-ui';

import AppContainer from '../App/AppContainer';

import { useQueryString } from '../App/useQueryString';
import openToastr from '../App/Toastr/openToastr';
import ACLBoundary from '../App/ACL/ACLBoundary';
import { useAddItem, useAddItemPhoto } from './data/useMenuItem';
import ItemInfo from './components/ItemInfo';
import ItemGallery from './components/ItemGallery';
import ItemSections from './components/ItemSections';

export default function AddItem() {
	const [errMsgs, setErrMsgs] = useState({});
	const history = useHistory();
	const [{ categoryId, menuId, subcategoryId }] = useQueryString();

	const initialFormValues = {
		name: '',
		price: '',
		points: '',
		description: '',
		sections: [],
		sectionsChanged: false,
		photos: [],
	};

	const addEditFormReducer = (prevState, newState) => ({ ...prevState, ...newState });

	const [formState, formDispatch] = useReducer(
		addEditFormReducer,
		initialFormValues,
	);

	const {
		doAddItem,
		isError,
		error,
		data: addedItemData,
	} = useAddItem({
		onSuccess: () => {
			formDispatch({
				name: '',
				price: '',
				points: '',
				description: '',
				sections: [],
				sectionsChanged: false,
			});

			if (formState.photos.length > 0) {uploadPhotos(addedItemData?.data);}
			else {
				goToMenu(true);
				openToastr({
					message: 'Menu Item Added',
				});
			}
		},
		onError: (error) => {
			const clonedError = error?.response.clone();

			if (!clonedError?.bodyUsed) {
				clonedError.json().then((err) => {
					openToastr({
						message: err?.message || 'Failed to add Item',
						isError: true,
					});
				});
			}
		},
	});

	const { doAddItemPhoto, variables } = useAddItemPhoto({
		onSuccess: () => {
			const { isLastPhoto } = variables;
			if (isLastPhoto) {
				togglePhotosStatus('uploaded');
				formDispatch({ photos: [] });
				goToMenu(true);
				openToastr({
					message: 'Menu Item Added',
				});
			}
		},
		onError: () => {
			const { isLastPhoto } = variables;
			if (isLastPhoto) {
				togglePhotosStatus('uploaded');
				formDispatch({ photos: [] });
			}
		},
	});

	const uploadPhotos = (addedItemData) => {
		togglePhotosStatus('loading');

		for (let i = 0; i < formState.photos.length; i++) {
			const formData = new FormData();
			formData.append('file', formState.photos[i].file);
			formData.append('item', addedItemData?.item?.uuid);
			doAddItemPhoto({
				formData,
				isLastPhoto: i === formState.photos.length - 1,
			});
		}
	};

	const togglePhotosStatus = (status) => {
		const photos = cloneDeep(formState.photos);

		const newPhotos = photos.map((p) => {
			if (status === 'uploaded') {p.loading = false;}
			p[status] = !p[status];
			return p;
		});

		formDispatch({ photos: newPhotos });
	};

	useEffect(() => {
		if (!error?.response?.bodyUsed)
		{error?.response?.json().then((err) => {
			setErrMsgs(err?.errors);
		});}
	}, [error]);

	const isEmptyObject = (o) => Object.keys(o).some(function (x) {
		return o[x] === '' || o[x] === null;
	});

	const isFormValid = () => {
		const isAnyItemSectionEmpty = formState?.sections?.some(
			(s) => s?.attributes?.length === 0,
		);

		return !isEmptyObject(formState) && !isAnyItemSectionEmpty;
	};

	const submitAddItem = () => {
		if (isFormValid()) {
			doAddItem({
				category: subcategoryId ? subcategoryId : categoryId,
				menu: menuId,
				item: {
					...formState,
					loyalty_multiplier: formState.points,
					attributeGroups: formState.sections,
				},
			});
		}
	};

	const goToMenu = (refreshMenu = false) => {
		history.push({
			pathname: `/menu/${menuId}`,
			search: `?categoryId=${categoryId}&subcategoryId=${subcategoryId}&refreshMenu=${refreshMenu}`,
		});
	};

	const currentPath = `${window.location.pathname}${window.location.search}`;

	const addItemBreadCrumb = [
		{
			name: 'Menu',
			to: `/menu/${menuId}?categoryId=${categoryId}&subcategoryId=${subcategoryId}`,
			icon: 'utensils',
		},
		{
			name: 'Add',
			to: currentPath,
		},
	];

	const headerEndContent = (
		<Align align="right">
			<Stack row p="none" gap="md">
				<ButtonWithIcon
					label="Cancel"
					size="xs"
					m="none"
					onClick={() => goToMenu()}
				/>
				<ACLBoundary>
					<ButtonWithIcon
						primary
						label="Add Item"
						size="xs"
						m="none"
						onClick={submitAddItem}
						disabled={!isFormValid()}
						aclUnAuthProps={{ disabled: true }}
						aclGrants={['control:menu']}
					/>
				</ACLBoundary>
			</Stack>
		</Align>
	);

	return (
		<AppContainer ownApp protected breadcrumbs={addItemBreadCrumb}>
			<Prompt
				when={formState?.photos.length > 0}
				message="Are you sure you want to leave without saving?"
			/>

			<CenterStackWithToolbar
				toolbarStart={<span></span>}
				toolbarEnd={headerEndContent}
			>
				<Card p="none">
					<ItemInfo
						formDispatch={formDispatch}
						formState={formState}
						errMsgs={errMsgs}
						isError={isError}
					/>
					<hr />
					<ItemSections formDispatch={formDispatch} formState={formState} />
					<hr />
					<ItemGallery formDispatch={formDispatch} photos={formState.photos} />
				</Card>
			</CenterStackWithToolbar>
		</AppContainer>
	);
}
