import React, { useEffect, useState } from 'react';
import { cloneDeep } from 'lodash';

import {
	ButtonWithIcon,
	Floating,
	Icon,
	Image,
	InlineLoading,
	Input,
	Label,
	SizedBox,
	Stack,
	Title,
} from '@zeal/zeal-ui';

export default function ItemGallery(props) {
	const { formDispatch, deletedPhotosIds, photos } = props;

	const [newPhotos, setNewPhotos] = useState([]);

	const removeSelectedPhoto = (index) => {
		const oldPhotos = cloneDeep(photos);
		if (oldPhotos[index].uuid)
		{formDispatch({
			deletedPhotosIds: [...deletedPhotosIds, oldPhotos[index].uuid],
		});}

		oldPhotos.splice(index, 1);
		formDispatch({ photos: oldPhotos });
	};

	const appendPhotos = () => {
		formDispatch({ photos: [...photos, ...newPhotos] });
		setNewPhotos([]);
	};

	useEffect(() => {
		if (newPhotos.length > 0) {appendPhotos();}
	}, [newPhotos]);

	const handleFilesUpload = (files) => {
		const selectedFiles = files.map((file) => ({
			file,
			url: URL.createObjectURL(file),
			loading: false,
			uploaded: false,
		}));

		setNewPhotos(selectedFiles);
	};

	const renderUploadedPhotos = photos?.length > 0 && (
		<ItemImages photos={photos} removeSelectedPhoto={removeSelectedPhoto} />
	);

	return (
		<Stack m="md" p="none">
			<Stack p="none">
				<Title title="Item Photos" size="md" />
			</Stack>
			<Stack>
				<Input.Upload onDropFiles={handleFilesUpload} acceptedFiles="image/*" />
				{renderUploadedPhotos}
			</Stack>
		</Stack>
	);
}

function ItemImages(props) {
	const { photos, removeSelectedPhoto } = props;

	return (
		<Stack m="lg" mb="none" p="none" marginX={false}>
			<Stack row wrap gap="xl">
				{React.Children.toArray(
					photos?.map((photo, index) => (
						<Stack m="md" marginX={false} p="none">
							<SizedBox height="lg" width="lg">
								<Floating
									outerFloat
									floatingComponent={
										<ButtonWithIcon
											transparent
											p="none"
											onClick={() => {
												removeSelectedPhoto(index);
											}}
										>
											<Icon bg="danger" rounded size="md" p="xs" name="cross" />
										</ButtonWithIcon>
									}
									xPosition="right"
									yPosition="top"
								>
									<Image
										cover
										rounded
										height="sm"
										width="md"
										src={photo.url}
										alt="gallery photo"
									/>
								</Floating>
							</SizedBox>
							<Stack>{renderUploadingLabel(photo)}</Stack>
						</Stack>
					)),
				)}
			</Stack>
		</Stack>
	);
}

function renderUploadingLabel(photo) {
	if (photo.loading) {return <InlineLoading label="Uploading" size="xs" />;}
	if (photo.uploaded) {return <Label textColor="primary">Uploaded</Label>;}
	return '';
}
