import React from 'react';

import { ApiRequest } from '@zeal/zeal-lib';
import { useQuery } from '../../App/QueryClient';
import { useQueryString } from '../../App/useQueryString';

async function apiOverview({ queryKey: [, filters] }) {
	return new ApiRequest('v3/feedbacks/overview')
		.addHeader('Accept', 'application/json')
		.addQuery('from', filters?.date?.[0])
		.addQuery('to', filters?.date?.[1])
		.GET();
}

export default function useOverview() {
	const [queryState] = useQueryString();

	const q = useQuery({
		queryFn: apiOverview,
		staleTime: 1000 * 60 * 30, // 30 min
		queryKey: [
			'z-feedback-overview',
			{
				date: queryState?.date,
			},
		],
	});

	React.useDebugValue(q);

	return q;
}
