import React, { useEffect, useReducer, useState } from 'react';

import {
	Align,
	Button,
	ButtonWithIcon,
	Card,
	Center,
	CenterStackWithToolbar,
	Grid,
	Icon,
	InlineLoading,
	Input,
	Modal,
	SizedBox,
	Stack,
	Title,
	TwoSideLayout,
} from '@zeal/zeal-ui';

import { useHistory, useParams } from 'react-router-dom';
import { NavLink } from 'react-router-dom';
import AppContainer from '../App/AppContainer';

import { useQueryString } from '../App/useQueryString';
import ACLBoundary from '../App/ACL/ACLBoundary';
import CategoryCard from './components/CategoryCard';
import { useMenu } from './data/useBranchesMenu';
import { useAddCategory, useDeleteCategory } from './data/useMenuCategory';
import { useDeleteItem } from './data/useMenuItem';
import MenuItemsTable from './components/ItemsTable';

export default function Menu(props) {
	const initialStats = {
		addCategoryModal: false,
		addSubcategoryModal: false,
		deleteCategoryModal: false,
		deleteSubcategoryModal: false,
		deleteItemModal: false,
		selectedCategory: null,
		selectedSubcategory: null,
		selectedItem: null,
	};

	const [{ categoryId, subcategoryId, refreshMenu }, updateQuery]
		= useQueryString();

	const menuReducer = (prevState, newState) => ({ ...prevState, ...newState });

	const [menuState, menuDispatch] = useReducer(menuReducer, initialStats);

	const history = useHistory();

	const { menuId } = useParams();

	const menuQuery = useMenu({ menuId });

	const { data: menuData, isLoading } = menuQuery;

	useEffect(() => {
		if (refreshMenu == 'true') {
			menuQuery.refetch();
		}
	}, [refreshMenu]);

	React.useEffect(() => { }, []);

	React.useEffect(() => {
		if (menuState?.selectedCategory) {
			if (subcategoryId) {
				const selectedSubcategory = menuState?.selectedCategory?.children?.find(
					(sub) => sub?.uuid === subcategoryId,
				);

				menuDispatch({
					selectedSubcategory,
				});
			} else {
				menuDispatch({
					selectedSubcategory: menuState?.selectedCategory?.children?.[0],
				});
			}

			updateQuery({
				categoryId: menuState?.selectedCategory?.uuid,
			});
		}
	}, [menuState?.selectedCategory]);

	React.useEffect(() => {
		if (menuState?.selectedSubcategory) {
			updateQuery({
				subcategoryId: menuState?.selectedSubcategory?.uuid,
			});
		}
	}, [menuState?.selectedSubcategory]);

	const categoriesData = menuData?.categories?.filter((cat) => !cat.parent_id);

	const ItemsData = [
		...(menuState?.selectedCategory?.items || []),
		...(menuState?.selectedSubcategory?.items || []),
	];

	useEffect(() => {
		if (categoryId) {
			const selectedCategory = menuData?.categories?.find(
				(cat) => cat?.uuid === categoryId,
			);

			menuDispatch({
				selectedCategory,
			});
		} else {
			menuDispatch({ selectedCategory: categoriesData?.[0] });
		}
	}, [menuData]);

	const currentPath = `${window.location.pathname}${window.location.search}`;

	const breadcrumbs = [
		{
			name: 'Menu',
			to: '/menu',
			icon: 'utensils',
		},
		{
			name: menuData?.name,
			to: currentPath,
		},
	];

	const { doAddCategory } = useAddCategory({
		onSuccess() {
			menuDispatch({
				addCategoryModal: false,
				addSubcategoryModal: false,
				selectedSubcategory: null,
			});
			menuQuery.refetch();
		},
	});

	const { doDeleteCategory } = useDeleteCategory({
		onSuccess() {
			menuDispatch({
				deleteCategoryModal: false,
				deleteSubcategoryModal: false,
				selectedSubcategory: null,
			});
			menuQuery.refetch();
		},
	});

	const { doDeleteItem } = useDeleteItem({
		onSuccess() {
			menuDispatch({
				selectedItem: null,
				deleteItemModal: false,
			});
			menuQuery.refetch();
		},
	});

	const showEmptyState = categoriesData?.length === 0 && <EmptyCategories />;

	const submitCategoryAdd = (name) => {
		doAddCategory({
			name,
			menu: menuId,
		});
	};

	const submitCategoryDelete = (uuid) => {
		doDeleteCategory({
			uuid,
		});
	};

	const submitItemDelete = (uuid) => {
		doDeleteItem({
			uuid,
		});
	};

	const submitSubcategoryAdd = (name) => {
		doAddCategory({
			name,
			menu: menuId,
			parent: menuState?.selectedCategory?.uuid,
		});
	};

	const handleDeleteCategoryClick = (cat) => {
		menuDispatch({ deleteCategoryModal: true, selectedCategory: cat });
	};

	const handleDeleteSubcategoryClick = (sub) => {
		menuDispatch({ deleteSubcategoryModal: true, selectedSubcategory: sub });
	};

	const subcategoriesSection = menuState?.selectedCategory && (
		<div>
			<Stack m="md" p="xs">
				<SubcategoriesCards
					subcategories={menuState?.selectedCategory?.children || []}
					handleSubcategorySelect={(sub) =>
						menuDispatch({ selectedSubcategory: sub })
					}
					selectedSubcategory={menuState?.selectedSubcategory}
					selectedCategory={menuState?.selectedCategory}
					handleDeleteSubcategory={handleDeleteSubcategoryClick}
					menuId={menuId}
				/>
				<AddSubcategoryButton
					subcategories={menuState?.selectedCategory?.children || []}
					onClick={() => menuDispatch({ addSubcategoryModal: true })}
				/>
			</Stack>
			<Stack mx="none" my="md" p="none">
				<hr />
			</Stack>
		</div>
	);

	const handleItemDeleteClick = (selectedItem) => {
		menuDispatch({ deleteItemModal: true, selectedItem });
	};

	const handleItemEditClick = (selectedItem) => {
		menuDispatch({ selectedItem });

		history.push({
			pathname: '/menu/items/edit',
			search: `?itemId=${selectedItem?.uuid}&categoryId=${categoryId}&subcategoryId=${subcategoryId}&menuId=${menuId}`,
			state: { selectedItem },
		});
	};

	const menuItemsTable = menuState?.selectedCategory && (
		<MenuItemsTable
			items={ItemsData}
			handleRowDelete={handleItemDeleteClick}
			handleRowEdit={handleItemEditClick}
		/>
	);

	const categoriesCards = isLoading ? (
		<Center>
			<Stack m="md">
				<InlineLoading />
			</Stack>
		</Center>
	) : (
		<CategoriesCards
			categories={categoriesData}
			handleCategorySelect={(cat) =>
				menuDispatch({
					selectedCategory: cat,
					selectedSubcategory: null,
				})
			}
			selectedCategory={menuState?.selectedCategory}
			handleDeleteCategory={handleDeleteCategoryClick}
		/>
	);

	return (
		<AppContainer ownApp protected breadcrumbs={breadcrumbs}>
			<CenterStackWithToolbar
				toolbarStart={<span></span>}
				toolbarEnd={
					<MenuHeader
						selectedCategory={menuState?.selectedCategory}
						addCategoryClick={() => menuDispatch({ addCategoryModal: true })}
						menuId={menuId}
					/>
				}
			>
				<Card p="none">
					<Stack m="md" p="xs">
						{categoriesCards}
						{showEmptyState}
						<AddCategoryButton
							onClick={() => menuDispatch({ addCategoryModal: true })}
						/>
					</Stack>
					<Stack mx="none" my="md" p="none">
						<hr />
					</Stack>
					{subcategoriesSection}
					{menuItemsTable}
				</Card>
				<AddCategoryModal
					isModalOpen={menuState?.addCategoryModal}
					closeModal={() => menuDispatch({ addCategoryModal: false })}
					handleSubmit={submitCategoryAdd}
				/>

				<AddSubcategoryModal
					isModalOpen={menuState?.addSubcategoryModal}
					closeModal={() => menuDispatch({ addSubcategoryModal: false })}
					handleSubmit={submitSubcategoryAdd}
				/>

				<DeleteModal
					title="Category"
					isModalOpen={menuState?.deleteCategoryModal}
					closeModal={() => menuDispatch({ deleteCategoryModal: false })}
					handleSubmit={() =>
						submitCategoryDelete(menuState?.selectedCategory?.uuid)
					}
				/>
				<DeleteModal
					title="Subcategory"
					isModalOpen={menuState?.deleteSubcategoryModal}
					closeModal={() => menuDispatch({ deleteSubcategoryModal: false })}
					handleSubmit={() =>
						submitCategoryDelete(menuState?.selectedSubcategory?.uuid)
					}
				/>
				<DeleteModal
					title="Item"
					isModalOpen={menuState?.deleteItemModal}
					closeModal={() => menuDispatch({ deleteItemModal: false })}
					handleSubmit={() => submitItemDelete(menuState?.selectedItem?.uuid)}
				/>
			</CenterStackWithToolbar>
		</AppContainer>
	);
}

function CategoriesCards(props) {
	const {
		categories,
		handleCategorySelect,
		selectedCategory,
		handleDeleteCategory,
	} = props;

	const isCategoryActive = (cat) => cat?.uuid === selectedCategory?.uuid;

	const getCategoryDropOptions = () => [
		<ButtonWithIcon
			transparent
			stretch
			m="none"
			p="none"
			onClick={handleDeleteCategory}
		>
			Delete
		</ButtonWithIcon>,
	];

	return (
		categories?.length > 0 && (
			<div>
				<Title title="Categories" size="sm" margin="none" />
				<Stack row wrap my="md" gap="xl">
					{React.Children.toArray(
						categories?.map((cat) => (
							<div onClick={() => handleCategorySelect(cat)}>
								<CategoryCard
									active={isCategoryActive(cat)}
									data={{ ...cat, title: cat?.name }}
									categoryDropOptions={getCategoryDropOptions(cat)}
								/>
							</div>
						)),
					)}
				</Stack>
			</div>
		)
	);
}

function SubcategoriesCards(props) {
	const {
		subcategories,
		handleSubcategorySelect,
		selectedSubcategory,
		selectedCategory,
		handleDeleteSubcategory,
		menuId,
	} = props;

	const isCategoryActive = (cat) => cat?.uuid === selectedSubcategory?.uuid;

	const getCategoryDropOptions = () => [
		<ButtonWithIcon
			transparent
			stretch
			m="none"
			p="none"
			onClick={handleDeleteSubcategory}
		>
			Delete
		</ButtonWithIcon>,
	];

	return (
		subcategories?.length > 0 && (
			<div>
				<Stack m="md" p="none" marginX={false} mt="none">
					<TwoSideLayout>
						<Title title="Sub-Categories" size="sm" />
						<AddItemButton
							to={{
								pathname: '/menu/items/add',
								search: `?categoryId=${selectedCategory?.uuid}&subcategoryId=${selectedSubcategory?.uuid}&menuId=${menuId}`,
							}}
							isHidden={!selectedSubcategory}
						/>
					</TwoSideLayout>
				</Stack>
				<Stack row wrap my="md" gap="xl" p="none">
					{React.Children.toArray(
						subcategories?.map((cat) => (
							<div onClick={() => handleSubcategorySelect(cat)}>
								<CategoryCard
									active={isCategoryActive(cat)}
									data={{ ...cat, title: cat?.name }}
									categoryDropOptions={getCategoryDropOptions()}
								/>
							</div>
						)),
					)}
				</Stack>
			</div>
		)
	);
}

function EmptyCategories() {
	return (
		<Title
			size="sm"
			title="Start by adding some categories to your menu"
			subtitle="use categories to customize your menu and filter the items based  on it's type"
			margin="md"
		/>
	);
}

function AddCategoryButton(props) {
	const { onClick } = props;
	return (
		<SizedBox width="60">
			<ACLBoundary
				aclUnAuthProps={{ disabled: true }}
				aclGrants={['control:menu']}
			>
				<Button stretch p="sm" bg="lightGray" onClick={onClick}>
					<Align gap="1">
						<Icon rounded name="plus" bg="black" size="md" p="xxs" />
						<p>Add Category</p>
					</Align>
				</Button>
			</ACLBoundary>
		</SizedBox>
	);
}

function AddSubcategoryButton(props) {
	const { subcategories, onClick } = props;

	return subcategories?.length > 0 ? (
		<SizedBox width="60">
			<ACLBoundary
				aclUnAuthProps={{ disabled: true }}
				aclGrants={['control:menu']}
			>
				<Button stretch p="sm" bg="lightGray" onClick={onClick}>
					<Align gap="1">
						<Icon rounded name="plus" bg="black" size="md" p="xxs" />
						<p>Add Sub-Category</p>
					</Align>
				</Button>
			</ACLBoundary>
		</SizedBox>
	) : (
		<ACLBoundary>
			<Button
				transparent
				p="none"
				onClick={onClick}
				aclUnAuthProps={{ disabled: true }}
				aclGrants={['control:menu']}
			>
				<Stack row gap="sm">
					<Center horizontal={false}>
						<Title
							primary
							title={<Icon name="plusCircle" size="xs" m="none" />}
							size="xs"
						/>
						<Title primary title="Add a sub-category" size="xs" />
					</Center>
				</Stack>
			</Button>
		</ACLBoundary>
	);
}

function AddItemButton(props) {
	const { to, isHidden } = props;
	const history = useHistory();

	return (
		!isHidden && (
			<ACLBoundary>
				<ButtonWithIcon
					primary
					label="Add Item"
					btnIcon="plus"
					size="xs"
					m="none"
					onClick={() => {
						history.push(to);
					}}
					aclUnAuthProps={{ disabled: true }}
					aclGrants={['control:menu']}
				/>
			</ACLBoundary>
		)
	);
}

function MenuHeader(props) {
	const { addCategoryClick, selectedCategory, menuId } = props;

	return (
		<Align align="right">
			<Stack row wrap p="none" gap="md">
				<AddItemButton
					isHidden={!selectedCategory}
					to={{
						pathname: '/menu/items/add',
						search: `?categoryId=${selectedCategory?.uuid}&menuId=${menuId}`,
					}}
				/>
				<ACLBoundary>
					<ButtonWithIcon
						primary
						label="Add Category"
						btnIcon="plus"
						size="xs"
						m="none"
						onClick={addCategoryClick}
						aclUnAuthProps={{ disabled: true }}
						aclGrants={['control:menu']}
					/>
				</ACLBoundary>
			</Stack>
		</Align>
	);
}

function AddCategoryModal(props) {
	const { isModalOpen, closeModal, handleSubmit } = props;

	const [categoryName, setCategoryName] = useState();

	return (
		<Modal isModalOpen={isModalOpen} title="Add Category" onClose={closeModal}>
			<Stack p="none">
				<Title title="Category Name" margin="xs" />
				<Input
					type="text"
					inputName="categoryName"
					placeholder="Enter Name"
					onChange={(evt) => setCategoryName(evt.target.value)}
					value={categoryName}
				/>

				<Grid gap="5">
					<span></span>
					<TwoSideLayout>
						<ButtonWithIcon
							stretch={false}
							size="md"
							m="xs"
							px="sm"
							py="none"
							onClick={closeModal}
						>
							Cancel
						</ButtonWithIcon>
						<ButtonWithIcon
							stretch={false}
							size="md"
							primary
							m="xs"
							px="sm"
							py="none"
							onClick={() => handleSubmit(categoryName)}
						>
							Add Category
						</ButtonWithIcon>
					</TwoSideLayout>
				</Grid>
			</Stack>
		</Modal>
	);
}

function DeleteModal(props) {
	const { isModalOpen, closeModal, handleSubmit, title } = props;

	return (
		<Modal
			isModalOpen={isModalOpen}
			title={`Delete ${title}`}
			onClose={closeModal}
		>
			<Stack p="none">
				<Title title={`Are you sure you want to delete this ${title}?`} />

				<Grid gap="5">
					<span></span>
					<TwoSideLayout>
						<Button stretch={false} size="md" m="xs" onClick={closeModal}>
							Cancel
						</Button>
						<ButtonWithIcon
							secondary
							stretch={false}
							size="md"
							m="xs"
							onClick={() => handleSubmit()}
						>
							Delete
						</ButtonWithIcon>
					</TwoSideLayout>
				</Grid>
			</Stack>
		</Modal>
	);
}

function AddSubcategoryModal(props) {
	const { isModalOpen, closeModal, handleSubmit } = props;

	const [categoryName, setCategoryName] = useState();

	return (
		<Modal
			isModalOpen={isModalOpen}
			title="Add Subcategory"
			onClose={closeModal}
		>
			<Stack p="none">
				<Title title="Subcategory Name" margin="xs" />
				<Input
					type="text"
					inputName="subcategoryName"
					placeholder="Enter Name"
					onChange={(evt) => setCategoryName(evt.target.value)}
					value={categoryName}
				/>

				<Grid gap="5">
					<span></span>
					<TwoSideLayout>
						<Button stretch={false} size="md" m="xs" onClick={closeModal}>
							Cancel
						</Button>
						<ButtonWithIcon
							primary
							stretch={false}
							size="md"
							m="xs"
							onClick={() => handleSubmit(categoryName)}
						>
							Add Subcategory
						</ButtonWithIcon>
					</TwoSideLayout>
				</Grid>
			</Stack>
		</Modal>
	);
}
