import {
	Badge,
	BasicTable,
	ButtonWithIcon,
	Card,
	Center,
	Icon,
	InlineEmpty,
	InlineLoading,
	Input,
	Label,
	Pagination,
	Select,
	SizedBox,
	Stack,
	Title,
} from '@zeal/zeal-ui';
import { isArray, isNil, mapValues, omitBy } from 'lodash';

import React, { useEffect, useReducer } from 'react';
import { NavLink } from 'react-router-dom';

import AppContainer from '../App/AppContainer';
import { useQueryString } from '../App/useQueryString';
import { useBranches } from './data/useBranches';

import useOrders from './data/useOrders';
import useTechnicians from './data/useTechnicians';

export default function Orders() {
	const [{ page, refreshOrders }, updateQuery] = useQueryString();
	const ordersQuery = useOrders();

	const { data: ordersData, pagesList, isLoading } = ordersQuery;

	useEffect(() => {
		updateQuery({ page: page || 1 });
	}, []);

	useEffect(() => {
		if (refreshOrders) {
			ordersQuery.refetch();
		}
	}, [refreshOrders]);

	const handleOnPageChange = ({ num }) => {
		updateQuery({ page: num });
	};

	const breadcrumbs = [{ name: 'Orders', to: '/orders', icon: 'receipt' }];

	return (
		<AppContainer ownApp protected breadcrumbs={breadcrumbs}>
			<Card p="none" m="md">
				<FiltersRow data={ordersData?.data || []} isLoading={isLoading} />
				<EmptyState
					data={ordersData?.data || []}
					isLoading={isLoading}
				></EmptyState>
				<OrdersTable
					data={ordersData?.data || []}
					isLoading={isLoading}
					pagesList={pagesList}
					page={page}
					handleOnPageChange={handleOnPageChange}
				></OrdersTable>
			</Card>
		</AppContainer>
	);
}

function FiltersRow(props) {
	const [queryState, updateQuery] = useQueryString();

	const { page, dateTo, dateFrom, branch, assigned, status } = queryState;

	const filtersInitialValues = {
		dateFrom: null,
		dateTo: null,
		branch: null,
		assigned: null,
		status: null,
	};

	const { data: branchesData } = useBranches({
		formatBranch(data) {
			return {
				value: data?.uuid,
				label: data?.name,
			};
		},
	});

	const { data: techniciansData } = useTechnicians({
		formatTechnicians(data) {
			return {
				value: data?.uuid,
				label: data?.name,
			};
		},
	});

	const statusOptions = [
		{ value: 'placed', label: 'Pending' },
		{ value: 'accepted', label: 'Accepted' },
		{ value: 'rejected', label: 'Rejected' },
		{ value: 'cancelled', label: 'Cancelled' },
		{ value: 'finished', label: 'Finished' },
	];

	useEffect(() => {
		const selectedBranch = branchesData?.find((b) => b?.value === branch);

		const selectedStatus = statusOptions?.find((s) => s.value === status);

		const selectedAssignee = techniciansData?.find((s) => s.value === assigned);

		const selectedFromDate = new Date(dateFrom);

		const selectedToDate = new Date(dateTo);

		filtersDispatch({
			branch: selectedBranch,
			status: selectedStatus,
			assigned: selectedAssignee,
			dateFrom: {
				value: isValidDate(selectedFromDate) ? selectedFromDate : null,
			},
			dateTo: { value: isValidDate(selectedToDate) ? selectedToDate : null },
		});
	}, [queryState, branchesData, techniciansData]);

	const filtersReducer = (prevState, newState) => ({
		...prevState,
		...newState,
	});

	const [filterState, filtersDispatch] = useReducer(
		filtersReducer,
		filtersInitialValues
	);

	const changeFilter = (type, value) => {
		filtersDispatch({ [type]: value });
	};

	const changeDateFilter = (dates) => {
		if (isArray(dates)) {
			const [dateFrom, dateTo] = dates;
			filtersDispatch({
				dateFrom: { value: dateFrom },
				dateTo: { value: dateTo },
			});
		}
	};

	const onApplyFilters = () => {
		const { dateFrom, dateTo } = filterState;

		if (
			(!dateFrom?.value && !dateTo?.value) ||
			(!!dateFrom?.value && !!dateTo?.value)
		) {
			updateFiltersQueries();
		}
	};

	const updateFiltersQueries = () => {
		const mappedFilters = mapValues(
			filterState,
			(filterValue, filterKey) => filterValue?.value
		);

		// remove non applied filters
		const filterQueries = omitBy(mappedFilters, isNil);

		const dateQueries = {
			...(filterState?.dateFrom?.value && {
				dateFrom: formatDate(filterState?.dateFrom?.value),
			}),
			...(filterState?.dateTo?.value && {
				dateTo: formatDate(filterState?.dateTo?.value),
			}),
		};

		updateQuery(
			{
				...filterQueries,
				...dateQueries,
				page,
			},
			{ noMerge: true }
		);
	};

	const isAnyFilterApplied = dateFrom || dateTo || assigned || status || branch;

	const formatDate = (date) => {
		if (date) {
			return new Intl.DateTimeFormat('en-CA').format(date);
		}

		return null;
	};

	const isAnyFilterChanged = () => {
		for (const key in filterState) {
			if (['dateFrom', 'dateTo'].includes(key)) {
				if (formatDate(filterState[key]?.value) != queryState[key]) {
					return true;
				}
			} else {
				if (filterState[key]?.value != queryState[key]) {
					return true;
				}
			}
		}
		return false;
	};

	const clearFilters = () => {
		filtersDispatch(filtersInitialValues);
		updateQuery({ page: 1 }, { noMerge: true });
	};

	const filtersButton =
		isAnyFilterApplied && !isAnyFilterChanged() ? (
			<ButtonWithIcon secondary m="none" size="md" onClick={clearFilters}>
				Clear Filters
			</ButtonWithIcon>
		) : (
			<ButtonWithIcon primary m="none" size="md" onClick={onApplyFilters}>
				Apply Filters
			</ButtonWithIcon>
		);

	const datePickerStartIcon =
		filterState.dateFrom?.value && filterState.dateTo?.value ? '' : 'calendar';

	const FiltersRow = (
		<Stack wrap row gap="xl" m="md">
			<SizedBox width="2xl">
				<Input.Date.Range
					customPlaceholder
					endIcon=""
					startIcon={datePickerStartIcon}
					iconColor="gray"
					transparent
					placeholder="Date: All Time"
					startDate={filterState.dateFrom?.value}
					endDate={filterState.dateTo?.value}
					onChange={changeDateFilter}
				/>
			</SizedBox>

			<FilterDropdown
				filterState={filterState}
				filterType="branch"
				changeFilter={changeFilter}
				options={branchesData}
				icon="store"
				label="Branch: All"
			/>
			<FilterDropdown
				filterState={filterState}
				filterType="assigned"
				changeFilter={changeFilter}
				options={techniciansData}
				icon="idCardSolid"
				label="Assigned: All"
			/>
			<FilterDropdown
				filterState={filterState}
				filterType="status"
				changeFilter={changeFilter}
				options={statusOptions}
				icon="flag"
				label="State: All"
			/>

			<SizedBox width="xl">{filtersButton}</SizedBox>
		</Stack>
	);
	return FiltersRow;
}

function OrdersTable(props) {
	const { data, pagesList, page, handleOnPageChange, isLoading } = props;
	const tableColumns = [
		{ Header: 'Creation Date', accessor: 'created_at', Cell: renderDateCell },
		{
			Header: 'Paid',
			accessor: 'total_price',
			Cell: renderPaidCell,
		},
		{
			Header: 'Customer Name',
			accessor: 'member.name',
		},
		{
			Header: 'Phone Number',
			accessor: 'phone_number',
		},
		{
			Header: 'Branch',
			accessor: 'branch.name',
		},
		{
			Header: 'Assigned To',
			accessor: 'assignee.name',
		},
		{
			Header: 'Status',
			accessor: 'status',
			Cell: renderStateCell,
		},
		{ Header: '', accessor: 'actions', Cell: renderActionCell },
	];

	const showPagination = pagesList?.length > 1 && data?.length > 0 && (
		<Pagination
			pages={pagesList}
			current={page}
			onPageChange={handleOnPageChange}
		></Pagination>
	);

	const ordersTable = (
		<div>
			<BasicTable
				shadow={false}
				border
				data={data || []}
				columns={tableColumns}
				headerPadding="lg"
				cellPadding="lg"
				isLoading={isLoading}
				isEmpty={!data?.length}
				loadingContent={
					<InlineLoading className="w-full py-10 !justify-center" />
				}
				emptyContent={<InlineEmpty className="w-full py-10 !justify-center" />}
			/>
			{showPagination}
		</div>
	);

	return ordersTable;
}

function renderActionCell({ row }) {
	return (
		<NavLink to={`orders/${row?.original?.uuid}`}>
			<ButtonWithIcon secondary stretch={false} m="none" size="sm" rounded="lg">
				Actions
			</ButtonWithIcon>
		</NavLink>
	);
}

function renderPaidCell({ value }) {
	return new Intl.NumberFormat('en', {
		style: 'currency',
		currency: 'EGP',
		maximumFractionDigits: 2,
	}).format(value || 0);
}

function renderDateCell({ value }) {
	const date = new Date(value);
	return date.toLocaleString();
}

function renderStateCell({ value }) {
	const statusLabels = {
		placed: { label: 'Pending', color: 'yellow' },
		accepted: { label: 'Accepted', color: 'green' },
		cancelled: { label: 'Cancelled', color: 'gray' },
		rejected: { label: 'Rejected', color: 'danger' },
		finished: { label: 'Finished', color: 'black' },
	};

	const label = statusLabels[value.toLowerCase()];

	return (
		<Badge bg={label?.color} size="xs" p="sm" rounded="lg">
			{label?.label}
		</Badge>
	);
}

function EmptyState(props) {
	const emptyState = !props?.data?.length > 0 && !props.isLoading && (
		<Stack m="lg">
			<Center>
				<Title
					title="No Orders Here Yet"
					size="md"
					subtitle="Once orders are added will be shown here"
				/>
			</Center>
		</Stack>
	);
	return emptyState;
}

/**
 * Renders Filters Placeholders
 * @param {{state: string, icon: string,label:string}} props
 * @returns { JSX.Element | string} Placeholder
 */
function RenderPlaceholder({ state, icon, label }) {
	const placeholderContent = !state ? (
		<Center gap="1" horizontal={false}>
			<Icon name={icon} size="sm" margin="none" />
			<Label size="xs">{label}</Label>
		</Center>
	) : (
		''
	);

	return placeholderContent;
}

function FilterDropdown(props) {
	const { filterType, filterState, changeFilter, options, icon, label } = props;

	const optionsWithDefaultState = [{ value: null, label: 'All' }, ...options];

	const selectedOption = filterState?.[filterType]?.value
		? filterState?.[filterType]
		: null;

	return (
		<SizedBox width="xl">
			<Select
				stretch
				margin="none"
				selected={selectedOption}
				placeholder={RenderPlaceholder({
					state: filterState?.[filterType]?.value,
					icon,
					label,
				})}
				onChange={(item) => changeFilter(filterType, item)}
				options={optionsWithDefaultState}
			/>
		</SizedBox>
	);
}

function isValidDate(d) {
	return d instanceof Date && !isNaN(d);
}
