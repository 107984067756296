import { ApiRequest } from '@zeal/zeal-lib';

import { getLoggedInSession } from './App/ACL/useLogin';
import {
	accountInfoManger,
	refetchUserPermissionsEvent,
} from './App/BusinessAccount/useBusinessInfo';

const API_HOST = process.env.REACT_APP_VENDOR_API_HOST;
// const API_BASE = process.env.REACT_APP_VENDOR_API_BASE;

localStorage.setItem('dev/allow', process.env.ALLOW_DEV_TOOLS);

function attachAppIdOnRequest(error, apiRequest, response) {
	if (!error && !response) {
		const { appId } = accountInfoManger();
		apiRequest.addHeader('AppId', appId);
		apiRequest.addHeader('App-Id', appId);
	}
}

function attachAuthHeaderOnRequest(error, apiRequest, response, { config }) {
	if (!error && !response && !config.noAuth) {
		const { authorization } = getLoggedInSession();
		apiRequest.addHeader('Authorization', authorization);
	}
}

function logoutOn401Middleware(error) {
	if (error) {
		const statusCode = error?.response?.status;

		if (statusCode === 401) {
			//delete Token, then redirect to login page
			localStorage.clear();
			window.location.href = '/login';
		}
	}
}

function refreshGrantsOn403Middleware(error) {
	if (error) {
		const statusCode = error?.response?.status;

		if (statusCode === 403) {
			refetchUserPermissionsEvent().emit();
		}
	}
}

ApiRequest.setURL(API_HOST, 'api/');
ApiRequest.use(attachAppIdOnRequest);
ApiRequest.use(attachAuthHeaderOnRequest);
ApiRequest.use(logoutOn401Middleware);
ApiRequest.use(refreshGrantsOn403Middleware);
