import React, { useReducer, useState } from 'react';

import {
	ButtonWithIcon,
	Center,
	InlineError,
	InlineLoading,
	Stack,
	Title,
} from '@zeal/zeal-ui';

import useMultiplePunches from '../data/useMultiplePunches';

export default function EmptyState({ title }) {
	const overview = useMultiplePunches();
	return (
		<Center>
			<Stack m="md">
				<Title
					className={overview.isSuccess ? '' : 'hidden'}
					subtitle={`No  ${title} Yet!`}
					size="md"
				/>
				<Center className="mt-8">
					<InlineError hide={!overview.isError} />
					<InlineLoading hide={!overview.isLoading} />
				</Center>
			</Stack>
		</Center>
	);
}
