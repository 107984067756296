import React from 'react';
import { useParams } from 'react-router-dom';

import { DateTime } from 'luxon';

import {
	Align,
	Avatar,
	BasicTable,
	Button,
	ButtonWithIcon,
	Card,
	CardStat,
	Center,
	Grid,
	GridColsDivided,
	InlineError,
	InlineLoading,
	Input,
	MainStackWithAside,
	Modal,
	Select,
	Stack,
	TabsHorizontal,
	Textarea,
	Title,
	TwoSideLayout,
} from '@zeal/zeal-ui';
import openToastr from '../App/Toastr/openToastr';
import AppContainer from '../App/AppContainer';

import ACLBoundary from '../App/ACL/ACLBoundary';
import useSubmitVisits from './data/useVisits';

import useMembers, { useChangeBalance, useGivePoints } from './data/useMembers';
import useVoucherss from './data/useTransactions';
export default function Members(props) {
	const [rewardNameInput, setRewardNameInput] = React.useState();
	const [visitsData, setVisitsData] = React.useState();
	const [isAddRewardModalOpen, setIsAddRewardModalOpen] = React.useState(false);

	const { memberId } = useParams();

	const memberQuery = useMembers();
	const { data: membersData } = memberQuery;
	const redeemsOverview = useMembers();
	const { data: redeemData } = redeemsOverview;

	const vouchersOverview = useVoucherss();
	const { data: vouchersData } = vouchersOverview;

	const { getVisitsList, data, isError, isLoading, isSuccess, status }
		= useSubmitVisits({
			onSuccess: () => {
				setVisitsData(data);
			},
		});

	const memberPointColumns = [
		{
			Header: 'Reward Name',
			accessor: 'reward',
			Cell: handleNoneData,
		},
		{
			Header: 'Branch',
			accessor: 'branch_name',
			Cell: handleNoneData,
		},
		{
			Header: 'Redeem Time',
			accessor: 'redeem_time',
			Cell: renderRelativeDatetime,
		},
	];

	const memberVisitsColumns = [
		{
			Header: 'Branch Name',
			accessor: 'branch.name',
			Cell: handleNoneData,
		},
		{
			Header: 'Date',
			accessor: 'created_at',
			Cell: renderRelativeDatetime,
		},
		{
			Header: 'Receipt No.',
			accessor: 'receipt_number',
			Cell: handleNoneData,
		},
		{
			Header: 'Receipt value',
			accessor: 'receipt',
			Cell: handleNoneData,
		},
		{
			Header: 'Points Added',
			accessor: 'details.points',
			Cell: handleNoneData,
		},
		{
			Header: 'Team',
			accessor: 'team.name',
			Cell: handleNoneData,
		},
		{
			Header: 'Payment Type',
			accessor: 'payment_method',
			Cell: handleNoneData,
		},
		{
			Header: 'From Wallet',
			accessor: 'voucher_wallet_tranasction',
			Cell: handleNoneData,
		},
		{
			Header: 'Cash',
			accessor: 'cash_paid',
			Cell: handleNoneData,
		},
		{
			Header: 'Voucher',
			accessor: 'voucher_paid',
			Cell: handleNoneData,
		},
		{
			Header: 'Zeal Pay',
			accessor: 'card_paid',
			Cell: handleNoneData,
		},
	];

	const memberVouchersColumns = [
		{
			Header: 'Amount',
			accessor: 'amount',
			Cell: handleNoneData,
		},
		{
			Header: 'Process',
			accessor: 'process',
			Cell: handleNoneData,
		},
		{
			Header: 'Date',
			accessor: 'created_at',
			Cell: renderRelativeDatetime,
		},
		{
			Header: 'Created By',
			accessor: 'by_business',
			Cell: handleBoolData,
		},
	];
	const handleVisitsSubmit = React.useCallback(() => {
		let data = {
			filters: [
				{
					column: 'member_uuid',
					condition: '=',
					value: window.location.href.split('/')[4],
				},
			],
		};
		getVisitsList(data);
	}, [getVisitsList]);

	React.useEffect(() => {
		handleVisitsSubmit();
	}, []);

	const redeemsTable
		= (redeemData?.data?.user_activity?.redeems || []).length > 0 ? (
			<BasicTable
				data={redeemData?.data?.user_activity?.redeems || []}
				columns={memberPointColumns}
				headerPadding="md"
				cellPadding="lg"
			></BasicTable>
		) : (
			<Center className="contents">
				<Stack m="xl">
					<Title
						title={redeemsOverview.isSuccess && ' No Redeems Yet.'}
						size="md"
					/>
					<Center className="mt-8">
						<InlineError hide={!redeemsOverview.isError} />
						<InlineLoading hide={!redeemsOverview.isLoading} />
					</Center>
				</Stack>
			</Center>
		);
	const visitsTable
		= (visitsData?.data || []).length > 0 ? (
			<BasicTable
				data={visitsData?.data || []}
				columns={memberVisitsColumns}
				headerPadding="md"
				cellPadding="lg"
			></BasicTable>
		) : (
			<Center className="contents">
				<Stack m="xl">
					<Title title={isSuccess && 'No Visits Yet.'} size="md" />
					<Center className="mt-8">
						<InlineError hide={!isError} />
						<InlineLoading hide={!isLoading} />
					</Center>
				</Stack>
			</Center>
		);
	const voucherssTable
		= (vouchersData?.data || []).length > 0 ? (
			<BasicTable
				data={vouchersData?.data || []}
				columns={memberVouchersColumns}
				headerPadding="md"
				cellPadding="lg"
			></BasicTable>
		) : (
			<Center className="contents">
				<Stack m="xl">
					<Title
						title={vouchersOverview.isSuccess && ' No Vouchers Wallet Yet.'}
						size="md"
					/>
					<Center className="mt-8">
						<InlineError hide={!vouchersOverview.isError} />
						<InlineLoading hide={!vouchersOverview.isLoading} />
					</Center>
				</Stack>
			</Center>
		);
	const tabsData = [
		{
			id: '1',
			tabTitle: 'Visits',
			tabContent: visitsTable,
		},
		{ id: '2', tabTitle: 'redeems', tabContent: redeemsTable },
		{ id: '3', tabTitle: 'Voucherss', tabContent: voucherssTable },
	];

	const currentPath = `${window.location.pathname}${window.location.search}`;

	const breadcrumbs = [
		{ name: 'Customers', to: '/customers', icon: 'userShoppingAlt' },
		{ name: 'Member Profile', to: currentPath },
	];
	const tabContent = <TabsHorizontal data={tabsData} my="xl" />;

	function StatsCards() {
		const itemsCards = [
			{
				title: `${membersData?.data?.total_transactions} EGP`,
				label: 'Total Transactions value',
			},
			{
				title: `${membersData?.data?.total_visits}`,
				label: 'Total Visits',
			},

			{
				title: membersData?.data?.total_points,
				label: 'Total Points',
			},
			{
				title: `${membersData?.data?.total_redeems}`,
				label: 'Total Redeems',
			},
		];
		return itemsCards.map((d) => (
			<Stack m="md">
				<CardStat light title={d.title} label={d.label}>
					{memberQuery.isError || memberQuery.isLoading || d.title}

					<InlineError hide={!memberQuery.isError} />
					<InlineLoading hide={!memberQuery.isLoading} />
				</CardStat>
			</Stack>
		));
	}
	function CustomerInfo() {
		const itemsCards = [
			{
				title: 'Gender',
				label: membersData?.data.gender,
			},
			{
				title: 'Date of Birth',
				label: membersData?.data.date_of_birth,
			},

			{
				title: 'sign up date',
				label: renderRelativeSignup(membersData?.data.created_at),
			},
			{
				title: 'day\'s left for birthday',
				label: membersData?.data.birthday_in,
			},
			{
				title: 'phone number',
				label: membersData?.data.phone_number
					? membersData?.data.phone_number
					: '__',
			},
		];
		return itemsCards.map((d) => (
			<Stack m="sm">
				<Title
					titleText={d.title}
					subtitleText={memberQuery.isError || memberQuery.isLoading || d.label}
				/>
				<InlineError hide={!memberQuery.isError} />
				<InlineLoading hide={!memberQuery.isLoading} />
			</Stack>
		));
	}

	const { doGivePoints, isLoading: isGivingPointsLoading } = useGivePoints({
		onSuccess: () => {
			setRewardNameInput('');
			setIsAddRewardModalOpen(false);
			openToastr({ message: 'Points given successfully' });
			memberQuery.refetch();
		},
		onError: (error) => {
			const clonedError = error?.response.clone();

			if (!clonedError?.bodyUsed) {
				clonedError.json().then((err) => {
					openToastr({
						message: err?.message || 'Failed to give points',
						isError: true,
					});
				});
			}
		},
	});

	const { doChangeBalance, isLoading: isChangeBalanceLoading }
		= useChangeBalance({
			onSuccess: () => {
				const balanceActionType
					= rewardNameInput?.value === 2 ? 'Given' : 'Reduced';

				openToastr({ message: `Balance ${balanceActionType} successfully` });

				setRewardNameInput('');
				setIsAddRewardModalOpen(false);
				memberQuery.refetch();
			},
			onError: (error) => {
				const clonedError = error?.response.clone();

				if (!clonedError?.bodyUsed) {
					clonedError.json().then((err) => {
						openToastr({
							message: err?.message || 'Failed to change balance',
							isError: true,
						});
					});
				}
			},
		});

	const handleModalSubmit = (amount) => {
		switch (rewardNameInput?.value) {
		case 2:
			doGivePoints({ amount, members: [memberId] });
			break;
		case 3:
			doChangeBalance({ amount, member_uuid: memberId, isAdd: true });
			break;
		case 4:
			doChangeBalance({ amount, member_uuid: memberId, isAdd: false });
			break;
		default:
			break;
		}
	};

	const handleAddRewardBtnClick = (evt) => {
		setIsAddRewardModalOpen(true);
		setRewardNameInput(evt);
	};

	return (
		<AppContainer protected breadcrumbs={breadcrumbs}>
			<MainStackWithAside
				fullWidth="true"
				toolbarStart=""
				toolbarEnd={
					<Align align="right">
						<ACLBoundary>
							<Select
								px="lg"
								placeholder={rewardNameInput ? rewardNameInput : 'Actions'}
								selected={rewardNameInput}
								onChange={handleAddRewardBtnClick}
								// onChange={setCol }
								fitContent
								options={[
									// { value: 1, label: "Give Reward" },
									{ value: 2, label: 'Give Points' },
									{ value: 3, label: 'Give Balance' },
									{ value: 4, label: 'Reduce Balance' },
								]}
								aclGrants={['add:customers']}
								aclUnAuthProps={{ disabled: true }}
							/>
						</ACLBoundary>
					</Align>
				}
			>
				<GridColsDivided
					leftFirstContent={
						<Center className="!block">
							<Stack align="center" mt="lg">
								<Avatar
									dark
									width="4xl"
									margin="auto"
									userName={membersData?.data.name}
								/>
								<Title
									margin="md"
									titleText={
										memberQuery.isError
										|| memberQuery.isLoading
										|| membersData?.data.name
									}
									subtitleText={membersData?.data.email}
								/>
								<InlineError hide={!memberQuery.isError} className="!block" />
								<InlineLoading
									hide={!memberQuery.isLoading}
									className="!block"
								/>
							</Stack>
						</Center>
					}
					rightLabel="Customer Info"
					leftLabel="Customer Info"
					leftsecondContent={<CustomerInfo />}
					rightContent={<StatsCards />}
				/>
				{membersData?.data && (
					<Card>
						<Title title={`${membersData?.data.name} Activity`} size="md" />
						{tabContent}
					</Card>
				)}
				<EmptyState data={membersData?.data} />
				<AddRewardModal
					closeModal={() => setIsAddRewardModalOpen(false)}
					isAddRewardModalOpen={isAddRewardModalOpen}
					rewardNameInput={rewardNameInput}
					handleModalSubmit={handleModalSubmit}
					isLoading={
						memberQuery.isLoading
						|| isGivingPointsLoading
						|| isChangeBalanceLoading
					}
				/>
			</MainStackWithAside>
		</AppContainer>
	);
}

function EmptyState({ data }) {
	const memberQuery = useMembers();
	return (
		(data || []).length === 0 && (
			<Center className="contents">
				<Stack m="xl">
					<Title title="Member not Found" size="md" />
					<Center className="mt-8">
						<InlineError hide={!memberQuery.isError} />
						<InlineLoading hide={!memberQuery.isLoading} />
					</Center>
				</Stack>
			</Center>
		)
	);
}
function renderRelativeSignup(value) {
	if (!value) {
		return '__';
	}

	let dtt = new Date(value);
	let dd = dtt.getDate();
	let mm = dtt.toLocaleString('default', { month: 'long' });
	let yyyy = dtt.getFullYear();

	if (dd < 10) {
		dd = '0' + dd;
	}
	if (mm < 10) {
		mm = '0' + mm;
	}

	return dd + '-' + mm + '-' + yyyy;
}
function renderRelativeDatetime({ value }) {
	if (!value) {
		return '__';
	}
	if (DateTime.fromFormat(value, 'yyyy-MM-dd HH:mm:ss').isValid) {
		value = value.split(' ')[0];
	}

	let dtt = new Date(value);
	let dd = dtt.getDate();
	let mm = dtt.toLocaleString('default', { month: 'long' });
	let yyyy = dtt.getFullYear();

	if (dd < 10) {
		dd = '0' + dd;
	}
	if (mm < 10) {
		mm = '0' + mm;
	}

	return dd + '-' + mm + '-' + yyyy;
}
function handleNoneData({ value }) {
	if (value == '0') {
		return value;
	}
	if (!value) {
		return '__';
	}

	return value;
}
function handleBoolData({ value }) {
	if (value == false) {
		return 'false';
	}
	if (value == true) {
		return 'true';
	}

	return value;
}
function AddRewardModal(props) {
	const [reward, setReward] = React.useState();

	const {
		isAddRewardModalOpen,
		rewardNameInput,
		closeModal,
		handleModalSubmit,
		isLoading,
	} = props;

	const buttonContent = rewardNameInput?.label;

	return (
		<Modal
			isModalOpen={isAddRewardModalOpen}
			title={rewardNameInput?.label || 'Give Reward'}
			onClose={closeModal}
		>
			<Stack pt="none">
				{rewardNameInput?.value == '2' && (
					<>
						<Title title="Points" />
						<Input.Number
							inputName="Points"
							placeholder="Enter points amount"
							value={reward}
							onChange={(evt) => setReward(evt.target.value)}
						/>
					</>
				)}
				{rewardNameInput?.value == '1' && (
					<>
						<Title title="Select Reward" />
						<Select
							px="lg"
							className="mb-2"
							placeholder={reward ? reward : '-- Select Reward --'}
							selected={reward}
							onChange={setReward}
							options={[
								{ value: 1, label: 'Give Reward' },
								{ value: 2, label: 'Give Points' },
								{ value: 3, label: 'Give Balance' },
								{ value: 4, label: 'Reduce Balance' },
							]}
						/>
					</>
				)}
				{rewardNameInput?.value == '4' || rewardNameInput?.value == '3' ? (
					<>
						<Title title="Balance Amount" />
						<Input.Number
							inputName="balance"
							placeholder="enter balance amount"
							value={reward}
							onChange={(evt) => setReward(evt.target.value)}
						/>
					</>
				) : (
					''
				)}
			</Stack>
			<Grid gap="5">
				<span></span>
				<TwoSideLayout>
					<Button stretch={false} size="sm" m="none" onClick={closeModal}>
						Cancel
					</Button>
					<Align align="left">
						<InlineLoading hide={!isLoading} />
						<ButtonWithIcon
							stretch={false}
							size="md"
							primary
							m="xxs"
							onClick={() => {
								setReward('');
								handleModalSubmit(reward);
							}}
							disabled={!reward}
						>
							{buttonContent}
						</ButtonWithIcon>
					</Align>
				</TwoSideLayout>
			</Grid>
		</Modal>
	);
}
