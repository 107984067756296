import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';

import {
	Align,
	Button,
	ButtonWithIcon,
	Card,
	Center,
	CenterStackWithToolbar,
	Grid,
	InlineLoading,
	Modal,
	Select,
	Stack,
	Title,
	TwoSideLayout,
} from '@zeal/zeal-ui';

import AppContainer from '../App/AppContainer';

import ACLBoundary from '../App/ACL/ACLBoundary';
import CategoryCard from './components/CategoryCard';

import useBranchesMenus, {
	useAddBranchMenu,
	useDeleteBranchMenu,
} from './data/useBranchesMenu';

export default function BranchesMenus() {
	const [isAddModalOpen, setIsAddModalOpen] = useState(false);
	const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
	const [selectedMenu, setSelectedMenu] = useState(null);

	const branchesMenusQuery = useBranchesMenus({
		formatBranch(data) {
			return {
				value: data?.uuid,
				label: data?.name,
				menu: data?.menu,
			};
		},
	});

	const { branchesWithMenu, branchesWithoutMenu, isLoading }
		= branchesMenusQuery;

	const { doAddBranchMenu } = useAddBranchMenu({
		onSuccess: () => {
			branchesMenusQuery.refetch();
			setIsAddModalOpen(false);
		},
	});

	const { doDeleteBranchMenu } = useDeleteBranchMenu({
		onSuccess: () => {
			setIsDeleteModalOpen(false);
			branchesMenusQuery.refetch();
		},
	});

	const handleAddBranch = (branch) => {
		if (branch?.value)
		{doAddBranchMenu({
			name: branch?.label,
			branch: branch?.value,
		});}
	};

	const onDeleteMenuButtonClicked = (menu) => {
		setSelectedMenu(menu);
		setIsDeleteModalOpen(true);
	};

	const handleDeleteMenu = () => {
		doDeleteBranchMenu({ uuid: selectedMenu?.menu?.uuid });
		setSelectedMenu(null);
	};

	const breadcrumbs = [
		{
			name: 'Menu',
			to: '/menu',
			icon: 'utensils',
		},
	];

	const branchesMenu = isLoading ? (
		<Center>
			<Stack m="md">
				<InlineLoading />
			</Stack>
		</Center>
	) : (
		<BranchesCards
			branchesMenus={branchesWithMenu}
			onDeleteMenuButtonClicked={onDeleteMenuButtonClicked}
		/>
	);

	return (
		<AppContainer ownApp protected breadcrumbs={breadcrumbs}>
			<CenterStackWithToolbar
				toolbarStart={<span></span>}
				toolbarEnd={
					<BranchesHeader addBranchClick={() => setIsAddModalOpen(true)} />
				}
			>
				<Card p="none">
					<Stack m="md" p="xs">
						<Title title="Branches Menus" size="sm" />
						{branchesMenu}
					</Stack>
				</Card>
			</CenterStackWithToolbar>
			<AddBranchMenuModal
				isModalOpen={isAddModalOpen}
				closeModal={() => setIsAddModalOpen(false)}
				handleSubmit={handleAddBranch}
				branchesData={branchesWithoutMenu}
			/>
			<DeleteBranchMenuModal
				isModalOpen={isDeleteModalOpen}
				closeModal={() => setIsDeleteModalOpen(false)}
				handleSubmit={handleDeleteMenu}
			/>
		</AppContainer>
	);
}

function BranchesCards(props) {
	const { branchesMenus, onDeleteMenuButtonClicked } = props;
	const history = useHistory();

	const getCategoryDropOptions = (menu) => [
		<p>Clone</p>,
		<ButtonWithIcon
			transparent
			stretch
			m="none"
			p="none"
			onClick={() => {
				onDeleteMenuButtonClicked({ ...menu, title: menu?.label });
			}}
		>
			Delete
		</ButtonWithIcon>,
	];

	return (
		<Stack row wrap gap="xl">
			{React.Children.toArray(
				branchesMenus?.map((menu) => (
					<div>
						<CategoryCard
							data={{ ...menu, title: menu?.label }}
							onCardClick={() => history.push(`menu/${menu?.menu?.uuid}`)}
							categoryDropOptions={getCategoryDropOptions(menu)}
						/>
					</div>
				)),
			)}
		</Stack>
	);
}

function BranchesHeader(props) {
	const { addBranchClick } = props;

	return (
		<Align align="right">
			<Stack row wrap p="none" gap="md">
				<ACLBoundary>
					<ButtonWithIcon
						primary
						label="Add Branch"
						btnIcon="plus"
						size="xs"
						m="none"
						onClick={addBranchClick}
						aclUnAuthProps={{ disabled: true }}
						aclGrants={['control:menu']}
					/>
				</ACLBoundary>
			</Stack>
		</Align>
	);
}

function AddBranchMenuModal(props) {
	const { isModalOpen, closeModal, handleSubmit, branchesData } = props;

	const [branchSelection, setBranchSelection] = useState();

	return (
		<Modal
			isModalOpen={isModalOpen}
			title="Delete Announcement"
			onClose={closeModal}
		>
			<Stack p="none">
				<Title title="Select Branch" margin="xs" />

				<Select
					inputName="branch"
					placeholder={branchSelection?.value ? '' : 'Select Branch'}
					options={branchesData}
					selected={branchSelection}
					onChange={setBranchSelection}
				/>

				<Grid gap="5">
					<span></span>
					<TwoSideLayout>
						<ButtonWithIcon
							stretch={false}
							size="md"
							m="xs"
							px="sm"
							py="none"
							onClick={closeModal}
						>
							Cancel
						</ButtonWithIcon>
						<ButtonWithIcon
							stretch={false}
							size="md"
							primary
							m="xs"
							px="sm"
							py="none"
							onClick={() => handleSubmit(branchSelection)}
						>
							Add Branch
						</ButtonWithIcon>
					</TwoSideLayout>
				</Grid>
			</Stack>
		</Modal>
	);
}

function DeleteBranchMenuModal(props) {
	const { isModalOpen, closeModal, handleSubmit } = props;

	return (
		<Modal isModalOpen={isModalOpen} title="Delete Menu" onClose={closeModal}>
			<Stack p="none">
				<Title title="Are you sure you want to delete this Menu?" />

				<Grid gap="5">
					<span></span>
					<TwoSideLayout>
						<Button stretch={false} size="md" m="xs" onClick={closeModal}>
							Cancel
						</Button>
						<ButtonWithIcon
							secondary
							stretch={false}
							size="md"
							m="xs"
							onClick={() => handleSubmit()}
						>
							Delete
						</ButtonWithIcon>
					</TwoSideLayout>
				</Grid>
			</Stack>
		</Modal>
	);
}
