import React, { useState } from 'react';
import {
	Anchor,
	BasicTable,
	ButtonWithIcon,
	Center,
	InlineError,
	MainStackWithAside,
	Stack,
	Title,
} from '@zeal/zeal-ui';

import ReactTooltip from 'react-tooltip';

import useArchived, {
	useAddReward,
	useDeleteReward,
	useEditReward,
} from '../data/useArchived';

export default function Archived(props) {
	const archivedQuery = useArchived();
	const { data: archivedData } = archivedQuery;

	const rewardPointColumns = [
		{ Header: 'customer name', accessor: 'name' },

		{
			Header: ' Story Type',
			accessor: 'type',
		},
		{
			Header: 'Branch',
			accessor: 'branch',
		},
		{
			Header: 'Data',
			accessor: 'data',
		},
		{
			accessor: 'rePost',
			Cell: WrapWithBadge,
		},
		{
			accessor: 'preview',
			Cell: WrapWithBadge,
		},
		{
			accessor: 'download',
			Cell: Download,
		},
	];

	const archivedTable = (archivedData || []).length > 0 && (
		<BasicTable
			data={archivedData || []}
			columns={rewardPointColumns}
			headerPadding="lg"
			cellPadding="lg"
		></BasicTable>
	);

	return (
		<MainStackWithAside
			fullWidth="true"
			toolbarStart={<Title title="" />}
			toolbarEnd=""
			toolbarEnd=""
		>
			<Stack m="md" p="none">
				{archivedTable}
			</Stack>

			<EmptyState data={archivedData} />
			<ReactTooltip />
		</MainStackWithAside>
	);
}

function WrapWithBadge({ value }) {
	return <Anchor label={value} />;
}
function Download({ value }) {
	return <Anchor href={value} label="Download" download />;
}
function EmptyState({ data, addRewardBtnClick }) {
	return (
		// (data || []).length === 0 && (
		<Center>
			<Stack>
				<Title
					title="No archived yet"
					// subtitle="Add yours"
					size="md"
				/>
				<Center>
					{/* no add button for now  */}
					{/* <ButtonWithIcon
              secondary
              label="Add archived"
              btnIcon="plus"
              size="xs"
              m="none"
              onClick={addRewardBtnClick}
            /> */}
					{/* <InlineError /> */}
				</Center>
			</Stack>
		</Center>
		// )
	);
}
