import React, { useEffect, useState } from 'react';

import { ApiRequest } from '@zeal/zeal-lib';
import { useQuery } from '../../QueryClient';

async function apiCategories({ queryKey: [, filters] }) {
	return new ApiRequest('category')
		.addHeader('Accept', 'application/json')
		.GET();
}

export default function useCategories(opt) {
	const [categoriesList, setCategoriesList] = useState([]);

	const q = useQuery({
		queryFn: apiCategories,
		queryKey: ['z-business-categories'],
		staleTime: 1000 * 60 * 15, // 15 min,
	});

	const formatCategory = opt?.formatCategory || ((d) => d);

	const { data: categoriesData } = q;

	useEffect(() => {
		if (categoriesData) {
			const formattedData = categoriesData?.map(formatCategory);
			setCategoriesList(formattedData);
		} else {
			setCategoriesList([]);
		}
	}, [categoriesData, setCategoriesList]);

	React.useDebugValue(q);

	return { ...q, categoriesList };
}
