import React, { useCallback, useReducer, useState } from 'react';
import { useHistory } from 'react-router-dom';
import {
	Align,
	ButtonWithIcon,
	Card,
	Checkbox,
	Grid,
	InlineLoading,
	Input,
	MainStackWithAside,
	Stack,
	Textarea,
	Title,
} from '@zeal/zeal-ui';

import useRoles, { useAddRoles, useEditRoles } from '../data/useRoles';
import TeamMembersFrame from '../TeamMembersFrame';
import useAllPermissions from '../data/useAllPermissions';
import openToastr from '../../App/Toastr/openToastr';
import PermissionGroup from './PermissionGroup';

export default function CreateRoles(props) {
	const permissionsQuery = useAllPermissions();
	const { data: permissionsData, formattedPermissions } = permissionsQuery;

	const rolesQuery = useRoles();

	const formInitialValues = {
		name: '',
		description: '',
		selectedPermissions: {},
	};

	const formReducer = (prevState, newState) => ({
		...prevState,
		...newState,
	});

	const [formState, formDispatch] = useReducer(formReducer, formInitialValues);

	const resetForm = () => {
		formDispatch(formInitialValues);
	};

	const { doAddRoles, isLoading } = useAddRoles({
		onSuccess: () => {
			resetForm();
			openToastr({ message: 'Role Created Successfully' });
			rolesQuery.refetch();
			history.push({
				pathname: '/teams/dashboard/roles',
			});
		},
		onError: (error) => {
			const clonedError = error?.response.clone();

			if (!clonedError?.bodyUsed) {
				clonedError.json().then((err) => {
					openToastr({
						message: err?.message || 'Failed to create new role',
						isError: true,
					});
				});
			}
		},
	});

	const handleRolesSubmit = React.useCallback(() => {
		const permissionsArray = Object.keys(formState.selectedPermissions);

		const { selectedPermissions, ...requestBody } = formState;

		doAddRoles({ ...requestBody, permissions: permissionsArray });
	}, [formState, doAddRoles]);

	const breadcrumbs = [
		{ name: 'Dashboard', to: '/teams/dashboard' },
		{ name: 'Roles', to: '/teams/dashboard/roles' },
		{ name: 'Create', to: '/teams/dashboard/roles/create' },
	];

	const FormModal = (
		<Card p="lg" m="none">
			<Grid cols="3" gap="3" className="mb-4">
				<div cols="1">
					<Input
						inputName="Text"
						placeholder="Enter role name"
						label="Role Name"
						onChange={(evt) => formDispatch({ name: evt.target.value })}
						value={formState.name}
						mb="xl"
						validateType="true"
					/>
					<Textarea
						inputName="Text"
						placeholder="Type Something"
						label="Description"
						endHint={`${formState.description?.length || 0}/60`}
						rowsVal="4"
						colsVal="50"
						value={formState.description}
						onChange={(evt) => formDispatch({ description: evt.target.value })}
						validateType="true"
						maxlength="60"
					/>
					<br />
				</div>
			</Grid>
			<hr />
			<PermissionGroup
				data={permissionsData?.data}
				selectedPermissions={formState.selectedPermissions}
				formattedPermissions={formattedPermissions}
				formDispatch={formDispatch}
				formState={formState}
			/>
		</Card>
	);

	const history = useHistory();

	const handleDiscardBtn = useCallback(
		() =>
			history.push({
				pathname: '/teams/dashboard/roles',
			}),
		[history],
	);

	const isFormValid = () => {
		const isDescriptionValid
			= formState.description?.length > 5 && formState.description?.length <= 60;

		const isNameValid = formState.name?.length > 0;

		const isPermissionsValid
			= Object.keys(formState.selectedPermissions)?.length > 0;

		return isDescriptionValid && isNameValid && isPermissionsValid;
	};

	const toolbarEnd = (
		<Align align="right" gap="1">
			<InlineLoading hide={!isLoading} />
			<ButtonWithIcon
				danger
				label="Discard"
				size="md"
				m="none"
				px="md"
				onClick={handleDiscardBtn}
			/>
			<ButtonWithIcon
				primary
				label="Add Role"
				size="md"
				m="none"
				px="md"
				onClick={handleRolesSubmit}
				disabled={!isFormValid()}
			/>
		</Align>
	);

	return (
		<TeamMembersFrame breadcrumbs={breadcrumbs}>
			<MainStackWithAside
				fullWidth="true"
				toolbarStart=""
				toolbarEnd={toolbarEnd}
			>
				{FormModal}
			</MainStackWithAside>
		</TeamMembersFrame>
	);
}
