import React, { useEffect } from 'react';
import { useMutation } from 'react-query';
import { get, has, isNaN } from 'lodash-es';

import { ApiRequest } from '@zeal/zeal-lib';
import { useQuery } from '../../App/QueryClient';
import { useQueryString } from '../../App/useQueryString';
import { relationalOperatorsMap } from '../../utils/relationalOperators';

const visitsFilters = [
	['memberName', 'member_name'],
	['branchName', 'branch_name'],
	['teamName', 'team_name'],
	['points', 'points'],
	['visitTime', 'visit_time'],
	['receipt', 'receipt'],
];

async function apiVisitTransactions({ queryKey: [, { page, filters }] }) {
	return new ApiRequest('v3/visits')
		.addHeader('Accept', 'application/json')
		.addHeader('Content-Type', 'application/json')
		.POST(
			JSON.stringify({
				page,
				filters,
				take: 10,
			}),
		)
		.then((payload) => ({
			data: payload?.data,
			totalCount: payload?.meta.total,
			pageNum: payload?.meta.current_page,
			pagesCount: payload?.meta.last_page,
		}));
}

export function usePagination({ pageNum, pagesCount }) {
	const [queryState] = useQueryString();

	if (queryState?.page != pageNum) {
		console.error(
			new Error(
				`Pagination mismatch query vs api, expected ${queryState?.page} found ${pageNum}`,
			),
		);
	}

	const pagesList = Array.from(
		new Set([
			1,
			2,
			pageNum - 1,
			pageNum,
			pageNum + 1,
			pagesCount - 1,
			pagesCount,
		]),
	).reduce((a, i, ndx, arr) => {
		const prv = arr[ndx - 1];
		const isCurrent = pageNum == i;

		if (i === 0) {
			i = prv + 1;
		}

		if (i - prv > 1) {
			a.push({ dff: i - prv, isMany: true });
		}

		if (isNaN(i) || i > pagesCount) {
			return a;
		}

		a.push({ num: i, isCurrent });
		return a;
	}, []);

	return {
		pagesList,
		pagesCount,
		page: queryState?.page,
	};
}

export default function useVisitTransactions(opt) {
	const [queryState] = useQueryString();

	const filters = formatFilters(queryState);

	const { data, status, ...queryProps } = useQuery({
		queryFn: apiVisitTransactions,
		staleTime: 1000 * 60 * 25,
		queryKey: ['z-visits-transactions', { filters, page: queryState.page }],
	});

	const pagination = usePagination(data || {});

	React.useDebugValue([
		{ opt },
		{ data, status, ...queryProps },
		{ pagination },
	]);

	return {
		data,
		status,
		...pagination,
		...queryProps,
	};
}

async function apiExportVisits({ ...payload }) {
	return new ApiRequest('visits/export')
		.addHeader('Accept', 'application/json')
		.addHeader('Content-Type', 'application/json')
		.POST(JSON.stringify(payload));
}

export function useExportVisits(opt) {
	const mutation = useMutation(apiExportVisits);

	const { data, mutate, isSuccess, isError, error } = mutation;

	useEffect(() => {
		if (isError) {
			if (opt.onError) {
				opt.onError(error);
			}
		}

		if (!isSuccess) {
			return null;
		}

		if (opt?.onSuccess) {
			opt?.onSuccess();
		}
	}, [data, isSuccess, isError]);

	return {
		...mutation,
		doExportVisits: mutate,
	};
}

export function formatFilters(queryState, filters = visitsFilters) {
	const formattedFilters = filters?.reduce?.((a, [k, v]) => {
		if (has(queryState, k)) {
			const [operator, operand] = get(queryState, k, []);

			a.push({
				column: v,
				value: operand,
				condition: relationalOperatorsMap().getSymbol(operator),
			});
		}

		return a;
	}, []);

	return formattedFilters;
}
