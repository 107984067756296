import React from 'react';

import {
	Grid,
	Input,
	Stack,
	Textarea,
	Title,
	TwoSideLayout,
} from '@zeal/zeal-ui';

import { renderErrorMessage } from './ErrorMessage';

export default function ItemInfo(props) {
	const { formDispatch, formState, errMsgs, isError } = props;

	const descriptionCount = (desc) => `${desc?.length || 0} / 60`;

	return (
		<Stack m="md" p="none">
			<Title title="Item Info" size="md" />
			<Grid cols="3">
				<Stack m="md" mb="none" p="none" marginX={false}>
					<Title muteSubtitle={false} subtitle="Item Name" size="md" />
					<Input
						type="text"
						inputName="itemName"
						placeholder="Enter item Name"
						onChange={(evt) => formDispatch({ name: evt.target.value })}
						value={formState.name}
						startHint={renderErrorMessage('name', isError, errMsgs)}
					/>
				</Stack>
			</Grid>
			<Grid cols="3">
				<Stack>
					<Title muteSubtitle={false} subtitle="Item Price" size="md" />
					<Input.Number
						inputName="price"
						placeholder="item Price"
						onChange={(evt) => formDispatch({ price: evt.target.value })}
						value={formState.price}
						startHint={renderErrorMessage('price', isError, errMsgs)}
					/>
				</Stack>
			</Grid>
			<Grid cols="3">
				<Stack>
					<Title muteSubtitle={false} subtitle="Item Points Value" size="md" />
					<Input.Number
						inputName="pointsValue"
						placeholder="Add Points Value"
						onChange={(evt) =>
							formDispatch({
								points: evt.target.value,
							})
						}
						value={formState.points}
						startHint={renderErrorMessage(
							'item.loyalty_multiplier',
							isError,
							errMsgs,
						)}
					/>
				</Stack>
			</Grid>
			<Grid cols="3">
				<Stack my="lg" mt="none">
					<Title muteSubtitle={false} subtitle="Description" size="md" />
					<Textarea
						name="description"
						inputName="description"
						placeholder="Type Something"
						rows="4"
						onChange={(evt) => formDispatch({ description: evt.target.value })}
						value={formState.description}
						startHint={renderErrorMessage('location', isError, errMsgs)}
						endHint={descriptionCount(formState?.description)}
						maxlength="60"
					/>
				</Stack>
			</Grid>
		</Stack>
	);
}
