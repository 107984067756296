import React, { useReducer, useState } from 'react';
import {
	Align,
	Badge,
	BasicTable,
	Button,
	ButtonWithIcon,
	Card,
	Center,
	Grid,
	Icon,
	InlineLoading,
	Input,
	MainStackWithAside,
	Modal,
	Select,
	Stack,
	Title,
	TwoSideLayout,
} from '@zeal/zeal-ui';

import AppContainer from '../../../App/AppContainer';

import RewardsToolbarStart from '../../components/RewardsToolbarStart';

import ACLBoundary from '../../../App/ACL/ACLBoundary';

import useLoyaltyPoints, {
	useAddVoucher,
	useDeleteVoucher,
	useEditVoucher,
} from './data/useLoyaltySystem';

import RewardProperties from './components/RewardProperties';

export default function LoyaltyPointsSystem(props) {
	const initialStats = {
		voucherBalance: null,
		voucherPoints: null,
		voucherGender: null,
		isAddEditModalOpen: false,
		isDeleteModalOpen: false,
		editedVoucher: null,
		deletedVoucher: null,
	};

	const vouchersReducer = (prevState, newState) => ({ ...prevState, ...newState });

	const [vouchersState, vouchersDispatch] = useReducer(
		vouchersReducer,
		initialStats,
	);

	const { doAddVoucher } = useAddVoucher({
		onSuccess: () => {
			vouchersDispatch({ isAddEditModalOpen: false });
			resetAddEditModal();
			loyaltyPointsQuery.refetch();
		},
	});

	const { doEditVoucher } = useEditVoucher({
		onSuccess: () => {
			loyaltyPointsQuery.refetch();
			vouchersDispatch({ isAddEditModalOpen: false });
			resetAddEditModal();
		},
	});

	const { doDeleteVoucher } = useDeleteVoucher({
		onSuccess: () => {
			loyaltyPointsQuery.refetch();
			vouchersDispatch({ isDeleteModalOpen: false });
		},
	});

	const resetAddEditModal = () => {
		vouchersDispatch({
			voucherBalance: null,
			voucherPoints: null,
			voucherGender: null,
		});
	};

	const loyaltyPointsQuery = useLoyaltyPoints();
	const { data: rewardsData, isLoading: isDataLoading } = loyaltyPointsQuery;

	const pointsBreadCrumb = [
		{
			name: 'Rewards: Points And Cashback',
			to: '/rewards',
			icon: 'gift',
		},
	];

	const openAddEditModal = () => {
		vouchersDispatch({ isAddEditModalOpen: true });
	};

	const handleOpenDeleteModal = (row) => {
		const rowData = row.original;
		vouchersDispatch({ deletedVoucher: rowData, isDeleteModalOpen: true });
	};

	const actionsCell = ({ row }) => (
		<Align align="right" gap="1">
			<ACLBoundary
				aclUnAuthProps={{ disabled: true }}
				aclGrants={['control:loyalty']}
			>
				<Button
					transparent
					stretch={false}
					p="none"
					m="none"
					onClick={() => handleOpenEditModal(row)}
				>
					<Icon
						rounded
						bg="disabled"
						name="pen"
						size="lg"
						p="xs"
						margin="none"
					/>
				</Button>
				<Button
					transparent
					stretch={false}
					p="none"
					m="none"
					onClick={() => handleOpenDeleteModal(row)}
				>
					<Icon
						rounded
						bg="danger"
						name="trashAlt"
						size="lg"
						p="xs"
						margin="none"
					/>
				</Button>
			</ACLBoundary>
		</Align>
	);

	const genderOptions = [
		{ value: 'male', label: 'Males' },
		{ value: 'female', label: 'Females' },
	];

	const addRewardBtnClick = () => {
		vouchersDispatch({ editedVoucher: null });
		resetAddEditModal();
		openAddEditModal();
	};

	const handleOpenEditModal = (row) => {
		const { original: rowData } = row;
		const selectedRewardGender = genderOptions.find(
			(op) => op.value === rowData?.gender,
		);

		vouchersDispatch({
			voucherBalance: rowData?.price,
			voucherPoints: rowData?.reward,
			voucherGender: selectedRewardGender,
			editedVoucher: rowData,
		});

		openAddEditModal();
	};

	const handleRewardSubmit = React.useCallback(() => {
		const hasRewardBalance = !!vouchersState.voucherBalance;
		const hasRewardPoints
			= !isNaN(vouchersState.voucherPoints) && vouchersState.voucherPoints > 0;
		const hasGender = !!vouchersState.voucherGender;

		if (hasRewardBalance && hasRewardPoints && hasGender) {
			const requestBody = {
				price: vouchersState.voucherBalance,
				reward: vouchersState.voucherPoints,
				gender: vouchersState.voucherGender?.value,
				name: 'Voucher',
			};

			vouchersState.editedVoucher
				? doEditVoucher({
					...requestBody,
					voucherId: vouchersState?.editedVoucher?.uuid,
				  })
				: doAddVoucher({ ...requestBody });
		} else {
			// pass
		}
	}, [
		vouchersState.voucherBalance,
		vouchersState.voucherPoints,
		vouchersState.voucherGender,
		doAddVoucher,
		doEditVoucher,
	]);

	const handleVoucherDelete = (voucherId) => {
		doDeleteVoucher({ voucherId });
	};

	const rewardPointColumns = [
		{
			Header: 'Voucher Value',
			accessor: 'price',
			Cell: RenderWithCurrencyPostfix,
		},
		{
			Header: 'Points Worth',
			accessor: 'reward',
			Cell: RenderWithBadge,
		},
		{
			Header: 'Gender',
			accessor: 'gender',
		},
		{ Header: '', accessor: 'actions', Cell: actionsCell },
	];

	const rewardsTable = (rewardsData || []).length > 0 && (
		<BasicTable
			data={rewardsData || []}
			columns={rewardPointColumns}
			headerPadding="lg"
			cellPadding="lg"
		/>
	);

	const pageContent = !isDataLoading ? (
		<Card p="none">
			<Stack m="0" mt="md" p="none">
				{rewardsTable}
			</Stack>
			<EmptyState data={rewardsData} addRewardBtnClick={addRewardBtnClick} />
		</Card>
	) : (
		<Center>
			<Stack m="lg">
				<InlineLoading />
			</Stack>
		</Center>
	);

	const onChangeInput = (evt) => {
		vouchersDispatch({ [evt.target.name]: evt.target.value });
	};

	const closeAddEditModal = () => {
		vouchersDispatch({ isAddEditModalOpen: false });
	};

	const closeDeleteModal = () => {
		vouchersDispatch({ isDeleteModalOpen: false });
	};

	return (
		<AppContainer protected breadcrumbs={pointsBreadCrumb}>
			<MainStackWithAside
				toolbarStart={<RewardsToolbarStart />}
				toolbarEnd={
					<ACLBoundary
						aclUnAuthProps={{ disabled: true }}
						aclGrants={['control:loyalty']}
					>
						<ButtonWithIcon
							label="Add Coupon"
							btnIcon="plus"
							size="xs"
							m="none"
							onClick={addRewardBtnClick}
						/>
					</ACLBoundary>
				}
				aside={<RewardProperties />}
			>
				{pageContent}
				<AddEditModal
					editedVoucher={!!vouchersState.editedVoucher}
					voucherBalance={vouchersState.voucherBalance}
					voucherPoints={vouchersState.voucherPoints}
					voucherGender={vouchersState.voucherGender}
					isAddEditModalOpen={vouchersState.isAddEditModalOpen}
					handleRewardSubmit={handleRewardSubmit}
					onChangeInput={onChangeInput}
					onChangeGenderSelect={(value) =>
						vouchersDispatch({ voucherGender: value })
					}
					closeModal={closeAddEditModal}
					genderOptions={genderOptions}
				/>
				<DeleteModal
					closeModal={closeDeleteModal}
					isDeleteModalOpen={vouchersState.isDeleteModalOpen}
					handleVoucherDelete={handleVoucherDelete}
					deletedVoucher={vouchersState.deletedVoucher}
				/>
			</MainStackWithAside>
		</AppContainer>
	);
}

function RenderWithBadge({ value }) {
	return <Badge secondary>{value}</Badge>;
}

function RenderWithCurrencyPostfix({ value }) {
	return <span>{value}EGP</span>;
}

function EmptyState({ data, addRewardBtnClick }) {
	return (
		(data || []).length === 0 && (
			<Center>
				<Stack>
					<Title
						title="Start Adding Rewards"
						subtitle="Add A Punch Card And Fill It With Your Rewards "
						size="md"
						m="md"
					/>
					<Center>
						<ButtonWithIcon
							secondary
							label="Add Voucher"
							btnIcon="plus"
							size="xs"
							onClick={addRewardBtnClick}
						/>
					</Center>
				</Stack>
			</Center>
		)
	);
}

function AddEditModal(props) {
	const {
		isAddEditModalOpen,
		editedVoucher,
		voucherBalance,
		voucherGender,
		voucherPoints,
		handleRewardSubmit,
		onChangeInput,
		onChangeGenderSelect,
		closeModal,
		genderOptions,
	} = props;

	const modalTitle = editedVoucher ? 'Edit Voucher' : 'Add Voucher';

	const modalBtnText = editedVoucher ? 'Save Coupon' : 'Add Coupon';

	return (
		<Modal
			isModalOpen={isAddEditModalOpen}
			title={modalTitle}
			onClose={closeModal}
		>
			<Stack p="none">
				<Stack p="none">
					<Title title="Apply To" margin="md" />
					<Select
						inputName="voucherGender"
						options={genderOptions}
						selected={voucherGender}
						onChange={onChangeGenderSelect}
						placeholder={voucherGender?.value ? '' : 'Select Gender'}
					/>
					<Stack>
						<Input.Number
							inputName="voucherBalance"
							placeholder="Example : 100EGP"
							margin="md"
							label="Add To Balance Amount"
							onChange={onChangeInput}
							value={voucherBalance}
						/>
					</Stack>
					<Input.Number
						inputName="voucherPoints"
						placeholder="Example : 1000Pts"
						margin="md"
						label="Required Points"
						onChange={onChangeInput}
						value={voucherPoints}
					/>
				</Stack>
				<Grid gap="5">
					<span></span>
					<TwoSideLayout>
						<ButtonWithIcon
							stretch={false}
							m="xxs"
							px="sm"
							py="sm"
							onClick={closeModal}
						>
							Cancel
						</ButtonWithIcon>
						<ButtonWithIcon
							stretch={false}
							primary
							m="xxs"
							px="sm"
							py="sm"
							onClick={handleRewardSubmit}
						>
							{modalBtnText}
						</ButtonWithIcon>
					</TwoSideLayout>
				</Grid>
			</Stack>
		</Modal>
	);
}

function DeleteModal(props) {
	const { isDeleteModalOpen, handleVoucherDelete, closeModal, deletedVoucher }
		= props;

	return (
		<Modal
			isModalOpen={isDeleteModalOpen}
			title="Delete Voucher"
			onClose={closeModal}
		>
			<Stack p="none">
				<Title title="Are you sure you want to delete this Voucher?" />

				<Grid gap="5">
					<span></span>
					<TwoSideLayout>
						<Button stretch={false} size="md" onClick={closeModal}>
							Cancel
						</Button>
						<ButtonWithIcon
							secondary
							stretch={false}
							size="md"
							onClick={() => handleVoucherDelete(deletedVoucher?.uuid)}
						>
							Delete
						</ButtonWithIcon>
					</TwoSideLayout>
				</Grid>
			</Stack>
		</Modal>
	);
}
