import { useEffect } from 'react';
import { useMutation } from 'react-query';

import { ApiRequest } from '@zeal/zeal-lib';

async function apiAddCategory({ ...payload }) {
	return new ApiRequest('menus/category')
		.addHeader('Accept', 'application/json')
		.addHeader('Content-Type', 'application/json')
		.POST(JSON.stringify(payload));
}

export function useAddCategory(opt) {
	const mutation = useMutation(apiAddCategory);

	const { data, mutate, isSuccess } = mutation;

	useEffect(() => {
		if (!isSuccess) {
			return null;
		}

		if (opt.onSuccess) {
			opt.onSuccess();
		}
	}, [data, isSuccess]);

	return {
		...mutation,
		doAddCategory: mutate,
	};
}

async function apiDeleteCategory({ ...payload }) {
	return new ApiRequest(`menus/category/${payload?.uuid}`)
		.addHeader('Accept', 'application/json')
		.addHeader('Content-Type', 'application/json')
		.DELETE();
}

export function useDeleteCategory(opt) {
	const mutation = useMutation(apiDeleteCategory);

	const { data, mutate, isSuccess } = mutation;

	useEffect(() => {
		if (!isSuccess) {
			return null;
		}

		if (opt.onSuccess) {
			opt.onSuccess();
		}
	}, [data, isSuccess]);

	return {
		...mutation,
		doDeleteCategory: mutate,
	};
}
