import React from 'react';
import { useMutation } from 'react-query';

import { ApiRequest } from '@zeal/zeal-lib';
import { useQuery } from '../../App/QueryClient';
import { useQueryString } from '../../App/useQueryString';

async function apiAddHoliday({ ...payload }) {
	return new ApiRequest('v3/booking-holidays')
		.addHeader('Accept', 'application/json')
		.addHeader('Content-type', 'application/json')
		.POST(JSON.stringify(payload));
}

export function useAddHoliday(opt) {
	const mutation = useMutation(apiAddHoliday);

	const { data, mutate, isSuccess, isError, error } = mutation;

	React.useEffect(() => {
		if (isError) {
			if (opt.onError) {
				opt.onError(error);
			}
		}

		if (!isSuccess) {
			return null;
		}

		if (opt.onSuccess) {
			opt.onSuccess();
		}
	}, [data, isSuccess, isError]);

	return {
		...mutation,
		doAddHoliday: mutate,
	};
}

async function apiEditHoliday({ ...payload }) {
	return new ApiRequest(`v3/booking-holidays/${payload?.holidayId}`)
		.addHeader('Accept', 'application/json')
		.addHeader('Content-type', 'application/json')
		.PUT(JSON.stringify(payload));
}

export function useEditHoliday(opt) {
	const mutation = useMutation(apiEditHoliday);

	const { data, mutate, isSuccess, isError, error } = mutation;

	React.useEffect(() => {
		if (isError) {
			if (opt.onError) {
				opt.onError(error);
			}
		}

		if (!isSuccess) {
			return null;
		}

		if (opt.onSuccess) {
			opt.onSuccess();
		}
	}, [data, isSuccess, isError]);

	return {
		...mutation,
		doEditHoliday: mutate,
	};
}

async function apiDeleteHoliday({ ...payload }) {
	return new ApiRequest(`v3/booking-holidays/${payload?.holidayId}`)
		.addHeader('Accept', 'application/json')
		.addHeader('Content-type', 'application/json')
		.DELETE();
}

export function useDeleteHoliday(opt) {
	const mutation = useMutation(apiDeleteHoliday);

	const { data, mutate, isSuccess, isError, error } = mutation;

	React.useEffect(() => {
		if (isError) {
			if (opt.onError) {
				opt.onError(error);
			}
		}

		if (!isSuccess) {
			return null;
		}

		if (opt.onSuccess) {
			opt.onSuccess();
		}
	}, [data, isSuccess, isError]);

	return {
		...mutation,
		doDeleteHoliday: mutate,
	};
}

async function apiHolidays({ queryKey: [, filters] }) {
	return new ApiRequest('v3/booking-holidays')
		.addHeader('Accept', 'application/json')
		.addQuery('paginate', filters?.paginate)
		.GET()
		.then((payload) => ({
			data: payload?.data,
		}));
}

export default function useHolidays() {
	const [queryState] = useQueryString();

	const q = useQuery({
		queryFn: apiHolidays,
		queryKey: [
			'z-holidays',
			{
				branchId: queryState?.branchId,
			},
		],
		enabled: !!queryState?.branchId,
		staleTime: Infinity,
	});

	React.useDebugValue(q);

	return q;
}
