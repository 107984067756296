import React, { useState } from 'react';

import {
	ButtonWithIcon,
	Image,
	InlineError,
	InlineLoading,
	Input,
	Stack,
	Title,
} from '@zeal/zeal-ui';
import { useUpdateCover, useUpdatePhoto } from '../data/useProfile';

export default function ProfilePhotoUploader({
	media,
	circle,
	rounded,
	cover,
	refetchProfile,
	width,
}) {
	const [photo, setPhoto] = useState();
	const [openUploader, setOpenUploader] = useState();
	const {
		isError: overviewisError,
		isLoading: overviewisLoading,
		doUpdateCover,
	} = useUpdateCover({
		onSuccess: () => {
			refetchProfile();
		},
	});

	const { isError, isLoading, doUpdatePhoto } = useUpdatePhoto({
		onSuccess: () => {
			refetchProfile();
		},
	});

	const handleFilesUpload = (files) => {
		const formData = new FormData();
		formData.append('photo', files[0]);
		if (cover) {
			doUpdateCover(formData);
		} else {doUpdatePhoto(formData);}
	};

	const uploader
		= photo
		|| (media && (
			<Stack row pt="none">
				<Image
					circle={circle}
					width={width}
					rounded={rounded}
					cover={cover}
					src={photo ? URL.createObjectURL(photo) : media}
					alt={cover ? 'cover photo' : 'profile photo'}
				/>
				<Stack mx="md" my="auto" row>
					<ButtonWithIcon
						primary
						m="xxs"
						px="xs"
						py="xs"
						onClick={() => {
							setOpenUploader(Date.now());
						}}
					>
						<Title
							Default
							title={cover ? 'Change Cover' : 'Change Profile'}
							size="sm"
						/>
					</ButtonWithIcon>
					<InlineError hide={!isError} />
					<InlineLoading hide={!isLoading} />
					<InlineError hide={!overviewisError} />
					<InlineLoading hide={!overviewisLoading} />
				</Stack>
			</Stack>
		));
	return (
		<Stack>
			{uploader}
			<Input.Upload
				onDropFiles={handleFilesUpload}
				acceptedFiles="image/*"
				maxFiles="1"
				openUploader={openUploader}
				style={{
					display: photo || media ? 'none' : 'block',
				}}
			/>
		</Stack>
	);
}
