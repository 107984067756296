import React from 'react';
import { Card, ChartBar } from '@zeal/zeal-ui';

import useAnalyticsAcquisitions from '../data/useAnalyticsAcquisitions';

export default function ChartCustomerAcquisition(props) {
	const data = useAnalyticsAcquisitions();

	return (
		<Card>
			<ChartBar
				title="Customer Acquisition"
				className="mb-12"
				isError={data.isError}
				showLoading={data.isLoading}
				options={{
					color: ['#1A415A', '#009F87'],
					xAxis: {
						type: 'category',
						data: Object.keys(data?.data?.returning || []),
					},
					yAxis: {
						type: 'value',
					},
					series: [
						{
							name: 'returning customers',
							data: Object.values(data?.data?.returning || []),
							type: 'bar',
						},
						{
							name: 'New customers',
							data: Object.values(data?.data?.new || []),
							type: 'bar',
						},
					],
				}}
			/>
		</Card>
	);
}
