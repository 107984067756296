import React from 'react';
import { useMutation } from 'react-query';

import { ApiRequest } from '@zeal/zeal-lib';
import { useQuery } from '../../App/QueryClient';
import { useQueryString } from '../../App/useQueryString';
import { getLoggedInSession } from '../../App/ACL/useLogin';

async function apiTeamApp({ queryKey: [, filters] }) {
	return new ApiRequest('v3/teams').addHeader('Accept', 'application/json')
		.GET();
}

async function apiBranch({ queryKey: [, filters] }) {
	return new ApiRequest('v3/branch').addHeader('Accept', 'application/json')
		.GET();
}

async function apiAddTeam({ ...payload }) {
	const { authorization } = getLoggedInSession();
	if (payload.uuid) {
		return new ApiRequest(`v3/teams/${payload.uuid}`)
			.addHeader('Accept', 'application/json')
			.addHeader('Content-type', 'application/json')
			.addHeader('Authorization', authorization)
			.PUT(JSON.stringify(payload), true);
	} else {
		return new ApiRequest('v3/teams')
			.addHeader('Accept', 'application/json')
			.addHeader('Content-type', 'application/json')
			.addHeader('Authorization', authorization)
			.POST(JSON.stringify(payload), true);
	}
}

async function apiDeleteTeam({ ...payload }) {
	return new ApiRequest(`v3/teams/${payload.uuid}`)
		.addHeader('Accept', 'application/json')
		.addHeader('Content-type', 'application/json')
		.DELETE(JSON.stringify(payload), true);
}

export function useAddTeam(opt) {
	const mutation = useMutation(apiAddTeam);

	const { data, mutate, isSuccess, isError } = mutation;

	React.useEffect(() => {
		if (isError) {
			if (opt.onError) {
				opt.onError();
			}
		}
		if (!isSuccess) {
			return null;
		}

		if (opt.onSuccess) {
			opt.onSuccess();
		}
	}, [data, isSuccess, isError]);

	return {
		...mutation,
		doAddTeam: mutate,
	};
}

export function useDeleteTeam(opt) {
	const mutation = useMutation(apiDeleteTeam);

	const { data, mutate, isSuccess, isError } = mutation;

	React.useEffect(() => {
		if (isError) {
			if (opt.onError) {
				opt.onError();
			}
		}
		if (!isSuccess) {
			return null;
		}

		if (opt.onSuccess) {
			opt.onSuccess();
		}
	}, [data, isSuccess, isError]);

	return {
		...mutation,
		doDeleteTeam: mutate,
	};
}

export default function useTeamApp() {
	const q = useQuery({
		queryFn: apiTeamApp,
		staleTime: 1000 * 60 * 15, // 15 min
		queryKey: ['z-team-app'],
	});

	React.useDebugValue(q);

	return q;
}

export function useBranch() {
	const q = useQuery({
		queryFn: apiBranch,
		staleTime: 1000 * 60 * 15, // 15 min
		queryKey: ['z-branch'],
	});

	React.useDebugValue(q);

	return q;
}
