import React from 'react';
import { get, has } from 'lodash-es';

import { ApiRequest } from '@zeal/zeal-lib';
import { useQuery } from '../../App/QueryClient';
import { useQueryString } from '../../App/useQueryString';
import { relationalOperatorsMap } from '../../utils/relationalOperators';

import { usePagination } from './useCustomersRecords';

async function apiActivitiesRecords({ queryKey: [, { page, filters }] }) {
	return new ApiRequest('v3/visits')
		.addHeader('Accept', 'application/json')
		.addHeader('Content-Type', 'application/json')
		.POST(JSON.stringify({ take: 10, page, filters }))
		.then((payload) => ({
			data: payload?.data,
			totalCount: payload?.meta?.total,
			pageSize: payload?.meta?.per_page,
			pageCurrent: payload?.meta?.current_page,
		}));
}

export default function useActivitiesRecords(opt) {
	const [queryState] = useQueryString();

	const filters = opt?.filters?.reduce?.((a, [k, v]) => {
		if (has(queryState, k)) {
			const [operator, operand] = get(queryState, k);

			a.push({
				column: v,
				value: operand,
				condition: relationalOperatorsMap().getSymbol(operator),
			});
		}

		return a;
	}, []);

	const { data, status, ...queryProps } = useQuery({
		queryFn: apiActivitiesRecords,
		staleTime: 1000 * 60 * 15, // 15 min,
		keepPreviousData: true,
		queryKey: ['z-activities-records', { filters, page: queryState.page }],
	});

	const pagination = usePagination(data || {});

	React.useDebugValue([
		{ opt },
		{ data, status, ...queryProps },
		{ pagination },
	]);

	return {
		data,
		status,
		...pagination,
		...queryProps,
	};
}
