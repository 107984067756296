import React from 'react';

import {
	Button,
	ButtonWithIcon,
	Center,
	Grid,
	Icon,
	InlineLoading,
	Input,
	MainStackWithIcon,
	Modal,
	Stack,
	Title,
	TwoSideLayout,
} from '@zeal/zeal-ui';
import ACLBoundary from '../../../../App/ACL/ACLBoundary';

export default function LoyaltyCardRewards(props) {
	const [isAddRewardModalOpen, setIsAddRewardModalOpen] = React.useState(false);
	const [isDeleteModalOpen, setIsDeleteModalOpen] = React.useState(false);
	const [rewardNameInput, setRewardNameInput] = React.useState('');
	const [deletedRewardIndex, setDeletedRewardIndex] = React.useState(-1);
	const { cardIndex, isPunchValueFilled, isCardLoading } = props;

	const handleDeleteRewardClick = (index) => {
		setIsDeleteModalOpen(true);
		setDeletedRewardIndex(index);
	};

	const handleAddRewardModalSubmit = () => {
		props.handleAddRewardModalSubmit(cardIndex, rewardNameInput);
		setRewardNameInput('');
		setIsAddRewardModalOpen(false);
	};

	const handleAddRewardBtnClick = () => {
		setIsAddRewardModalOpen(true);
	};

	const handleRewardDelete = (index) => {
		props.handleDeleteReward(cardIndex, index);
		setIsDeleteModalOpen(false);
	};

	const handleRewardNameChange = (evt) => setRewardNameInput(evt.target.value);

	const addRewardButton = (
		isCardLoading ? (
			<ButtonWithIcon secondary size="sm">
				<InlineLoading textColor="default" />
			</ButtonWithIcon>
		) : (
			<ACLBoundary>
				<ButtonWithIcon
					secondary
					label="Add Reward"
					size="sm"
					btnIcon="plus"
					disabled={isPunchValueFilled}
					onClick={handleAddRewardBtnClick}
					aclUnAuthProps={{ disabled: true }}
					aclGrants={['control:loyalty']}
				/>
			</ACLBoundary>
		)
	);

	return (
		<React.Fragment>
			<ACLBoundary>
				<MainStackWithIcon
					aclUnAuthProps={{ disabled: true }}
					aclGrants={['control:loyalty']}
					icon={<Icon bg="disabled" rounded size="lg" p="xs" name="cross" />}
					buttonProps={{
						onClick: () => props.deletePunchCard(cardIndex),
					}}
				>
					<Stack row gap="lg" m="md" wrap p="none">
						{React.Children.toArray(
							props.rewards?.map((reward, index) => (
								<ACLBoundary>
									<ButtonWithIcon
										primary
										size="sm"
										label={reward}
										btnIcon="minus"
										onClick={() => handleDeleteRewardClick(index)}
										aclUnAuthProps={{ disabled: true }}
										aclGrants={['control:loyalty']}
									/>
								</ACLBoundary>
							)),
						)}
					</Stack>
					<Stack m="md" p="none" mt="none">
						{addRewardButton}
					</Stack>
				</MainStackWithIcon>
			</ACLBoundary>

			<AddRewardModal
				closeModal={() => setIsAddRewardModalOpen(false)}
				isAddRewardModalOpen={isAddRewardModalOpen}
				rewardNameInput={rewardNameInput}
				handleRewardNameChange={handleRewardNameChange}
				handleAddRewardModalSubmit={handleAddRewardModalSubmit}
			/>
			<DeleteRewardModal
				isDeleteModalOpen={isDeleteModalOpen}
				closeDeleteModal={() => setIsDeleteModalOpen(false)}
				handleRewardDelete={handleRewardDelete}
				rewardIndex={deletedRewardIndex}
			/>
		</React.Fragment>
	);
}

function AddRewardModal(props) {
	const {
		isAddRewardModalOpen,
		rewardNameInput,
		handleRewardNameChange,
		closeModal,
		handleAddRewardModalSubmit,
	} = props;
	return (
		<Modal
			isModalOpen={isAddRewardModalOpen}
			title="Add Reward"
			onClose={closeModal}
		>
			<Stack>
				<Title title="Reward Name" margin="xs" />
				<Input
					type="text"
					inputName="rewardName"
					placeholder="Name"
					onChange={handleRewardNameChange}
					value={rewardNameInput}
				/>
			</Stack>
			<Grid gap="5">
				<span></span>
				<TwoSideLayout>
					<Button stretch={false} size="md" m="xs" onClick={closeModal}>
						Cancel
					</Button>
					<ButtonWithIcon
						stretch={false}
						size="md"
						primary
						m="xs"
						onClick={handleAddRewardModalSubmit}
					>
						Save
					</ButtonWithIcon>
				</TwoSideLayout>
			</Grid>
		</Modal>
	);
}

function DeleteRewardModal(props) {
	const {
		isDeleteModalOpen,
		closeDeleteModal,
		handleRewardDelete,
		rewardIndex,
	} = props;

	return (
		<Modal
			isModalOpen={isDeleteModalOpen}
			title="Delete Reward"
			onClose={closeDeleteModal}
		>
			<Stack p="none">
				<Title title="Are you sure you want to delete this reward?" />

				<Grid gap="5">
					<span></span>
					<TwoSideLayout>
						<Button stretch={false} size="md" m="xs" onClick={closeDeleteModal}>
							Cancel
						</Button>
						<ACLBoundary>
							<ButtonWithIcon
								secondary
								stretch={false}
								size="md"
								m="xs"
								onClick={() => handleRewardDelete(rewardIndex)}
								aclUnAuthProps={{ disabled: true }}
								aclGrants={['control:loyalty']}
							>
								Delete
							</ButtonWithIcon>
						</ACLBoundary>
					</TwoSideLayout>
				</Grid>
			</Stack>
		</Modal>
	);
}
