import React, { useCallback } from 'react';
import {
	ButtonWithIcon,
	Title,
	ToggleButton,
	TwoSideLayout,
} from '@zeal/zeal-ui';
import ACLBoundary from '../../App/ACL/ACLBoundary';

export default function WelcomeRewardRow(props) {
	const {
		welcomeRewardValue,
		openEditModal,
		changeWelcomeRewardToggle,
		isLoading,
	} = props;

	const onToggleChange = useCallback(() => {
		changeWelcomeRewardToggle(welcomeRewardValue)
	}, [changeWelcomeRewardToggle, welcomeRewardValue])

	const onChangeBtnClick = useCallback(() => {
		openEditModal(welcomeRewardValue)
	}, [openEditModal, welcomeRewardValue])

	return welcomeRewardValue?.reward ? (
		<TwoSideLayout>
			<Title
				muteSubtitle={false}
				subtitle={welcomeRewardValue.reward}
				size="sm"
			/>

			<TwoSideLayout>
				<ACLBoundary>
					<ButtonWithIcon
						transparent
						px="xs"
						m="none"
						onClick={onChangeBtnClick}
						aclUnAuthProps={{ disabled: true }}
						aclGrants={['control:loyalty']}
					>
						<Title primary title="change" size="sm" />
					</ButtonWithIcon>
				</ACLBoundary>

				<RewardToggle
					isChecked={welcomeRewardValue.active || false}
					onChange={onToggleChange}
					isLoading={isLoading}
				/>
			</TwoSideLayout>
		</TwoSideLayout>
	) : (
		<Title subtitle="No Rewards." />
	);
}

function RewardToggle(props) {
	const { isLoading, isChecked, onChange } = props;
	return (
		<ACLBoundary>
			<ToggleButton
				isChecked={isChecked}
				size="sm"
				onChange={onChange}
				disabled={isLoading}
				aclUnAuthProps={{ disabled: true }}
				aclGrants={['control:loyalty']}
			/>
		</ACLBoundary>
	);
}
