import React from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';

import ACLBoundary from '../App/ACL/ACLBoundary';

import AnalyticsViz from './AnalyticsViz';

export default function AnalyticsRoutes() {
	return (
		<Switch>
			<ACLBoundary
				aclUnAuthProps={{ replace: true }}
				aclGrants={['view:analytics']}
			>
				<Route exact path="/analytics" component={AnalyticsViz} />
				<Route path="/feedback/analytics">
					<Redirect push to="/analytics" />
				</Route>
			</ACLBoundary>
		</Switch>
	);
}
