import React from 'react';

import { useMutation } from 'react-query';

import { ApiRequest } from '@zeal/zeal-lib';
import { useQuery } from '../../App/QueryClient';
import { getLoggedInSession } from '../../App/ACL/useLogin';

async function apiRecommendations({ queryKey: [, filters] }) {
	return new ApiRequest('product-recommendations')
		.addHeader('Accept', 'application/json')
		.GET();
}

async function apiDeleteAnnou({ ...payload }) {
	const { authorization } = getLoggedInSession();

	return new ApiRequest(`product-recommendations/${payload.uuid}`)
		.addHeader('Accept', 'application/json')
		.addHeader('Authorization', authorization)
		.addHeader('Content-type', 'application/json')
		.DELETE(JSON.stringify(payload), true);
}

async function apiAddAnnou(payload) {
	const { authorization } = getLoggedInSession();
	if (payload.uuid) {
		return new ApiRequest(`product-recommendations/${payload.uuid}`)
			.addHeader('Accept', 'application/json')
			.addHeader('Content-type', 'application/json')
			.addHeader('Authorization', authorization)
			.PUT(JSON.stringify(payload), true);
	} else {
		return new ApiRequest('product-recommendations')
			.addHeader('Accept', 'application/json')
			.addHeader('Content-type', 'application/json')
			.addHeader('Authorization', authorization)
			.POST(JSON.stringify(payload), true);
	}
}

export default function useRecommendations() {
	const q = useQuery({
		queryFn: apiRecommendations,
		staleTime: 1000 * 60 * 15, // 15 min
		queryKey: ['z-recommendations'],
	});

	React.useDebugValue(q);

	return q;
}

export function useDeleteAnnou(opt) {
	const mutation = useMutation(apiDeleteAnnou);

	const { data, mutate, isSuccess } = mutation;

	React.useEffect(() => {
		if (!isSuccess) {
			return null;
		}

		if (opt.onSuccess) {
			opt.onSuccess();
		}
	}, [data, isSuccess]);

	return {
		...mutation,
		doDeleteAnnou: mutate,
	};
}

export function useAddAnnou(opt) {
	const mutation = useMutation(apiAddAnnou);

	const { data, mutate, isSuccess } = mutation;

	React.useEffect(() => {
		if (!isSuccess) {
			return null;
		}

		if (opt.onSuccess) {
			opt.onSuccess();
		}
	}, [data, isSuccess]);

	return {
		...mutation,
		doAddAnnou: mutate,
	};
}
