import React from 'react';
import { useMutation } from 'react-query';
import { ApiRequest } from '@zeal/zeal-lib';

async function apiSubmitSocialMedia(body) {
	return new ApiRequest('v3/settings/social-media')
		.addHeader('Accept', 'application/json')
		.addHeader('Content-Type', 'application/json')
		.PUT(JSON.stringify(body))
		.then((payload) => ({
			data: payload?.data,
			totalCount: payload?.meta?.total,
			pageSize: payload?.meta?.per_page,
			pageCurrent: payload?.meta?.current_page,
		}));
}
export default function useSubmitSocialMedia(opt) {
	const mutation = useMutation(apiSubmitSocialMedia);

	const { data, mutate, isSuccess, isError } = mutation;

	React.useEffect(() => {
		if (isError) {
			if (opt.onError) {
				opt.onError();
			}
		}
		if (!isSuccess) {
			return null;
		}

		if (opt.onSuccess) {
			opt.onSuccess();
		}
	}, [data, isSuccess]);

	return {
		...mutation,
		doAddSocialMedia: mutate,
	};
}
