import React from 'react';

import { Checkbox, Grid, Stack, Title } from '@zeal/zeal-ui';

export default function PermissionGroup(props) {
	const {
		data,
		selectedPermissions,
		formattedPermissions,
		formDispatch,
		formState,
	} = props;

	const allPermissionsCount = Object.values(formattedPermissions).length / 2;

	const updateSelectedPermissions = (evt, permission) => {
		if (evt.target.checked) {
			if (evt.target.name === 'all') {
				const allPermissions = Object.values(formattedPermissions)?.reduce(
					(acc, cur) => ({
						...acc,
						[cur]: 'checked',
					}),
					{},
				);

				formDispatch({
					selectedPermissions: allPermissions,
				});
			} else {
				const parentPermissions = (permission?.depends_on || []).reduce(
					(acc, cur) => ({ ...acc, [cur]: { dependents: [evt.target.name] } }),
					{},
				);

				formDispatch({
					selectedPermissions: {
						...formState.selectedPermissions,
						[evt.target.name]: 'checked',
						...parentPermissions,
					},
				});
			}
		} else {
			if (evt.target.name === 'all') {
				formDispatch({
					selectedPermissions: {},
				});
			} else {
				const { [evt.target.name]: removed, ...restPermissions }
					= formState.selectedPermissions;

				(removed.dependents || []).forEach(function (arrayItem) {
					delete restPermissions[arrayItem];
				});

				formDispatch({ selectedPermissions: restPermissions });
			}
		}
	};

	const permissionsCheckboxes = (permissions) =>
		React.Children.toArray(
			(permissions || []).map((permission) => (
				<div>
					<Checkbox
						labelText={permission?.display_name}
						inputName={permission?.id.toString()}
						defaultChecked={!!selectedPermissions?.[permission?.id.toString()]}
						checked={!!selectedPermissions?.[permission?.id.toString()]}
						onChange={(evt) => updateSelectedPermissions(evt, permission)}
					/>
				</div>
			)),
		);

	const permissionGroup = React.Children.toArray(
		(data || []).map((permissionGroup) => (
			<>
				<Stack my="md" pt="none">
					<Title size="sm" margin="xs" py="sm" title={permissionGroup?.name} />
					<Grid cols="2" gap="3">
						{permissionsCheckboxes(permissionGroup.permissions)}
					</Grid>
				</Stack>
			</>
		)),
	);

	const isChecked
		= allPermissionsCount === Object.values(selectedPermissions)?.length && data;

	return (
		<div>
			<Stack my="md" pt="sm">
				<Title
					size="lg"
					subtitle="All System Privileges"
					muteSubtitle={false}
				/>
			</Stack>
			<Checkbox
				labelText="Select All Permissions"
				inputName="all"
				defaultChecked={isChecked}
				checked={isChecked}
				onChange={updateSelectedPermissions}
			/>
			{permissionGroup}
		</div>
	);
}
