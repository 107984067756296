import React, { useReducer, useState } from 'react';
import {
	Align,
	Badge,
	BasicTable,
	Button,
	ButtonWithIcon,
	Card,
	Center,
	Grid,
	Icon,
	InlineLoading,
	Input,
	Label,
	MainStackWithAside,
	Modal,
	Select,
	Stack,
	Title,
	TwoSideLayout,
} from '@zeal/zeal-ui';

import RewardsToolbarStart from '../../components/RewardsToolbarStart';

import AppContainer from '../../../App/AppContainer';
import ACLBoundary from '../../../App/ACL/ACLBoundary';

import useLoyaltySpecifics, {
	useAddReward,
	useDeleteReward,
	useEditReward,
	useLoyaltySpecificsTypes,
} from './data/useLoyaltySpecifics';

import RewardProperties from './components/RewardProperties';

export default function LoyaltySpecifics() {
	const initialStats = {
		rewardName: null,
		rewardType: null,
		rewardPoints: null,
		isAddEditModalOpen: false,
		isDeleteModalOpen: false,
		editedReward: null,
		deletedReward: null,
	};

	const rewardsReducer = (prevState, newState) => ({ ...prevState, ...newState });

	const [rewardsState, rewardsDispatch] = useReducer(
		rewardsReducer,
		initialStats,
	);

	const loyaltySpecificsQuery = useLoyaltySpecifics();

	const { data: rewardsData, isLoading: isDataLoading } = loyaltySpecificsQuery;

	const { data: typesData } = useLoyaltySpecificsTypes({
		formatType(data) {
			return {
				value: data?.id,
				label: data?.type,
			};
		},
	});

	const { doAddReward } = useAddReward({
		onSuccess: () => {
			loyaltySpecificsQuery.refetch();
			rewardsDispatch({ isAddEditModalOpen: false });
			resetAddEditModal();
		},
	});

	const { doEditReward } = useEditReward({
		onSuccess: () => {
			loyaltySpecificsQuery.refetch();
			rewardsDispatch({ isAddEditModalOpen: false });
			resetAddEditModal();
		},
	});

	const { doDeleteReward } = useDeleteReward({
		onSuccess: () => {
			loyaltySpecificsQuery.refetch();
			rewardsDispatch({ isDeleteModalOpen: false });
		},
	});

	const resetAddEditModal = () => {
		rewardsDispatch({ rewardName: '', rewardPoints: '', rewardType: '' });
	};

	const pointsBreadCrumb = [
		{
			name: 'Rewards: Scoped Rewards',
			to: '/rewards',
			icon: 'gift',
		},
	];

	const openAddEditModal = () => {
		rewardsDispatch({ isAddEditModalOpen: true });
	};

	const handleOpenDeleteModal = (row) => {
		const { original: rowData } = row;
		rewardsDispatch({ isDeleteModalOpen: true, deletedReward: rowData });
	};

	const actionsCell = ({ row }) => (
		<Align align="right" gap="1">
			<ACLBoundary
				aclUnAuthProps={{ disabled: true }}
				aclGrants={['control:loyalty']}
			>
				<Button
					transparent
					stretch={false}
					p="none"
					m="none"
					onClick={() => handleOpenEditModal(row)}
				>
					<Icon
						rounded
						bg="disabled"
						name="pen"
						size="lg"
						p="xs"
						margin="none"
					/>
				</Button>
				<Button
					transparent
					stretch={false}
					p="none"
					m="none"
					onClick={() => handleOpenDeleteModal(row)}
				>
					<Icon
						rounded
						bg="danger"
						name="trashAlt"
						size="lg"
						p="xs"
						margin="none"
					/>
				</Button>
			</ACLBoundary>
		</Align>
	);

	const addRewardBtnClick = () => {
		rewardsDispatch({ editedReward: null });
		resetAddEditModal();
		openAddEditModal();
	};

	const handleOpenEditModal = (row) => {
		const { original: rowData } = row;

		const selectedType = typesData.find((t) => t.label === rowData?.type);

		rewardsDispatch({
			editedReward: rowData,
			rewardName: rowData?.rewards,
			rewardType: selectedType || null,
			rewardPoints: rowData?.points,
		});
		openAddEditModal();
	};

	const handleRewardSubmit = React.useCallback(() => {
		const hasRewardName = !!rewardsState?.rewardName;
		const hasRewardType = !!rewardsState?.rewardType;
		const hasRewardPoints
			= !isNaN(rewardsState?.rewardPoints) && rewardsState?.rewardPoints > 0;

		if (hasRewardName && hasRewardType && hasRewardPoints) {
			rewardsState?.editedReward
				? doEditReward({
					rewards: rewardsState?.rewardName,
					points: rewardsState?.rewardPoints,
					type: rewardsState?.rewardType?.value,
					rewardId: rewardsState?.editedReward?.uuid,
				  })
				: doAddReward({
					rewards: rewardsState?.rewardName,
					points: rewardsState?.rewardPoints,
					type: rewardsState?.rewardType?.value,
				  });
		} else {
			// pass
		}
	}, [
		rewardsState?.rewardName,
		rewardsState?.rewardType,
		rewardsState?.rewardPoints,
		doAddReward,
		doEditReward,
	]);

	const handleRewardDelete = () => {
		doDeleteReward({ rewardId: rewardsState?.deletedReward?.uuid });
	};

	const rewardPointColumns = [
		{ Header: 'reward name', accessor: 'rewards' },
		{
			Header: 'punches',
			accessor: 'points',
			Cell: WrapWithBadge,
		},
		{
			Header: 'type',
			accessor: 'type',
		},
		{ Header: '', accessor: 'actions', Cell: actionsCell },
	];

	const rewardsTable = (rewardsData || []).length > 0 && (
		<BasicTable
			data={rewardsData || []}
			columns={rewardPointColumns}
			headerPadding="md"
			cellPadding="lg"
		></BasicTable>
	);

	const onChangeRewardPoints = (evt) => {
		rewardsDispatch({ rewardPoints: evt.target.value });
	};

	const onChangeRewardName = (evt) => {
		rewardsDispatch({ rewardName: evt.target.value });
	};
	const onChangePromoCode = (evt) => {
		rewardsDispatch({ rewardType: evt });
	};
	const closeAddEditModal = () => {
		rewardsDispatch({ isAddEditModalOpen: false });
	};

	const closeDeleteModal = () => {
		rewardsDispatch({ isDeleteModalOpen: false });
	};

	const pageContent = !isDataLoading ? (
		<Card p="none">
			<Stack m="none" p="none">
				<br />
				{rewardsTable}
			</Stack>
			<EmptyState data={rewardsData} addRewardBtnClick={addRewardBtnClick} />
		</Card>
	) : (
		<Center>
			<Stack m="lg">
				<InlineLoading />
			</Stack>
		</Center>
	);

	return (
		<AppContainer protected breadcrumbs={pointsBreadCrumb}>
			<MainStackWithAside
				toolbarStart={<RewardsToolbarStart />}
				toolbarEnd={
					<ACLBoundary
						aclUnAuthProps={{ disabled: true }}
						aclGrants={['control:loyalty']}
					>
						<ButtonWithIcon
							secondary
							label="Add Reward"
							btnIcon="plus"
							size="xs"
							m="none"
							pt="none"
							onClick={addRewardBtnClick}
						/>
					</ACLBoundary>
				}
				aside={<RewardProperties />}
			>
				{pageContent}
				<AddEditModal
					isEditingReward={rewardsState?.editedReward}
					rewardName={rewardsState?.rewardName}
					rewardPoints={rewardsState?.rewardPoints}
					rewardType={rewardsState?.rewardType}
					isAddEditModalOpen={rewardsState?.isAddEditModalOpen}
					handleRewardSubmit={handleRewardSubmit}
					onChangeRewardPoints={onChangeRewardPoints}
					onChangeRewardName={onChangeRewardName}
					onChangePromoCode={onChangePromoCode}
					closeModal={closeAddEditModal}
					types={typesData}
				/>
				<DeleteModal
					closeModal={closeDeleteModal}
					isDeleteModalOpen={rewardsState?.isDeleteModalOpen}
					handleRewardDelete={handleRewardDelete}
				/>
			</MainStackWithAside>
		</AppContainer>
	);
}

function WrapWithBadge({ value }) {
	return <Badge secondary>{value}</Badge>;
}

function EmptyState({ data, addRewardBtnClick }) {
	return (
		(data || []).length === 0 && (
			<Center>
				<Stack>
					<Title
						title="Start Adding Rewards"
						subtitle="Add A Punch Card And Fill It With Your Rewards "
						size="md"
					/>
					<Center>
						<ACLBoundary
							aclUnAuthProps={{ disabled: true }}
							aclGrants={['control:loyalty']}
						>
							<ButtonWithIcon
								secondary
								label="Add Reward"
								btnIcon="plus"
								size="xs"
								onClick={addRewardBtnClick}
							/>
						</ACLBoundary>
					</Center>
				</Stack>
			</Center>
		)
	);
}

function AddEditModal(props) {
	const {
		isAddEditModalOpen,
		isEditingReward,
		rewardName,
		rewardType,
		rewardPoints,
		handleRewardSubmit,
		onChangeRewardName,
		onChangePromoCode,
		onChangeRewardPoints,
		closeModal,
		types,
	} = props;

	const modalTitle = isEditingReward ? 'Edit Reward' : 'Add Reward';

	const modalBtnText = isEditingReward ? 'Save' : 'Add Reward';

	const punchesInputHint
		= (rewardPoints > 10 || rewardPoints <= 0)
		&& renderErrorMessage('Punches number must be between 0 and 10');

	const isFormValid = rewardPoints <= 10 && rewardPoints > 0 && rewardName;

	return (
		<Modal
			isModalOpen={isAddEditModalOpen}
			title={modalTitle}
			onClose={closeModal}
		>
			<Stack p="none">
				<Stack>
					<Input
						inputName="name"
						placeholder="name"
						margin="sm"
						label="Reward Name"
						onChange={onChangeRewardName}
						value={rewardName}
					/>
					<Input.Number
						inputName="punches"
						placeholder="punches number"
						margin="sm"
						label="Number of punches"
						onChange={onChangeRewardPoints}
						value={rewardPoints}
						startHint={punchesInputHint}
					/>
					<Title title="Reward Type" margin="xs" />
					<Select
						inputName="rewardType"
						placeholder={rewardType ? rewardType : '-- select type --'}
						options={types}
						selected={rewardType}
						onChange={onChangePromoCode}
					/>
				</Stack>
				<Grid gap="5">
					<span></span>
					<TwoSideLayout>
						<ButtonWithIcon stretch={false} size="md" onClick={closeModal}>
							Cancel
						</ButtonWithIcon>
						<ButtonWithIcon
							stretch={false}
							size="md"
							primary
							onClick={handleRewardSubmit}
							disabled={!isFormValid}
						>
							{modalBtnText}
						</ButtonWithIcon>
					</TwoSideLayout>
				</Grid>
			</Stack>
		</Modal>
	);
}

function renderErrorMessage(errMsg) {
	if (errMsg)
	{return (
		<Label textColor="danger" size="xs">
			{errMsg}
		</Label>
	);}
	return null;
}

function DeleteModal(props) {
	const { isDeleteModalOpen, handleRewardDelete, closeModal } = props;

	return (
		<Modal
			isModalOpen={isDeleteModalOpen}
			title="Delete Reward"
			onClose={closeModal}
		>
			<Stack p="none">
				<Title title="Are you sure you want to delete this reward?" />

				<Grid gap="5">
					<span></span>
					<TwoSideLayout>
						<ButtonWithIcon stretch={false} size="md" onClick={closeModal}>
							Cancel
						</ButtonWithIcon>
						<ButtonWithIcon
							secondary
							stretch={false}
							size="md"
							onClick={() => handleRewardDelete()}
						>
							Delete
						</ButtonWithIcon>
					</TwoSideLayout>
				</Grid>
			</Stack>
		</Modal>
	);
}
