import {
	Button,
	ButtonWithIcon,
	Center,
	Grid,
	Icon,
	Input,
	Label,
	Modal,
	Stack,
	Title,
	TwoSideLayout,
} from '@zeal/zeal-ui';
import { isDate } from 'lodash';
import React, { useEffect, useReducer, useState } from 'react';
import ACLBoundary from '../../App/ACL/ACLBoundary';
import openToastr from '../../App/Toastr/openToastr';

import { useQueryString } from '../../App/useQueryString';
import useHolidays, {
	useAddHoliday,
	useDeleteHoliday,
	useEditHoliday,
} from '../data/useHoliday';

export default function Holidays(props) {
	const holidayQuery = useHolidays();
	const { data: holidaysData } = holidayQuery;
	const [{ branchId }, updateQuery] = useQueryString();
	const [isAddEditModalOpen, setIsAddEditModalOpen] = useState(false);
	const [editedHoliday, setEditedHoliday] = useState(null);

	const { doAddHoliday, isError: isAddError } = useAddHoliday({
		onSuccess: () => {
			holidayQuery.refetch();
			setIsAddEditModalOpen(false);
			resetForm();
			openToastr({
				message: 'Holiday Added',
			});
		},
		onError: (error) => {
			error?.response?.json().then((err) => {
				openToastr({
					message: err?.message || 'Failed to add holiday',
					isError: true,
				});
			});
		},
	});

	const { doEditHoliday, isError: isEditError } = useEditHoliday({
		onSuccess: () => {
			holidayQuery.refetch();
			setIsAddEditModalOpen(false);
			openToastr({
				message: 'Holiday Updated',
			});
			resetForm();
		},
		onError: (error) => {
			error?.response?.json().then((err) => {
				openToastr({
					message: err?.message || 'Failed to update holiday',
					isError: true,
				});
			});
		},
	});

	const { doDeleteHoliday } = useDeleteHoliday({
		onSuccess: () => {
			holidayQuery.refetch();
			setIsAddEditModalOpen(false);
			setIsAddEditModalOpen(false);
			resetForm();
			openToastr({
				message: 'Holiday Deleted',
			});
		},
		onError: (error) => {
			error?.response?.json().then((err) => {
				openToastr({
					message: err?.message || 'Failed to delete holiday',
					isError: true,
				});
			});
		},
	});

	const initialFormValues = {
		from: '',
		to: '',
	};

	const resetForm = () => {
		formDispatch({ ...initialFormValues });
	};

	const addEditFormReducer = (prevState, newState) => ({ ...prevState, ...newState });

	const [formState, formDispatch] = useReducer(
		addEditFormReducer,
		initialFormValues,
	);

	useEffect(() => {
		updateQuery({
			branchId,
		});
	}, [branchId]);

	const openAddModal = () => {
		setEditedHoliday(null);
		resetForm();
		openModal();
	};

	const openEditModal = (holiday) => {
		setEditedHoliday(holiday);
		formDispatch({ from: new Date(holiday.from), to: new Date(holiday.to) });
		openModal();
	};

	const openModal = () => {
		setIsAddEditModalOpen(true);
	};

	const holidays
		= holidaysData?.data?.length > 0 ? (
			React.Children.toArray(
				(holidaysData?.data || [])?.map((item) => (
					<HolidayRow data={item} openEditModal={openEditModal} />
				)),
			)
		) : (
			<EmptyState />
		);

	const showHolidays = branchId ? holidays : <EmptyBranches />;

	const handleModalSubmit = () => {
		editedHoliday ? EditHoliday() : AddHoliday();
	};

	const AddHoliday = () => {
		doAddHoliday({
			from: addHours(formState.from, 2),
			to: addHours(formState.to, 2),
			branch_uuid: branchId,
		});
	};

	const EditHoliday = () => {
		doEditHoliday({
			from: addHours(formState.from, 2),
			to: addHours(formState.to, 2),
			branch_uuid: branchId,
			holidayId: editedHoliday.uuid,
		});
	};

	const handleHolidayDelete = () => {
		doDeleteHoliday({
			holidayId: editedHoliday.uuid,
		});
	};

	return (
		<div>
			<TwoSideLayout>
				<Stack wrap row gap="xl" m="md" pt="none">
					<h3>Holidays</h3>
					<Icon name="questionCircle" />
				</Stack>
				<Stack row gap="xl" m="sm" wrap>
					<ACLBoundary>
						<ButtonWithIcon
							primary
							stretch={false}
							circle
							onClick={openAddModal}
							py="xs"
							aclUnAuthProps={{ disabled: true }}
							aclGrants={['control:orders schedule']}
						>
							<Icon name="plusCircle" size="sm" margin="none" bg="pure" />
						</ButtonWithIcon>
					</ACLBoundary>
				</Stack>
			</TwoSideLayout>
			{showHolidays}
			<AddEditModal
				isModalOpen={isAddEditModalOpen}
				closeModal={() => setIsAddEditModalOpen(false)}
				formState={formState}
				formDispatch={formDispatch}
				editedHoliday={editedHoliday}
				handleSubmit={handleModalSubmit}
				handleHolidayDelete={handleHolidayDelete}
				isAddOrEditError={isAddError || isEditError}
			/>
		</div>
	);
}

function EmptyBranches(props) {
	return (
		<Center>
			<Title title="No branch selected" />
		</Center>
	);
}

function EmptyState() {
	return (
		<div>
			<Stack m="md" gap="lg" p="none" marginY={false}>
				<Title subtitle="No holidays set yet." />
			</Stack>
		</div>
	);
}

function HolidayRow(props) {
	const { data, openEditModal } = props;
	return (
		<Stack p="none" m="sm">
			<TwoSideLayout>
				<Stack row gap="xs" pt="none">
					<Center horizontal={false} text={false}>
						<Icon name="calendarLight" size="sm" />
						<Title
							subtitle={`${formatHolidayDate(data.from)} - ${formatHolidayDate(
								data.to,
							)}`}
							size="sm"
							muteSubtitle={false}
						/>
					</Center>
				</Stack>
				<ACLBoundary>
					<ButtonWithIcon
						transparent
						p="none"
						m="none"
						onClick={() => {
							openEditModal(data);
						}}
						aclUnAuthProps={{ disabled: true }}
						aclGrants={['control:orders schedule']}
					>
						<Icon name="cog" />
					</ButtonWithIcon>
				</ACLBoundary>
			</TwoSideLayout>
		</Stack>
	);
}

function formatHolidayDate(date, formatter = null) {
	const timestamp = Date.parse(date);

	if (isNaN(timestamp)) {
		return 'NA';
	}
	const formattedDate = new Date(timestamp);

	if (!formatter) {
		formatter = new Intl.DateTimeFormat('en-GB', {
			month: 'short',
			day: 'numeric',
		});
	}

	return formatter.format(formattedDate);
}

function DeleteModal(props) {
	const { isDeleteModalOpen, closeDeleteModal, handleDelete, deletedHoliday }
		= props;

	return (
		<Modal isModalOpen={isDeleteModalOpen} title="Delete Holiday">
			<Stack p="none">
				<Title title="Are you sure you want to delete this holiday?" />

				<Grid gap="5">
					<span></span>
					<TwoSideLayout>
						<Button stretch={false} size="md" m="xs" onClick={closeDeleteModal}>
							Cancel
						</Button>
						<ButtonWithIcon
							secondary
							stretch={false}
							size="md"
							m="xs"
							onClick={() => handleDelete(deletedHoliday?.uuid)}
						>
							Delete
						</ButtonWithIcon>
					</TwoSideLayout>
				</Grid>
			</Stack>
		</Modal>
	);
}

function AddEditModal(props) {
	const {
		formState,
		formDispatch,
		isModalOpen,
		closeModal,
		handleSubmit,
		editedHoliday,
		handleHolidayDelete,
		isAddOrEditError,
	} = props;

	const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);

	const modalTitle = editedHoliday ? 'Edit Holiday' : 'Add Holiday';

	const modalSubmitLabel = editedHoliday ? 'Done' : 'Add';

	const deleteHolidayButton = editedHoliday && (
		<ButtonWithIcon
			secondary
			stretch={false}
			size="sm"
			m="xs"
			onClick={() => setIsDeleteModalOpen(true)}
		>
			Delete
		</ButtonWithIcon>
	);

	const onDeleteHoliday = () => {
		handleHolidayDelete();
		setIsDeleteModalOpen(false);
	};

	const isSaveHolidayDisabled
		= !isDate(formState?.to) || !isDate(formState?.from);

	const errorMessage = isAddOrEditError && (
		<Label textColor="danger" size="xs">
			Start Date must be before End date
		</Label>
	);

	return (
		<Modal isModalOpen={isModalOpen} title={modalTitle} onClose={closeModal}>
			<Stack>
				<Stack p="none">
					<Title title="Holiday Start Date" margin="xs" />
					<Input.Date
						placeholder="DD/MM/YYYY"
						fillWidth={true}
						selected={formState.from}
						onChange={(date) => formDispatch({ from: date })}
					/>
				</Stack>
				<Stack p="none">
					<Title title="Holiday End Date" margin="xs" />
					<Input.Date
						placeholder="DD/MM/YYYY"
						fillWidth={true}
						selected={formState.to}
						onChange={(date) => formDispatch({ to: date })}
						startHint={errorMessage}
					/>
				</Stack>

				<Grid gap="5">
					<span></span>
					<TwoSideLayout>
						<Button stretch={false} size="sm" m="xs" onClick={closeModal}>
							Cancel
						</Button>
						<Stack row p="none" gap="md">
							{deleteHolidayButton}
							<ButtonWithIcon
								stretch={false}
								size="sm"
								primary
								m="xs"
								onClick={handleSubmit}
								disabled={isSaveHolidayDisabled}
							>
								{modalSubmitLabel}
							</ButtonWithIcon>
						</Stack>
					</TwoSideLayout>
				</Grid>
			</Stack>
			<DeleteModal
				isDeleteModalOpen={isDeleteModalOpen}
				closeDeleteModal={() => setIsDeleteModalOpen(false)}
				handleDelete={onDeleteHoliday}
				deletedHoliday={editedHoliday}
			/>
		</Modal>
	);
}

function addHours(date, hours) {
	if (isDate(date)) {date.setTime(date.getTime() + hours * 60 * 60 * 1000);}

	return date;
}
