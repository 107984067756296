import React, { useState } from 'react';
import {
	Button,
	ButtonWithIcon,
	Center,
	Grid,
	InlineLoading,
	Label,
	Modal,
	Stack,
	Textarea,
	Title,
	TwoSideLayout,
} from '@zeal/zeal-ui';

import openToastr from '../../App/Toastr/openToastr';
import ACLBoundary from '../../App/ACL/ACLBoundary';

export default function CustomersActions(props) {
	const {
		isAllSelected,
		toggleAllSelected,
		pushNotification,
		notificationAudienceCount,
		isLoadingPushNotification,
	} = props;
	const [isNotificationModalOpen, setIsNotificationModalOpen] = useState(false);

	const onPushNotification = (message) => {
		pushNotification(message);
		setIsNotificationModalOpen(false);
	};

	const openNotificationModal = () => {
		if (notificationAudienceCount > 0) {setIsNotificationModalOpen(true);}
		else {
			openToastr({
				message: 'No customers selected',
				isError: true,
			});
		}
	};

	const pushNotificationBtnContent = isLoadingPushNotification ? (
		<InlineLoading textColor="basic" label=" " />
	) : (
		<Label textColor="basic" my="xs">
			Push Notification
		</Label>
	);

	const selectAllButton = (
		<Stack p="none" ml="none" marginY={false} m="lg">
			{isAllSelected ? (
				<Button
					size="sm"
					secondary
					onClick={() => toggleAllSelected(false)}
					rounded="md"
				>
					<Label size="xs" textColor="default">
						Unselect all
					</Label>
				</Button>
			) : (
				<Button size="sm" onClick={() => toggleAllSelected(true)} rounded="md">
					<Label size="xs" textColor="basic">
						Select all
					</Label>
				</Button>
			)}
		</Stack>
	);

	return (
		<Stack row wrap p="none" m="sm">
			<Center wrap horizontal={false} gap="3">
				{selectAllButton}
				<Label size="xs" textColor="basic">
					Actions
				</Label>
				<ACLBoundary>
					<Button
						size="sm"
						onClick={openNotificationModal}
						rounded="md"
						aclGrants={['send:customers']}
						aclUnAuthProps={{ disabled: true }}
					>
						{pushNotificationBtnContent}
					</Button>
				</ACLBoundary>
			</Center>
			<NotificationModal
				isModalOpen={isNotificationModalOpen}
				closeModal={() => setIsNotificationModalOpen(false)}
				onSubmit={onPushNotification}
				notificationAudienceCount={notificationAudienceCount}
			/>
		</Stack>
	);
}

function NotificationModal(props) {
	const [message, setMessage] = useState('');

	const { isModalOpen, closeModal, onSubmit, notificationAudienceCount }
		= props;

	const submitReason = () => {
		if (message) {
			onSubmit(message);
			setMessage('');
		}
	};

	return (
		<Modal
			isModalOpen={isModalOpen}
			title="Notification Message"
			onClose={closeModal}
		>
			<Stack p="none">
				<Center>
					<Title
						primary
						title={`${notificationAudienceCount} Customer(s) will receive this notification`}
						size="sm"
					/>
				</Center>
				<Stack p="none" my="md" mt="none">
					<Title title="Message" margin="xs" />
					<Textarea
						name="message"
						placeholder="Type Something"
						rowsVal="4"
						onChange={(evt) => setMessage(evt.target.value)}
						value={message}
						endHint="must be 6 or more characters"
					/>
				</Stack>

				<Grid gap="5">
					<span></span>
					<TwoSideLayout>
						<ButtonWithIcon stretch={false} size="md" onClick={closeModal}>
							Cancel
						</ButtonWithIcon>
						<ButtonWithIcon
							stretch={false}
							primary
							size="md"
							onClick={submitReason}
							disabled={message.length < 6}
						>
							Send
						</ButtonWithIcon>
					</TwoSideLayout>
				</Grid>
			</Stack>
		</Modal>
	);
}
