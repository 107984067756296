import React from 'react';

import {
	Center,
	Icon,
	Input,
	MainStackWithIcon,
	Stack,
	Title,
	TitleChild,
} from '@zeal/zeal-ui';
import ACLBoundary from '../../../../App/ACL/ACLBoundary';

export default function LoyaltyCardPunchValue({ cardIndex, ...props }) {
	const [punchValue, setPunchValue] = React.useState(0);

	const IconComp = !props.isFillingPunch && (
		<Icon bg="disabled" name="edit" rounded size="lg" p="xs" />
	);

	React.useEffect(() => {
		setPunchValue(props?.punch ?? 0);
	}, [props?.punch]);

	const handlePunchValueChange = (evt) => {
		props.handlePunchValueChange(cardIndex, evt.target.value);
	};
	const handleKeyDown = (event) => {
		if (event.key === 'Enter') {
			props.handleCardBlur(event, cardIndex);
		}
	};

	const handlePunchValueEdit = () => {
		props.handleEditBtnClick(cardIndex);
		setPunchValue(props?.punch);
	};

	const titleChildComp = props.isFillingPunch ? (
		<Center>
			<Input.Transparent
				placeholder=""
				inputName="punchValue"
				value={punchValue === 0 ? '' : punchValue}
				autoFocus
				textSize="lg"
				textAlign="center"
				onChange={handlePunchValueChange}
				onKeyPress={handleKeyDown}
			/>
		</Center>
	) : (
		<Title title={punchValue.toString()} size="lg" />
	);

	return (
		<ACLBoundary>
			<MainStackWithIcon
				icon={IconComp}
				xPosition="right"
				yPosition="bottom"
				iconPadding="none"
				primary={props.isNewlyCreated}
				buttonProps={{
					onClick: handlePunchValueEdit,
				}}
				aclUnAuthProps={{ disabled: true }}
				aclGrants={['control:loyalty']}
			>
				<Center>
					<Stack m="lg">
						<TitleChild
							title="Punches Value"
							size="sm"
							white={props.isNewlyCreated}
						>
							{titleChildComp}
						</TitleChild>
					</Stack>
				</Center>
			</MainStackWithIcon>
		</ACLBoundary>
	);
}
