import React, { useEffect, useState } from 'react';
import { DateTime } from 'luxon';

import {
	Align,
	Button,
	ButtonWithIcon,
	Grid,
	Image,
	InlineError,
	InlineLoading,
	Input,
	Label,
	Modal,
	Select,
	Stack,
	Title,
	TwoSideLayout,
} from '@zeal/zeal-ui';

import useStories, { useAddStory } from '../data/useStories';
import PhotoUploader from './PhotoUploader';
import HandleOnStorySybmit from './HandleOnStorySybmit';
import formatDate from './FormatDate';

export default function AddEditModal(props) {
	const {
		isModalOpen,
		closeModal,
		handleFilesUpload,
		photo,
		uuid,
		branch,
		media,
		resetForm,
		expireDate,
		selected,
		branch_uuid,
		setExpireDate,
		optionList,
		optionsHandel,
		onBranchChange,
		setBranchUuid,
		removeError,
		setRemoveError,
	} = props;
	const [openUploader, setOpenUploader] = useState();
	const modalTitle = uuid ? 'Edit Story' : 'Add Story';
	const modalSubmitLabel = uuid ? 'Edit Story' : 'Add Story';

	const storiesQuery = useStories();
	let { doAddStory, isError, isLoading, status } = useAddStory({
		onSuccess: () => {
			storiesQuery.refetch();
			closeModal(false);
			resetForm();
		},
	});

	const addStory = (story) => {
		doAddStory(story);
		storiesQuery.refetch();
		setRemoveError(true);
	};

	const handleOn = React.useCallback(() => {
		let formData = HandleOnStorySybmit(
			expireDate,
			media,
			branch_uuid,
			selected,
			uuid,
		);

		addStory(formData);
	}, [media, branch_uuid, expireDate]);

	return (
		<Modal isModalOpen={isModalOpen} title={modalTitle} onClose={closeModal}>
			<Stack>
				<PhotoUploader
					setOpenUploader={setOpenUploader}
					photo={photo}
					media={media}
				/>

				<Input.Upload
					onDropFiles={handleFilesUpload}
					acceptedFiles="image/*"
					maxFiles="1"
					openUploader={openUploader}
					style={{ display: media || photo ? 'none' : 'block' }}
				/>
				<Label hide={!isError + !removeError} error="red">
					Hint : The media field is required.
				</Label>
				<Stack>
					<Title title="Pick Branch" margin="xs" />
					<Select
						inputName="branch"
						placeholder={branch ? branch : '--Choose Branch--'}
						options={optionList}
						selected={branch}
						onClick={optionsHandel()}
						onChange={(e) => {
							setBranchUuid(e.value);
							onBranchChange(e.label);
						}}
					/>
					<Title title="Expiration Date" margin="xs" />
					<Input.Date
						transparent
						showTimeSelect
						validateType="true"
						placeholder="dd MMM yyyy  hh:mm aa"
						selected={expireDate ? expireDate : new Date(selected)}
						onChange={(date) => setExpireDate(date)}
					/>
					<Label hide={!isError + !removeError} error="red">
						Hint : The expires in must be a date after now.
					</Label>
				</Stack>
				<Grid gap="5">
					<Title title={<hr />} margin="2xl" />
					<TwoSideLayout>
						<ButtonWithIcon
							stretch={false}
							size="md"
							m="xs"
							px="sm"
							py="none"
							onClick={closeModal}
						>
							Cancel
						</ButtonWithIcon>
						<Align align="left">
							<InlineError hide={!isError + !removeError} />
							<InlineLoading hide={!isLoading} />
							<ButtonWithIcon
								stretch={false}
								size="md"
								primary
								m="xs"
								px="sm"
								py="none"
								onClick={handleOn}
							>
								{modalSubmitLabel}
							</ButtonWithIcon>
						</Align>
					</TwoSideLayout>
				</Grid>
			</Stack>
		</Modal>
	);
}
