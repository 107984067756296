import React from 'react';
import { isNaN } from 'lodash-es';
import {
	Center,
	ChartPictorialBar,
	InlineEmpty,
	InlineError,
	InlineLoading,
	Stack,
	Title,
	className as cx,
} from '@zeal/zeal-ui';

import useAnalyticsLapsedCustomers from '../data/useAnalyticsLapsedCustomers';

export default function LapsedCustomers(props) {
	const { data } = useAnalyticsLapsedCustomers();
	let overview = useAnalyticsLapsedCustomers();

	const cRate = data?.churn_rate;
	const value = 100 - cRate;

	const subtextCn = cx('p-2 pt-0', isNaN(value) && '!hidden');

	let topBar;
	let bottomBar;

	if (value > 50) {
		topBar = 100;
		bottomBar = 100 - cRate * 2;
	} else if (value < 50) {
		topBar = 100 - (cRate - 50) * 2;
		bottomBar = 0;
	} else if (value === 50) {
		topBar = 100;
		bottomBar = 0;
	}

	return (
		<Stack gap="sm" p="xs" borderColor="gray" className="border-b">
			<Title className="mx-4" titleText="Lapsed Consumers" />
			{data ? (
				<>
					<div className="w-full h-28">
						<ChartPictorialBar
							options={{
								series: [
									{
										data: [topBar, bottomBar],
									},
								],
							}}
						/>
					</div>

					<Title
						size="xs"
						className={subtextCn}
						titleText={`${cRate}% of customers didn't come back this month`}
					/>
				</>
			) : (
				<Stack
					m="xs"
					gap="sm"
					overflowAuto
					mx="sm"
					maxH="md"
					children={
						<Center>
							<Stack m="lg">
								<InlineError hide={!overview.isError} />
								<InlineLoading hide={!overview.isLoading} />
							</Stack>
						</Center>
					}
				/>
			)}
		</Stack>
	);
}
