import React from 'react';
import { Stack } from '@zeal/zeal-ui';

import AppContainer from '../App/AppContainer';
import ConsumersRecords from './components/ConsumersRecords';

export default function Consumers(props) {
	return (
		<AppContainer
			protected
			breadcrumbs={[
				{
					name: 'Customers',
					to: '/customers',
					icon: 'userShoppingAlt',
				},
			]}
		>
			<Stack m="md">
				<ConsumersRecords />
			</Stack>
		</AppContainer>
	);
}
