import React from 'react';
import { DateTime } from 'luxon';

import {
	Button,
	ButtonWithIcon,
	Icon,
	Input,
	Label,
	Select,
	Stack,
	className as cx,
} from '@zeal/zeal-ui';

import { useQueryString } from '../../App/useQueryString';
import { relationalOperatorsMap } from '../../utils/relationalOperators';
import FilterRuleHandle from './FilterRuleHandle';

export default function FilterRule(props) {
	const [, updateQuery] = useQueryString();

	const [hasChanges, setHasChanges] = React.useState(false);
	const [filter, setFilter] = React.useState(props.rule);
	const [operator, setOperator] = React.useState(props.rule?.operator);
	const [operand, setOperand] = React.useState(props.rule?.operand);
	const [col, setCol] = React.useState(props.rule);

	React.useEffect(() => {
		setFilter(props.rule);
	}, [props.rule, setFilter]);

	React.useEffect(() => {
		setHasChanges(
			col?.value !== props.rule?.value
				|| operator !== props.rule?.operator
				|| operand !== props.rule?.operand,
		);
	}, [
		operand,
		operator,
		col?.value,
		props.rule?.value,
		props.rule?.operator,
		props.rule?.operand,
	]);

	const handleOnInit = () => setFilter({});

	const handleOnCancelFilter = () => {
		setFilter(undefined);
		setFilter(undefined);
		setOperator(undefined);
		setOperand(undefined);
		setCol(undefined);
	};

	const handleOnClearFilter = () => {
		props?.onClear?.(filter);

		if (filter.value) {
			updateQuery({
				[filter.value]: undefined,
				page: 1,
			});
		}
	};

	const handleOnApplyFilter = () => {
		if (!col?.value || !operand || !operator) {
			return null;
		}

		props?.onChange?.(
			{
				...col,
				operand,
				operator,
			},
			filter,
		);

		if (col.value) {
			updateQuery({
				[col.value]: [relationalOperatorsMap().getCode(operator), operand],
				page: 1,
			});
		}

		if (props.rule === false) {
			setFilter(undefined);
			setOperator(undefined);
			setOperand(undefined);
			setCol(undefined);
		} else {
			setFilter({
				...col,
				operand,
				operator,
			});
		}
	};

	const options = props.rule ? [props.rule, ...props.options] : props.options;

	const colOptions = options?.filter((i) => {
		const noSelected = i.value !== col?.value;
		return !(noSelected && i.isActive);
	});

	if (props.hide) {
		return null;
	}

	if (!filter) {
		return <FilterRuleHandle handleOnInit={handleOnInit} />;
	}

	const checkBtnCn = cx(props?.rule && !hasChanges && '!hidden');
	const cancelBtnCn = cx(props?.rule && '!hidden', props?.rule || '!block');

	const trashBtnCn = cx(props?.rule || '!hidden', props?.rule && '!block');

	const checkIfMatchRule = (e) => {
		if (col?.type === 'integer') {
			return /^\d+$/.exec(e) || e === '';
		}
		if (col.type === 'float') {
			return /^[+-]?([0-9]+\.?[0-9]*|\.[0-9]+)$/.exec(e) || e === '';
		}
		return true;
	};
	function handlerRelationalOperators() {
		if (
			col?.value == 'visits'
			|| col?.value == 'spent'
			|| col?.value == 'redeems'
		)
		{return relationalOperatorsMap().getOnlySymbol(['>', '<', '=']);}

		if (col?.value == 'phoneNumber')
		{return relationalOperatorsMap().getOnlySymbol(['=']);}

		return relationalOperatorsMap().getExcludeSymbol(['like', '!=']);
	}
	const handleNumber = (event) => {
		if (!/[0-9]/.test(event.key)) {
			event.preventDefault();
		}
	};
	const handleAfterSelect = (e) => {
		setCol(e);
		setOperand('');
		setOperator('');
	};
	return (
		<Stack row gap="md">
			<Icon name="dotsVertical" m="auto" />
			<Select
				state={col ? 'secondary' : 'light'}
				placeholder={col ? col : 'Select property'}
				selected={col}
				disable={filter?.col}
				onChange={handleAfterSelect}
				options={colOptions}
			/>
			<Select
				state={operator ? 'secondary' : 'light'}
				placeholder={operator ? operator : 'Pick an Operator'}
				selected={operator}
				onChange={setOperator}
				options={handlerRelationalOperators()}
			/>
			<div className="mt-1">
				{col?.value !== 'signupDate'
				&& col?.value !== 'firstPunch'
				&& col?.value !== 'lastVisit' ? (
						<Input
							className="relative py-1.5"
							mainClassName="w-auto"
							type="text"
							pattern="[0-9]"
							onKeyPress={handleNumber}
							inputName="title"
							margin="none"
							value={operand || ''}
							onChange={(evt) =>
								checkIfMatchRule(evt.target.value) && setOperand(evt.target.value)
							}
							placeholder="Enter value"
						/>
					) : (
						<Input.Date
							placeholder="Date: All Time"
							onChange={(evt) =>
								checkIfMatchRule(evt)
							&& setOperand(DateTime.fromJSDate(evt).toISODate())
							}
							value={operand}
							autocomplete="off"
							inputName="date"
						/>
					)}
			</div>
			<Stack row gap="sm" p="none" my="xxs" mt="xs">
				<ButtonWithIcon
					secondary
					m="xxs"
					px="sm"
					py="sm"
					className={checkBtnCn + 'py-0'}
					onClick={handleOnApplyFilter}
				>
					Apply Filter
				</ButtonWithIcon>
				<ButtonWithIcon
					danger
					m="xxs"
					px="sm"
					py="sm"
					className={cancelBtnCn + 'py-0'}
					onClick={handleOnCancelFilter}
				>
					Delete Filter
				</ButtonWithIcon>
				<ButtonWithIcon
					danger
					m="xxs"
					px="sm"
					py="sm"
					className={trashBtnCn}
					onClick={handleOnClearFilter}
				>
					Delete Filter
				</ButtonWithIcon>
			</Stack>
		</Stack>
	);
}
