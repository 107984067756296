import React from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';

import ACLBoundary from '../App/ACL/ACLBoundary';
import { UnAuthCard } from '../App/UnAuth';

import Photos from './components/Photos';
import Videos from './components/Videos';
import GalleryFrame from './GalleryFrame';

export default function GalleryRoutes() {
	return (
		<GalleryFrame>
			<Switch>
				<ACLBoundary
					aclUnAuthProps={{ replace: true }}
					aclGrants={['view:gallery']}
					unAuthComponent={UnAuthCard}
				>
					<Route path="/gallery/photos" component={Photos} />
					<Route path="/gallery/videos" component={Videos} />
					<Route path="/gallery">
						<Redirect push to="/gallery/photos" />
					</Route>
				</ACLBoundary>
			</Switch>
		</GalleryFrame>
	);
}
