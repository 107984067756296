import React from 'react';
import {
	BasicTable,
	InlineEmpty,
	InlineError,
	InlineLoading,
	Pagination,
	Stack,
} from '@zeal/zeal-ui';

import useVisitTransactions, { formatFilters, useExportVisits } from '../data/useVisitsTransactions';
import { useQueryString } from '../../App/useQueryString';
import openToastr from '../../App/Toastr/openToastr';

import VisitsActions from './VisitsActions';
import CompoundFilters from './CompoundFilters';
import renderRelativeDateTime from './renderRelativeDateTime';

export default function TransactionsRecords(props) {
	const [queryState, updateQuery] = useQueryString();

	const { page } = queryState;

	const [isAllSelected, setIsAllSelected] = React.useState(false);

	const { data, pagesList, isLoading, isError } = useVisitTransactions();

	React.useEffect(() => {
		updateQuery((s) => ({ page: s.page || 1 }));
	}, []);

	const handleOnPageChange = ({ num }) => {
		updateQuery({ page: num });
	};

	const toggleAllSelected = (state) => {
		setIsAllSelected(state);
	};

	const isEmpty = !data?.data?.length;

	const tableColumns = [
		{ Header: 'Customer', accessor: 'member.name' },
		{ Header: 'Branch', accessor: 'branch.name' },
		{
			Header: 'Date',
			accessor: 'created_at',
			Cell: renderRelativeDateTime,
		},
		{ Header: 'Receipt No.', accessor: 'receipt_number' },
		{
			Header: 'Receipt Value',
			accessor: 'receipt',
		},
		{
			Header: 'Points Added',
			accessor: 'details.points',
		},
		{
			Header: 'From Wallet',
			accessor: 'wallet_paid',
		},
		{
			Header: 'Team',
			accessor: 'team.name',
		},
		{
			Header: 'Payment Type',
			accessor: 'payment_method',
		},
	];

	const { doExportVisits, isLoading: isExportLoading } = useExportVisits({
		onSuccess: () => {
			openToastr({
				message:
					'Exporting File Successfully Requested. Please navigate to exports page to Download',
			});
		},
		onError: (error) => {
			if (error?.response?.status === 500)
			{openToastr({
				message: 'Something went wrong',
			});}
			else {
				if (!error?.response?.bodyUsed)
				{error?.response?.json().then((err) => {
					openToastr({
						message: err.message || 'Failed to Export visits',
						isError: true,
					});
				});}
			}
		},
	});

	const exportTransaction = (format) => {
		const formattedFilters = formatFilters(queryState);

		doExportVisits({
			format: format?.value,
			...(formattedFilters?.length > 0 && { filters: formattedFilters }),
		});
	};

	const visitsTableActions = (
		<div>
			<Stack>
				<hr />
			</Stack>
			<VisitsActions
				toggleAllSelected={toggleAllSelected}
				isAllSelected={isAllSelected}
				exportTransaction={exportTransaction}
				isExportLoading={isExportLoading}
			/>
		</div>
	);

	const tableTopContent = (
		<div>
			<CompoundFilters />

			{visitsTableActions}
		</div>
	);

	const showPagination = pagesList?.length > 1 && data?.data?.length > 0 && (
		<Pagination
			pages={pagesList}
			current={page}
			onPageChange={handleOnPageChange}
		></Pagination>
	);

	return (
		<div>
			<BasicTable
				border
				data={data?.data || []}
				topContent={tableTopContent}
				isError={isError}
				isEmpty={isEmpty}
				isLoading={isLoading}
				errorContent={<InlineError className="w-full py-10 !justify-center" />}
				emptyContent={<InlineEmpty className="w-full py-10 !justify-center" />}
				loadingContent={
					<InlineLoading className="w-full py-10 !justify-center" />
				}
				columns={tableColumns}
			/>
			{showPagination}
		</div>
	);
}
