import React, { Fragment, useEffect } from 'react';
import toast from 'react-hot-toast';

import zealLogo from '@zeal/zeal-ui/src/assets/images/zeal-logo-colored.png';
import zealLogoMin from '@zeal/zeal-ui/src/assets/images/zeal-logo-min.png';

import {
	ButtonWithIcon,
	Card,
	HalvesMainImg,
	InlineLoading,
	Input,
	Label,
	Title,
	TwoSideLayout,
} from '@zeal/zeal-ui';

import { useLogin } from '../ACL/useLogin';
import ForgotPassword from '../ForgotPassword/forgotPassword';
import openToastr from '../Toastr/openToastr';

export default function Login(props) {
	const [email, setEmail] = React.useState();
	const [password, setPassword] = React.useState();
	const [showForgotPassword, setShowForgotPassword] = React.useState(false);
	const [errorToasterId, setErrorToasterId] = React.useState(null);

	const { doLogin, error, isLoading } = useLogin({
		onSuccess: () => {
			const { history, location } = props;

			if (location?.state?.shouldGoBack) {
				const { fromLocation } = location?.state;
				history.push(fromLocation.pathname);
				return null;
			} else {
				history.push('/');
				return null;
			}
		},
	});

	useEffect(() => {
		if (error?.response) {
			let errMsg = '';

			if ([400, 422].includes(error?.response?.status)) { errMsg = 'Wrong email or password'; }
			else { errMsg = 'Server error. Please try again Later'; }

			setErrorToasterId(openToastr({ message: errMsg, isError: true }));
		}
	}, [error]);

	const handleEnterKeyPress = React.useCallback((event) => {
		if (event.key === 'Enter') {
			handleLoginSubmit();
		}
	}, [email, password, doLogin]);

	const handleLoginSubmit = React.useCallback(() => {
		if (email && password) {
			doLogin({ email, password, keepSignIn: true });
			if (errorToasterId) {
				toast.dismiss(errorToasterId);
			}
		} else {
			// pass
		}
	}, [email, password, doLogin]);

	const loginCardTitle = (
		<Fragment>
			Login To Your <br /> Zeal Dashboard
		</Fragment>
	);

	const loginButtonContent = isLoading ? (
		<InlineLoading textColor="default" label=" " />
	) : (
		<Label textColor="default" my="xs">
			Login
		</Label>
	);

	const loginForm = (
		<div>
			<Card shadow="xl" border={false} p="lg" maxWidth="md">
				<Title
					size="lg"
					titleText={loginCardTitle}
					subtitleText="Connecting you with your customers seamlessly."
				/>

				<Input
					type="email"
					inputName="email"
					placeholder="Email address"
					onChange={(evt) => setEmail(evt.target.value)}
					onKeyPress={handleEnterKeyPress}
				/>
				<Input
					type="password"
					placeholder="Password"
					inputName="password"
					onChange={(evt) => setPassword(evt.target.value)}
					onKeyPress={handleEnterKeyPress}
				/>
				<Title
					margin="xs"
					title={
						<TwoSideLayout>
							<span></span>
							<ButtonWithIcon
								transparent
								m="none"
								p="none"
								onClick={() => {
									setShowForgotPassword(true);
								}}
							>
								<Title primary title="Forgot Password?" />
							</ButtonWithIcon>
						</TwoSideLayout>
					}
				/>
				<ButtonWithIcon
					primary
					stretch
					size="md"
					disabled={isLoading}
					onClick={handleLoginSubmit}
				>
					{loginButtonContent}
				</ButtonWithIcon>
			</Card>
		</div>
	);

	const shownCard = showForgotPassword ? (
		<ForgotPassword setShowForgotPassword={setShowForgotPassword} />
	) : (
		loginForm
	);

	return (
		<HalvesMainImg
			img={zealLogo}
			smallLogo={zealLogoMin}
			imageContainerClassName="side-bg"
			wrapOn="lg"
		>
			{shownCard}
		</HalvesMainImg>
	);
}
