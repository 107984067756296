import React, { useEffect } from 'react';
import { useMutation } from 'react-query';

import { ApiRequest } from '@zeal/zeal-lib';
import { useQuery } from '../../../../App/QueryClient';

async function apiLoyaltyPoints({ queryKey: [, filters] }) {
	return new ApiRequest('rewards')
		.addHeader('Accept', 'application/json')
		.GET();
}

export default function useLoyaltyPoints() {
	const q = useQuery({
		queryFn: apiLoyaltyPoints,
		staleTime: Infinity,
		queryKey: ['z-loyalty-points'],
	});

	React.useDebugValue(q);

	return q;
}

async function apiAddReward({ ...payload }) {
	return new ApiRequest('rewards')
		.addHeader('Accept', 'application/json')
		.addHeader('Content-Type', 'application/json')
		.POST(JSON.stringify(payload));
}

export function useAddReward(opt) {
	const mutation = useMutation(apiAddReward);

	const { data, mutate, isSuccess } = mutation;

	useEffect(() => {
		if (!isSuccess) {
			return null;
		}

		if (opt.onSuccess) {
			opt.onSuccess();
		}
	}, [data, isSuccess]);

	return {
		...mutation,
		doAddReward: mutate,
	};
}

async function apiUpdateReward({ ...payload }) {
	return new ApiRequest(`rewards/${payload?.rewardId}`)
		.addHeader('Accept', 'application/json')
		.addHeader('Content-Type', 'application/json')
		.POST(JSON.stringify(payload));
}

export function useUpdateReward(opt) {
	const mutation = useMutation(apiUpdateReward);

	const { data, mutate, isSuccess } = mutation;

	useEffect(() => {
		if (!isSuccess) {
			return null;
		}

		if (opt.onSuccess) {
			opt.onSuccess();
		}
	}, [data, isSuccess]);

	return {
		...mutation,
		doUpdateReward: mutate,
	};
}

async function apiDeleteReward({ ...payload }) {
	return new ApiRequest(`rewards/${payload?.rewardId}`)
		.addHeader('Accept', 'application/json')
		.addHeader('Content-Type', 'application/json')
		.DELETE();
}

export function useDeleteReward(opt) {
	const mutation = useMutation(apiDeleteReward);

	const { data, mutate, isSuccess } = mutation;

	React.useEffect(() => {
		if (!isSuccess) {
			return null;
		}

		if (opt.onSuccess) {
			opt.onSuccess();
		}
	}, [data, isSuccess]);

	return {
		...mutation,
		doDeleteReward: mutate,
	};
}
