import React from 'react';

import {
	Align,
	Button,
	ButtonWithIcon,
	Grid,
	InlineError,
	InlineLoading,
	Modal,
	Stack,
	Title,
	TwoSideLayout,
} from '@zeal/zeal-ui';

import useVideos, { useDeleteVideo } from '../data/useVideos';

export default function DeleteVideoModal(props) {
	const {
		isDeleteModalOpen,
		closeDeleteModal,
		setIsDeleteModalOpen,
		formState,
		removeError,
		setRemoveError,
		id,
	} = props;
	const videosQuery = useVideos();

	const { doDeleteVideo, isError, isLoading, isSuccess, status }
		= useDeleteVideo({
			onSuccess: () => {
				videosQuery.refetch();
				setIsDeleteModalOpen(false);
			},
		});
	const handleDelete = (id) => {
		doDeleteVideo({ id });
		setRemoveError(true);
	};

	return (
		<Modal
			isModalOpen={isDeleteModalOpen}
			title="Delete Video"
			onClose={closeDeleteModal}
		>
			<Stack p="none">
				<Title title="Are you sure you want to delete this video?" />
				<Grid gap="5">
					<span></span>
					<TwoSideLayout>
						<ButtonWithIcon
							stretch={false}
							m="xxs"
							px="md"
							py="sm"
							onClick={closeDeleteModal}
						>
							Cancel
						</ButtonWithIcon>
						<Align align="left">
							<InlineError hide={!isError + !removeError} />
							<InlineLoading hide={!isLoading} />
							<ButtonWithIcon
								secondary
								stretch={false}
								m="xxs"
								px="md"
								py="sm"
								onClick={() => handleDelete(id)}
							>
								Delete
							</ButtonWithIcon>
						</Align>
					</TwoSideLayout>
				</Grid>
			</Stack>
		</Modal>
	);
}
