import React, { useReducer, useState } from 'react';
import {
	ButtonWithIcon,
	Card,
	Center,
	Floating,
	Grid,
	Header,
	Icon,
	Image,
	SizedBox,
	Stack,
	Title,
	TwoSideLayout,
} from '@zeal/zeal-ui';
import usePhotos, { useAddPhoto } from '../data/usePhotos';
import ACLBoundary from '../../App/ACL/ACLBoundary';
import EmptyState from './EmptyState';
import AddEditModal from './AddEditModal';
import DeletePhotoModal from './DeletePhotoModal';


function addEditFormReducer(prevState, newState) {
	return { ...prevState, ...newState };
}
export default function Photos({ ...props }) {
	const [photosData, setPhotosData] = useState();
	const [isEditing, setIsEditing] = useState(false);
	const [isAddEditModalOpen, setIsAddEditModalOpen] = useState(false);
	const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
	const [id, setId] = useState('');
	const [removeError, setRemoveError] = React.useState(true);

	const initialAddEditForm = {
		photo: null,
		description: '',
		id: '',
		url: null,
	};

	const [formState, formDispatch] = useReducer(
		addEditFormReducer,
		initialAddEditForm,
	);

	const photosQuery = usePhotos();
	const { data } = photosQuery;

	const { doAddPhoto, isError, isLoading } = useAddPhoto({
		onSuccess: () => {
			photosQuery.refetch();
			setIsAddEditModalOpen(false);
			resetAddEditModal();
		},
	});

	const openAddEditModal = () => {
		setIsAddEditModalOpen(true);
		setRemoveError(false);
	};

	const openAddModal = () => {
		setIsEditing(false);
		resetAddEditModal();
		openAddEditModal();
		setRemoveError(false);
	};

	const openEditModal = (photo) => {
		setIsEditing(true);
		formDispatch({
			url: photo?.url,
			photo: photo?.photo,
			description: photo?.description,
			id: photo?.id,
		});
		openAddEditModal();
		setRemoveError(false);
	};

	const resetAddEditModal = () => {
		formDispatch(initialAddEditForm);
	};

	const optionList = [];
	const optionsHandel = (data) => {
		data?.data?.map((d) => d.type == 'photo' && optionList.push(d));
	};

	React.useEffect(() => {
		optionsHandel(data);
		setPhotosData(optionList);
	}, [data]);

	const handleClose = (item) => {
		setIsDeleteModalOpen(true);
		setId(item.id);
		setRemoveError(false);
	};
	const ActionsComp = (item) => (
		<TwoSideLayout>
			<Stack row>
				<Center>
					<ACLBoundary>
						<ButtonWithIcon
							transparent
							p="none"
							m="none"
							onClick={() => {}}
							aclUnAuthProps={{ disabled: true }}
							aclGrants={['control:gallery']}
						>
							<Icon bg="red" name="heart" size="lg" onClick={() => {}} />
						</ButtonWithIcon>
					</ACLBoundary>
				</Center>
			</Stack>
			<Stack row>
				<ACLBoundary>
					<ButtonWithIcon
						transparent
						p="none"
						m="none"
						onClick={() => openEditModal(item)}
						aclUnAuthProps={{ disabled: true }}
						aclGrants={['control:gallery']}
					>
						<Icon bg="snow" rounded name="edit" size="xl" p="sm" />
					</ButtonWithIcon>
					<ButtonWithIcon
						transparent
						p="none"
						m="none"
						onClick={() => handleClose(item)}
						aclUnAuthProps={{ disabled: true }}
						aclGrants={['control:gallery']}
					>
						<Icon bg="snow" rounded name="trash" size="xl" p="sm" />
					</ButtonWithIcon>
				</ACLBoundary>
			</Stack>
		</TwoSideLayout>
	);

	const photosCards
		= photosData?.length > 0
		&& photosData?.map((item) => (
			<Card p="none" key={item.id}>
				<Floating
					stretch
					floatingComponent={ActionsComp(item)}
					yPosition="bottom"
				>
					<Stack p="none">
						<SizedBox height="3xl">
							<Image
								cover
								rounded
								src={
									(item?.photo && URL.createObjectURL(item?.photo)) || item?.url
								}
								alt="card"
							/>
						</SizedBox>
					</Stack>
				</Floating>
				<Stack m="md" p="none">
					<Title
						subtitleClassName="line-clamp"
						size="sm"
						subtitleText={
							item?.description ? item?.description : 'No Description Yet!'
						}
					/>
				</Stack>
			</Card>
		));

	const closeAddEditModal = () => {
		setIsAddEditModalOpen(false);
	};

	const handleAddEditSubmit = () => {
		setRemoveError(true);

		const formData = new FormData();

		if (typeof formState?.photo == 'object') {
			formData.append('photo', formState?.photo);
		}
		formData.append('description', formState.description);
		formData.append('id', formState.id);
		doAddPhoto(formData);
	};

	const closeDeleteModal = () => {
		setIsDeleteModalOpen(false);
	};

	const handleRewardDelete = () => {
		closeDeleteModal();
		photosQuery.refetch();
	};

	return (
		<div>
			<Header
				padding="sm"
				startContent={<span></span>}
				endContent={
					<ACLBoundary>
						<ButtonWithIcon
							primary
							label="Add Photo"
							btnIcon="plus"
							size="xs"
							m="none"
							onClick={openAddModal}
							aclUnAuthProps={{ disabled: true }}
							aclGrants={['control:gallery']}
						/>
					</ACLBoundary>
				}
			/>
			<Stack m="md">
				<Grid cols="3" gap="3" withSpace>
					{photosCards}
				</Grid>
				<EmptyState data={photosData} openAddModal={openAddModal} />
			</Stack>
			<AddEditModal
				isEditing={isEditing}
				isAddEditModalOpen={isAddEditModalOpen}
				closeAddEditModal={closeAddEditModal}
				handleAddEditSubmit={handleAddEditSubmit}
				formDispatch={formDispatch}
				formState={formState}
				removeError={removeError}
				setRemoveError={setRemoveError}
				isLoading={isLoading}
				isError={isError}
			/>
			<DeletePhotoModal
				isDeleteModalOpen={isDeleteModalOpen}
				closeDeleteModal={closeDeleteModal}
				handleRewardDelete={handleRewardDelete}
				setIsDeleteModalOpen={setIsDeleteModalOpen}
				removeError={removeError}
				setRemoveError={setRemoveError}
				id={id}
			/>
		</div>
	);
}
