import React from 'react';
import { useMutation } from 'react-query';

import { ApiRequest } from '@zeal/zeal-lib';
import { useQuery } from '../../App/QueryClient';
import { useQueryString } from '../../App/useQueryString';
import { getLoggedInSession } from '../../App/ACL/useLogin';

async function apiStories({ queryKey: [, filters] }) {
	return new ApiRequest('v3/stories')
		.addHeader('Accept', 'application/json')
		.GET();
}

async function apiDeleteStory({ ...payload }) {
	const { authorization } = getLoggedInSession();

	return new ApiRequest(`v3/stories/${payload.uuid}`)
		.addHeader('Accept', 'application/json')
		.addHeader('Authorization', authorization)
		.addHeader('Content-type', 'application/json')
		.DELETE(JSON.stringify(payload), true);
}

async function apiBranch({ queryKey: [, filters] }) {
	return new ApiRequest('v3/branch').addHeader('Accept', 'application/json')
		.GET();
}

async function apiAddStory(payload) {
	let url = 'v3/stories';
	if (payload.get('uuid')) {url = `v3/stories/${payload.get('uuid')}`;}
	return new ApiRequest(url)
		.addHeader('Accept', 'application/json')
		.POST(payload);
}

export default function useStories() {
	const q = useQuery({
		queryFn: apiStories,
		staleTime: 1000 * 60 * 15, // 15 min
		queryKey: ['z-stories'],
	});

	React.useDebugValue(q);

	return q;
}

export function useDeleteStory(opt) {
	const mutation = useMutation(apiDeleteStory);

	const { data, mutate, isSuccess } = mutation;

	React.useEffect(() => {
		if (!isSuccess) {
			return null;
		}

		if (opt.onSuccess) {
			opt.onSuccess();
		}
	}, [data, isSuccess]);

	return {
		...mutation,
		doDeleteStory: mutate,
	};
}

export function useBranch() {
	const q = useQuery({
		queryFn: apiBranch,
		staleTime: 1000 * 60 * 15, // 15 min
		queryKey: ['z-branch'],
	});

	React.useDebugValue(q);

	return q;
}

export function useAddStory(opt) {
	const mutation = useMutation(apiAddStory);

	const { data, mutate, isSuccess } = mutation;

	React.useEffect(() => {
		if (!isSuccess) {
			return null;
		}

		if (opt.onSuccess) {
			opt.onSuccess();
		}
	}, [data, isSuccess]);

	return {
		...mutation,
		doAddStory: mutate,
	};
}
