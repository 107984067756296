import React, { Fragment } from 'react';
import PropTypes from 'prop-types';

import { Route } from 'react-router-dom';
import { Center, InlineLoading } from '@zeal/zeal-ui';
import UnAuth from '../UnAuth';

import useGrants from './useGrants';

export default function ACLBoundary(props) {
	const { unAuthComponent, children, aclGrants, asElement, aclUnAuthProps }
		= props;

	const UnAuthComponent = unAuthComponent || UnAuth;

	const {
		userGrants,
		isSuperAdmin,
		allFormattedGrants,
		isFetchingPermissions,
	} = useGrants();

	// if permissions query is loading return loading component
	if (isFetchingPermissions) {
		return (
			<div className="h-screen">
				<Center>
					<InlineLoading />
				</Center>
			</div>
		);
	}

	const changedChildren = React.Children.map(children, (child) => {
		if (!React.isValidElement(child)) {
			return;
		}

		const childGrants = [
			...(child?.props?.aclGrants || []),
			...(aclGrants || []),
		];

		const isChildRequiresGrants = childGrants.length === 0;

		const childUnAuthProps = child?.props?.aclUnAuthProps || aclUnAuthProps;

		const unAuthPropsInvalid
			= !childUnAuthProps || Object.keys(childUnAuthProps || {}).length === 0;

		if (unAuthPropsInvalid) {
			throw new Error('No aclUnAuthProps provided');
		}

		if (isChildRequiresGrants) {
			return child;
		}

		const isChildHaveUserGrant = (grant) =>
			(childGrants || []).some(
				(childPermission) => allFormattedGrants[childPermission] == grant.id,
			);

		// checks if user has any grant to access child
		const isAllowed = (userGrants || [])?.some(isChildHaveUserGrant);

		if (!isAllowed && !isSuperAdmin) {
			return manageChildState(child, UnAuthComponent, childUnAuthProps);
		}

		return removeGrantsProp(child);
	});

	const AsElement = asElement || Fragment;

	return <AsElement>{changedChildren}</AsElement>;
}

/**
 *
 * @param {React.ReactElement} child
 * @param {JSX.Element} UnAuthComponent
 * @returns {(React.ReactElement | JSX.Element)} New child
 */

function manageChildState(child, UnAuthComponent, aclUnAuthProps) {
	if (aclUnAuthProps.replace) {
		if (child.type === Route) {
			const { component: removed, ...childProps } = child.props;

			return <Route {...childProps} component={UnAuthComponent} />;
		} else {
			return <UnAuthComponent />;
		}
	} else {
		return React.cloneElement(child, { ...aclUnAuthProps });
	}
}

function removeGrantsProp(child) {
	const { aclGrants: removed, ...childProps } = child.props;

	const childWithoutGrantsProp = (
		<child.type key={child.key} ref={child.ref} {...childProps} />
	);

	return childWithoutGrantsProp;
}

ACLBoundary.propTypes = {
	unAuthComponent: PropTypes.element,
	asElement: PropTypes.element,
	aclGrants: PropTypes.array,
	aclUnAuthProps: PropTypes.object,
};
