import React, { useReducer, useState } from 'react';
import ReactPlayer from 'react-player';
import {
	ButtonWithIcon,
	Card,
	Grid,
	Header,
	Icon,
	MainStackWithIcon,
	Stack,
	Title,
} from '@zeal/zeal-ui';
import useVideos from '../data/useVideos';
import ACLBoundary from '../../App/ACL/ACLBoundary';
import VideoEmptyState from './VideoEmptyState';
import VideoAddEditModal from './VideoAddEditModal';
import DeleteVideoModal from './DeleteVideoModal';


function addEditFormReducer(prevState, newState) {
	return { ...prevState, ...newState };
}
export default function Videos({ ...props }) {
	const [videosData, setVideosData] = useState([]);
	const [isEditing, setIsEditing] = useState(false);
	const [isAddEditModalOpen, setIsAddEditModalOpen] = useState(false);
	const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
	const [removeError, setRemoveError] = React.useState(true);

	const initialAddEditForm = {
		url: '',
		description: '',
		id: '',
	};

	const [formState, formDispatch] = useReducer(
		addEditFormReducer,
		initialAddEditForm,
	);

	const videosQuery = useVideos();
	const { data } = videosQuery;

	React.useEffect(() => {
		setVideosData(data);
	}, [data]);

	const openAddEditModal = () => {
		setIsAddEditModalOpen(true);
		setRemoveError(false);
	};

	const openAddModal = () => {
		setIsEditing(false);
		resetAddEditModal();
		setRemoveError(false);
		openAddEditModal();
	};
	const openEditModal = (video) => {
		setIsEditing(true);
		formDispatch({
			url: video?.url,
			description: video?.description,
			id: video?.id,
		});
		openAddEditModal();
		setRemoveError(false);
	};

	const resetAddEditModal = () => {
		formDispatch(initialAddEditForm);
	};
	const optionList = [];
	const optionsHandel = (data) => {
		data?.data?.map((d) => d.type == 'video' && optionList.push(d));
	};

	React.useEffect(() => {
		optionsHandel(data);
		setVideosData(optionList);
	}, [data]);
	const handleClose = (item) => {
		setIsDeleteModalOpen(true);
		formDispatch({ id: item?.id });

		setRemoveError(false);
	};
	const ActionsComp = (item) => (
		<Stack row>
			<ACLBoundary
				aclUnAuthProps={{ disabled: true }}
				aclGrants={['control:gallery']}
			>
				<ButtonWithIcon
					stretch={false}
					p="none"
					m="none"
					transparent
					onClick={() => openEditModal(item)}
				>
					<Icon bg="snow" rounded name="edit" size="xl" p="sm" />
				</ButtonWithIcon>
				<ButtonWithIcon
					stretch={false}
					p="none"
					m="none"
					transparent
					onClick={() => handleClose(item)}
				>
					<Icon bg="snow" rounded name="trash" size="xl" p="sm" />
				</ButtonWithIcon>
			</ACLBoundary>
		</Stack>
	);

	const videosCards
		= videosData?.length > 0
		&& videosData?.map((item) => (
			<Card p="none">
				<MainStackWithIcon
					icon={ActionsComp(item)}
					xPosition="right"
					yPosition="bottom"
					iconPadding="none"
					fillContainer={false}
					primary={props.isNewlyCreated}
				>
					<Stack p="none">
						<ReactPlayer url={item?.url} width="100%" />
					</Stack>
				</MainStackWithIcon>

				<Stack m="md" p="none">
					<Title
						subtitleClassName="line-clamp"
						size="sm"
						subtitleText={
							item?.description ? item?.description : 'No Description Yet!'
						}
					/>
				</Stack>
			</Card>
		));

	const closeAddEditModal = () => {
		setIsAddEditModalOpen(false);
	};

	const closeDeleteModal = () => {
		setIsDeleteModalOpen(false);
	};

	const handleRewardDelete = () => {
		closeDeleteModal();
		videosQuery.refetch();
	};

	return (
		<div>
			<Header
				padding="sm"
				startContent={<span></span>}
				endContent={
					<ACLBoundary>
						<ButtonWithIcon
							primary
							label="Add Video"
							btnIcon="plus"
							size="xs"
							m="none"
							onClick={openAddModal}
							aclUnAuthProps={{ disabled: true }}
							aclGrants={['control:gallery']}
						/>
					</ACLBoundary>
				}
			/>
			<Stack m="md">
				<Grid cols="3" gap="3" withSpace>
					{videosCards}
				</Grid>
				<VideoEmptyState data={videosData} openAddModal={openAddModal} />
			</Stack>
			<VideoAddEditModal
				isEditing={isEditing}
				isAddEditModalOpen={isAddEditModalOpen}
				closeAddEditModal={closeAddEditModal}
				formDispatch={formDispatch}
				formState={formState}
				setIsAddEditModalOpen={setIsAddEditModalOpen}
				setRemoveError={setRemoveError}
				removeError={removeError}
			/>
			<DeleteVideoModal
				isDeleteModalOpen={isDeleteModalOpen}
				closeDeleteModal={closeDeleteModal}
				handleRewardDelete={handleRewardDelete}
				setIsDeleteModalOpen={setIsDeleteModalOpen}
				removeError={removeError}
				setRemoveError={setRemoveError}
				id={formState.id}
			/>
		</div>
	);
}
