import React from 'react';

import {
	Center,
	Icon,
	Input,
	Label,
	Stack,
	Title,
	TwoSideLayout,
} from '@zeal/zeal-ui';

import { renderErrorMessage } from './ErrorMessage';

export default function BranchAccount(props) {
	const { formDispatch, formState, arePasswordsMatch, isError, errMsgs }
		= props;

	const renderPasswordMatchLabel = arePasswordsMatch && <PasswordMatchLabel />;

	const showPasswordLengthMessage = formState.password?.length > 0
		&& formState.password?.length < 6 && (
		<Label textColor="danger" size="xs">
				Password must be 6 chars or more
		</Label>
	);

	return (
		<Stack m="md" p="none">
			<Stack p="none">
				<Title title="Branch Account" size="md" />
			</Stack>
			<TwoSideLayout>
				<Stack m="md" mb="none" p="none" marginX={false}>
					<Title muteSubtitle={false} subtitle="Username" size="md" />
					<Input
						type="text"
						inputName="title"
						placeholder="Add Username"
						onChange={(evt) => formDispatch({ username: evt.target.value })}
						value={formState.username}
						startHint={renderErrorMessage('username', isError, errMsgs)}
					/>
				</Stack>
			</TwoSideLayout>
			<Stack wrap row gap="xl">
				<Stack>
					<Title muteSubtitle={false} subtitle="Password" size="md" />
					<Input
						type="password"
						inputName="password"
						placeholder="Type Password"
						onChange={(evt) => formDispatch({ password: evt.target.value })}
						value={formState.password}
						startHint={
							renderErrorMessage('password', isError, errMsgs)
							|| showPasswordLengthMessage
						}
					/>
				</Stack>
				<Stack>
					<Title muteSubtitle={false} subtitle="Confirm Password" size="md" />
					<Input
						type="password"
						inputName="passwordConfirm"
						placeholder="Re-type Password"
						onChange={(evt) =>
							formDispatch({ confirmPassword: evt.target.value })
						}
						value={formState.confirmPassword}
						startHint={renderPasswordMatchLabel}
					/>
				</Stack>
			</Stack>
		</Stack>
	);
}

function PasswordMatchLabel() {
	return (
		<Stack row p="none">
			<Center horizontal={false}>
				<Title
					primary
					title={<Icon name="checkCircle" size="xs" m="none" />}
					size="xs"
				/>
				<Title primary title="Password Match" size="xs" />
			</Center>
		</Stack>
	);
}
