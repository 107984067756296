import React from 'react';

import { Card, Center, Icon, Title } from '@zeal/zeal-ui';

export default function UnAuthOwnApp() {
	return (
		<Card fillHeight m="md" p="sm">
			<Center column>
				<Icon name="lock" size="3xl" margin="md" />
				<Title
					margin="md"
					size="md"
					title="Please turn on custom app mode to access this page"
				/>
			</Center>
		</Card>
	);
}
