import React, { useCallback, useEffect, useMemo, useState } from 'react';
import PropTypes from 'prop-types';

import { Breadcrumbs, Center, Icon } from '@zeal/zeal-ui';

import AppMenu from '../AppMenu';
import { useAppMenuProvider } from '../AppMenuProvider';
import { useLoggedIn } from '../ACL/useLogin';

import UnAuthOwnApp from './components/UnAuthOwnApp';
import {
	OwnAppProvider,
	useOwnAppAccess,
	useOwnAppProvider,
} from './useOwnApp';
import OwnAppSelector from './components/OwnAppSelector';
import UnAuthorized from './components/UnAuthorized';
import {
	AppHeader,
	CollapseButton,
	Content,
	MainGrid,
} from './components/StyledComponents';

const mainGridVariants = {
	open: { '--main-navbar-width': '14rem' },
	minimizing: { '--main-navbar-width': '5.5rem' },
	minimized: { '--main-navbar-width': '5.5rem' },
	expanding: { '--main-navbar-width': '14rem' },
};

export default function AppContainer({ children, ...props }) {
	const { isLoggedIn } = useLoggedIn();
	const { isRouteAllowed } = useOwnAppAccess(props.ownApp);
	const [appMenuState, toggleAppMenu] = useAppMenuProvider();
	const [isMenuMini, setIsMenuMini] = useState(appMenuState === 'minimized');
	useOwnAppProvider(props.ownApp ?? false);

	useEffect(() => {
		setIsMenuMini(appMenuState === 'minimized');
	}, [appMenuState, setIsMenuMini]);

	const handleMenuToggle = useCallback(() => {
		toggleAppMenu();
	}, [toggleAppMenu]);

	const handleMenuAnimationDone = useCallback(() => {
		if (appMenuState === 'minimizing' || appMenuState === 'expanding') {
			toggleAppMenu();
		}
	}, [appMenuState, toggleAppMenu]);

	const breadcrumbs = props.breadcrumbs ? (
		<Breadcrumbs paths={props.breadcrumbs} />
	) : null;

	const content = isRouteAllowed ? children : <UnAuthOwnApp />;

	if (props.protected && !isLoggedIn) {
		return (
			<UnAuthorized
				unauthorizedRender={props.unauthorizedRender}
				unauthorizedComponent={props.unauthorizedComponent}
				unauthorizedRedirect={props.unauthorizedRedirect}
			/>
		);
	}

	return (
		<MainGrid
			animate={appMenuState}
			variants={mainGridVariants}
			initial={appMenuState}
			onAnimationComplete={handleMenuAnimationDone}
			transition={{
				type: 'easeIn',
				duration: 0.2,
			}}
		>
			<AppHeader
				padding="xl"
				startContent={breadcrumbs || <span />}
				middleContent={props.middleContent || <span />}
				endContent={<OwnAppSelector />}
				flex={props.flex}
			/>
			<AppMenu gridArea="menu" isCollapsed={isMenuMini} />
			<CollapseButton
				onClick={handleMenuToggle}
				style={{ left: 'calc(var(--main-navbar-width) - 0.9rem)' }}
			>
				<Center>
					<Icon
						name={isMenuMini ? 'chevronDoubleRight' : 'chevronDoubleLeft'}
						size="xs"
						margin="auto"
					/>
				</Center>
			</CollapseButton>
			<Content>{content}</Content>
		</MainGrid>
	);
}

AppContainer.propTypes = {
	public: PropTypes.bool,
	protected: PropTypes.bool,
	ownApp: PropTypes.bool,
	unauthorizedRender: PropTypes.func,
	unauthorizedRedirect: PropTypes.string,
	unauthorizedComponent: PropTypes.element,
};
