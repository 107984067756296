export default function renderRelativeDateTime({ value }) {
	if (!value) {
		return '--';
	}

	const date = new Date(value);
	return new Intl.DateTimeFormat('en-GB', {
		dateStyle: 'medium',
		timeStyle: 'short',
		hour12: true,
	}).format(date);
}
