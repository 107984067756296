import React from 'react';
import { useMutation } from 'react-query';
import { ApiRequest } from '@zeal/zeal-lib';
import { getLoggedInSession } from '../../App/ACL/useLogin';

async function apiSubmitAccount(body) {
	const { authorization } = getLoggedInSession();

	return new ApiRequest('v3/settings/account')
		.addHeader('Accept', 'application/json')
		.addHeader('Content-Type', 'application/json')
		.addHeader('Authorization', authorization)

		.PUT(JSON.stringify(body))
		.then((payload) => {});
}
export default function useSubmitAccount(opt) {
	const mutation = useMutation(apiSubmitAccount);

	const { data, mutate, isSuccess, isError } = mutation;

	React.useEffect(() => {
		if (isError) {
			if (opt.onError) {
				opt.onError();
			}
		}
		if (!isSuccess) {
			return null;
		}

		if (opt.onSuccess) {
			opt.onSuccess();
		}
	}, [data, isSuccess, isError]);

	return {
		...mutation,
		doAddAccount: mutate,
	};
}
