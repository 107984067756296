import React from 'react';

import { Stack } from '@zeal/zeal-ui';

import AppContainer from '../App/AppContainer';
import TransactionsRecords from './components/TransactionsRecords';

export default function VisitTransactions(props) {
	return (
		<AppContainer
			protected
			breadcrumbs={[
				{
					name: 'Visit Transactions',
					to: '/visit-transactions',
					icon: 'cash',
				},
			]}
		>
			<Stack m="md">
				<TransactionsRecords />
			</Stack>
		</AppContainer>
	);
}
