import React from 'react';

import MultiplePunches from '../../../components/MultiplePunches';
import PunchProperties from '../../../components/PunchProperties';
import WelcomeReward from '../../../components/WelcomeReward';

export default function RewardPropertiesAside(props) {
	return (
		<div>
			<PunchProperties />
			<hr />
			<MultiplePunches />
			<hr />
			<WelcomeReward />
		</div>
	);
}
