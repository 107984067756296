import React from 'react';

import { useLogin } from '../ACL/useLogin';

import EmailSentCard from './components/EmailSentCard';
import ForgetPasswordCard from './components/ForgetPasswordCard';

export default function ForgotPassword(props) {
	const [emailSent, setEmailSent] = React.useState(false);

	const { doLogin, isError, isLoading } = useLogin({
		onSuccess: () => {
			const { history, location } = props;

			if (location?.state?.shouldGoBack) {
				const { fromLocation } = location?.state;
				history.push(fromLocation.pathname);
				return null;
			} else {
				history.push('/');
				return null;
			}
		},
	});

	const shownCard = emailSent ? (
		<EmailSentCard />
	) : (
		<ForgetPasswordCard
			setShowForgotPassword={props.setShowForgotPassword}
			setEmailSent={setEmailSent}
		/>
	);

	return <div>{shownCard}</div>;
}
