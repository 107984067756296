import React from 'react';
import { useMutation } from 'react-query';

import { ApiRequest } from '@zeal/zeal-lib';
import { useQuery } from '../../App/QueryClient';
import { getLoggedInSession } from '../../App/ACL/useLogin';

async function apiPhotos({ queryKey: [, filters] }) {
	return new ApiRequest('v3/gallery')
		.addHeader('Accept', 'application/json')
		.GET();
}

async function apiAddPhoto(payload) {
	let url = 'v3/gallery/photo';
	if (payload.get('id')) {url = `v3/gallery/photo/${payload.get('id')}`;}
	return new ApiRequest(url)
		.addHeader('Accept', 'application/json')
		.POST(payload);
}

async function apiDeletePhoto({ ...payload }) {
	const { authorization } = getLoggedInSession();

	return new ApiRequest(`v3/gallery/${payload.id}`)
		.addHeader('Accept', 'application/json')
		.addHeader('Authorization', authorization)
		.addHeader('Content-type', 'application/json')
		.DELETE(JSON.stringify(payload), true);
}

export default function usePhotos() {
	const q = useQuery({
		queryFn: apiPhotos,
		staleTime: 1000 * 60 * 15, // 15 min
		queryKey: ['z-photos'],
	});

	React.useDebugValue(q);

	return q;
}

export function useAddPhoto(opt) {
	const mutation = useMutation(apiAddPhoto);

	const { data, mutate, isSuccess } = mutation;

	React.useEffect(() => {
		if (!isSuccess) {
			return null;
		}

		if (opt.onSuccess) {
			opt.onSuccess();
		}
	}, [data, isSuccess]);

	return {
		...mutation,
		doAddPhoto: mutate,
	};
}
export function useDeletePhoto(opt) {
	const mutation = useMutation(apiDeletePhoto);

	const { data, mutate, isSuccess } = mutation;

	React.useEffect(() => {
		if (!isSuccess) {
			return null;
		}

		if (opt.onSuccess) {
			opt.onSuccess();
		}
	}, [data, isSuccess]);

	return {
		...mutation,
		doDeletePhoto: mutate,
	};
}
