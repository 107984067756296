import React from 'react';

import { Stack, Title } from '@zeal/zeal-ui';

export default function RewardsToolbarStart({ title }) {
	return (
		<Stack m="md" my="none" mr="none" p="none">
			<Title title="Customize your rewards" />
		</Stack>
	);
}
