import React from 'react';

import {
	Card,
	Center,
	Dropdown,
	Icon,
	MainStackWithIcon,
	SizedBox,
	Title,
	className as cx,
} from '@zeal/zeal-ui';
import ACLBoundary from '../../App/ACL/ACLBoundary';

export default function CategoryCard(props) {
	const { data, active, onCardClick, categoryDropOptions } = props;

	const categoryIcon = (
		<ACLBoundary>
			<Dropdown
				padding
				options={categoryDropOptions}
				button={<Icon name="ellipsisHorizontal" size="xs" color="lightGray" />}
				aclUnAuthProps={{ disabled: true }}
				aclGrants={['control:menu']}
			/>
		</ACLBoundary>
	);

	const activeCategoryClassName = cx(active && 'zeal-active');

	const handleClick = () => {
		if (onCardClick) {onCardClick();}
	};

	return (
		<SizedBox fillHeight>
			<MainStackWithIcon
				pointer
				icon={categoryIcon}
				xPosition="right"
				yPosition="top"
				iconPadding="none"
				fillContainer={false}
			>
				<SizedBox width="60" fillHeight>
					<Card
						className={activeCategoryClassName}
						p="md"
						shadow="none"
						onClick={handleClick}
					>
						<Center horizontal={false} text={false}>
							<Title
								title={data?.title}
								size="sm"
								titleClassName="line-clamp"
							/>
						</Center>
					</Card>
				</SizedBox>
			</MainStackWithIcon>
		</SizedBox>
	);
}
