import React from 'react';
import { useMutation } from 'react-query';

import { ApiRequest } from '@zeal/zeal-lib';
import { useQuery } from '../../App/QueryClient';

async function apiArticles({ queryKey: [, filters] }) {
	return new ApiRequest('articles')
		.addHeader('Accept', 'application/json')
		.GET();
}

export default function useArticles() {
	const q = useQuery({
		queryFn: apiArticles,
		queryKey: [
			'z-all-articles',
			{
				paginate: false,
			},
		],
		staleTime: Infinity,
	});

	React.useDebugValue(q);

	return q;
}

async function apiAddArticle({ ...payload }) {
	return new ApiRequest('articles')
		.addHeader('Accept', 'application/json')
		.addHeader('Content-Type', 'application/json')
		.POST(JSON.stringify(payload));
}

export function useAddArticle(opt) {
	const mutation = useMutation(apiAddArticle);

	const { data, mutate, isSuccess, isError, error } = mutation;

	React.useEffect(() => {
		if (isError) {
			if (opt.onError) {
				opt.onError(error);
			}
		}

		if (!isSuccess) {
			return null;
		}

		if (opt.onSuccess) {
			opt.onSuccess();
		}
	}, [data, isSuccess, isError]);

	return {
		...mutation,
		doAddArticle: mutate,
	};
}

async function apiDeleteArticle({ ...payload }) {
	return new ApiRequest(`articles/${payload?.id}/delete`)
		.addHeader('Accept', 'application/json')
		.addHeader('Content-Type', 'application/json')
		.GET();
}

export function useDeleteArticle(opt) {
	const mutation = useMutation(apiDeleteArticle);

	const { data, mutate, isSuccess, isError, error } = mutation;

	React.useEffect(() => {
		if (isError) {
			if (opt.onError) {
				opt.onError(error);
			}
		}

		if (!isSuccess) {
			return null;
		}

		if (opt.onSuccess) {
			opt.onSuccess();
		}
	}, [data, isSuccess, isError]);

	return {
		...mutation,
		doDeleteArticle: mutate,
	};
}

async function apiPublishArticle({ ...payload }) {
	return new ApiRequest(`articles/${payload?.id}/publish`)
		.addHeader('Accept', 'application/json')
		.addHeader('Content-Type', 'application/json')
		.GET();
}

export function usePublishArticle(opt) {
	const mutation = useMutation(apiPublishArticle);

	const { data, mutate, isSuccess, isError, error } = mutation;

	React.useEffect(() => {
		if (isError) {
			if (opt.onError) {
				opt.onError(error);
			}
		}

		if (!isSuccess) {
			return null;
		}

		if (opt.onSuccess) {
			opt.onSuccess();
		}
	}, [data, isSuccess, isError]);

	return {
		...mutation,
		doPublishArticle: mutate,
	};
}
