import React from 'react';

import PunchProperties from '../../../components/PunchProperties';
import WelcomeReward from '../../../components/WelcomeReward';

export default function RewardProperties(props) {
	return (
		<div>
			<PunchProperties pointsEgp />
			<hr />
			<WelcomeReward />
		</div>
	);
}
