import React from 'react';
import {
	Center,
	Grid,
	InlineEmpty,
	InlineError,
	InlineLoading,
	Stack,
	Title,
	TitleBadge,
} from '@zeal/zeal-ui';

import useAnalyticsRedeemed from '../data/useAnalyticsRedeemed';

export default function RedeemedRewards(props) {
	const { data } = useAnalyticsRedeemed();
	let overview = useAnalyticsRedeemed();

	const rewardsList = data?.map((i) => (
		<TitleBadge
			size="xs"
			title={i.reward_name}
			subtitle={`${i.reward_points} reward points`}
		>
			{i.count}
		</TitleBadge>
	));

	return (
		<Stack gap="md" p="xs" borderColor="gray" className="border-b">
			<Title titleText="Top Redeemed Rewards" className="mx-4" />
			<Stack m="xs" gap="sm" overflowAuto mx="sm" maxH="md">
				{rewardsList?.length > 0 ? (
					<Grid cols="5" gap="3" children={rewardsList} my="sm" />
				) : (
					<Stack
						m="xs"
						gap="sm"
						overflowAuto
						mx="sm"
						maxH="md"
						children={
							<Center>
								<Stack m="lg">
									<InlineError hide={!overview.isError} />
									<InlineLoading hide={!overview.isLoading} />
									{rewardsList?.length == 0 && !overview.isLoading ? (
										<InlineEmpty />
									) : null}
								</Stack>
							</Center>
						}
					/>
				)}
			</Stack>
		</Stack>
	);
}
