import React, { useReducer, useState } from 'react';
import { ButtonWithIcon, Grid, Header, Icon, Stack } from '@zeal/zeal-ui';
import AppContainer from '../App/AppContainer';
import openToastr from '../App/Toastr/openToastr';
import ACLBoundary from '../App/ACL/ACLBoundary';
import EmptyState from './EmptyState';
import AddEditModal from './AddEditModal';
import DeleteAnnouncementModal from './DeleteAnnouncementModal';
import AnnouncementsCards from './AnnouncementsCards';

import useAnnouncements, { useDeleteAnnou } from './data/useAnnouncements';

export default function Announcements({ ...props }) {
	var today = new Date();
	var nextweek = new Date(
		today.getFullYear(),
		today.getMonth(),
		today.getDate() + 7,
	);
	const [isAddEditModalOpen, setIsAddEditModalOpen] = useState(false);
	const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
	const [editedAnnouncement, setEditedAnnouncement] = useState(null);
	const [announcementsData, setAnnouncementsData] = useState([]);
	const [removeError, setRemoveError] = React.useState(true);
	const [expireDate, setExpireDate] = useState(nextweek);
	const [selected, setSelected] = useState(false);
	const [uuid, setUuid] = React.useState('');

	const announcementQuery = useAnnouncements();
	const { data } = announcementQuery;

	const refetchAnnouncementsData = () => {
		announcementQuery.refetch();
	};

	React.useEffect(() => {
		setAnnouncementsData(data);
	}, [data]);

	const initialFormValues = {
		photo: '',
		image: '',
		type: '',
		promocode: '',
		title: '',
		description: '',
		promo_code_uuid: '',
		hideAtQuotaEnd: false,
	};

	const addEditFormReducer = (prevState, newState) => ({ ...prevState, ...newState });

	const [formState, formDispatch] = useReducer(
		addEditFormReducer,
		initialFormValues,
	);

	const openAnnouncementModal = () => {
		setIsAddEditModalOpen(true);
	};

	const addAnnouncementBtnClick = () => {
		resetForm();
		setUuid('');
		setEditedAnnouncement(null);
		openAnnouncementModal();
	};

	const handleAnnouncementEdit = (item) => {
		openAnnouncementModal();
		formDispatch(item);
		setExpireDate('');
		setSelected(item?.expire_at);
		setEditedAnnouncement(item);
		setUuid(item.uuid);
	};

	const handleAnnouncementDelete = (item) => {
		setUuid(item.uuid);
		setIsDeleteModalOpen(true);
	};

	const handleFilesUpload = (files) => {
		formDispatch({ photo: files[0] });
	};

	const resetForm = () => {
		formDispatch(initialFormValues);
		setExpireDate(nextweek);
	};

	const { doDeleteAnnou, isError, isLoading, isSuccess, status }
		= useDeleteAnnou({
			onSuccess: () => {
				refetchAnnouncementsData();
				setIsDeleteModalOpen(false);
				openToastr({ message: 'Announcement Deleted Successfully' });
			},
			onError: (error) => {
				const clonedError = error?.response.clone();

				if (!clonedError?.bodyUsed) {
					clonedError.json().then((err) => {
						openToastr({
							message: err?.message || 'Failed to delete announcement',
							isError: true,
						});
					});
				}
			},
		});

	const deleteAnnouncement = (uuid) => {
		doDeleteAnnou({ uuid });
		setRemoveError(true);
	};

	const ActionsComp = (item) => (
		<Stack row>
			<ACLBoundary
				aclUnAuthProps={{ disabled: true }}
				aclGrants={['control:announcements']}
			>
				<ButtonWithIcon
					stretch={false}
					p="none"
					m="none"
					transparent
					onClick={() => handleAnnouncementEdit(item)}
				>
					<Icon bg="snow" rounded name="edit" size="!w7" p="sm" />
				</ButtonWithIcon>
				<ButtonWithIcon
					stretch={false}
					p="none"
					m="none"
					transparent
					onClick={() => handleAnnouncementDelete(item)}
				>
					<Icon bg="snow" rounded name="trash" size="!w7" p="sm" />
				</ButtonWithIcon>
			</ACLBoundary>
		</Stack>
	);

	const announcementsBreadCrumb = [
		{
			name: 'Announcements',
			to: '/announcements',
			icon: 'horn',
		},
	];

	return (
		<AppContainer ownApp protected breadcrumbs={announcementsBreadCrumb}>
			<div>
				<Header
					padding="sm"
					startContent={<span></span>}
					endContent={
						<ACLBoundary>
							<ButtonWithIcon
								primary
								label="Add Announcements"
								btnIcon="plus"
								size="xs"
								m="none"
								onClick={addAnnouncementBtnClick}
								aclUnAuthProps={{ disabled: true }}
								aclGrants={['control:announcements']}
							/>
						</ACLBoundary>
					}
				/>
				<Stack m="md">
					<Grid cols="3" gap="3" withSpace>
						<AnnouncementsCards
							announcementsData={announcementsData}
							ActionsComp={ActionsComp}
						/>
					</Grid>
				</Stack>
				<EmptyState
					data={announcementsData?.data}
					addAnnouncementBtnClick={addAnnouncementBtnClick}
				/>
			</div>
			<AddEditModal
				closeModal={() => {
					setIsAddEditModalOpen(false);
					setRemoveError(false);
				}}
				formState={formState}
				resetForm={resetForm}
				formDispatch={formDispatch}
				isModalOpen={isAddEditModalOpen}
				editedAnnouncement={editedAnnouncement}
				handleFilesUpload={handleFilesUpload}
				removeError={removeError}
				setRemoveError={setRemoveError}
				uuid={uuid}
				selected={selected}
				expireDate={expireDate}
				setExpireDate={setExpireDate}
			/>
			<DeleteAnnouncementModal
				isDeleteModalOpen={isDeleteModalOpen}
				closeDeleteModal={() => {
					setIsDeleteModalOpen();
					setRemoveError(false);
				}}
				deleteAnnouncement={deleteAnnouncement}
				isError={isError}
				isLoading={isLoading}
				removeError={removeError}
				uuid={uuid}
			/>
		</AppContainer>
	);
}
