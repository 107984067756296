import React, { useEffect } from 'react';

import {
	Anchor,
	BasicTable,
	Card,
	Center,
	InlineLoading,
	Pagination,
	Stack,
	Title,
} from '@zeal/zeal-ui';

import AppContainer from '../App/AppContainer';
import { useQueryString } from '../App/useQueryString';
import useExports from './data/useExports';

export default function Exports() {
	const [{ page }, updateQuery] = useQueryString();

	const breadcrumbs = [{ name: 'Exports', to: '/exports', icon: 'download' }];

	const tableColumns = [
		{ Header: 'Type', accessor: 'type' },
		{ Header: 'Status', accessor: 'status' },
		{
			Header: 'Download Link',
			accessor: 'download_link',
			Cell: renderLinkCell,
		},
		{
			Header: 'Date Created',
			accessor: 'created_at',
			Cell: renderDateTimeCell,
		},
	];

	const { data: exportsData, isLoading, pagesList } = useExports();

	const tableLoadingContent = (
		<Center>
			<Stack m="xl">
				<InlineLoading />
			</Stack>
		</Center>
	);

	const emptyContent = (
		<Center>
			<Stack m="xl">
				<Title
					title="There is no data here yet"
					subtitle="No data yet to be shown. Check back later"
					size="md"
				/>
			</Stack>
		</Center>
	);

	useEffect(() => {
		updateQuery({ page: page || 1 });
	}, []);

	const handleOnPageChange = ({ num }) => {
		updateQuery({ page: num });
	};

	const showPagination = pagesList?.length > 1 && exportsData?.length > 0 && (
		<Pagination
			pages={pagesList}
			current={page}
			onPageChange={handleOnPageChange}
		></Pagination>
	);

	return (
		<AppContainer protected breadcrumbs={breadcrumbs}>
			<Card m="md">
				<BasicTable
					shadow={false}
					data={exportsData || []}
					columns={tableColumns}
					headerPadding="lg"
					cellPadding="lg"
					isLoading={isLoading}
					isEmpty={!exportsData?.length}
					loadingContent={tableLoadingContent}
					emptyContent={emptyContent}
				/>
				{showPagination}
			</Card>
		</AppContainer>
	);
}

function renderDateTimeCell({ value }) {
	if (!value) {
		return '--';
	}

	const date = new Date(value);
	return new Intl.DateTimeFormat('en-GB', {
		dateStyle: 'medium',
		timeStyle: 'short',
		hour12: true,
	}).format(date);
}

function renderLinkCell({ value }) {
	if (!value) {return 'NA';}

	return (
		<Anchor
			download
			label="Click Here"
			color="primary"
			href={value}
			target="_blank"
		/>
	);
}
