import React from 'react';
import { useMutation } from 'react-query';

import { ApiRequest } from '@zeal/zeal-lib';
import { useQuery } from '../../App/QueryClient';
import { useQueryString } from '../../App/useQueryString';

async function apiFeedback({ queryKey: [, filters] }) {
	return new ApiRequest('v3/feedbacks')
		.addHeader('Accept', 'application/json')
		.addQuery('from', filters?.date?.[0])
		.addQuery('to', filters?.date?.[1])
		.GET();
}

export default function useFeedback() {
	const [queryState] = useQueryString();

	const q = useQuery({
		queryFn: apiFeedback,
		staleTime: 1000 * 60 * 30, // 30 min
		queryKey: [
			'z-feedback',
			{
				date: queryState?.date,
			},
		],
	});

	React.useDebugValue(q);

	return q;
}

async function apiAddReward({ ...payload }) {
	// return new ApiRequest("v3/specifics")
	// .addHeader("Accept", "application/json")
	// .addHeader("Content-type", "application/json")
	// .POST(JSON.stringify(payload), true)

	console.warn('[API/dummy]', 'useFeedback::apiAddReward');

	return Promise.resolve(true);
}

async function apiEditReward({ ...payload }) {
	// return new ApiRequest("v3/specifics")
	// .addHeader("Accept", "application/json")
	// .addHeader("Content-type", "application/json")
	// .POST(JSON.stringify(payload), true)

	console.warn('[API/dummy]', 'useFeedback::apiEditReward');

	return Promise.resolve(true);
}

async function apiDeleteReward({ ...payload }) {
	// return new ApiRequest("v3/specifics")
	// .addHeader("Accept", "application/json")
	// .addHeader("Content-type", "application/json")
	// .POST(JSON.stringify(payload), true)

	console.warn('[API/dummy]', 'useFeedback::apiDeleteReward');

	return Promise.resolve(true);
}

export function useAddReward(opt) {
	const mutation = useMutation(apiAddReward);

	const { data, mutate, isSuccess } = mutation;

	React.useEffect(() => {
		if (!isSuccess) {
			return null;
		}

		if (opt.onSuccess) {
			opt.onSuccess();
		}
	}, [data, isSuccess]);

	return {
		...mutation,
		doAddReward: mutate,
	};
}

export function useEditReward(opt) {
	const mutation = useMutation(apiEditReward);

	const { data, mutate, isSuccess } = mutation;

	React.useEffect(() => {
		if (!isSuccess) {
			return null;
		}

		if (opt.onSuccess) {
			opt.onSuccess();
		}
	}, [data, isSuccess]);

	return {
		...mutation,
		doEditReward: mutate,
	};
}

export function useDeleteReward(opt) {
	const mutation = useMutation(apiDeleteReward);

	const { data, mutate, isSuccess } = mutation;

	React.useEffect(() => {
		if (!isSuccess) {
			return null;
		}

		if (opt.onSuccess) {
			opt.onSuccess();
		}
	}, [data, isSuccess]);

	return {
		...mutation,
		doDeleteReward: mutate,
	};
}
