import React from 'react';

export default function handleNoneData({ value }) {
	if (value == '0') {
		return value;
	}
	if (!value) {
		return '__';
	}

	return value;
}
