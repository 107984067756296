import React from 'react';

import { Label } from '@zeal/zeal-ui';

export function renderErrorMessage(input, isError, errMsgs) {
	if (!isError || !errMsgs[input]) {return null;}

	return (
		<Label textColor="danger" size="xs">
			{errMsgs[input][0]}
		</Label>
	);
}
