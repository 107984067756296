import React from 'react';
import { Route, Switch } from 'react-router-dom';
import ACLBoundary from '../App/ACL/ACLBoundary';

import VisitTransactions from './VisitTransactions';

export default function VisitTransactionsRoutes() {
	return (
		<Switch>
			<ACLBoundary
				aclUnAuthProps={{ replace: true }}
				aclGrants={['view:transactions']}
			>
				<Route exact path="/visit-transactions" component={VisitTransactions} />
			</ACLBoundary>
		</Switch>
	);
}
