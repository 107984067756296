import React from 'react';
import { DateTime } from 'luxon';

export default function renderRelativeDatetime({ value }) {
	if (!value) {
		return '--';
	}

	let dt;
	dt = DateTime.fromFormat(value, 'yyyy-MM-dd HH:mm aa');

	if (dt.isValid) {
		return new Intl.DateTimeFormat('en-GB', {
			dateStyle: 'medium',
			timeStyle: 'short',
		}).format(dt);
	}

	dt = DateTime.fromISO(value);

	if (dt.isValid) {
		return new Intl.DateTimeFormat('en-GB', {
			dateStyle: 'medium',
			timeStyle: 'short',
		}).format(dt);
	}

	return value;
}
