import React from 'react';
import { useMutation } from 'react-query';

import { ApiRequest } from '@zeal/zeal-lib';
import { useQuery } from '../../App/QueryClient';

async function apiMultiplePunches({ queryKey: [, filters] }) {
	return new ApiRequest('visits/range')
		.addHeader('Accept', 'application/json')
		.GET();
}

async function apiAddMultiplePunches(payload) {
	return new ApiRequest('v3/punch-ranges')
		.addHeader('Accept', 'application/json')
		.addHeader('Content-Type', 'application/json')
		.POST(JSON.stringify(payload));
}

async function apiDeleteMultiplePunches(payload) {
	return new ApiRequest(`visits/range/${payload?.id}`)
		.addHeader('Accept', 'application/json')
		.addHeader('Content-Type', 'application/json')
		.DELETE();
}

async function apiEditMultiplePunches(payload) {
	return new ApiRequest(`v3/punch-ranges/${payload?.id}`)
		.addHeader('Accept', 'application/json')
		.addHeader('Content-Type', 'application/json')
		.PUT(JSON.stringify(payload));
}

export default function useMultiplePunches() {
	const q = useQuery({
		queryFn: apiMultiplePunches,
		staleTime: Infinity,
		queryKey: ['z-multiple-punches'],
	});

	React.useDebugValue(q);

	return q;
}

export function useAddMultiplePunches(opt) {
	const mutation = useMutation(apiAddMultiplePunches);

	const { data, mutate, isSuccess } = mutation;

	React.useEffect(() => {
		if (!isSuccess) {
			return null;
		}

		if (opt.onSuccess) {
			opt.onSuccess();
		}
	}, [data, isSuccess]);

	return {
		...mutation,
		doAddMultiplePunches: mutate,
	};
}

export function useEditMultiplePunches(opt) {
	const mutation = useMutation(apiEditMultiplePunches);

	const { data, mutate, isSuccess } = mutation;

	React.useEffect(() => {
		if (!isSuccess) {
			return null;
		}

		if (opt.onSuccess) {
			opt.onSuccess();
		}
	}, [data, isSuccess]);

	return {
		...mutation,
		doEditMultiplePunches: mutate,
	};
}

export function useDeleteMultiplePunches(opt) {
	const mutation = useMutation(apiDeleteMultiplePunches);

	const { data, mutate, isSuccess, isError, error } = mutation;

	React.useEffect(() => {
		if (isError) {
			if (opt.onError) {
				opt.onError(error);
			}
		}

		if (!isSuccess) {
			return null;
		}

		if (opt.onSuccess) {
			opt.onSuccess();
		}
	}, [data, isSuccess, isError]);

	return {
		...mutation,
		doDeleteMultiplePunches: mutate,
	};
}
