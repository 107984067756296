import React, { useCallback, useEffect, useReducer, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { cloneDeep } from 'lodash';

import {
	BasicTable,
	Button,
	Checkbox,
	InlineEmpty,
	InlineError,
	InlineLoading,
	Pagination,
	Stack,
	Table,
} from '@zeal/zeal-ui';

import useCustomersRecords, {
	formatFilters,
} from '../data/useCustomersRecords';

import { useQueryString } from '../../App/useQueryString';
import openToastr from '../../App/Toastr/openToastr';

import { useSendNotification } from '../data/useNotifications';
import CompoundFilters from './CompoundFilters';
import renderRelativeDatetime from './renderRelativeDatetime';
import CustomersActions from './CustomersActions';

export default function ConsumersRecords(props) {
	const [queryState, updateQuery] = useQueryString();

	const { page } = queryState;

	const initialCustomersState = {
		customersData: [],
		isAllSelectedClicked: false,
		excludedIds: {},
		selectedIds: {},
	};

	const { doSendNotification, isLoading: isLoadingPushNotification }
		= useSendNotification({
			onSuccess: () => {
				openToastr({
					message: 'Success. Notification will be sent once it is reviewed',
				});
			},
			onError: (error) => {
				if (!error?.response?.bodyUsed)
				{error?.response?.json().then((err) => {
					openToastr({
						message: err.message || 'Failed to send notifications',
						isError: true,
					});
				});}
			},
		});

	const customersReducer = (prevState, newState) => ({ ...prevState, ...newState });

	const [customersState, customersDispatch] = useReducer(
		customersReducer,
		initialCustomersState,
	);

	const { data, pagesList, isLoading, isError, totalCustomersCount }
		= useCustomersRecords({
			formatCustomer(data) {
				const {
					last_visit,
					created_at,
					points,
					total_spent,
					total_points,
					name,
					uuid,
				} = data;
				return {
					last_visit,
					created_at,
					points,
					total_spent,
					total_points,
					name,
					uuid,
					selected: isRowSelected(uuid),
				};
			},
		});

	const isRowSelected = (uuid) => {
		if (customersState.isAllSelectedClicked) {
			return !Object.keys(customersState.excludedIds).includes(uuid);
		} else {
			return Object.keys(customersState.selectedIds).includes(uuid);
		}
	};

	useEffect(() => {
		updateQuery({ page: page || 1 });
	}, []);

	const handleOnPageChange = ({ num }) => {
		updateQuery({ page: num });
	};

	useEffect(() => {
		customersDispatch({ customersData: data });
	}, [data]);

	const isEmpty = !data?.length;

	const history = useHistory();

	const handleRoute = useCallback(
		(row) => {
			history.push({
				pathname: `/customers/${row?.original?.uuid}`,
			});
		},
		[history],
	);

	const actionsCell = ({ row }) => (
		<Button
			transparent
			stretch={false}
			p="none"
			m="none"
			onClick={() => handleRoute(row)}
		>
			{row.values.name}
		</Button>
	);

	const handleCheckedChange = (row) => {
		data[row.id].selected = !(data[row.id].selected || false);

		let changedIds = {};

		if (customersState.isAllSelectedClicked) {
			changedIds = manageExcludedId(row);
		} else {
			changedIds = manageSelectedId(row);
		}

		customersDispatch({ customersData: data, ...changedIds });
	};

	const renderCheckboxCell = ({ row, value }) => (
		<div>
			<Checkbox
				inputName={'checkbox' + row.id}
				defaultChecked={value}
				onChange={(evt) => handleCheckedChange(row)}
			/>
		</div>
	);

	const manageSelectedId = (row) => {
		const rowData = row.original;

		let changedIds = {};

		if (data[row.id].selected)
		{changedIds = {
			selectedIds: {
				...customersState.selectedIds,
				[rowData.uuid]: '',
			},
			excludedIds: {},
		};}
		else {
			const selectedIdsCopy = cloneDeep(customersState.selectedIds);
			delete selectedIdsCopy[rowData.uuid];

			changedIds = {
				selectedIds: {
					...selectedIdsCopy,
				},
				excludedIds: {},
			};
		}
		return changedIds;
	};

	const manageExcludedId = (row) => {
		const rowData = row.original;

		let changedIds = {};

		if (!data[row.id].selected)
		{changedIds = {
			excludedIds: {
				...customersState.excludedIds,
				[rowData.uuid]: '',
			},
			selectedIds: {},
		};}
		else {
			const excludedIdsCopy = cloneDeep(customersState.excludedIds);
			delete excludedIdsCopy[rowData.uuid];

			changedIds = {
				excludedIds: {
					...excludedIdsCopy,
				},
				selectedIds: {},
			};
		}
		return changedIds;
	};

	const tableColumns = [
		{
			Header: '',
			accessor: 'selected',
			Cell: renderCheckboxCell,
		},
		{ Header: 'Customer', accessor: 'name', Cell: actionsCell },
		{ Header: 'Total Points', accessor: 'total_points' },
		{ Header: 'Total Spent', accessor: 'total_spent' },
		{ Header: 'Loyalty Points', accessor: 'points' },
		{
			Header: 'Signup Date',
			accessor: 'created_at',
			Cell: renderRelativeDatetime,
		},
		{
			Header: 'Last Visit',
			accessor: 'last_visit',
			Cell: renderRelativeDatetime,
		},
	];

	const showPagination = pagesList?.length > 1 && data?.length > 0 && (
		<Pagination
			pages={pagesList}
			current={page}
			onPageChange={handleOnPageChange}
		></Pagination>
	);

	const selectAllRows = (allSelected) => {
		data?.map((c) => {
			c.selected = allSelected;
			return c;
		});
		customersDispatch({ customersData: data });
	};

	const toggleAllSelectedButton = (state) => {
		selectAllRows(state);
		customersDispatch({
			isAllSelectedClicked: state,
			excludedIds: {},
			selectedIds: {},
		});
	};

	const getObjectKeysCount = (object) => {
		console.log(Object.keys(object).length);
		return Object.keys(object).length;
	};

	const excludedIdsCount = getObjectKeysCount(customersState.excludedIds);

	const selectedIdsCount = getObjectKeysCount(customersState.selectedIds);

	const areAllRowsSelected
		= (customersState.isAllSelectedClicked && excludedIdsCount === 0)
		|| selectedIdsCount === totalCustomersCount;

	const notificationAudienceCount = customersState.isAllSelectedClicked
		? totalCustomersCount - excludedIdsCount
		: selectedIdsCount;

	const pushNotification = (message) => {
		const formattedFilters = formatFilters(queryState);

		if (notificationAudienceCount > 0) {
			const membersWithFilters = {
				...(formattedFilters?.length > 0 && {
					filters: formattedFilters,
					type: 'filters',
				}),
			};

			const membersWithSelectedIds = {
				...(selectedIdsCount
					&& !customersState.isAllSelectedClicked && {
					member_uuids: Object.keys(customersState.selectedIds),
					type: 'array',
				}),
			};

			const membersWithExcludedIds = {
				...(excludedIdsCount
					&& customersState.isAllSelectedClicked && {
					excluded_uuids: Object.keys(customersState.excludedIds),
				}),
			};

			doSendNotification({
				type: 'all',
				body: message,
				...membersWithFilters,
				...membersWithSelectedIds,
				...membersWithExcludedIds,
			});
		}
	};

	const tableTopContent = (
		<div>
			<CompoundFilters />
			<Stack>
				<hr />
			</Stack>
			<CustomersActions
				toggleAllSelected={toggleAllSelectedButton}
				isAllSelected={areAllRowsSelected}
				pushNotification={pushNotification}
				notificationAudienceCount={notificationAudienceCount}
				isLoadingPushNotification={isLoadingPushNotification}
			/>
		</div>
	);

	return (
		<div>
			<BasicTable
				border
				data={customersState.customersData || []}
				topContent={!isError && tableTopContent}
				isError={isError}
				isEmpty={isEmpty}
				isLoading={isLoading}
				errorContent={<InlineError className="w-full py-10 !justify-center" />}
				emptyContent={<InlineEmpty className="w-full py-10 !justify-center" />}
				loadingContent={
					<InlineLoading className="w-full py-10 !justify-center" />
				}
				columns={tableColumns}
			/>
			{showPagination}
		</div>
	);
}
