import React, { useReducer, useState } from 'react';

import {
	ButtonWithIcon,
	Center,
	InlineError,
	InlineLoading,
	Stack,
	Title,
} from '@zeal/zeal-ui';

import useStories from '../data/useStories';
import ACLBoundary from '../../App/ACL/ACLBoundary';

export default function StoriesEmptyState({ data, addStoryBtnClick }) {
	const overview = useStories();
	return (
		(data || []).length === 0 && (
			<Center className="contents">
				<Stack m="xl">
					<Title
						className={overview.isSuccess ? '' : 'hidden'}
						title="No Stories Yet!"
						subtitle="Create your own"
						size="md"
					/>
					<Center className={overview.isSuccess ? 'mt-8' : 'hidden'}>
						<ACLBoundary>
							<ButtonWithIcon
								primary
								label="Add Story"
								btnIcon="plus"
								size="xs"
								m="none"
								onClick={addStoryBtnClick}
								aclUnAuthProps={{ disabled: true }}
								aclGrants={['control:stories']}
							/>
						</ACLBoundary>
					</Center>
					<Center className="mt-8">
						<InlineError hide={!overview.isError} />
						<InlineLoading hide={!overview.isLoading} />
					</Center>
				</Stack>
			</Center>
		)
	);
}
