import React from 'react';
import {
	ButtonWithIcon,
	Center,
	InlineError,
	InlineLoading,
	Stack,
	Title,
} from '@zeal/zeal-ui';

import useTeamApp from '../data/useTeamApp';
import ACLBoundary from '../../App/ACL/ACLBoundary';

export default function EmptyState({ data, handleTeamAdd }) {
	const overview = useTeamApp();
	return (
		(data || []).length === 0 && (
			<Center className="contents">
				<Stack m="xl">
					<Title
						className={overview.isSuccess ? '' : 'hidden'}
						title="team managment page is empty "
						subtitle="Create some teams and invite members to have access to your dashboard "
						size="md"
					/>
					<Center className={overview.isSuccess ? 'mt-8' : 'hidden'}>
						<ACLBoundary>
							<ButtonWithIcon
								primary
								label="Add Team"
								btnIcon="plus"
								size="xs"
								m="none"
								pt="none"
								onClick={handleTeamAdd}
								aclUnAuthProps={{ disabled: true }}
								aclGrants={['control:team']}
							/>
						</ACLBoundary>
					</Center>
					<Center>
						<Stack>
							<InlineError hide={!overview.isError} />
							<InlineLoading hide={!overview.isLoading} />
						</Stack>
					</Center>
				</Stack>
			</Center>
		)
	);
}
