import React, { useReducer, useState } from 'react';

import {
	ButtonWithIcon,
	Center,
	InlineError,
	InlineLoading,
	Stack,
	Title,
} from '@zeal/zeal-ui';

import useVideos from '../data/useVideos';
import ACLBoundary from '../../App/ACL/ACLBoundary';

export default function VideoEmptyState({ data, openAddModal }) {
	const overview = useVideos();
	return (
		(data || []).length === 0 && (
			<Center className="contents">
				<Stack m="xl">
					<Title
						className={overview.isSuccess ? '' : 'hidden'}
						title="No Videos Yet!"
						subtitle="Create your own"
						size="md"
					/>
					<Center className={overview.isSuccess ? 'mt-8' : 'hidden'}>
						<ACLBoundary>
							<ButtonWithIcon
								primary
								label="Add Video"
								btnIcon="plus"
								size="xs"
								m="none"
								onClick={openAddModal}
								aclUnAuthProps={{ disabled: true }}
								aclGrants={['control:gallery']}
							/>
						</ACLBoundary>
					</Center>
					<Center className="mt-8">
						<InlineError hide={!overview.isError} />
						<InlineLoading hide={!overview.isLoading} />
					</Center>
				</Stack>
			</Center>
		)
	);
}
