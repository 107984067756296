import React from 'react';

import useRewardModel from '../../Rewards/pages/SetReward/data/useSetRewardModel';
import RedeemedRewards from './RedeemedRewards';
import LapsedCustomers from './LapsedCustomers';
import PunchesAnalytics from './PunchesAnalytics';

export default function MetricsAside() {
	const rewardModelQuery = useRewardModel();

	const { data, isLoading } = rewardModelQuery;

	const model = Number(data?.model);
	if (isLoading)
	{return (
		<div>
			<PunchesAnalytics />
			<RedeemedRewards />
			<LapsedCustomers />
		</div>
	);}
	switch (model) {
	case 1:
		return (
			<div>
				<PunchesAnalytics />
				<RedeemedRewards />
				<LapsedCustomers />
			</div>
		);
	case 2:
		return (
			<div>
				<RedeemedRewards />
				<LapsedCustomers />
			</div>
		);
	case 3:
		return (
			<div>
				<RedeemedRewards />
				<LapsedCustomers />
			</div>
		);
	case 4:
		return (
			<div>
				<LapsedCustomers />
			</div>
		);
	default:
		return '';
	}
}
