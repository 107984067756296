import React, { useState } from 'react';
import { Tabs } from '@zeal/zeal-ui';
import AppContainer from '../App/AppContainer';

export default function TeamMembersFrame(props) {
	const paths = [
		{
			name: 'Dashboard',
			to: '/teams/dashboard',
		},
		{
			name: 'Team App',
			to: '/teams/app',
		},
	];
	const tabContent = paths.map((d) => <Tabs to={d.to} name={d.name} />);

	return (
		<AppContainer
			protected
			middleContent={tabContent}
			breadcrumbs={props.breadcrumbs}
		>
			{props?.children}
		</AppContainer>
	);
}
