import useAllPermissions from '../../TeamMembers/data/useAllPermissions';
import { useLoggedIn } from './useLogin';

export default function useGrants() {
	const { permissions: userPermissions, isSuperAdmin } = useLoggedIn();

	const { formattedPermissions, isLoading: isFetchingPermissions }
		= useAllPermissions();

	return {
		userGrants: userPermissions,
		isSuperAdmin,
		allFormattedGrants: formattedPermissions,
		isFetchingPermissions,
	};
}
