import React, { useEffect, useState } from 'react';
import { useMutation } from 'react-query';

import { useQuery } from '../../../../App/QueryClient';
import { ApiRequest } from '@zeal/zeal-lib';

async function apiLoyaltySpecifics({ queryKey: [, filters] }) {
	return new ApiRequest('rewards')
		.addHeader('Accept', 'application/json')
		.GET();
}

async function apiLoyaltySpecificsTypes({ queryKey: [, filters] }) {
	return new ApiRequest('rewards/types')
		.addHeader('Accept', 'application/json')
		.GET();
}

async function apiAddReward({ ...payload }) {
	return new ApiRequest('rewards')
		.addHeader('Accept', 'application/json')
		.addHeader('Content-Type', 'application/json')
		.POST(JSON.stringify(payload));
}

async function apiEditReward({ ...payload }) {
	return new ApiRequest(`rewards/${payload?.rewardId}`)
		.addHeader('Accept', 'application/json')
		.addHeader('Content-Type', 'application/json')
		.POST(JSON.stringify(payload));
}

async function apiDeleteReward({ ...payload }) {
	return new ApiRequest(`rewards/${payload?.rewardId}`)
		.addHeader('Accept', 'application/json')
		.addHeader('Content-Type', 'application/json')
		.DELETE();
}

export function useAddReward(opt) {
	const mutation = useMutation(apiAddReward);

	const { data, mutate, isSuccess } = mutation;

	React.useEffect(() => {
		if (!isSuccess) {
			return null;
		}

		if (opt.onSuccess) {
			opt.onSuccess();
		}
	}, [data, isSuccess]);

	return {
		...mutation,
		doAddReward: mutate,
	};
}

export function useEditReward(opt) {
	const mutation = useMutation(apiEditReward);

	const { data, mutate, isSuccess } = mutation;

	React.useEffect(() => {
		if (!isSuccess) {
			return null;
		}

		if (opt.onSuccess) {
			opt.onSuccess();
		}
	}, [data, isSuccess]);

	return {
		...mutation,
		doEditReward: mutate,
	};
}

export function useDeleteReward(opt) {
	const mutation = useMutation(apiDeleteReward);

	const { data, mutate, isSuccess } = mutation;

	React.useEffect(() => {
		if (!isSuccess) {
			return null;
		}

		if (opt.onSuccess) {
			opt.onSuccess();
		}
	}, [data, isSuccess]);

	return {
		...mutation,
		doDeleteReward: mutate,
	};
}

export default function useLoyaltySpecifics() {
	const q = useQuery({
		queryFn: apiLoyaltySpecifics,
		staleTime: Infinity,
		queryKey: ['z-loyalty-specifics'],
	});

	React.useDebugValue(q);

	return q;
}

export function useLoyaltySpecificsTypes(opt) {
	const [typesList, setTypesList] = useState([]);

	const q = useQuery({
		queryFn: apiLoyaltySpecificsTypes,
		staleTime: Infinity,
		queryKey: ['z-reward-specific-types'],
	});

	const formatType = opt?.formatType || ((d) => d);

	const { data: typesData } = q;

	useEffect(() => {
		if (typesData) {
			const formattedData = typesData?.map(formatType);
			setTypesList(formattedData);
		} else {setTypesList([]);}
	}, [typesData, setTypesList]);

	React.useDebugValue(q);

	return { ...q, data: typesList };
}
