import React from 'react';
import { get, has } from 'lodash-es';
import { Stack, Title } from '@zeal/zeal-ui';

import { useQueryString } from '../../App/useQueryString';
import { relationalOperatorsMap } from '../../utils/relationalOperators';
import FilterRule from './FilterRule';

export default function CompoundFilters(props) {
	const [allFilters] = React.useState({
		memberName: { label: 'Member Name', value: 'memberName' },
		branchName: { label: 'Branch Name', value: 'branchName' },
		teamName: { label: 'Team Name', value: 'teamName' },
		points: { label: 'Points', value: 'points' },
		visitTime: { label: 'Visit Time', value: 'visitTime' },
		receipt: { label: 'Receipt', value: 'receipt' },
	});

	const [queryState, , queryString] = useQueryString();

	const [activeFilters, setActiveFilters] = React.useState({});

	const [filterOptions, setFilterOptions] = React.useState(allFilters);

	React.useEffect(() => {
		setActiveFilters((s) => Object.entries(allFilters).reduce((a, [k, info]) => {
			const hasFilterQuery = has(queryState, k);

			if (hasFilterQuery) {
				const [operator, operand] = get(queryState, k);

				a[k] = {
					...info,
					operand,
					operator: relationalOperatorsMap().getSymbol(operator),
				};
			}

			return a;
		}, {}));

		setFilterOptions((s) => Object.entries(allFilters).reduce((a, [k, info]) => {
			const noFilterQuery = !has(queryState, k);

			if (noFilterQuery) {
				a[k] = { ...info };
			}

			return a;
		}, {}));
	}, [queryString, setFilterOptions, setActiveFilters]);

	const handleOnFilterSelect = (newRule, rmRule) => {
		setFilterOptions((s) => {
			const newState = { ...s };

			if (newRule?.value) {
				newState[rmRule.value] = {
					label: rmRule.label,
					value: rmRule.value,
				};
			}

			delete newState[rmRule?.value];

			return newState;
		});

		setActiveFilters((s) => {
			const newState = { ...s };

			if (rmRule?.value) {
				delete newState[rmRule.value];
			}

			newState[newRule.value] = newRule;

			return newState;
		});
	};

	const handleOnFilterClear = (rmRule) => {
		setFilterOptions((s) => {
			const newState = { ...s };

			newState[rmRule.value] = {
				label: rmRule.label,
				value: rmRule.value,
			};

			return newState;
		});

		setActiveFilters((s) => {
			const newState = { ...s };

			delete newState[rmRule.value];

			return newState;
		});
	};

	const filterOptionsList = Object.values(filterOptions);

	const filterRules = Object.values(activeFilters).map((rule, ndx) => (
		<FilterRule
			rule={rule}
			key={rule.label}
			options={filterOptionsList}
			onClear={handleOnFilterClear}
			onChange={handleOnFilterSelect}
		/>
	));

	const createNewRule = filterOptionsList.length ? (
		<FilterRule
			key="new"
			filtersCount={Object.values(activeFilters).length}
			rule={false}
			options={filterOptionsList}
			onClear={handleOnFilterClear}
			onChange={handleOnFilterSelect}
		/>
	) : null;

	return (
		<div>
			<Stack m="md" pt="none" gap="xs">
				<Title title="Filters" size="sm" />
				{filterRules}
				{createNewRule}
			</Stack>
		</div>
	);
}
