import React, { useEffect, useReducer, useState } from 'react';
import { useParams } from 'react-router-dom';
import { cloneDeep } from 'lodash';

import {
	Align,
	BasicTable,
	Button,
	ButtonWithIcon,
	Card,
	Center,
	Grid,
	Icon,
	Input,
	Label,
	MainStackWithAside,
	Modal,
	Select,
	Stack,
	Title,
	TwoSideLayout,
} from '@zeal/zeal-ui';

import AppContainer from '../App/AppContainer';
import openToastr from '../App/Toastr/openToastr';
import formatDate from '../utils/formatDate';

import ACLBoundary from '../App/ACL/ACLBoundary';
import {
	useBranchMenu,
	useOrderDetails,
	useUpdateOrderItems,
} from './data/useOrders';

import OrderActions from './components/OrderActions';

export default function OrderDetails() {
	const [isItemModalOpen, setIsItemModalOpen] = useState(false);
	const [editedItemIndex, setEditedItemIndex] = useState(-1);
	const [itemsData, setItemsData] = useState([]);
	const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);

	const orderDetailsQuery = useOrderDetails();
	const { data } = orderDetailsQuery;

	useEffect(() => {
		setItemsData(data?.data?.items);
	}, [data]);

	const currentPath = `${window.location.pathname}${window.location.search}`;

	const outerBreadcrumbs = [
		{ name: 'Orders', to: '/orders', icon: 'list' },
		{ name: 'Details', to: currentPath },
	];

	const handleItemSubmit = (item) => {
		const formattedItem = { ...item.item, ...item };
		editedItemIndex !== -1 ? editItem(formattedItem) : addItem(formattedItem);
	};

	const onCancelEdit = () => {
		setItemsData(data?.data?.items);
	};

	const addItem = (item) => {
		setItemsData([...itemsData, item]);
		setIsItemModalOpen(false);
	};

	const editItem = (item) => {
		const oldItems = cloneDeep(itemsData);

		oldItems.splice(editedItemIndex, 1, item);

		setItemsData(oldItems);
		setIsItemModalOpen(false);
	};

	const handleDeleteItem = () => {
		const oldItems = cloneDeep(itemsData);

		oldItems.splice(editedItemIndex, 1);

		setItemsData(oldItems);

		setIsDeleteModalOpen(false);
	};

	const openItemModal = (index = -1) => {
		setEditedItemIndex(index);
		setIsItemModalOpen(true);
	};

	const openDeleteModal = (index = -1) => {
		setEditedItemIndex(index);
		setIsDeleteModalOpen(true);
	};

	return (
		<AppContainer ownApp protected breadcrumbs={outerBreadcrumbs}>
			<MainStackWithAside
				aside={<OrderActions orderDetailsQuery={orderDetailsQuery} />}
			>
				<Card p="none">
					<CustomerDetails data={data?.data} />
					<hr />
					<OrderInformation data={data?.data} />
					<hr />
					<OrderItems
						itemData={itemsData}
						openItemModal={openItemModal}
						openDeleteModal={openDeleteModal}
						orderData={data?.data}
						onCancelEdit={onCancelEdit}
						editedItemIndex={editedItemIndex}
					/>
				</Card>
			</MainStackWithAside>
			<AddEditItemModal
				isModalOpen={isItemModalOpen}
				closeModal={() => setIsItemModalOpen(false)}
				handleSubmit={handleItemSubmit}
				editedItemIndex={editedItemIndex}
				orderData={data?.data}
			/>
			<DeleteItemModal
				isDeleteModalOpen={isDeleteModalOpen}
				closeDeleteModal={() => setIsDeleteModalOpen(false)}
				handleDelete={handleDeleteItem}
			/>
		</AppContainer>
	);
}

function CustomerDetails({ data }) {
	return (
		<Stack p="none" m="md">
			<Title title="Customer Details" size="md" />
			<Stack>
				<Card p="md" shadow="0" bg="gray" border={false}>
					<Stack row p="none" gap="md">
						<Title subtitle="Full Name:" size="md" muteTitle />
						<Title
							subtitle={data?.member?.name}
							size="md"
							muteSubtitle={false}
						/>
					</Stack>
					<Stack row gap="md">
						<Title subtitle="Mobile Number:" size="md" muteTitle />
						<Title
							subtitle={data?.member?.phone_number || data?.address?.phone}
							size="md"
							muteSubtitle={false}
						/>
					</Stack>
					<Stack row gap="md">
						<Title subtitle="Address:" size="md" muteTitle />
						<Title
							subtitle={data?.address?.address}
							size="md"
							muteSubtitle={false}
						/>
					</Stack>
					<Stack row gap="4xl" wrap>
						<Stack p="none" row gap="md">
							<Title subtitle="Building:" size="md" muteTitle />
							<Title
								subtitle={data?.address?.building}
								size="md"
								muteSubtitle={false}
							/>
						</Stack>
						<Stack p="none" row gap="md">
							<Title subtitle="Floor:" size="md" muteTitle />
							<Title
								subtitle={data?.address?.floor}
								size="md"
								muteSubtitle={false}
							/>
						</Stack>
						<Stack p="none" row gap="md">
							<Title subtitle="Apartment:" size="md" muteTitle />
							<Title
								subtitle={data?.address?.apartment}
								size="md"
								muteSubtitle={false}
							/>
						</Stack>
					</Stack>
				</Card>
			</Stack>
		</Stack>
	);
}

function OrderInformation({ data }) {
	return (
		<Stack p="none" m="md">
			<Title title="Order Details" size="md" />
			<Stack>
				<Card p="md" shadow="0" bg="gray" border={false}>
					<Stack row p="none" gap="md">
						<Title subtitle="Order ID:" size="md" muteTitle />
						<Title
							subtitle={data?.member?.name}
							size="md"
							muteSubtitle={false}
						/>
					</Stack>
					<Stack row gap="4xl" wrap>
						<Stack p="none" row gap="md">
							<Title subtitle="Creation Date:" size="md" muteTitle />
							<Title
								subtitle={formatDate(data?.created_at)}
								size="md"
								muteSubtitle={false}
							/>
						</Stack>
						<Stack p="none" row gap="md">
							<Title subtitle="Schedule Date:" size="md" muteTitle />
							<Title
								subtitle={formatDate(data?.booking_time)}
								size="md"
								muteSubtitle={false}
							/>
						</Stack>
					</Stack>
					<Stack row gap="md">
						<Title subtitle="Payment Method:" size="md" muteTitle />
						<Title
							subtitle={data?.payment_details?.payment_method}
							size="md"
							muteSubtitle={false}
						/>
					</Stack>
					<Stack row gap="md">
						<Title subtitle="Notes:" size="md" muteTitle />
						<Title
							subtitle={data?.address?.instructions}
							size="md"
							muteSubtitle={false}
						/>
					</Stack>
				</Card>
			</Stack>
		</Stack>
	);
}

function OrderItems(props) {
	const {
		itemData,
		orderData,
		openItemModal,
		openDeleteModal,
		onCancelEdit,
		editedItemIndex,
	} = props;

	const [isEditing, setIsEditing] = useState(false);

	const { orderId } = useParams();

	const itemsColumns = [
		{
			Header: 'Quantity',
			accessor: 'quantity',
		},
		{
			Header: 'Item Name',
			accessor: 'name',
		},
		{
			Header: 'Price',
			accessor: 'price',
			Cell: renderCurrencyCell,
		},
		{
			Header: '',
			accessor: 'actions',
			Cell: (rows) =>
				renderActionCell(
					rows,
					() => openItemModal(Number(rows?.row?.id)),
					() => openDeleteModal(Number(rows?.row?.id)),
				),
		},
	];

	const itemsWithoutActionsColumns = [
		{
			Header: 'Quantity',
			accessor: 'quantity',
		},
		{
			Header: 'Item Name',
			accessor: 'name',
		},
		{
			Header: 'Price',
			accessor: 'price',
			Cell: renderCurrencyCell,
		},
	];

	const tableColumns = isEditing ? itemsColumns : itemsWithoutActionsColumns;

	const orderDetailsQuery = useOrderDetails();

	const { doUpdateOrderItems, error: updateError } = useUpdateOrderItems({
		onSuccess: () => {
			orderDetailsQuery.refetch();
			openToastr({
				message: 'Order Items Updated',
			});
			setIsEditing(false);
		},
		onError: () => {
			updateError?.response.json().then((err) => {
				openToastr({
					message: err?.message || 'Failed to update order items',
					isError: true,
				});
			});
		},
	});

	const submitOrderItems = () => {
		const formattedItems = itemData?.map((item) => ({
			quantity: item?.quantity,
			uuid: item?.uuid,
			attributes: item?.attributes || [],
		}));
		doUpdateOrderItems({
			orderId,
			items: formattedItems,
		});
	};

	const cancelEdit = () => {
		onCancelEdit();
		setIsEditing(false);
	};

	const editButtons = isEditing ? (
		<Stack row p="none" m="sm" gap="md" marginX={false}>
			<Button stretch={false} size="md" m="none" onClick={cancelEdit}>
				Cancel
			</Button>
			<Button
				secondary
				stretch={false}
				size="md"
				m="none"
				onClick={() => submitOrderItems()}
				disabled={itemData?.length === 0}
			>
				Confirm
			</Button>
		</Stack>
	) : (
		<ACLBoundary>
			<ButtonWithIcon
				secondary
				stretch={false}
				m="none"
				size="md"
				onClick={() => setIsEditing(true)}
				aclUnAuthProps={{ disabled: true }}
				aclGrants={['control:orders']}
			>
				Edit Order
			</ButtonWithIcon>
		</ACLBoundary>
	);

	const addItemButton = isEditing && (
		<Button transparent p="none" onClick={() => openItemModal()}>
			<Stack row gap="sm">
				<Center horizontal={false}>
					<Title
						primary
						title={<Icon name="plus" size="xs" m="none" />}
						size="xs"
					/>
					<Title primary title="Add Item" size="xs" />
				</Center>
			</Stack>
		</Button>
	);

	const itemsTable = (
		<BasicTable
			border
			data={itemData || []}
			columns={tableColumns}
			headerPadding="lg"
			cellPadding="lg"
			shadow={false}
		/>
	);

	const sumReducer = (prev, curr) => prev + Number(curr.price * curr.quantity);

	const subtotalPrice = itemData?.reduce(sumReducer, 0);

	const discount = orderData?.payment_details?.discount;

	const totalAmount = subtotalPrice - discount;

	const subtotalPriceCell = renderCurrencyCell({
		value: subtotalPrice,
	});

	const discountCell = renderCurrencyCell({
		value: orderData?.payment_details?.discount,
	});

	const totalAmountCell = renderCurrencyCell({
		value: totalAmount,
	});

	return (
		<Stack p="none" m="md">
			<TwoSideLayout>
				<Title title="Order Items" size="md" />
				{editButtons}
			</TwoSideLayout>
			<Stack>{itemsTable}</Stack>
			{addItemButton}

			<Stack gap="md">
				<TwoSideLayout>
					<Title subtitle="Sub total" size="md" muteSubtitle={false} />
					<Title subtitle={subtotalPriceCell} size="md" muteSubtitle />
				</TwoSideLayout>
				<TwoSideLayout>
					<Title subtitle="Promo Code" size="md" muteSubtitle={false} />
					<Title subtitle={discountCell} size="md" />
				</TwoSideLayout>
				<TwoSideLayout>
					<Title title="Total" size="sm" />
					<Title primary title={totalAmountCell} size="sm" />
				</TwoSideLayout>
			</Stack>
		</Stack>
	);
}

function renderCurrencyCell({ value }) {
	return new Intl.NumberFormat('en', {
		style: 'currency',
		currency: 'EGP',
		maximumFractionDigits: 2,
	}).format(value || 0);
}

function renderActionCell({ row }, openItemModal, openDeleteModal) {
	return (
		<Align align="right" gap="1">
			<Button
				transparent
				stretch={false}
				p="none"
				m="none"
				onClick={openItemModal}
			>
				<Icon
					rounded
					bg="disabled"
					name="edit"
					size="lg"
					p="xs"
					margin="none"
				/>
			</Button>
			<Button
				transparent
				stretch={false}
				p="none"
				m="none"
				onClick={openDeleteModal}
			>
				<Icon
					rounded
					bg="danger"
					name="trashAlt"
					size="lg"
					p="xs"
					margin="none"
				/>
			</Button>
		</Align>
	);
}

function AddEditItemModal(props) {
	const { isModalOpen, closeModal, handleSubmit, editedItemIndex, orderData }
		= props;

	const { categoriesList } = useBranchMenu({
		branchId: orderData?.branch?.uuid,
		formatCategory(data) {
			return {
				...data,
				value: data?.uuid,
				label: data?.name,
			};
		},
	});

	const initialFormValues = {
		category: '',
		services: '',
		item: '',
		quantity: '',
		itemsList: [],
	};

	const addEditFormReducer = (prevState, newState) => ({ ...prevState, ...newState });

	const [formState, formDispatch] = useReducer(
		addEditFormReducer,
		initialFormValues,
	);

	const modalTitle = editedItemIndex !== -1 ? 'Edit Item' : 'Add Item';

	const modalSubmitLabel = editedItemIndex !== -1 ? 'Save' : 'Add';

	const isFormValid
		= formState?.category
		&& formState?.item
		&& formState?.quantity
		&& !isNaN(formState?.quantity);

	useEffect(() => {
		formDispatch({ item: '' });

		if (formState.category) {
			const formattedData = formState.category?.items?.map((item) => ({
				...item,
				label: item?.name,
				value: item?.uuid,
			}));
			formDispatch({ itemsList: formattedData });
		}
	}, [formState.category]);

	const handleModalSubmit = (values) => {
		handleSubmit(values);
		formDispatch(initialFormValues);
	};

	const emptyCategoryMessage = categoriesList?.length === 0 && (
		<Label textColor="danger" size="xs">
			No Categories in this branch yet
		</Label>
	);

	const emptyItemMessage = formState?.category
		&& formState?.itemsList?.length === 0 && (
		<Label textColor="danger" size="xs">
				No Items in this category yet
		</Label>
	);

	return (
		<Modal isModalOpen={isModalOpen} title={modalTitle} onClose={closeModal}>
			<Stack>
				<Stack>
					<Title title="Category" margin="xs" />
					<Select
						inputName="category"
						options={categoriesList}
						selected={formState.category}
						onChange={(category) => formDispatch({ category })}
						placeholder={
							formState?.category?.value ? '' : '--Select Category--'
						}
						startHint={emptyCategoryMessage}
					/>
				</Stack>
				<Stack>
					<Title title="Services" margin="xs" />
					<Select
						inputName="services"
						options={[
							{ value: 'men', label: 'For Men' },
							{ value: 'women', label: 'For Women' },
						]}
						selected={formState.services}
						onChange={(services) => formDispatch({ services })}
						placeholder={formState?.services?.value ? '' : '--Select Service--'}
					/>
				</Stack>
				<Stack>
					<Title title="Item" margin="xs" />
					<Select
						inputName="item"
						options={formState.itemsList}
						selected={formState.item}
						onChange={(item) => formDispatch({ item })}
						placeholder={formState?.item?.value ? '' : '--Select Item--'}
						startHint={emptyItemMessage}
					/>
				</Stack>
				<Stack>
					<Title title="Quantity" margin="xs" />
					<Input.Number
						inputName="quantity"
						placeholder="Add Quantity"
						onChange={(evt) => formDispatch({ quantity: evt.target.value })}
						value={formState.quantity}
					/>
				</Stack>

				<Grid gap="5">
					<span></span>
					<TwoSideLayout>
						<Button stretch={false} size="sm" m="xs" onClick={closeModal}>
							Cancel
						</Button>
						<ButtonWithIcon
							stretch={false}
							size="sm"
							primary
							m="xs"
							onClick={() => handleModalSubmit(formState)}
							disabled={!isFormValid}
						>
							{modalSubmitLabel}
						</ButtonWithIcon>
					</TwoSideLayout>
				</Grid>
			</Stack>
		</Modal>
	);
}

function DeleteItemModal(props) {
	const { isDeleteModalOpen, closeDeleteModal, handleDelete } = props;

	return (
		<Modal
			isModalOpen={isDeleteModalOpen}
			title="Delete Item"
			onClose={closeDeleteModal}
		>
			<Stack p="none">
				<Title title="Are you sure you want to delete this Item?" />

				<Grid gap="5">
					<span></span>
					<TwoSideLayout>
						<Button stretch={false} size="md" m="xs" onClick={closeDeleteModal}>
							Cancel
						</Button>
						<ButtonWithIcon
							secondary
							stretch={false}
							size="md"
							m="xs"
							onClick={() => handleDelete()}
						>
							Delete
						</ButtonWithIcon>
					</TwoSideLayout>
				</Grid>
			</Stack>
		</Modal>
	);
}
