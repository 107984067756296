import React from 'react';
import {
	Button,
	Icon,
	Input,
	Label,
	Select,
	Stack,
	className as cx,
} from '@zeal/zeal-ui';

export default function FilterRuleHandle(props) {
	return (
		<Stack row gap="sm" my="sm">
			<Button
				size="xs"
				m="none"
				p="none"
				className="-m-0.5"
				onClick={props.handleOnInit}
			>
				<Icon name="plus" className="p-1" />
			</Button>
			<Label children="Add another filter" />
		</Stack>
	);
}
