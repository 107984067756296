import React from 'react';
import { useParams } from 'react-router-dom';

import { useMutation } from 'react-query';
import { ApiRequest } from '@zeal/zeal-lib';
import { useQuery } from '../../App/QueryClient';

async function apiMembers({ queryKey: [, filters] }) {
	return new ApiRequest(`members/${filters.memberId}`)
		.addHeader('Accept', 'application/json')
		.GET();
}

export default function useMembers() {
	const { memberId } = useParams();

	const q = useQuery({
		queryFn: apiMembers,
		staleTime: 1000 * 60 * 15, // 15 min
		queryKey: [
			'z-members',
			{
				memberId,
			},
		],
	});

	React.useDebugValue(q);

	return q;
}

async function apiGivePoints({ ...payload }) {
	return new ApiRequest('members/free-punch')
		.addHeader('Accept', 'application/json')
		.addHeader('Content-type', 'application/json')
		.POST(JSON.stringify(payload));
}

export function useGivePoints(opt) {
	const mutation = useMutation(apiGivePoints);

	const { data, mutate, isSuccess, error, isError } = mutation;

	React.useEffect(() => {
		if (isError && opt.onError) {
			opt.onError(error);
		}

		if (!isSuccess) {
			return null;
		}

		if (opt.onSuccess) {
			opt.onSuccess();
		}
	}, [data, isSuccess, isError]);

	return {
		...mutation,
		doGivePoints: mutate,
	};
}

async function apiChangeBalance({ ...payload }) {
	const isAdd = payload?.isAdd || false;
	const endpointUrl = isAdd ? 'add' : 'subtract';

	return new ApiRequest(`members/balance/${endpointUrl}`)
		.addHeader('Accept', 'application/json')
		.addHeader('Content-type', 'application/json')
		.POST(JSON.stringify(payload));
}

export function useChangeBalance(opt) {
	const mutation = useMutation(apiChangeBalance);

	const { data, mutate, isSuccess, error, isError } = mutation;

	React.useEffect(() => {
		if (isError && opt.onError) {
			opt.onError(error);
		}

		if (!isSuccess) {
			return null;
		}

		if (opt.onSuccess) {
			opt.onSuccess();
		}
	}, [data, isSuccess, isError]);

	return {
		...mutation,
		doChangeBalance: mutate,
	};
}
