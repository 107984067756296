import React from 'react';
import { Route, Switch } from 'react-router-dom';

import ACLBoundary from '../App/ACL/ACLBoundary';
import Branches from './Branches';
import AddBranch from './AddBranch';
import EditBranch from './EditBranch';

export default function BranchesRoutes() {
	return (
		<Switch>
			<ACLBoundary
				aclUnAuthProps={{ replace: true }}
				aclGrants={['view:branches']}
			>
				<Route exact path="/branches" component={Branches} />
				<Route path="/branches/add" component={AddBranch} />
				<Route exact path="/branches/edit/:branchId" component={EditBranch} />
			</ACLBoundary>
		</Switch>
	);
}
