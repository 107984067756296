import { useEffect, useState } from 'react';

import { ApiRequest } from '@zeal/zeal-lib';
import { useQuery } from '../../App/QueryClient';

async function apiTechnicians({ queryKey: [, filters] }) {
	const request = new ApiRequest('v3/teams')
		.addHeader('Accept', 'application/json')
		.addQuery('role', 'Technician')
		.GET();

	return request;
}

export default function useTechnicians(opt) {
	const [techniciansList, setTechniciansList] = useState([]);

	const q = useQuery({
		queryFn: apiTechnicians,
		queryKey: [
			'z-all-technicians',
			{
				paginate: false,
			},
		],
		staleTime: Infinity,
	});

	const formatTechnicians = opt?.formatTechnicians || ((d) => d);

	const { data: techniciansData } = q;

	useEffect(() => {
		if (techniciansData) {
			const formattedData = techniciansData?.data?.map(formatTechnicians);
			setTechniciansList(formattedData);
		}
	}, [techniciansData, setTechniciansList]);

	return { ...q, data: techniciansList };
}
