import React, { useState } from 'react';
import { ReactQueryDevtools } from 'react-query/devtools';
import { QueryClient, QueryClientProvider, useQuery } from 'react-query';

import { useAccountInfo } from '../BusinessAccount/useBusinessInfo';
import { useOwnAppProvider } from '../AppContainer/useOwnApp';

const queryClient = new QueryClient();

export default function QueryProvider({ children }) {
	return (
		<QueryClientProvider client={queryClient}>
			<ReactQueryDevtools position="bottom-right" />
			{children}
		</QueryClientProvider>
	);
}

export function useAppQuery(options) {
	const { appId, appName, isAppOwn } = useAccountInfo();

	const [queryOptions, setQueryOptions] = useState({
		...options,
		enabled: false,
	});

	const [isRouteOwnApp] = useOwnAppProvider();

	const manageOptions = () => {
		options.queryKey = [...options.queryKey, [appName, appId]];
		options.retry = options.retry || false;
		options.enabled
			= (options.enabled ?? true)
			&& ((isRouteOwnApp && isAppOwn)
				|| !isRouteOwnApp
				|| options?.skipOwnApp === true);

		setQueryOptions({ ...options });
	};

	React.useEffect(() => {
		manageOptions();
	}, [isAppOwn, isRouteOwnApp]);

	options.queryKey = [...options.queryKey, [appName, appId]];

	return useQuery({ ...queryOptions, queryKey: options.queryKey, enabled: options.enabled });
}
