import React from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';
import ACLBoundary from '../App/ACL/ACLBoundary';
import { UnAuthCard } from '../App/UnAuth';
import Archived from './components/Archived';
import Stories from './components/Stories';
import Requests from './components/Requests';
import StoriesFrame from './StoriesFrame';


export default function StoriesRoutes() {
	return (
		<StoriesFrame>
			<Switch>
				<ACLBoundary
					aclUnAuthProps={{ replace: true }}
					aclGrants={['view:stories']}
					unAuthComponent={UnAuthCard}
				>
					<Route path="/stories/page" component={Stories} />
					<Route path="/stories/requests" component={Requests} />
					<Route path="/stories/archived" component={Archived} />
					<Route path="/stories">
						<Redirect push to="/stories/page" />
					</Route>
				</ACLBoundary>
			</Switch>
		</StoriesFrame>
	);
}
