import React from 'react';
import { MainStackWithAside } from '@zeal/zeal-ui';

import { DateTime } from 'luxon';
import { useQueryString } from '../App/useQueryString';
import AppContainer from '../App/AppContainer';
import Metrics from './components/Metrics';
import ChartPeakHours from './components/ChartPeakHours';
import ChartCustomerAcquisition from './components/ChartCustomerAcquisition';
import ChartBranchAnalytics from './components/ChartBranchAnalytics';
import MetricsAside from './components/MetricsAside';
import FiltersToolbar from './components/FiltersToolbar';

export default function AnalyticsViz(props) {
	const [queryState, updateQuery] = useQueryString();

	React.useEffect(() => {
		const { date } = queryState;
		const [dateFromStr, dateToStr] = date || [];

		if (queryState)
		{updateQuery({
			date:
					isValidDate(dateFromStr) && isValidDate(dateToStr)
						? [dateFromStr, dateToStr]
						: [
							DateTime.now().minus({ days: 6 })
								.toISODate(),
							DateTime.now().toISODate(),
						  ],
		});}
	}, []);

	const breadcrumbs = [
		{
			name: 'Analytics',
			to: '/analytics',
			icon: 'chartBar',
		},
	];

	return (
		<AppContainer breadcrumbs={breadcrumbs}>
			<MainStackWithAside
				toolbarEnd={<FiltersToolbar />}
				topContent={<Metrics />}
				aside={<MetricsAside />}
			>
				<ChartCustomerAcquisition />
				<ChartPeakHours />
				<ChartBranchAnalytics />
			</MainStackWithAside>
		</AppContainer>
	);
}

function isValidDate(value) {
	const dateWrapper = new Date(value);
	return !isNaN(dateWrapper.getDate());
}
