import React from 'react';
import queryString from 'query-string';
import { isFunction, isObject } from 'lodash-es';
import { useHistory, useLocation } from 'react-router-dom';

const queryStateCtx = React.createContext({});
queryStateCtx.displayName = 'QueryStateCtx';

const QueryStateConsumer = queryStateCtx.Consumer;

export function QueryStateProvider(props) {
	const [searchObject, updateQuery, searchString] = useQueryStringProvider(
		props.initialQuery,
	);

	const ctx = {
		searchObject,
		updateQuery,
		searchString,
	};

	return (
		<queryStateCtx.Provider value={ctx}>
			{props.children}
		</queryStateCtx.Provider>
	);
}

export function useQueryString(init) {
	const { searchObject, updateQuery, searchString }
		= React.useContext(queryStateCtx);

	return [searchObject, updateQuery, searchString];
}

export function useQueryStringProvider(init) {
	const location = useLocation();
	const history = useHistory();

	const { parse: parseQs, stringify: stringifyQs } = qs();

	console.log(
		'[useQueryString]',
		'init',
		{
			init,
			location,
			history,
		},
		new Error('-'),
	);

	const [searchObject, setSearchObject] = React.useState(
		parseQs(location.search),
	);

	React.useEffect(() => {
		console.log('[useQueryString]', 'useEffect[location.search]', {
			init,
			location,
			history,
		});

		setSearchObject(parseQs(location.search));
	}, [location.search]);

	/**
	 * updateQuery
	 * @param {object} value - query string object
	 * @param {object} [options] - update options
	 * @param {function} [options.cb] - callback func
	 * @param {Boolean} [options.replace] - replace history
	 * @param {Boolean} [options.noMerge=false] - don't merge query string
	 */
	const updateQuery = (value, options) => {
		const { cb, replace, noMerge } = options || {};

		console.log('[useQueryString]', 'updateQuery', {
			args: { value, options },
			hooks: { location, history },
		});

		setSearchObject((s) => {
			const search = noMerge
				? stringifyQs(Object.assign({}, value))
				: stringifyQs(Object.assign({}, s, value));

			if (replace) {
				history.replace({ search });
			} else {
				history.push({ search });
			}

			if (isFunction(cb)) {
				cb(search);
			}

			console.log('[useQueryString]', 'updateQuery:setSearchObject', {
				scope: { search },
			});

			return parseQs(search);
		});
	};

	React.useEffect(() => {
		console.log('[useQueryString]', 'useEffect[]', {
			args: { init },
			hooks: { history, location },
		});

		setTimeout(() => {
			console.log('[useQueryString]', 'useEffect[]:timeout', {
				args: { init },
				hooks: { history, location },
			});
			updateQuery(init || {});
		}, 0);
	}, []);

	React.useDebugValue({
		searchString: location.search,
		setSearchObject,
		searchObject,
		updateQuery,
		location,
		history,
	});

	console.log('[useQueryString]', 'return', {
		searchObject,
		updateQuery,
		search: location.search,
	});

	return [searchObject, updateQuery, location.search];
}

function qs(opt = {}) {
	const options = {
		arrayFormat: 'separator',
		arrayFormatSeparator: ':',
		...opt,
	};

	return {
		stringify(o) {
			if (isObject(o)) {
				return queryString.stringify(o, options);
			}

			throw new Error(`qs cant stringify ${typeof o}`);
		},
		parse(o) {
			return Object.assign({}, queryString.parse(o, options));
		},
	};
}
