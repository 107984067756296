import React from 'react';
import { useHistory } from 'react-router-dom';
import { NavLink } from 'react-router-dom';

import branchImagePlaceholder from '@zeal/zeal-ui/src/assets/images/branch-placeholder.png';

import {
	Badge,
	ButtonWithIcon,
	Card,
	CenterStackWithToolbar,
	Floating,
	Grid,
	Icon,
	Image,
	InlineLoading,
	SizedBox,
	Stack,
	Title,
} from '@zeal/zeal-ui';

import AppContainer from '../App/AppContainer';
import { useQueryString } from '../App/useQueryString';

import ACLBoundary from '../App/ACL/ACLBoundary';
import useBranches from './data/useBranches';

export default function Branches() {
	const history = useHistory();

	const [, updateQuery] = useQueryString();
	const { data: branchesData, isLoading } = useBranches();

	React.useEffect(() => {
		updateQuery();
	}, []);

	const branchesCards = !isLoading ? (
		<BranchesCards branchesData={branchesData} />
	) : (
		<InlineLoading />
	);

	const branchesBreadCrumb = [
		{
			name: 'Branches',
			to: '/branches',
			icon: 'mapMarker',
		},
	];

	const headerEndContent = (
		<ACLBoundary>
			<ButtonWithIcon
				primary
				label="Add Branch"
				btnIcon="plus"
				size="xs"
				m="none"
				onClick={() => {
					history.push('/branches/add');
				}}
				aclUnAuthProps={{ disabled: true }}
				aclGrants={['control:branches']}
			/>
		</ACLBoundary>
	);

	return (
		<AppContainer protected breadcrumbs={branchesBreadCrumb}>
			<CenterStackWithToolbar
				toolbarStart={<span></span>}
				toolbarEnd={headerEndContent}
			>
				<Stack m="xl" my="none" ml="none" p="none">
					<Grid cols="5" gap="3">
						{branchesCards}
					</Grid>
				</Stack>
			</CenterStackWithToolbar>
		</AppContainer>
	);
}

function BranchesCards(props) {
	const { branchesData } = props;
	return React.Children.toArray(
		(branchesData?.data || []).map((branch) => <BranchCard branch={branch} />),
	);
}

function BranchCard(props) {
	const { branch } = props;
	return (
		<Floating
			floatingComponent={<EditIcon branch={branch} />}
			xPosition="right"
			yPosition="top"
		>
			<Card p="none" fillHeight fillWidth>
				<SizedBox fillHeight fillWidth>
					<Stack p="none">
						<Floating
							floatingComponent={<StatusBadge branch={branch} />}
							xPosition="left"
							yPosition="bottom"
						>
							<SizedBox fillHeight fillWidth>
								<Image
									height="md"
									cover
									rounded
									src={branch?.photo || branchImagePlaceholder}
									alt=""
								/>
							</SizedBox>
						</Floating>
						<Stack m="sm" p="none">
							<Title title={branch?.name} size="xs" />
							<Title
								muteSubtitle={false}
								subtitle={branch?.phone_number}
								size="sm"
							/>
							<Stack>
								<Title subtitle={`${branch?.phone_number || 'NA'}`} size="sm" />
							</Stack>
						</Stack>
					</Stack>
				</SizedBox>
			</Card>
		</Floating>
	);
}

function StatusBadge(props) {
	const { branch } = props;
	return branch?.status === 'CLOSED' ? (
		<Badge danger size="xs">
			{branch?.status}
		</Badge>
	) : (
		<Badge secondary size="xs">
			{branch?.status || 'OPEN'}
		</Badge>
	);
}

function EditIcon(props) {
	const { branch } = props;
	const history = useHistory();

	const handleClick = () => {
		history.push({
			pathname: `/branches/edit/${branch?.uuid}`,
			state: { branch },
		});
	};
	return (
		<ACLBoundary>
			<ButtonWithIcon
				transparent
				p="none"
				m="none"
				onClick={handleClick}
				aclUnAuthProps={{ disabled: true }}
				aclGrants={['control:branches']}
			>
				<Icon rounded name="edit" size="lg" p="xs" margin="none" bg="snow" />
			</ButtonWithIcon>
		</ACLBoundary>
	);
}
