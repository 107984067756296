import React from 'react';
import { Route, Switch } from 'react-router-dom';
import ACLBoundary from '../App/ACL/ACLBoundary';

import Rewards from './Rewards';

export default function RewardsRoutes() {
	return (
		<Switch>
			<ACLBoundary
				aclUnAuthProps={{ replace: true }}
				aclGrants={['view:loyalty']}
			>
				<Route exact path="/rewards" component={Rewards} />
			</ACLBoundary>
		</Switch>
	);
}
