import React, { useCallback, useMemo, useState } from 'react';
import { useHistory } from 'react-router-dom';
import {
	Align,
	BasicTable,
	Button,
	ButtonWithIcon,
	Card,
	Center,
	Icon,
	InlineEmpty,
	MainStackWithAside,
	TabsHorizontal,
	Title,
} from '@zeal/zeal-ui';
import useTeamApp, { useDeleteTeam } from '../data/useTeamApp';
import TeamMembersFrame from '../TeamMembersFrame';
import ACLBoundary from '../../App/ACL/ACLBoundary';
import handleRole from './handleRole';
import handleNoneData from './handleNoneData';
import renderRelativeDatetime from './renderRelativeDatetime';
import DeleteModal from './DeleteModal';
import EmptyState from './EmptyState';

export default function TeamApp(props) {
	const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
	const [uuid, setUuid] = useState();

	const teamAppQuery = useTeamApp();
	const { data: teamsData } = teamAppQuery;
	const overview = useTeamApp();

	const { doDeleteTeam, isError, isLoading, isSuccess, status } = useDeleteTeam(
		{
			onSuccess: () => {
				teamAppQuery.refetch();
				setIsDeleteModalOpen(false);
			},
		}
	);

	const handleOpenDeleteModal = () => {
		setIsDeleteModalOpen(true);
	};
	const actionsCell = ({ row }) => (
		<Align align="right" gap="1">
			<ACLBoundary
				aclUnAuthProps={{ disabled: true }}
				aclGrants={['control:team']}
			>
				<Button
					transparent
					stretch={false}
					p="none"
					m="none"
					onClick={() => {
						handleTeamEdit(row.original);
					}}
				>
					<Icon
						rounded
						bg="disabled"
						name="pen"
						size="lg"
						p="xs"
						margin="none"
					/>
				</Button>
				<Button
					transparent
					stretch={false}
					p="none"
					m="none"
					onClick={() => {
						handleOpenDeleteModal(row);
						setUuid(row.original.uuid);
					}}
				>
					<Icon
						rounded
						bg="danger"
						name="trashAlt"
						size="lg"
						p="xs"
						margin="none"
					/>
				</Button>
			</ACLBoundary>
		</Align>
	);
	const history = useHistory();

	const handleTeamEdit = (val) =>
		val &&
		history.push({ pathname: `/teams/app/edit/${val?.uuid}`, state: val });

	const handleTeamAdd = () =>
		history.push({ pathname: '/teams/app/add', state: null });

	const handleTeamDelete = (uuid) => {
		doDeleteTeam({ uuid });
	};

	const teamPointColumns = useMemo(
		() => [
			{
				Header: 'Name',
				accessor: 'name',
				Cell: handleNoneData,
			},
			{
				Header: 'Email',
				accessor: 'email',
				Cell: handleNoneData,
			},
			{ Header: 'Username', accessor: 'username', Cell: handleNoneData },
			{
				Header: 'Roles',
				accessor: 'roles',
				Cell: handleRole,
			},
			{
				Header: 'Date Created',
				accessor: 'created_at',
				Cell: renderRelativeDatetime,
			},
			{ Header: '', accessor: 'actions', Cell: actionsCell },
		],
		[actionsCell]
	);

	let cashierData = [];
	let technicianData = [];
	const handleCashierData = () => {
		for (let index = 0; index < teamsData?.data?.length; index++) {
			if (!teamsData?.data[index]?.roles == []) {
				if (teamsData?.data[index]?.roles[0]?.name == 'Cashier') {
					cashierData.push(teamsData?.data[index]);
				}
				if (teamsData?.data[index]?.roles[0]?.name == 'Technician') {
					technicianData.push(teamsData?.data[index]);
				}
			}
		}
	};
	const teamsTable =
		(teamsData?.data || []).length > 0 ? (
			<>
				{handleCashierData()}
				<BasicTable
					data={teamsData?.data || []}
					columns={teamPointColumns}
					headerPadding="lg"
					cellPadding="lg"
				></BasicTable>
			</>
		) : (
			<Center>
				{' '}
				<InlineEmpty />{' '}
			</Center>
		);

	const cashierTable =
		(cashierData || []).length > 0 ? (
			<BasicTable
				data={cashierData || []}
				columns={teamPointColumns}
				headerPadding="lg"
				cellPadding="lg"
			></BasicTable>
		) : (
			<Center>
				<InlineEmpty />
			</Center>
		);

	const technicianTable =
		(technicianData || []).length > 0 ? (
			<BasicTable
				data={technicianData || []}
				columns={teamPointColumns}
				headerPadding="lg"
				cellPadding="lg"
			></BasicTable>
		) : (
			<Center>
				<InlineEmpty />
			</Center>
		);

	const closeDeleteModal = () => {
		setIsDeleteModalOpen(false);
	};
	const tabsData = [
		{
			id: '1',
			tabTitle: `all (${teamsData?.data?.length})`,
			tabContent: teamsTable,
		},
		{
			id: '2',
			tabTitle: `cashier (${cashierData?.length})`,
			tabContent: cashierTable,
		},
		{
			id: '3',
			tabTitle: `Technician (${technicianData?.length})`,
			tabContent: technicianTable,
		},
	];

	const tabContent = <TabsHorizontal data={tabsData} mt="xl" />;

	const breadcrumbs = [
		{
			name: 'Teams',
			to: '/teams/app',
			icon: 'idCard',
		},
	];

	return (
		<TeamMembersFrame breadcrumbs={breadcrumbs}>
			<MainStackWithAside
				fullWidth="true"
				toolbarStart=""
				toolbarEnd={
					<ACLBoundary>
						<ButtonWithIcon
							primary
							label="Add Team"
							btnIcon="plus"
							size="xs"
							m="none"
							pt="none"
							onClick={handleTeamAdd}
							aclUnAuthProps={{ disabled: true }}
							aclGrants={['control:team']}
						/>
					</ACLBoundary>
				}
			>
				{((overview.isSuccess && teamsData?.data) || []).length > 0 && (
					<Card>
						<Title
							title="Administrators"
							subtitle="Find all of your company's team accounts and their associated teams."
							size="md"
						/>
						{tabContent}
					</Card>
				)}
				<DeleteModal
					closeModal={closeDeleteModal}
					isDeleteModalOpen={isDeleteModalOpen}
					handleTeamDelete={handleTeamDelete}
					uuid={uuid}
					isError={isError}
					isLoading={isLoading}
				/>
				<EmptyState data={teamsData?.data} handleTeamAdd={handleTeamAdd} />
			</MainStackWithAside>
		</TeamMembersFrame>
	);
}
