import React from 'react';

export default function HandleOnStorySybmit(
	expireDate,
	media,
	branch_uuid,
	selected,
	uuid,
) {
	let expiresIn;

	if (expireDate) {
		let dtt = new Date(expireDate);

		let dd = dtt.getDate();
		if (dd < 10) {
			dd = '0' + dd;
		}

		let MMM = dtt.getMonth() + 1;
		if (MMM < 10) {
			MMM = '0' + MMM;
		}

		let yyyy = dtt.getFullYear();

		let h = dtt.getHours();
		if (h < 10) {
			h = '0' + h;
		}

		let mm = dtt.getMinutes();
		if (mm < 10) {
			mm = '0' + mm;
		}

		expiresIn = yyyy + '-' + MMM + '-' + dd + ' ' + h + ':' + mm + ':' + '00';
	}

	const formData = new FormData();

	if (typeof media == 'object') {
		formData.append('media', media);
	}
	formData.append('branch_uuid', branch_uuid);
	formData.append('expires_in', expiresIn ? expiresIn : selected);
	formData.append('uuid', uuid);

	return formData;
}
