import React from 'react';
import { useMutation } from 'react-query';

import { ApiRequest } from '@zeal/zeal-lib';
import { useQuery } from '../../App/QueryClient';
import { useQueryString } from '../../App/useQueryString';

async function apiBusinessAdmins({ queryKey: [, filters] }) {
	console.log(filters)
	return new ApiRequest('v3/business-admins')
		.addHeader('Accept', 'application/json')
		.addHeader('Content-type', 'application/json')
		.addQuery('page', filters?.page)
		.addQuery('role_id', filters?.roleId)
		.GET()
		.then((payload) => ({
			data: payload?.data,
			pageNum: payload?.meta.current_page,
			pagesCount: payload?.meta.last_page || 1,
			total: payload?.meta.total,
		}));
}

async function apiShowBusinessAdmin({ queryKey: [, filters] }) {
	return new ApiRequest(`v3/business-admins/${filters?.adminId}`)
		.addHeader('Accept', 'application/json')
		.addHeader('Content-type', 'application/json')
		.GET();
}

async function apiAddBusinessAdmin({ ...payload }) {
	return new ApiRequest('v3/business-admins')
		.addHeader('Accept', 'application/json')
		.addHeader('Content-type', 'application/json')
		.POST(JSON.stringify(payload));
}

async function apiEditBusinessAdmin({ ...payload }) {
	return new ApiRequest(`v3/business-admins/${payload?.uuid}`)
		.addHeader('Accept', 'application/json')
		.addHeader('Content-type', 'application/json')
		.PUT(JSON.stringify(payload));
}

async function apiEditDash({ ...payload }) {
	// return new ApiRequest("v3/specifics")
	// .addHeader("Accept", "application/json")
	// .addHeader("Content-type", "application/json")
	// .POST(JSON.stringify(payload), true)

	console.warn('[API/dummy]', 'useDashboard::apiEditDash');

	return Promise.resolve(true);
}

async function apiDeleteBusinessAdmin({ ...payload }) {
	return new ApiRequest(`business-admins/${payload?.uuid}`)
		.addHeader('Accept', 'application/json')
		.addHeader('Content-type', 'application/json')
		.DELETE();
}

export function useEditBusinessAdmin(opt) {
	const mutation = useMutation(apiEditBusinessAdmin);

	const { data, mutate, isSuccess, isError, error } = mutation;

	React.useEffect(() => {
		if (isError && opt.onError) {
			opt.onError(error);
		}

		if (!isSuccess) {
			return null;
		}

		if (opt.onSuccess) {
			opt.onSuccess();
		}
	}, [data, isSuccess, isError]);

	return {
		...mutation,
		doEditBusinessAdmin: mutate,
	};
}

export function useAddBusinessAdmin(opt) {
	const mutation = useMutation(apiAddBusinessAdmin);

	const { data, mutate, isSuccess, isError, error } = mutation;

	React.useEffect(() => {
		if (isError && opt.onError) {
			opt.onError(error);
		}

		if (!isSuccess) {
			return null;
		}

		if (opt.onSuccess) {
			opt.onSuccess();
		}
	}, [data, isSuccess, isError]);

	return {
		...mutation,
		doAddBusinessAdmin: mutate,
	};
}

export function useEditDash(opt) {
	const mutation = useMutation(apiEditDash);

	const { data, mutate, isSuccess } = mutation;

	React.useEffect(() => {
		if (!isSuccess) {
			return null;
		}

		if (opt.onSuccess) {
			opt.onSuccess();
		}
	}, [data, isSuccess]);

	return {
		...mutation,
		doEditDash: mutate,
	};
}

export function useDeleteBusinessAdmin(opt) {
	const mutation = useMutation(apiDeleteBusinessAdmin);

	const { data, mutate, isSuccess, isError, error } = mutation;

	React.useEffect(() => {
		if (isError && opt.onError) {
			opt.onError(error);
		}
		if (!isSuccess) {
			return null;
		}

		if (opt.onSuccess) {
			opt.onSuccess();
		}
	}, [data, isSuccess, isError]);

	return {
		...mutation,
		doDeleteBusinessAdmin: mutate,
	};
}

export default function useBusinessAdmins(opt) {
	const [queryState] = useQueryString();
	const { page } = queryState;

	const q = useQuery({
		queryFn: apiBusinessAdmins,
		staleTime: Infinity,
		enabled: opt?.role !== null,
		queryKey: [
			'z-business-admins',
			{
				page,
				...(opt?.roleId && { roleId: opt?.roleId })
			},
		],

	});

	const pagination = usePagination(q?.data || {});

	React.useDebugValue(q);

	return { ...q, ...pagination, data: q?.data?.data || [] };
}

export function useShowBusinessAdmin() {
	const [queryState] = useQueryString();

	const q = useQuery({
		queryFn: apiShowBusinessAdmin,
		queryKey: ['z-single-business-admins', { adminId: queryState?.adminId }],
		staleTime: Infinity,
		enabled: !!queryState?.adminId,
	});

	React.useDebugValue(q);

	return { ...q, data: q?.data?.data };
}

export function usePagination(pageData) {
	const { pageCurrent, pagesCount } = pageData;
	const [queryState] = useQueryString();

	if (queryState?.page != pageCurrent) {
		console.error(
			new Error(
				`Pagination mismatch query vs api, expected ${queryState?.page} found ${pageCurrent}`,
			),
		);
	}

	const pageNum = parseInt(pageCurrent);

	const pagesList = Array.from(
		new Set([
			1,
			2,
			pageNum - 1,
			pageNum,
			pageNum + 1,
			pagesCount - 1,
			pagesCount,
		]),
	).reduce((a, i, ndx, arr) => {
		const prv = arr[ndx - 1];
		const isCurrent = pageNum == i;

		if (i === 0) {
			i = prv + 1;
		}

		if (i - prv > 1) {
			a.push({ dff: i - prv, isMany: true });
		}

		if (isNaN(i) || i > pagesCount) {
			return a;
		}

		a.push({ num: i, isCurrent });
		return a;
	}, []);

	return {
		pagesList,
		pagesCount,
		page: queryState?.page,
	};
}
