import React, { Fragment } from 'react';
import { Card, Center, Icon, Stack, Title } from '@zeal/zeal-ui';

export default function EmailSentCard(props) {
	const emailSubtitle = (
		<Fragment>
			For any questions or problems
			<br />
			please email us at
		</Fragment>
	);

	const sentEmailText = (
		<Fragment>
			We just emailed you with the instructions to reset
			<br />
			your password
		</Fragment>
	);

	return (
		<div>
			<Card shadow="xl" border={false} p="lg">
				<Stack m="md">
					<Center>
						<Title primary title={<Icon name="envelope" size="3xl" />} />
					</Center>
				</Stack>
				<Stack m="md">
					<Center>
						<Title
							size="lg"
							titleText="Check in your mail!"
							subtitleText={sentEmailText}
						/>
					</Center>
				</Stack>

				<hr />
				<Center>
					<div>
						<Title margin="md" subtitle={emailSubtitle} />
						<Title primary title="LOL@myzeal.app" size="md" />
					</div>
				</Center>
			</Card>
		</div>
	);
}
