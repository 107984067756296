import React, { useCallback, useState } from 'react';
import { useHistory } from 'react-router-dom';
import {
	Align,
	BasicTable,
	Button,
	ButtonWithIcon,
	Center,
	Grid,
	Icon,
	InlineError,
	InlineLoading,
	MainStackWithAside,
	Modal,
	Stack,
	Title,
	TwoSideLayout,
} from '@zeal/zeal-ui';

import useRoles, { useDeleteRoles } from '../data/useRoles';
import TeamMembersFrame from '../TeamMembersFrame';
import openToastr from '../../App/Toastr/openToastr';

export default function Roles(props) {
	const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
	const [deletedRoleId, setDeletedRoleId] = useState();

	const rolesQuery = useRoles();
	const { data: rolesData } = rolesQuery;
	const { doDeleteRoles, isLoading: isDeleteLoading } = useDeleteRoles({
		onSuccess: () => {
			rolesQuery.refetch();
			setIsDeleteModalOpen(false);
			setDeletedRoleId(null);
			openToastr({ message: 'Role Deleted Successfully' });
		},
		onError: (error) => {
			const clonedError = error?.response.clone();

			if (!clonedError?.bodyUsed) {
				clonedError.json().then((err) => {
					openToastr({
						message: err?.message || 'Failed to delete role',
						isError: true,
					});
				});
			}
		},
	});

	const handleOpenDeleteModal = (row) => {
		setIsDeleteModalOpen(true);
		setDeletedRoleId(row?.original?.id);
	};

	const actionsCell = ({ row }) => (
		<Align align="right" gap="1">
			<Button
				transparent
				stretch={false}
				p="none"
				m="none"
				onClick={() => {
					handleRolesEdit(row.original);
				}}
			>
				<Icon
					rounded
					bg="disabled"
					name="pen"
					size="lg"
					p="xs"
					margin="none"
				/>
			</Button>

			<Button
				transparent
				stretch={false}
				p="none"
				m="none"
				onClick={() => handleOpenDeleteModal(row)}
			>
				<Icon
					rounded
					bg="danger"
					name="trashAlt"
					size="lg"
					p="xs"
					margin="none"
				/>
			</Button>
		</Align>
	);
	const history = useHistory();

	const handleRolesAdd = useCallback(
		() =>
			history.push({
				pathname: '/teams/dashboard/roles/create',
			}),
		[history],
	);

	const handleRolesEdit = useCallback(
		(rowData) =>
			history.push({
				pathname: '/teams/dashboard/roles/edit',
				search: `?roleId=${rowData.id}`,
			}),
		[history],
	);

	const handleRolesDelete = () => {
		doDeleteRoles({ id: deletedRoleId });
	};

	const rolesPointColumns = [
		{ Header: 'Role Name', accessor: 'name' },
		{
			Header: 'Role Description',
			accessor: 'description',
		},
		{ Header: '', accessor: 'actions', Cell: actionsCell },
	];

	const rolesTable = rolesData?.data?.length > 0 && (
		<BasicTable
			shadow
			data={rolesData?.data || []}
			columns={rolesPointColumns}
			headerPadding="md"
			cellPadding="lg"
		></BasicTable>
	);

	const closeDeleteModal = () => {
		setIsDeleteModalOpen(false);
	};
	const breadcrumbs = [
		{ name: 'Dashboard', to: '/teams/dashboard' },
		{ name: 'Roles', to: '/teams/dashboard/roles' },
	];

	return (
		<TeamMembersFrame breadcrumbs={breadcrumbs}>
			<MainStackWithAside
				fullWidth="true"
				toolbarStart=""
				toolbarEnd={
					<ButtonWithIcon
						primary
						label="Add Roles"
						btnIcon="plus"
						size="xs"
						m="none"
						pt="none"
						onClick={handleRolesAdd}
					/>
				}
			>
				<Stack m="md" p="none">
					{rolesTable}
				</Stack>
				<DeleteModal
					closeModal={closeDeleteModal}
					isDeleteModalOpen={isDeleteModalOpen}
					handleRolesDelete={handleRolesDelete}
					isLoading={isDeleteLoading}
				/>
			</MainStackWithAside>
			<EmptyState data={rolesData?.data} handleRolesAdd={handleRolesAdd} />
		</TeamMembersFrame>
	);
}

function EmptyState({ data, handleRolesAdd }) {
	const overview = useRoles();

	const emptyState = !overview.isLoading && (data || []).length === 0 && (
		<Stack>
			<Center>
				<Title
					title="Roles management page is empty "
					subtitle="Create some roles and invite members to have access to your dashboard "
					size="md"
				/>
			</Center>
			<Center className="mt-8">
				<Stack>
					<ButtonWithIcon
						primary
						label="Add Roles"
						btnIcon="plus"
						size="xs"
						m="none"
						pt="none"
						onClick={handleRolesAdd}
					/>
				</Stack>
			</Center>
		</Stack>
	);

	return (
		<div>
			{emptyState}
			<Center>
				<InlineError hide={!overview.isError} />
				<InlineLoading hide={!overview.isLoading} />
			</Center>
		</div>
	);
}

function DeleteModal(props) {
	const { isDeleteModalOpen, handleRolesDelete, closeModal, isLoading } = props;

	return (
		<Modal
			isModalOpen={isDeleteModalOpen}
			title="Delete Role"
			onClose={closeModal}
		>
			<Stack p="none">
				<Title title="Are you sure you want to delete this role?" />

				<Grid gap="5">
					<span></span>
					<TwoSideLayout>
						<Button stretch={false} size="md" onClick={closeModal}>
							Cancel
						</Button>

						<Align align="left">
							<InlineLoading hide={!isLoading} />
							<ButtonWithIcon
								secondary
								stretch={false}
								size="md"
								onClick={() => handleRolesDelete()}
							>
								Delete
							</ButtonWithIcon>
						</Align>
					</TwoSideLayout>
				</Grid>
			</Stack>
		</Modal>
	);
}
