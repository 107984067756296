import React from 'react';
import { useMutation } from 'react-query';

import { ApiRequest } from '@zeal/zeal-lib';
import { useQuery } from '../../../../App/QueryClient';

async function apiRewardModel({ queryKey: [, filters] }) {
	return new ApiRequest('punch-properties/model')
		.addHeader('Accept', 'application/json')
		.GET();
}

export default function useRewardModel() {
	const q = useQuery({
		queryFn: apiRewardModel,
		staleTime: Infinity,
		queryKey: ['z-reward-model'],
	});

	React.useDebugValue(q);

	return q;
}

async function apiSetModel({ ...payload }) {
	return new ApiRequest('model')
		.addHeader('Accept', 'application/json')
		.addHeader('Content-Type', 'application/json')
		.POST(JSON.stringify(payload));
}

export function useSetModel(opt) {
	const mutation = useMutation(apiSetModel);

	const { data, mutate, isSuccess, isError } = mutation;

	React.useEffect(() => {
		if (isError) {
			if (opt.onError) {
				opt.onError();
			}
		}

		if (!isSuccess) {
			return null;
		}

		if (opt.onSuccess) {
			opt.onSuccess();
		}
	}, [data, isSuccess, isError]);

	return {
		...mutation,
		doSetModel: mutate,
	};
}
