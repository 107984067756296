import React, { useReducer, useState } from 'react';
import {
	Button,
	ButtonWithIcon,
	Grid,
	Icon,
	InlineLoading,
	Input,
	Modal,
	Select,
	Stack,
	Title,
	ToggleButton,
	TwoSideLayout,
} from '@zeal/zeal-ui';

import usePunchProperties, {
	useUpdatePunchProperties,
} from '../data/usePunchProperties';
import ACLBoundary from '../../App/ACL/ACLBoundary';

function propertiesReducer(prevState, newState) {
	return { ...prevState, ...newState };
}

export default function PunchProperties({ pointsEgp, ...props }) {
	const punchDelayTimeUnitOptions = [
		{ value: 'h', label: 'Hours' },
		{ value: 'm', label: 'Minutes' },
	];

	const initialProperties = {
		numberToggle: false,
		pointsModalOpen: false,
		delayModalOpen: false,
		valueToggle: false,
		punchDelayToggle: false,
		pointsPerEgp: 0,
		punchDelayValue: 0,
		punchDelayTimeUnit: punchDelayTimeUnitOptions[0],
	};

	const [state, dispatch] = useReducer(propertiesReducer, initialProperties);

	const punchPropertiesQuery = usePunchProperties();

	const { data: punchPropertiesData, isLoading: isLoadingPropertiesFetch }
		= punchPropertiesQuery;

	const getPunchDelayData = (propertiesData) => {
		const [, delayValue] = (propertiesData?.delay || '')?.split(':');

		const delayType = delayValue >= 60 ? 'h' : 'm';

		const punchDelayUnit = punchDelayTimeUnitOptions.find(
			(option) => option.value === delayType,
		);

		const convertedDelayValue
			= delayType === 'h' ? Math.ceil(delayValue / 60) : delayValue;

		const punchDelayValue = punchDelayValueOptions[punchDelayUnit?.value]?.find(
			(option) => option.value == convertedDelayValue,
		);

		return [punchDelayUnit, punchDelayValue];
	};

	React.useEffect(() => {
		const propertiesData = punchPropertiesData?.data;

		const [punchDelayUnit, punchDelayValue] = getPunchDelayData(propertiesData);

		dispatch({
			punchDelayToggle: !!propertiesData?.delay,
			valueToggle: propertiesData?.receipt_value,
			numberToggle: propertiesData?.receipt_no,
			pointsPerEgp: propertiesData?.factor,
			punchDelayTimeUnit: punchDelayUnit,
			punchDelayValue,
		});
	}, [punchPropertiesData]);

	const { doUpdateProperties, isLoading: isLoadingPropertiesUpdate }
		= useUpdatePunchProperties({
			onSuccess: () => {
				punchPropertiesQuery.refetch();
				dispatch({ delayModalOpen: false, pointsModalOpen: false });
			},
		});

	const submitPointsPerEgp = () => {
		const payload = {
			factor: `${state?.pointsPerEgp}`,
		};

		doUpdateProperties(payload);
	};

	const openDelayModal = () => {
		dispatch({ delayModalOpen: true });
	};

	const openPointsModal = () => {
		dispatch({ pointsModalOpen: true });
	};

	const punchDelayValueOptions = {
		h: generateNumberOptions(1, 1, 24),
		m: generateNumberOptions(5, 5, 11),
	};

	const closeDelayModal = () => {
		const propertiesData = punchPropertiesData?.data;

		const [punchDelayUnit, punchDelayValue] = getPunchDelayData(propertiesData);

		dispatch({
			delayModalOpen: false,
			punchDelayTimeUnit: punchDelayUnit,
			punchDelayValue,
		});
	};

	const closePointsModal = () => {
		const propertiesData = punchPropertiesData?.data;

		dispatch({ pointsModalOpen: false, pointsPerEgp: propertiesData?.factor });
	};

	const onChangeToggle = (ev) => {
		const propertiesMap = {
			punchDelayToggle: 'delay',
			valueToggle: 'receipt_value',
			numberToggle: 'receipt_no',
		};

		const toggleName = ev.target.name;

		let toggleValue = !state[toggleName];

		if (toggleName === 'punchDelayToggle') {
			//if punch delay toggle was false and now true. open punch delay modal
			//else set delay to null and send it to API
			if (toggleValue) {
				dispatch({ delayModalOpen: true });
				return;
			} else {
				toggleValue = null;
				dispatch({ [toggleName]: toggleValue });
			}
		} else {
			//any toggle state other than punch delay will be changed once user
			// changes it without waiting for api
			dispatch({ [toggleName]: toggleValue });
		}

		const payload = {
			[propertiesMap[toggleName]]: toggleValue,
		};

		doUpdateProperties(payload);
	};

	const isToggleLoading = isLoadingPropertiesUpdate || isLoadingPropertiesFetch;

	const doSubmitPunchDelay = () => {
		const payload = {
			delay: `${state?.punchDelayValue?.value}`,
		};

		if (state?.punchDelayTimeUnit?.value === 'h')
		{payload.delay = state?.punchDelayValue?.value * 60;}

		doUpdateProperties(payload);
	};

	return (
		<div>
			<Stack wrap row gap="xl" m="md" pt="none">
				<h3>Punch Properties</h3>
				<Icon name="questionCircle" />
			</Stack>
			<Stack m="md" gap="lg">
				<TwoSideLayout>
					<Title muteSubtitle={false} subtitle="Receipt Number" size="sm" />
					<PropertyToggle
						isLoading={isToggleLoading}
						state={state}
						onChangeToggle={onChangeToggle}
						name="numberToggle"
					/>
				</TwoSideLayout>
				<TwoSideLayout>
					<Title muteSubtitle={false} subtitle="Receipt Value" size="sm" />
					<PropertyToggle
						isLoading={isToggleLoading}
						state={state}
						onChangeToggle={onChangeToggle}
						name="valueToggle"
					/>
				</TwoSideLayout>
				<TwoSideLayout>
					<Title muteSubtitle={false} subtitle="Punch Delay" size="sm" />
					<Stack row gap="sm" p="none">
						<ACLBoundary>
							<ButtonWithIcon
								transparent
								p="none"
								m="none"
								onClick={openDelayModal}
								aclUnAuthProps={{ disabled: true }}
								aclGrants={['control:loyalty']}
							>
								<Icon name="cog" margin="none" />
							</ButtonWithIcon>
						</ACLBoundary>
						<PropertyToggle
							isLoading={isToggleLoading}
							state={state}
							onChangeToggle={onChangeToggle}
							name="punchDelayToggle"
						/>
					</Stack>
				</TwoSideLayout>
				<PointsPerEgp
					pointsEgp={pointsEgp}
					openPointsModal={openPointsModal}
					pointsPerEgp={state.pointsPerEgp}
				/>
			</Stack>
			<AddEditDelayModal
				doSubmitPunchDelay={doSubmitPunchDelay}
				closeModal={closeDelayModal}
				punchDelayValue={state.punchDelayValue}
				onChangePunchDelay={(value) => dispatch({ punchDelayValue: value })}
				isDelayModalOpen={state.delayModalOpen}
				punchDelayTimeUnitOptions={punchDelayTimeUnitOptions}
				punchDelayValueOptions={punchDelayValueOptions}
				punchDelayTimeUnit={state.punchDelayTimeUnit}
				dispatch={dispatch}
			/>
			<PointsModal
				isPointsModalOpen={state.pointsModalOpen}
				pointsPerEgpInput={state.pointsPerEgp}
				onChangePunchRange={(evt) =>
					dispatch({ pointsPerEgp: evt.target.value })
				}
				closeModal={closePointsModal}
				doSubmitPointsPerEgp={submitPointsPerEgp}
			/>
		</div>
	);
}

function PointsPerEgp(props) {
	const { pointsEgp, openPointsModal, pointsPerEgp } = props;

	const pointsValue = `${pointsPerEgp?.toString() || ''}PTS/EGP`;

	return pointsEgp ? (
		<TwoSideLayout>
			<Title muteSubtitle={false} subtitle="Pts/EGP" size="sm" />
			<Stack row gap="md">
				<ACLBoundary
					aclUnAuthProps={{ disabled: true }}
					aclGrants={['control:loyalty']}
				>
					<ButtonWithIcon
						transparent
						p="none"
						m="none"
						onClick={openPointsModal}
					>
						<Icon name="cog" margin="none" />
					</ButtonWithIcon>
				</ACLBoundary>
				<Title muteSubtitle={false} subtitle={pointsValue} size="xs" />
			</Stack>
		</TwoSideLayout>
	) : (
		<span></span>
	);
}

function AddEditDelayModal(props) {
	const {
		doSubmitPunchDelay,
		closeModal,
		punchDelayValue,
		onChangePunchDelay,
		isDelayModalOpen,
		punchDelayTimeUnitOptions,
		punchDelayValueOptions,
		punchDelayTimeUnit,
		dispatch,
	} = props;

	const isSubmitDisabled = !punchDelayValue?.value || !punchDelayTimeUnit.value;

	return (
		<Modal
			isModalOpen={isDelayModalOpen}
			title="Punch Delay"
			onClose={closeModal}
		>
			<Stack row gap="xl">
				<Select
					inputName="punchDelayTimeUnit"
					options={punchDelayTimeUnitOptions}
					selected={punchDelayTimeUnit}
					onChange={(unit) =>
						dispatch({ punchDelayTimeUnit: unit, punchDelayValue: null })
					}
					placeholder={punchDelayTimeUnit?.value ? '' : 'Time unit'}
				/>
				<Select
					inputName="punchDelayValue"
					options={punchDelayValueOptions[punchDelayTimeUnit?.value]}
					selected={punchDelayValue}
					onChange={onChangePunchDelay}
					placeholder={punchDelayValue?.value ? '' : 'Value'}
				/>
			</Stack>

			<Grid gap="5">
				<span></span>
				<TwoSideLayout>
					<Button stretch={false} size="md" m="xs" onClick={closeModal}>
						Cancel
					</Button>
					<ButtonWithIcon
						stretch={false}
						m="xs"
						px="sm"
						py="xs"
						primary
						onClick={() => doSubmitPunchDelay()}
						disabled={isSubmitDisabled}
					>
						Save
					</ButtonWithIcon>
				</TwoSideLayout>
			</Grid>
		</Modal>
	);
}

function PointsModal(props) {
	const {
		isPointsModalOpen,
		pointsPerEgpInput,
		onChangePunchRange,
		closeModal,
		doSubmitPointsPerEgp,
	} = props;

	return (
		<Modal
			isModalOpen={isPointsModalOpen}
			title="Points/EGP"
			onClose={closeModal}
		>
			<Stack row gap="xl">
				<Input.Number
					type="text"
					inputName="punchRange"
					placeholder="PTS/EGP"
					label="Points/EGP"
					onChange={onChangePunchRange}
					value={pointsPerEgpInput}
				/>
			</Stack>

			<Grid gap="5">
				<span></span>
				<TwoSideLayout>
					<Button stretch={false} size="md" m="xs" onClick={closeModal}>
						Cancel
					</Button>
					<ButtonWithIcon
						stretch={false}
						m="xs"
						px="sm"
						py="xs"
						primary
						onClick={() => doSubmitPointsPerEgp()}
					>
						Save
					</ButtonWithIcon>
				</TwoSideLayout>
			</Grid>
		</Modal>
	);
}

function PropertyToggle(props) {
	const { isLoading, state, onChangeToggle, name } = props;

	return (
		<ACLBoundary>
			<ToggleButton
				isChecked={state[name]}
				name={name}
				size="sm"
				onChange={onChangeToggle}
				disabled={isLoading}
				aclUnAuthProps={{ disabled: true }}
				aclGrants={['control:loyalty']}
			/>
		</ACLBoundary>
	);
}

function generateNumberOptions(start, step, count) {
	const numbersArray = Array.from(
		{ length: count },
		(_, i) => start + step * i,
	);

	return numbersArray.map((n) => ({ label: n, value: n }));
}
