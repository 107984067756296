import React, { useEffect, useReducer } from 'react';
import {
	Anchor,
	Badge,
	BasicSelect,
	Button,
	ButtonWithIcon,
	Center,
	Grid,
	Icon,
	Input,
	Modal,
	Stack,
	Title,
	TwoSideLayout,
} from '@zeal/zeal-ui';
import { cloneDeep } from 'lodash';

import { hoursDifference } from '../Schedule';

export default function SetDayModal(props) {
	const { isModalOpen, closeModal, handleSubmit, editedDay } = props;

	const initialFormValues = {
		start: '',
		end: '',
		slot_duration: '',
		slot_capacity: '',
	};

	const addEditFormReducer = (state, action) => {
		switch (action.type) {
			case 'init': {
				return action.data;
			}
			case 'add': {
				return [...state, initialFormValues];
			}
			case 'update': {
				const updatedItem = {
					...state[action.index],
					...action.data,
				};

				const newState = cloneDeep(state);
				newState[action.index] = updatedItem;

				return newState;
			}
			case 'delete': {
				const newState = state.filter((element, i) => i !== action.index);
				return newState;
			}
			default:
				return state;
		}
	};

	const [formState, formDispatch] = useReducer(addEditFormReducer, [
		initialFormValues,
	]);

	useEffect(() => {
		const initialDayState
			= editedDay?.bookingDurations?.length > 0
				? editedDay?.bookingDurations
				: [initialFormValues];

		formDispatch({
			data: initialDayState,
			type: 'init',
		});
	}, [editedDay]);

	const durationsOptions = React.Children.toArray([
		<option>-- Pick A Duration --</option>,
		...[0.5, 0.75, 1, 2, 3, 4, 5, 6, 7, 8]?.map((b) => (
			<option value={b}>{b < 1 ? b * 60 : b} {b < 1 ? 'Minutes' : 'Hours'}</option>
		)),
	]);

	const capacityOptions = React.Children.toArray([
		<option>-- Pick Capacity --</option>,
		...[1, 2, 3, 4, 5, 6, 7, 8, 9, 10]?.map((b) => <option>{b}</option>),
	]);

	const formDurations = React.Children.toArray(
		formState?.map((state, index) => (
			<div>
				<Stack p="none">
					<TwoSideLayout>
						<Title title="Starts In" />
						<DurationButton
							length={formState.length}
							index={index}
							formDispatch={formDispatch}
						/>
					</TwoSideLayout>
					<Input
						type="time"
						fillWidth={true}
						value={state.start}
						onChange={(evt) => {
							formDispatch({
								index: index,
								type: 'update',
								data: { start: evt.target.value },
							});
						}}
					/>
				</Stack>
				<Stack>
					<Title title="Ends At" />
					<Input
						type="time"
						fillWidth={true}
						value={state.end}
						onChange={(evt) =>
							formDispatch({
								index: index,
								type: 'update',
								data: { end: evt.target.value },
							})
						}
					/>
				</Stack>
				<Stack>
					<Title title="Slot Duration" />
					<BasicSelect
						name="duration"
						value={state?.slot_duration}
						onChange={(evt) =>
							formDispatch({
								index: index,
								type: 'update',
								data: { slot_duration: evt.target.value },
							})
						}
						placeholder="-- Pick A Duration --"
						options={durationsOptions}
					/>
				</Stack>
				<Stack>
					<Title title="Slot Capacity" />
					<BasicSelect
						name="capacity"
						value={state?.slot_capacity}
						onChange={(evt) =>
							formDispatch({
								index: index,
								type: 'update',
								data: { slot_capacity: evt.target.value },
							})
						}
						placeholder="-- Pick Capacity --"
						options={capacityOptions}
					/>
				</Stack>
				<Stack>
					<Title title="Available Slots" />
					<Stack wrap row gap="md">
						<AvailableSlots state={state} />
					</Stack>
				</Stack>
				<Stack my="md" p="none">
					<hr />
				</Stack>
			</div>
		)),
	);

	return (
		<Modal
			isModalOpen={isModalOpen}
			title={editedDay?.day || 'Set Day'}
			onClose={closeModal}
		>
			<Stack>
				{formDurations}

				<Button
					transparent
					p="none"
					onClick={() => {
						formDispatch({
							type: 'add',
						});
					}}
				>
					<Stack row gap="sm" p="none">
						<Center horizontal={false}>
							<Title
								primary
								title={<Icon name="plusCircle" size="xs" m="sm" />}
								size="xs"
							/>
							<Title
								primary
								title="Set another duration in the day"
								size="xs"
							/>
						</Center>
					</Stack>
				</Button>
				<Stack my="md" p="none">
					<hr />
				</Stack>

				<Grid gap="5">
					<span></span>
					<TwoSideLayout>
						<Button stretch={false} size="sm" m="xs" onClick={closeModal}>
							Cancel
						</Button>
						<Stack row p="none" gap="md">
							<ButtonWithIcon
								stretch={false}
								size="sm"
								primary
								m="xs"
								onClick={() => handleSubmit(formState)}
							>
								Save
							</ButtonWithIcon>
						</Stack>
					</TwoSideLayout>
				</Grid>
			</Stack>
		</Modal>
	);
}

function DurationButton(props) {
	const { length, index, formDispatch } = props;

	const showButton = (length > 1 || index !== 0) && (
		<ButtonWithIcon
			transparent
			p="none"
			m="none"
			onClick={() => {
				formDispatch({
					index,
					type: 'delete',
				});
			}}
		>
			<Anchor color="danger" label="Delete Duration" />
		</ButtonWithIcon>
	);

	return showButton;
}

function getSlotsNumber(difference, duration) {
	const slotNumber = difference / (duration || 1);

	if (slotNumber < 0) { return -1; }

	return Math.floor(slotNumber);
}

function generateSlotsTimes(startTime, count, duration) {
	const times = [];
	for (let i = 0; i < count; i++) {
		const slotTime = addHours(startTime, duration * i);
		times.push(slotTime);
	}
	return times;
}

function addHours(time, hours) {
	const date = new Date(`1/1/2000 ${time}`);
	date.setTime(date.getTime() + hours * 60 * 60 * 1000);

	return date.toLocaleString('en-US', {
		hour: 'numeric',
		minute: 'numeric',
		hour12: true,
	});
}

function AvailableSlots(props) {
	const { state } = props;
	if (state.start && state.end && state.slot_duration) {
		const difference = hoursDifference(state.start, state.end) || 0;

		const slotNumber = getSlotsNumber(difference, state.slot_duration);

		if (slotNumber < 0) {
			return (
				<Title
					danger
					title="Error Start Date must be before End date"
					size="xs"
				/>
			);
		} else if (!isNaN(slotNumber)) {
			const slotTimes = generateSlotsTimes(
				state.start,
				slotNumber,
				state.slot_duration,
			);

			return <SlotTime data={slotTimes} />;
		}
	}
	return null;
}

function SlotTime(props) {
	const { data } = props;

	const slotTimes = data?.map((time) => (
		<Badge bg="green" size="xs" p="sm" rounded="lg">
			{time}
		</Badge>
	));

	return slotTimes;
}
