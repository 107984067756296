import React, { useEffect, useState } from 'react';
import { useMutation } from 'react-query';
import { useParams } from 'react-router-dom';

import { ApiRequest } from '@zeal/zeal-lib';
import { useQuery } from '../../App/QueryClient';
import { useQueryString } from '../../App/useQueryString';

async function apiOrders({ queryKey: [, filters] }) {
	const request = new ApiRequest('v3/orders')
		.addHeader('Accept', 'application/json')
		.addQuery('page', filters?.page)
		.addQuery('filter[status]', filters?.status)
		.addQuery('filter[branch_uuid]', filters?.branch)
		.addQuery('filter[from]', filters?.dateFrom)
		.addQuery('filter[to]', filters?.dateTo)
		.addQuery('filter[assignee_uuid]', filters?.assigned)
		.GET()
		.then((payload) => ({
			data: payload?.data,
			totalCount: payload?.meta.total,
			pageNum: payload?.meta.current_page,
			pagesCount: payload?.meta.last_page,
		}));

	return request;
}

async function apiOrderDetails({ queryKey: [, filters] }) {
	const request = new ApiRequest(`v3/orders/${filters.orderId}`)
		.addHeader('Accept', 'application/json')
		.GET();

	return request;
}

export function useOrderDetails() {
	const { orderId } = useParams();

	const q = useQuery({
		queryFn: apiOrderDetails,
		queryKey: [
			'z-order-details',
			{
				orderId,
			},
		],
		staleTime: Infinity,
	});

	return q;
}

export default function useOrders() {
	const [queryState] = useQueryString();

	const { page, branch, status, dateFrom, dateTo, assigned } = queryState;

	const q = useQuery({
		queryFn: apiOrders,
		staleTime: 1000 * 60 * 10, // 10 min
		queryKey: [
			'z-orders',
			{
				page,
				branch,
				status,
				dateFrom,
				dateTo,
				assigned,
			},
		],
	});

	const pagination = usePagination(q?.data || {});

	const [searchTerm, setSearchTerm] = React.useState();
	const [dataGrouped, setDataGrouped] = React.useState([]);

	React.useEffect(() => {
		const grouped = q.data;
		setDataGrouped(grouped);
	}, [searchTerm, q.data, setDataGrouped]);

	const search = React.useCallback(
		(term) => {
			setSearchTerm(term);
		},
		[setSearchTerm],
	);

	return { search, ...q, ...pagination, data: dataGrouped };
}

export function usePagination({ pageNum, pagesCount }) {
	const [queryState] = useQueryString();

	if (queryState?.page != pageNum) {
		console.error(
			new Error(
				`Pagination mismatch query vs api, expected ${queryState?.page} found ${pageNum}`,
			),
		);
	}

	const pagesList = Array.from(
		new Set([
			1,
			2,
			pageNum - 1,
			pageNum,
			pageNum + 1,
			pagesCount - 1,
			pagesCount,
		]),
	).reduce((a, i, ndx, arr) => {
		const prv = arr[ndx - 1];
		const isCurrent = pageNum == i;

		if (i === 0) {
			i = prv + 1;
		}

		if (i - prv > 1) {
			a.push({ dff: i - prv, isMany: true });
		}

		if (isNaN(i) || i > pagesCount) {
			return a;
		}

		a.push({ num: i, isCurrent });
		return a;
	}, []);

	return {
		pagesList,
		pagesCount,
		page: queryState?.page,
	};
}

async function apiUpdateOrderActions({ ...payload }) {
	return new ApiRequest(`v3/orders/${payload.orderId}`)
		.addHeader('Accept', 'application/json')
		.addHeader('Content-Type', 'application/json')
		.PUT(JSON.stringify(payload));
}

export function useUpdateOrderActions(opt) {
	const mutation = useMutation(apiUpdateOrderActions);

	const { data, mutate, isSuccess, isError } = mutation;

	React.useEffect(() => {
		if (isError) {
			if (opt.onError) {
				opt.onError();
			}
		}

		if (!isSuccess) {
			return null;
		}

		if (opt.onSuccess) {
			opt.onSuccess();
		}
	}, [data, isSuccess, isError]);

	return {
		...mutation,
		doUpdateOrderActions: mutate,
	};
}

async function apiUpdateOrderItems({ ...payload }) {
	return new ApiRequest(`v3/orders/${payload.orderId}/items`)
		.addHeader('Accept', 'application/json')
		.addHeader('Content-Type', 'application/json')
		.PUT(JSON.stringify(payload));
}

export function useUpdateOrderItems(opt) {
	const mutation = useMutation(apiUpdateOrderItems);

	const { data, mutate, isSuccess, isError } = mutation;

	React.useEffect(() => {
		if (isError) {
			if (opt.onError) {
				opt.onError();
			}
		}

		if (!isSuccess) {
			return null;
		}

		if (opt.onSuccess) {
			opt.onSuccess();
		}
	}, [data, isSuccess, isError]);

	return {
		...mutation,
		doUpdateOrderItems: mutate,
	};
}

async function apiBranchMenu({ queryKey: [, filters] }) {
	return new ApiRequest(`menus/branch/${filters.branchId}`)
		.addHeader('Accept', 'application/json')
		.GET();
}

export function useBranchMenu(opt) {
	const q = useQuery({
		queryFn: apiBranchMenu,
		queryKey: [
			'z-branch-menu',
			{
				branchId: opt?.branchId,
			},
		],
		enabled: !!opt?.branchId,
		staleTime: Infinity,
	});

	const [categoriesList, setCategoriesList] = useState([]);

	const formatCategory = opt?.formatCategory || ((d) => d);

	const { data: branchesData } = q;

	useEffect(() => {
		if (branchesData) {
			const formattedData = branchesData?.categories?.map(formatCategory);
			setCategoriesList(formattedData);
		}
	}, [branchesData, setCategoriesList]);

	return { ...q, data: branchesData, categoriesList };
}
