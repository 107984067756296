import React, { useEffect } from 'react';
import {
	Align,
	Button,
	ButtonWithIcon,
	Grid,
	InlineError,
	InlineLoading,
	Input,
	Label,
	Modal,
	Stack,
	Title,
	TwoSideLayout,
} from '@zeal/zeal-ui';

import useMultiplePunches, {
	useAddMultiplePunches,
	useEditMultiplePunches,
} from '../data/useMultiplePunches';
import openToastr from '../../App/Toastr/openToastr';

export default function MultiplePunchesAddEditModal(props) {
	const {
		isAddEditModalOpen,
		closeModal,
		editedMultiplePunch,
		onChangePunchRange,
		punchRangeInput,
		onChangePunchNumber,
		punchesNumberInput,
		removeError,
		setRemoveError,
		resetForm,
	} = props;

	const multiplePunchesModalTitle = editedMultiplePunch
		? 'Edit Punch Range'
		: 'Add Punch Range';

	const storiesQuery = useMultiplePunches();

	const {
		doAddMultiplePunches,
		isError: isAddError,
		isLoading: isAddLoading,
		error: addError,
	} = useAddMultiplePunches({
		onSuccess: () => {
			storiesQuery.refetch();
			closeModal(false);
			resetForm();
		},
	});

	const {
		doEditMultiplePunches,
		isError: isEditError,
		isLoading: isEditLoading,
		error: editError,
	} = useEditMultiplePunches({
		onSuccess: () => {
			storiesQuery.refetch();
			closeModal(false);
			resetForm();
		},
	});

	const handleMultiplePunchesSubmit = (punches, value) => {
		setRemoveError(false);

		if (editedMultiplePunch) {editPunchRange({ punches, value });}
		else {addPunchRange({ punches, value });}
	};

	useEffect(() => {
		if (addError?.response) {
			let errMsg = '';

			if ([400, 422].includes(addError?.response?.status))
			{errMsg
					= addError?.response?.message
					|| 'The range value and punches can’t be less than the previous value or greater than the next value';}
			else {errMsg = 'Server error. Please try again Later';}

			openToastr({ message: errMsg, isError: true });
		}
	}, [addError]);

	useEffect(() => {
		if (editError?.response) {
			let errMsg = '';

			if ([400, 422].includes(editError?.response?.status))
			{errMsg
					= editError?.response?.message
					|| 'The range value and punches can’t be less than the previous value or greater than the next value';}
			else {errMsg = 'Server error. Please try again Later';}

			openToastr({ message: errMsg, isError: true });
		}
	}, [editError]);

	const addPunchRange = (range) => {
		const punchRangeList = {
			value: range.value,
			punches: range.punches,
		};

		doAddMultiplePunches(punchRangeList);
	};
	const editPunchRange = (range) => {
		const punchRangeList = {
			value: range.value,
			punches: range.punches,
			id: editedMultiplePunch?.uuid,
		};

		doEditMultiplePunches(punchRangeList);
	};

	const handleNumber = (event) => {
		if (!/[0-9]/.test(event.key)) {
			event.preventDefault();
		}
	};

	const isErrorHidden
		= !isAddError || !isEditError || removeError || isEditLoading || isAddLoading;

	return (
		<Modal
			isModalOpen={isAddEditModalOpen}
			title={multiplePunchesModalTitle}
			onClose={closeModal}
		>
			<Stack>
				<Title title="Punch range" margin="xs" />
				<Input
					type="text"
					pattern="[0-9]"
					onKeyPress={handleNumber}
					inputName="punchRange"
					placeholder="Punch Range (EGP)"
					onChange={onChangePunchRange}
					value={punchRangeInput}
				/>
			</Stack>
			<Stack>
				<Title title="Number of punches" margin="xs" />
				<Input
					type="text"
					pattern="[0-9]"
					onKeyPress={handleNumber}
					inputName="punchesNumber"
					placeholder="Punches Number"
					onChange={onChangePunchNumber}
					value={punchesNumberInput}
				/>
				<Label hide={isErrorHidden} error="red">
					Hint : New range and punches value should be greater than the last
					range.
				</Label>
			</Stack>

			<Grid gap="5">
				<span></span>
				<TwoSideLayout>
					<ButtonWithIcon
						stretch={false}
						m="xxs"
						px="md"
						py="sm"
						onClick={closeModal}
					>
						Cancel
					</ButtonWithIcon>
					<Align align="left">
						<InlineError hide={isErrorHidden} />
						<InlineLoading hide={!isAddLoading && !isEditLoading} />
						<ButtonWithIcon
							stretch={false}
							m="xxs"
							px="md"
							py="sm"
							primary
							onClick={() =>
								handleMultiplePunchesSubmit(punchRangeInput, punchesNumberInput)
							}
						>
							Save
						</ButtonWithIcon>
					</Align>
				</TwoSideLayout>
			</Grid>
		</Modal>
	);
}
