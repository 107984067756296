import React from 'react';
import { useMutation } from 'react-query';

import { ApiRequest } from '@zeal/zeal-lib';
import { useQuery } from '../../App/QueryClient';
import { getLoggedInSession } from '../../App/ACL/useLogin';

async function apiVideos({ queryKey: [, filters] }) {
	return new ApiRequest('v3/gallery')
		.addHeader('Accept', 'application/json')
		.GET();
}

async function apiAddVideo({ ...payload }) {
	const { authorization } = getLoggedInSession();
	let url = 'v3/gallery/video';
	if (payload?.id) {url = `v3/gallery/video/${payload?.id}`;}
	return new ApiRequest(url)
		.addHeader('Accept', 'application/json')
		.addHeader('Content-Type', 'application/json')
		.addHeader('Authorization', authorization)
		.POST(JSON.stringify(payload), true);
}

async function apiDeleteVideo({ ...payload }) {
	const { authorization } = getLoggedInSession();

	return new ApiRequest(`v3/gallery/${payload.id}`)
		.addHeader('Accept', 'application/json')
		.addHeader('Authorization', authorization)
		.addHeader('Content-type', 'application/json')
		.DELETE(JSON.stringify(payload), true);
}

export default function useVideos() {
	const q = useQuery({
		queryFn: apiVideos,
		staleTime: 1000 * 60 * 15, // 15 min
		queryKey: ['z-gallery-videos'],
	});

	React.useDebugValue(q);

	return q;
}

export function useAddVideo(opt) {
	const mutation = useMutation(apiAddVideo);

	const { data, mutate, isSuccess } = mutation;

	React.useEffect(() => {
		if (!isSuccess) {
			return null;
		}

		if (opt.onSuccess) {
			opt.onSuccess();
		}
	}, [data, isSuccess]);

	return {
		...mutation,
		doAddVideo: mutate,
	};
}
export function useDeleteVideo(opt) {
	const mutation = useMutation(apiDeleteVideo);

	const { data, mutate, isSuccess } = mutation;

	React.useEffect(() => {
		if (!isSuccess) {
			return null;
		}

		if (opt.onSuccess) {
			opt.onSuccess();
		}
	}, [data, isSuccess]);

	return {
		...mutation,
		doDeleteVideo: mutate,
	};
}
