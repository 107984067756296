import React, { useReducer, useState } from 'react';
import {
	Align,
	Button,
	ButtonWithIcon,
	Grid,
	Image,
	InlineError,
	InlineLoading,
	Input,
	Modal,
	Stack,
	Textarea,
	Title,
	TwoSideLayout,
} from '@zeal/zeal-ui';

export default function AddEditModal(props) {
	const {
		isEditing,
		isAddEditModalOpen,
		closeAddEditModal,
		handleAddEditSubmit,
		formDispatch,
		formState,
		isError,
		isLoading,
		removeError,
	} = props;

	const [openUploader, setOpenUploader] = useState();

	const modalSubmitLabel = isEditing ? 'Save' : 'Add';

	const modalTitle = isEditing ? 'Edit Photo' : 'Add Photo';

	const handlePhotoUpload = (files) => {
		formDispatch({ photo: files[0] });
	};

	const photoUploader
		= !!formState.photo || !!formState.url ? (
			<Stack>
				<Title title="Preview" margin="xs" />
				<Image
					cover
					rounded
					height="2xl"
					src={
						(formState?.photo && URL.createObjectURL(formState?.photo))
						|| formState?.url
					}
					alt="gallery photo"
				/>
				<ButtonWithIcon
					transparent
					m="xxs"
					px="md"
					py="sm"
					onClick={() => {
						setOpenUploader(Date.now());
					}}
				>
					<Title primary title="Change Photo" size="sm" />
				</ButtonWithIcon>
			</Stack>
		) : (
			<Stack>
				<Title title="Upload Photo" margin="xs" />
			</Stack>
		);

	return (
		<Modal
			isModalOpen={isAddEditModalOpen}
			title={modalTitle}
			onClose={closeAddEditModal}
		>
			<Stack>
				{photoUploader}
				<Input.Upload
					onDropFiles={handlePhotoUpload}
					acceptedFiles="image/*"
					maxFiles="1"
					openUploader={openUploader}
					style={{
						display: formState.photo || formState.url ? 'none' : 'block',
					}}
				/>
				<Stack>
					<Title title="Add Description" margin="xs" />
					<Textarea
						name="description"
						placeholder="Type Something"
						rows="4"
						onChange={(evt) => formDispatch({ description: evt.target.value })}
						value={formState.description}
					/>
				</Stack>
				<Grid gap="5">
					<span></span>
					<TwoSideLayout>
						<ButtonWithIcon
							stretch={false}
							m="xxs"
							px="md"
							py="sm"
							onClick={closeAddEditModal}
						>
							Cancel
						</ButtonWithIcon>
						<Align align="left">
							<InlineError hide={!isError + !removeError} />
							<InlineLoading hide={!isLoading} />
							<ButtonWithIcon
								stretch={false}
								m="xxs"
								px="md"
								py="sm"
								primary
								onClick={handleAddEditSubmit}
							>
								{modalSubmitLabel}
							</ButtonWithIcon>
						</Align>
					</TwoSideLayout>
				</Grid>
			</Stack>
		</Modal>
	);
}
