import React, { Fragment } from 'react';
import {
	Button,
	ButtonWithIcon,
	Card,
	Center,
	Icon,
	InlineError,
	InlineLoading,
	Input,
	Stack,
	Title,
} from '@zeal/zeal-ui';

import { useLogin } from '../../ACL/useLogin';

export default function ForgetPasswordCard(props) {
	const [email, setEmail] = React.useState();

	const { doLogin, isError, isLoading } = useLogin({
		onSuccess: () => {
			const { history, location } = props;

			if (location?.state?.shouldGoBack) {
				const { fromLocation } = location?.state;
				history.push(fromLocation.pathname);
				return null;
			} else {
				history.push('/');
				return null;
			}
		},
	});

	const handleSubmit = React.useCallback(() => {
		props.setEmailSent(true);
	}, [email, doLogin]);

	const handleCancel = () => {
		props.setShowForgotPassword(false);
		return null;
	};

	const forgetPasswordSubtitle = (
		<Fragment>
			Enter your E-mail address and we will send you
			<br />a link to reset your password.
		</Fragment>
	);

	return (
		<div>
			<Card shadow="xl" border={false} p="lg">
				<Center>
					<Title primary title={<Icon name="lock" size="3xl" />} />
				</Center>
				<Center>
					<Stack m="xs">
						<Title
							size="lg"
							titleText={'Forgot your password?'}
							subtitleText={forgetPasswordSubtitle}
						/>
					</Stack>
				</Center>
				<Input
					type="email"
					inputName="email"
					placeholder="Email address"
					onChange={(evt) => setEmail(evt.target.value)}
				/>

				<ButtonWithIcon
					primary
					stretch
					size="md"
					label="Continue"
					disabled={isLoading}
					onClick={handleSubmit}
				/>
				<Button
					size="md"
					label="Cancel"
					disabled={isLoading}
					onClick={handleCancel}
				/>
			</Card>
			<InlineError hide={!isError} />
			<InlineLoading hide={!isLoading} />
		</div>
	);
}
