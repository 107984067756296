import React from 'react';
import { useMutation } from 'react-query';
import { useParams } from 'react-router-dom';

import { ApiRequest } from '@zeal/zeal-lib';
import { useQuery } from '../../App/QueryClient';

async function apiWorkingDays({ queryKey: [, filters] }) {
	return new ApiRequest(`v3/branch/${filters.branchId}/hours`)
		.addHeader('Accept', 'application/json')
		.addHeader('Content-Type', 'application/json')
		.GET();
}

async function apiAddWorkingDays({ ...payload }) {
	return new ApiRequest(`v3/branch/${payload.branchId}/hours`)
		.addHeader('Accept', 'application/json')
		.addHeader('Content-Type', 'application/json')
		.POST(JSON.stringify({ days: payload.body }));
}

export default function useWorkingDays(opt) {
	const { branchId } = useParams();

	const formatDays = opt?.formatDays || ((d) => d);

	const q = useQuery({
		queryFn: apiWorkingDays,
		queryKey: [
			'z-working-days',
			{
				branchId,
			},
		],
		staleTime: Infinity,
	});

	const { data: daysData } = q;

	React.useEffect(() => {
		if (daysData) {
			daysData.data = [...Array(7).keys()].map((dayIndex) => {
				const workingDay = daysData?.data?.find(
					(d) => d.day_index === dayIndex,
				);

				if (workingDay) {return formatDays(workingDay);}

				return { day: dayIndex, selected: false };
			});
		}
	}, [daysData]);

	React.useDebugValue(q);

	return q;
}

export function useAddWorkingDays(opt) {
	const mutation = useMutation(apiAddWorkingDays);

	const { data, mutate, isSuccess, isError } = mutation;

	React.useEffect(() => {
		if (isError) {
			if (opt.onError) {
				opt.onError();
			}
		}

		if (!isSuccess) {
			return null;
		}

		if (opt.onSuccess) {
			opt.onSuccess();
		}
	}, [data, isSuccess, isError]);

	return {
		...mutation,
		doAddDays: mutate,
	};
}
