import React from 'react';
import { Route, Switch } from 'react-router-dom';

import ACLBoundary from '../App/ACL/ACLBoundary';

import AddMenuItem from './AddMenuItem';
import EditMenuItem from './EditMenuItem';
import BranchesMenus from './BranchesMenus';
import Menu from './Menu';

export default function MenuRoutes() {
	return (
		<Switch>
			<ACLBoundary aclUnAuthProps={{ replace: true }} aclGrants={['view:menu']}>
				<Route exact path="/menu" component={BranchesMenus} />

				<Route exact path="/menu/items/add" component={AddMenuItem} />

				<Route exact path="/menu/items/edit" component={EditMenuItem} />

				<Route exact path="/menu/:menuId" component={Menu} />
			</ACLBoundary>
		</Switch>
	);
}
