import React, { useReducer, useState } from 'react';

import {
	Align,
	Button,
	ButtonWithIcon,
	Checkbox,
	Grid,
	Image,
	InlineError,
	InlineLoading,
	Input,
	Modal,
	Select,
	Stack,
	Textarea,
	Title,
	TwoSideLayout,
} from '@zeal/zeal-ui';
import PhotoUploader from './PhotoUploader';
import useRecommendations, {
	useAddAnnou,
	useBromoCode,
} from './data/useRecommendations';

export default function AddEditModal(props) {
	const {
		formState,
		formDispatch,
		isModalOpen,
		closeModal,
		editedRecommendation,
		handleFilesUpload,
		uuid,
		resetForm,
		removeError,
		setRemoveError,
	} = props;
	const [openUploader, setOpenUploader] = useState();
	const [photo, setPhoto] = useState();

	const storiesQuery = useRecommendations();

	const { doAddAnnou, isError, isLoading, status } = useAddAnnou({
		onSuccess: () => {
			storiesQuery.refetch();
			closeModal(false);
			resetForm();
		},
	});

	const addStory = (story) => {
		doAddAnnou(story);
		storiesQuery.refetch();
		setRemoveError(true);
	};

	const HandleOnStorySubmit = async (recommendation, uuid) => {
		let photo;
		if (recommendation.photo) {
			const photoPromise = new Promise((resolve, reject) => {
				const reader = new FileReader();
				reader.readAsDataURL(recommendation.photo);

				reader.onload = async () => {
					resolve(reader.result);
				};
				reader.onerror = function (error) {
					reject(error);
				};
			});
			photo = await photoPromise;
		}
		const formData = {
			media: photo,
			uuid: uuid,
			description: recommendation.description,
		};
		return formData;
	};

	const handleOn = React.useCallback(() => {
		HandleOnStorySubmit(formState, uuid).then((formData) => {
			addStory(formData);
		});
	}, [formState, uuid]);

	const modalTitle = editedRecommendation
		? 'Edit Recommendation'
		: 'Add Recommendation';

	const modalSubmitLabel = editedRecommendation ? 'Edit' : 'Add';

	return (
		<Modal isModalOpen={isModalOpen} title={modalTitle} onClose={closeModal}>
			<Stack>
				<PhotoUploader
					setOpenUploader={setOpenUploader}
					photo={formState?.photo}
					media={formState?.media}
				/>
				<Input.Upload
					onDropFiles={handleFilesUpload}
					acceptedFiles="image/*"
					maxFiles="1"
					openUploader={openUploader}
					label="recommended size ratio (1:1)"
					style={{
						display: formState.photo || formState.media ? 'none' : 'block',
					}}
				/>
				<Stack>
					<Title title="Add Description" margin="xs" />
					<Textarea
						name="description"
						placeholder="Type Something"
						rows="4"
						onChange={(evt) => formDispatch({ description: evt.target.value })}
						value={formState.description}
					/>
				</Stack>
				<Grid gap="5">
					<span />
					<TwoSideLayout>
						<ButtonWithIcon
							stretch={false}
							m="xxs"
							px="md"
							py="sm"
							onClick={closeModal}
						>
							Cancel
						</ButtonWithIcon>
						<Align align="left">
							<InlineError hide={!isError + !removeError} />
							<InlineLoading hide={!isLoading} />
							<ButtonWithIcon
								stretch={false}
								m="xxs"
								px="md"
								py="sm"
								primary
								onClick={handleOn}
							>
								{modalSubmitLabel}
							</ButtonWithIcon>
						</Align>
					</TwoSideLayout>
				</Grid>
			</Stack>
		</Modal>
	);
}
