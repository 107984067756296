import {
	Align,
	BasicTable,
	Button,
	ButtonWithIcon,
	Card,
	Center,
	Header,
	Icon,
	InlineEmpty,
	InlineLoading,
	Stack,
	Title,
	ToggleButton,
} from '@zeal/zeal-ui';
import React, { useEffect, useReducer, useState } from 'react';
import ACLBoundary from '../App/ACL/ACLBoundary';

import AppContainer from '../App/AppContainer';
import AddModal from './AddModal';

import usePromocodes, { useDeletePromocode } from './data/usePromocodes';
import DeletePromocodeModal from './DeletePromocodeModal';

export default function Promocodes() {
	var today = new Date();
	var nextweek = new Date(
		today.getFullYear(),
		today.getMonth(),
		today.getDate() + 7,
	);
	const PromocodeQuery = usePromocodes();
	const [isAddModalOpen, setIsAddModalOpen] = useState(false);
	const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
	const [removeError, setRemoveError] = React.useState(true);
	const [uuid, setUuid] = React.useState('');
	const overview = usePromocodes();

	const { data: promocodesData, search } = PromocodeQuery;

	const { doDeletePromocode, isError, isLoading } = useDeletePromocode({
		onSuccess: () => {
			refetchPromocodesData();
			setIsDeleteModalOpen(false);
		},
	});
	const initialFormValues = {
		type: '',
		promocode: '',
		code: '',
		quota: '',
		expireDate: new Date(),
		when: '',
		reward: '',
		amount: '',
		min_payment: '',
		max_discount: '',
		name: '',
	};

	const addFormReducer = (prevState, newState) => ({ ...prevState, ...newState });

	const [formState, formDispatch] = useReducer(
		addFormReducer,
		initialFormValues,
	);

	const openPromocodeModal = () => {
		setIsAddModalOpen(true);
	};

	const addPromocodeBtnClick = () => {
		resetForm();
		openPromocodeModal();
	};

	const resetForm = () => {
		formDispatch(initialFormValues);
	};
	const refetchPromocodesData = () => {
		PromocodeQuery.refetch();
	};

	const deletePromocode = (uuid) => {
		doDeletePromocode({ uuid });
		setRemoveError(true);
	};

	const handlePromocodeDelete = (item) => {
		setUuid(item?.original?.uuid);
		setIsDeleteModalOpen(true);
	};

	const breadcrumbs = [
		{ name: 'Promo Codes', to: '/promocodes', icon: 'badgePercent' },
	];
	const actionsCell = ({ row }) => (
		<Align align="right" gap="1">
			<ACLBoundary>
				<Button
					transparent
					stretch={false}
					p="none"
					m="none"
					mx="sm"
					onClick={() => handlePromocodeDelete(row)}
					aclUnAuthProps={{ disabled: true }}
					aclGrants={['control:promocode']}
				>
					<Icon
						rounded
						bg="danger"
						name="trashAlt"
						size="lg"
						p="xs"
						margin="none"
					/>
				</Button>
			</ACLBoundary>
		</Align>
	);
	function PromocodesTable(props) {
		const { data, isLoading } = props;
		const tableColumns = [
			{
				Header: 'promocode',
				accessor: 'code',
				Cell: handleNoneData,
			},
			{
				Header: 'Action',
				accessor: 'action.rewardType',
				Cell: handleRewardName,
			},
			{
				Header: 'Quota',
				accessor: 'quota',
				Cell: handleNoneData,
			},
			{
				Header: 'Active Users',
				accessor: 'assignee.name',
				Cell: temporarilyHandler,
			},
			{ Header: '', accessor: 'actions', Cell: actionsCell },
		];

		const promocodesTable = (
			<div>
				<BasicTable
					shadow={false}
					data={data || []}
					columns={tableColumns}
					headerPadding="lg"
					cellPadding="lg"
					isLoading={overview?.isLoading}
					isEmpty={!data?.length}
					loadingContent={
						<InlineLoading className="w-full py-10 !justify-center" />
					}
					emptyContent={
						<InlineEmpty className="w-full py-10 !justify-center" />
					}
				/>
			</div>
		);

		return promocodesTable;
	}

	function EmptyState(props) {
		const emptyState = !props?.data?.length > 0 && !props.isLoading && (
			<Stack m="lg">
				<Center>
					<Title
						title="No Promo Codes Here Yet"
						size="md"
						subtitle="Once promo Codes are added will be shown here"
					/>
				</Center>
			</Stack>
		);
		return emptyState;
	}
	function handleNoneData({ value }) {
		if (value == '0') {
			return value;
		}
		if (!value) {
			return '__';
		}

		return value;
	}
	function handleRewardName({ value }) {
		if (value == '0') {
			return value;
		}
		if (!value) {
			return '__';
		}
		return value.replace('_', ' ');
	}

	function temporarilyHandler({ value }) {
		if (value == '0') {
			return value;
		}
		if (!value) {
			return '0';
		}

		return value;
	}
	return (
		<AppContainer protected breadcrumbs={breadcrumbs}>
			<div>
				<Header
					padding="sm"
					startContent={<span></span>}
					endContent={
						<ACLBoundary>
							<ButtonWithIcon
								primary
								label="Add Promocode"
								btnIcon="plus"
								size="xs"
								m="none"
								onClick={addPromocodeBtnClick}
								aclUnAuthProps={{ disabled: true }}
								aclGrants={['control:promocode']}
							/>
						</ACLBoundary>
					}
				/>
			</div>
			<Card p="none" m="md">
				<EmptyState
					data={promocodesData?.data || []}
					isLoading={overview?.isLoading}
				></EmptyState>

				<PromocodesTable
					data={promocodesData?.data || []}
					isLoading={overview?.isLoading}
				></PromocodesTable>
			</Card>
			<DeletePromocodeModal
				isDeleteModalOpen={isDeleteModalOpen}
				closeDeleteModal={() => {
					setIsDeleteModalOpen();
					setRemoveError(false);
				}}
				deletePromocode={deletePromocode}
				isError={isError}
				isLoading={isLoading}
				removeError={removeError}
				uuid={uuid}
			/>

			<AddModal
				isModalOpen={isAddModalOpen}
				closeModal={() => {
					setIsAddModalOpen(false);
					setRemoveError(false);
				}}
				formState={formState}
				resetForm={resetForm}
				formDispatch={formDispatch}
				removeError={removeError}
				setRemoveError={setRemoveError}
			/>
		</AppContainer>
	);
}
