import styled from 'styled-components';
import { Header } from '@zeal/zeal-ui';
import { motion } from 'framer-motion/dist/framer-motion';

export const AppHeader = styled(Header)`
	grid-area: breadcrumbs;
	padding-left: 0 !important;
	padding-right: 0 !important;
	padding-top: 2.1em !important;
	padding-bottom: 2.1em !important;
`;

export const Content = styled.div`
	grid-area: appContent;
	//height: calc(100vh - var(--maxin-headerbar-height));
	max-height: calc(100vh - var(--main-headerbar-height));
	overflow-y: auto;
	margin: 0 !important;
	margin-top: 0.75em !important;
	padding: 0 !important;
`;

export const CollapseButton = styled.div`
	position: absolute;
	cursor: pointer;
	top: 1.1em;
	display: flex;
	height: 1.7em;
	width: 1.7em;
	border-radius: 50%;
	border: 1px solid rgba(229, 231, 235, var(--tw-border-opacity));
	box-shadow: 0 0 3px rgba(145, 147, 164, 0.16);
	background: white;
	transform: scale(0.75);
`;

export const MainGrid = styled(motion.div)`
	--main-headerbar-height: 3rem;
	display: grid;
	grid-template-columns: var(--main-navbar-width) calc(
			100vw - var(--main-navbar-width)
		);
	grid-template-rows: var(--main-headerbar-height) calc(
			100vh - var(--main-headerbar-height)
		);
	grid-template-areas:
		'menu breadcrumbs'
		'menu appContent';
`;
