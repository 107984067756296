import React from 'react';
import {
	Align,
	Button,
	ButtonWithIcon,
	Grid,
	InlineError,
	InlineLoading,
	Modal,
	Stack,
	Title,
	TwoSideLayout,
} from '@zeal/zeal-ui';

export default function DeletePromocodeModal(props) {
	const {
		isDeleteModalOpen,
		closeDeleteModal,
		deletePromocode,
		isError,
		uuid,
		isLoading,
		removeError,
	} = props;

	return (
		<Modal
			isModalOpen={isDeleteModalOpen}
			title="Delete Promocode"
			onClose={closeDeleteModal}
		>
			<Stack p="none">
				<Title title="Are you sure you want to delete this promocode?" />

				<Grid gap="5">
					<span></span>
					<TwoSideLayout>
						<ButtonWithIcon
							stretch={false}
							m="xxs"
							px="md"
							py="sm"
							onClick={closeDeleteModal}
						>
							Cancel
						</ButtonWithIcon>
						<Align align="left">
							<InlineError hide={!isError + !removeError} />
							<InlineLoading hide={!isLoading} />

							<ButtonWithIcon
								secondary
								stretch={false}
								m="xxs"
								px="md"
								py="sm"
								onClick={() => deletePromocode(uuid)}
							>
								Delete
							</ButtonWithIcon>
						</Align>
					</TwoSideLayout>
				</Grid>
			</Stack>
		</Modal>
	);
}
