import React from 'react';

import { ApiRequest } from '@zeal/zeal-lib';
import { useQuery } from '../../App/QueryClient';
import { useQueryString } from '../../App/useQueryString';

async function apiAnalyticsPeakHours({ queryKey: [, filters] }) {
	return new ApiRequest('v3/analytics/peak')
		.addHeader('Accept', 'application/json')
		.addQuery('bussiness_uuid', filters?.vendorId)
		.addQuery('from', filters?.date?.[0])
		.addQuery('to', filters?.date?.[1])
		.GET()
		.then(({ data }) => data);
}

export default function useAnalyticsPeakHours() {
	const [queryState] = useQueryString();

	const q = useQuery({
		queryFn: apiAnalyticsPeakHours,
		staleTime: 1000 * 60 * 1, // 1 min
		queryKey: [
			'z-analytics-visits-peakHours',
			{
				date: queryState?.date,
			},
		],
	});

	React.useDebugValue(q);

	return q;
}
