import React, { useEffect, useState } from 'react';
import { cloneDeep, orderBy } from 'lodash-es';

import {
	Align,
	Button,
	Card,
	Grid,
	Header,
	InlineError,
	InlineLoading,
	Label,
	Select,
	Stack,
} from '@zeal/zeal-ui';
import CategoriesCard from '../components/categoriesCard';
import openToastr from '../../App/Toastr/openToastr';
import useCategories, { useSubmitCategory } from '../data/useCategories';
import { useProfile } from '../data/useProfile';

export default function Categories() {
	const profileQuery = useProfile();
	const { data: incomeData } = profileQuery;

	const refetchProfile = () => {
		profileQuery.refetch();
	};
	const [cardsData, setCardsData] = React.useState();
	const [cardsDataAdded, setCardsDataAdded] = React.useState(
		incomeData?.data?.tags,
	);
	const [type, setType] = useState(incomeData?.data?.category);
	const [categoryId, setCategoryId] = useState(incomeData?.data?.category?.id);
	const [hasSelect, setHasSelect] = useState(false);
	const [tagIds, setTagIds] = useState([]);
	const [maximum, setMaximum] = useState();
	const { data } = useCategories();

	useEffect(() => {
		setType(incomeData?.data?.category);
		setCardsDataAdded(incomeData?.data?.tags);
		setCategoryId(incomeData?.data?.category?.id);
	}, [incomeData]);

	const handleAddTag = (tag) => {
		setCardsDataAdded((prev) => {
			let newCards = cloneDeep(prev);
			if (cardsDataAdded?.length < 6) {
				newCards.push(tag);
				setCardsData(cardsData.filter((item) => item != tag));
			} else {setMaximum('You have reached the maximum');}
			for (let index = 0; index < newCards.length; index++) {
				tagIds.push(newCards[index].id);
			}
			return newCards;
		});
	};

	const handleDeleteTag = (index, tag) => {
		setMaximum('');
		setCardsDataAdded((prev) => {
			if (hasSelect) {cardsData.push(tag);}
			let newCards = cloneDeep(prev);
			newCards.splice(index, 1);
			for (let index = 0; index < newCards.length; index++) {
				tagIds.push(newCards[index].id);
			}
			return newCards;
		});
	};

	const { doAddCategory, isError, isLoading, isSuccess, error, status }
		= useSubmitCategory({
			onSuccess: () => {
				openToastr({
					message: 'Categories date changed',
				});
				refetchProfile();
				setCardsData([]);
				setHasSelect(false);
			},
			onError: () => {
				let errMsg = '';
				if ([400, 422].includes(error?.response?.status))
				{errMsg = 'Failed to change Category';}
				else {errMsg = 'Server error. Please try again Later';}
				openToastr({ message: errMsg, isError: true });
			},
		});

	const handleSubmit = React.useCallback(() => {
		let uniqueIds = [...new Set(tagIds)];
		let categoryData = {
			category_id: categoryId,
			tag_ids: uniqueIds,
		};
		doAddCategory(categoryData);
	}, [categoryId, doAddCategory]);

	let selectedTags = cardsData?.length > 0 && (
		<Stack m="none" p="none">
			<CategoriesCard
				className={cardsData?.length == 0 && 'hidden'}
				my="md"
				tags={cardsData}
				handleAddTag={handleAddTag}
				cardIndex={cardsData?.length - 1}
			/>
		</Stack>
	);

	let emptyState = (
		<Stack gap="xl" m="none" p="none">
			<Card
				p="none"
				m="none"
				shadow="none"
				className={cardsDataAdded?.length <= 0 && 'w-2/5'}
				borderColor={maximum && 'primary'}
			>
				<Label p="xs" m="xs" className={cardsDataAdded?.length > 0 && 'hidden'}>
					Add Tags
				</Label>
				<CategoriesCard
					tags={cardsDataAdded}
					handleDeleteTag={handleDeleteTag}
					m="sm"
				/>
			</Card>

			<Header
				padding="none"
				startContent={
					<Align align="left" gap="1">
						<Label p="none" m="none">
							Up to 6 tags
						</Label>
					</Align>
				}
				endContent={
					<Align align="right" gap="1">
						<Label p="none" m="none" textColor="primary">
							{maximum}
						</Label>
					</Align>
				}
			/>
		</Stack>
	);
	const handleAfterSelectTag = (val) => {
		setType(val?.name);
		setCardsData(val?.tags);
		setCardsDataAdded([]);
		setCategoryId(val?.id);
		setHasSelect(true);
	};
	return (
		<>
			<Grid cols="5" gap="3">
				<div className="col-span-2">
					<Label textColor="basic">Category</Label>
					<Select
						inputName="Text"
						placeholder={type ? type : 'Add Tags'}
						startHint="Required"
						options={data?.data}
						selected={type}
						onChange={(val) => handleAfterSelectTag(val)}
					/>
				</div>
			</Grid>
			<Grid cols="1" gap="3">
				<Label textColor="basic" mt="md">
					Other Info
				</Label>
				{emptyState}
			</Grid>
			<Grid cols="1" gap="3">
				{selectedTags}
			</Grid>
			<Grid cols="3" gap="3">
				<Align align="left" className="mt-28">
					<Button
						size="sm"
						secondary
						label="Save Changes"
						onClick={handleSubmit}
					/>
					<Stack mx="md">
						<InlineLoading hide={!isLoading} />
						<InlineError hide={!isError} />
					</Stack>
				</Align>
			</Grid>
		</>
	);
}
