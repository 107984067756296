import React from 'react';
import {
	Button,
	Center,
	InlineLoading,
	Label,
	Select,
	Stack,
} from '@zeal/zeal-ui';
import ACLBoundary from '../../App/ACL/ACLBoundary';

export default function VisitsActions(props) {
	const {
		isAllSelected,
		toggleAllSelected,
		exportTransaction,
		isExportLoading,
	} = props;

	const exportButtonContent = (
		<Center>
			{isExportLoading ? (
				<InlineLoading textColor="basic" label=" " />
			) : (
				<Label textColor="basic" my="xs">
					Export
				</Label>
			)}
		</Center>
	);

	return (
		<Stack p="none" m="md" marginY={false} mr="none">
			<Stack row wrap p="none" m="sm">
				<Center wrap horizontal={false} gap="3">
					<Label size="xs" textColor="basic">
						Actions
					</Label>
					<ACLBoundary
						aclGrants={['create:reports']}
						aclUnAuthProps={{ disabled: true }}
					>
						<Select
							pointer
							hideIcon
							hideOutline
							px="md"
							state="light"
							margin="none"
							placeholder={exportButtonContent}
							onChange={exportTransaction}
							options={[
								{ value: 'xlsx', label: 'xlsx' },
								{ value: 'csv', label: 'csv' },
							]}
						/>
					</ACLBoundary>
				</Center>
			</Stack>
		</Stack>
	);
}

function SelectAllButton(props) {
	const { isAllSelected, toggleAllSelected } = props;
	return (
		<Stack p="none" ml="none" marginY={false} m="lg">
			{isAllSelected ? (
				<Button
					size="sm"
					secondary
					onClick={() => toggleAllSelected(false)}
					rounded="md"
				>
					<Label size="xs" textColor="default">
						Unselect all
					</Label>
				</Button>
			) : (
				<Button size="sm" onClick={() => toggleAllSelected(true)} rounded="md">
					<Label size="xs" textColor="basic">
						Select all
					</Label>
				</Button>
			)}
		</Stack>
	);
}
