import React from 'react';

import { Input, Stack, Title, TwoSideLayout } from '@zeal/zeal-ui';

import { renderErrorMessage } from './ErrorMessage';

export default function BranchInfo(props) {
	const { formDispatch, formState, errMsgs, isError } = props;

	const handleFilesUpload = (evt) => {
		const files = evt.target.files;
		formDispatch({ photo: files[0] });
	};

	return (
		<Stack m="md" p="none">
			<Title title="Branch Info" size="md" />
			<TwoSideLayout>
				<Stack m="md" mb="none" p="none" marginX={false}>
					<Title muteSubtitle={false} subtitle="Branch Name" size="md" />
					<Input
						type="text"
						inputName="branchName"
						placeholder="Enter Branch Name"
						onChange={(evt) => formDispatch({ name: evt.target.value })}
						value={formState.name}
						startHint={renderErrorMessage('name', isError, errMsgs)}
					/>
				</Stack>
			</TwoSideLayout>
			<TwoSideLayout>
				<Stack>
					<Title muteSubtitle={false} subtitle="Phone Number" size="md" />
					<Input
						type="text"
						inputName="phone"
						placeholder="Branch Phone Number"
						onChange={(evt) => formDispatch({ phone: evt.target.value })}
						value={formState.phone}
						startHint={renderErrorMessage('phone', isError, errMsgs)}
					/>
				</Stack>
			</TwoSideLayout>
			<TwoSideLayout>
				<Stack>
					<Title muteSubtitle={false} subtitle="Branch Location" size="md" />
					<Input
						type="text"
						inputName="map_url"
						placeholder="Location’s Google Maps URL"
						onChange={(evt) =>
							formDispatch({
								map_url: evt.target.value,
							})
						}
						value={formState.map_url}
						startHint={renderErrorMessage('map_url', isError, errMsgs)}
					/>
				</Stack>
			</TwoSideLayout>
			<TwoSideLayout>
				<Stack>
					<Title
						muteSubtitle={false}
						subtitle="Branch Photo"
						size="md"
						margin="xs"
					/>
					<Input.Uploader
						inputName="photo"
						placeholder="Upload a branch photo"
						onChange={handleFilesUpload}
						value={formState.photo?.name || formState.photo}
						margin="none"
						startHint={renderErrorMessage('photo', isError, errMsgs)}
					/>
				</Stack>
			</TwoSideLayout>
		</Stack>
	);
}
