import React from 'react';
import { useQueryString } from '../../App/useQueryString';

import { ApiRequest } from '@zeal/zeal-lib';
import { useQuery } from '../../App/QueryClient';

async function apiExports({ queryKey: [, filters] }) {
	return new ApiRequest('visits/export')
		.addHeader('Accept', 'application/json')
		.addQuery('page', filters?.page)
		.GET()
		.then((payload) => ({
			data: payload?.data,
			pageNum: payload?.meta.current_page,
			pagesCount: payload?.meta.last_page,
		}));
}

export default function useExports() {
	const [queryState] = useQueryString();
	const { page } = queryState;

	const q = useQuery({
		queryFn: apiExports,
		staleTime: Infinity,
		queryKey: [
			'z-exports',
			{
				page,
			},
		],
	});

	const pagination = usePagination(q?.data || {});

	React.useDebugValue(q);

	return { ...q, ...pagination, data: q?.data?.data };
}

export function usePagination({ pageNum, pagesCount }) {
	const [queryState] = useQueryString();

	if (queryState?.page != pageNum) {
		console.error(
			new Error(
				`Pagination mismatch query vs api, expected ${queryState?.page} found ${pageNum}`,
			),
		);
	}

	const pagesList = Array.from(
		new Set([
			1,
			2,
			pageNum - 1,
			pageNum,
			pageNum + 1,
			pagesCount - 1,
			pagesCount,
		]),
	).reduce((a, i, ndx, arr) => {
		const prv = arr[ndx - 1];
		const isCurrent = pageNum == i;

		if (i === 0) {
			i = prv + 1;
		}

		if (i - prv > 1) {
			a.push({ dff: i - prv, isMany: true });
		}

		if (isNaN(i) || i > pagesCount) {
			return a;
		}

		a.push({ num: i, isCurrent });
		return a;
	}, []);

	return {
		pagesList,
		pagesCount,
		page: queryState?.page,
	};
}
