import { Center, InlineLoading, Stack } from '@zeal/zeal-ui';
import React from 'react';
import AppContainer from '../App/AppContainer';

import LoyaltyPoints from './pages/LoyaltyPoints/LoyaltyPoints';
import LoyaltyPointsCashback from './pages/LoyaltyPointsSystem/LoyaltyPointsSystem';
import LoyaltyRewards from './pages/LoyaltyRewards/LoyaltyRewards';
import LoyaltySpecifics from './pages/LoyaltySpecifics/LoyaltySpecifics';
import useRewardModel from './pages/SetReward/data/useSetRewardModel';
import SetReward from './pages/SetReward/SetReward';

export default function Rewards() {
	const rewardModelQuery = useRewardModel();

	const { data, isLoading } = rewardModelQuery;

	const model = Number(data?.model);

	if (isLoading)
	{return (
		<AppContainer>
			<Center>
				<InlineLoading />
			</Center>
		</AppContainer>
	);}

	switch (model) {
	case 1:
		return <LoyaltyRewards />;
	case 2:
		return <LoyaltyPoints />;
	case 3:
		return <LoyaltySpecifics />;
	case 4:
		return <LoyaltyPointsCashback />;
	default:
		return <SetReward rewardModelQuery={rewardModelQuery} />;
	}
}
