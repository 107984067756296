import React from 'react';
import toast from 'react-hot-toast';

import { Toastr } from '@zeal/zeal-ui';

/**
 * Creates and opens new toast.
 * @param {{message: string, isError: boolean}} props - Props passed to Created Toast
 * @returns {number | string} Created toast Id
 */
export default function openToastr(props) {
	return toast.custom((t) => (
		<Toastr {...props} onClick={() => toast.dismiss(t?.id)} />
	));
}
