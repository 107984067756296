import React from 'react';
import { useMutation } from 'react-query';
import { ApiRequest } from '@zeal/zeal-lib';

import { useQuery } from '../../../../App/QueryClient';

async function apiLoyaltySystems() {
	return new ApiRequest('vouchers')
		.addHeader('Accept', 'application/json')
		.GET();
}

async function apiAddVoucher({ ...payload }) {
	return new ApiRequest('vouchers')
		.addHeader('Accept', 'application/json')
		.addHeader('Content-Type', 'application/json')
		.POST(JSON.stringify(payload));
}

async function apiEditVoucher({ ...payload }) {
	return new ApiRequest(`vouchers/${payload?.voucherId}`)
		.addHeader('Accept', 'application/json')
		.addHeader('Content-Type', 'application/json')
		.PUT(JSON.stringify(payload));
}

async function apiDeleteVoucher({ ...payload }) {
	return new ApiRequest(`vouchers/${payload?.voucherId}`)
		.addHeader('Accept', 'application/json')
		.addHeader('Content-Type', 'application/json')
		.DELETE();
}

export function useAddVoucher(opt) {
	const mutation = useMutation(apiAddVoucher);

	const { data, mutate, isSuccess } = mutation;

	React.useEffect(() => {
		if (!isSuccess) {
			return null;
		}

		if (opt.onSuccess) {
			opt.onSuccess();
		}
	}, [data, isSuccess]);

	return {
		...mutation,
		doAddVoucher: mutate,
	};
}

export function useEditVoucher(opt) {
	const mutation = useMutation(apiEditVoucher);

	const { data, mutate, isSuccess } = mutation;

	React.useEffect(() => {
		if (!isSuccess) {
			return null;
		}

		if (opt.onSuccess) {
			opt.onSuccess();
		}
	}, [data, isSuccess]);

	return {
		...mutation,
		doEditVoucher: mutate,
	};
}

export function useDeleteVoucher(opt) {
	const mutation = useMutation(apiDeleteVoucher);

	const { data, mutate, isSuccess } = mutation;

	React.useEffect(() => {
		if (!isSuccess) {
			return null;
		}

		if (opt.onSuccess) {
			opt.onSuccess();
		}
	}, [data, isSuccess]);

	return {
		...mutation,
		doDeleteVoucher: mutate,
	};
}

export default function useLoyaltySystems() {
	const q = useQuery({
		queryFn: apiLoyaltySystems,
		staleTime: Infinity,
		queryKey: ['z-loyalty-system-vouchers'],
	});

	React.useDebugValue(q);

	return q;
}
