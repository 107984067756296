import React, { useState } from 'react';

import {
	Button,
	Card,
	Label,
	MainStackWithIcon,
	SizedBox,
	Stack,
} from '@zeal/zeal-ui';
import renderRelativeDatetime from './renderRelativeDatetime';

export default function StoriesCards({
	storiesData,
	ActionsComp,
	media,
	...props
}) {
	return (
		(storiesData?.data || []).length > 0
		&& storiesData?.data?.map((item) => (
			<Stack m="xs">
				<Card p="none" display="contents">
					<Button m="none" p="none" stretch display="contents" fullHight>
						<MainStackWithIcon
							icon={ActionsComp(item)}
							onClick={console.log(item?.media)}
							xPosition="right"
							yPosition="top"
							iconPadding="none"
							pointer
							fillContainer={false}
							primary={props.isNewlyCreated}
						>
							<Label
								xPosition="left"
								yPosition="bottom"
								textColor="default"
								iconName="eye"
								children={item?.views ? item?.views : '0 views'}
							/>
							<Label
								xPosition="right"
								yPosition="bottom"
								textColor="default"
								p="xs"
								children={renderRelativeDatetime(item?.created_at)}
							/>
							<Stack p="none">
								<SizedBox height="!h60">
									<img
										src={
											(item?.photo && URL.createObjectURL(item?.photo))
											|| item?.media
										}
										alt="card"
									/>
								</SizedBox>
							</Stack>
						</MainStackWithIcon>
					</Button>
				</Card>
			</Stack>
		))
	);
}
