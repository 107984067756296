import React from 'react';
import { Route, Switch } from 'react-router-dom';

import ACLBoundary from '../App/ACL/ACLBoundary';

import OrderDetails from './OrderDetails';
import Orders from './Orders';

export default function OrdersRoutes() {
	return (
		<Switch>
			<ACLBoundary
				aclUnAuthProps={{ replace: true }}
				aclGrants={['view:orders']}
			>
				<Route exact path="/orders" component={Orders} />
				<Route path="/orders/:orderId" component={OrderDetails} />
			</ACLBoundary>
		</Switch>
	);
}
