import React, { useState } from 'react';
import {
	Button,
	ButtonWithIcon,
	Grid,
	Icon,
	MainStackWithAside,
	Stack,
} from '@zeal/zeal-ui';
import AddEditModal from '../components/CreateStory';
import useStories, { useBranch, useDeleteStory } from '../data/useStories';
import ACLBoundary from '../../App/ACL/ACLBoundary';
import StoriesEmptyState from './StoriesEmptyState';
import DeleteStoryModal from './DeleteStoryModal';
import StoriesCards from './StoriesCards';

export default function Stories({ ...props }) {
	var today = new Date();
	var nextweek = new Date(
		today.getFullYear(),
		today.getMonth(),
		today.getDate() + 7,
	);
	const [isAddEditModalOpen, setIsAddEditModalOpen] = useState(false);
	const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
	const [storiesData, setStoriesData] = useState([]);
	const [photo, setPhoto] = useState();
	const [media, setMedia] = useState();
	const [branch, setBranch] = useState(null);
	const [expireDate, setExpireDate] = useState(nextweek);
	const [selected, setSelected] = useState(false);
	const [uuid, setUuid] = useState();
	const { data: branchsList } = useBranch();
	const [branch_uuid, setBranchUuid] = React.useState();
	const [removeError, setRemoveError] = React.useState(true);

	const optionList = [];

	const optionsHandel = () => {
		branchsList?.data?.length > 0
			&& branchsList?.data.map((d) =>
				optionList.push({ label: d.name, value: d.uuid }),
			);
	};

	const storiesQuery = useStories();
	const { data } = storiesQuery;

	const { doDeleteStory, isError, isLoading, isSuccess, status }
		= useDeleteStory({
			onSuccess: () => {
				storiesQuery.refetch();
				setIsDeleteModalOpen(false);
			},
		});
	React.useEffect(() => {
		setStoriesData(data);
	}, [data]);

	const openStoryModal = () => {
		setIsAddEditModalOpen(true);
	};

	const addStoryBtnClick = () => {
		resetForm();
		openStoryModal();
	};

	const handleStoryEdit = (item) => {
		openStoryModal();
		setPhoto('');
		setMedia(item?.media);
		setBranch(item?.branch?.name);
		setUuid(item?.uuid);
		setExpireDate('');
		setSelected(item?.expires_in);
		setBranchUuid(item?.branch?.uuid);
	};

	const handleOpenDeleteModal = () => {
		setIsDeleteModalOpen(true);
	};

	const handleDelete = (uuid) => {
		doDeleteStory({ uuid });
	};

	const handleFilesUpload = (files) => {
		setMedia(files[0]);
		setPhoto(files[0]);
	};

	const resetForm = () => {
		setPhoto('');
		setExpireDate(nextweek);
		setBranch('');
		setMedia('');
		setUuid('');
	};

	const ActionsComp = (item) => (
		<Stack row>
			<ACLBoundary>
				<Button
					transparent
					stretch={false}
					p="none"
					m="none"
					onClick={() => handleStoryEdit(item)}
					aclUnAuthProps={{ disabled: true }}
					aclGrants={['control:stories']}
				>
					<Icon bg="disabled" rounded name="edit" size="lg" p="xs" />
				</Button>
				<Button
					transparent
					stretch={false}
					p="none"
					m="none"
					onClick={() => {
						handleOpenDeleteModal(item);
						setUuid(item.uuid);
					}}
					aclUnAuthProps={{ disabled: true }}
					aclGrants={['control:stories']}
				>
					<Icon
						rounded
						bg="danger"
						name="trashAlt"
						size="lg"
						p="xs"
						margin="none"
					/>
				</Button>
			</ACLBoundary>
		</Stack>
	);
	const handleCloseModal = () => {
		setIsAddEditModalOpen(false);
		setRemoveError(false);
	};
	return (
		<MainStackWithAside
			fullWidth
			toolbarEnd={
				<ACLBoundary>
					<ButtonWithIcon
						primary
						label="Add Story"
						btnIcon="plus"
						size="xs"
						m="none"
						onClick={addStoryBtnClick}
						aclUnAuthProps={{ disabled: true }}
						aclGrants={['control:stories']}
					/>
				</ACLBoundary>
			}
		>
			<Grid cols="4" gap="3">
				<StoriesCards storiesData={storiesData} ActionsComp={ActionsComp} />
			</Grid>
			<StoriesEmptyState
				data={storiesData?.data}
				addStoryBtnClick={addStoryBtnClick}
			/>
			<AddEditModal
				isModalOpen={isAddEditModalOpen}
				closeModal={handleCloseModal}
				handleFilesUpload={handleFilesUpload}
				photo={photo}
				uuid={uuid}
				branch={branch}
				media={media}
				resetForm={resetForm}
				selected={selected}
				branch_uuid={branch_uuid}
				onBranchChange={setBranch}
				setBranchUuid={setBranchUuid}
				optionsHandel={optionsHandel}
				expireDate={expireDate}
				setExpireDate={setExpireDate}
				optionList={optionList}
				removeError={removeError}
				setRemoveError={setRemoveError}
			/>
			<DeleteStoryModal
				isDeleteModalOpen={isDeleteModalOpen}
				closeDeleteModal={() => setIsDeleteModalOpen(false)}
				handleDelete={handleDelete}
				uuid={uuid}
				isError={isError}
				isLoading={isLoading}
			/>
		</MainStackWithAside>
	);
}
